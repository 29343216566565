import PropTypes from "prop-types";
import { Config, useDebug } from "ripple";
import { useIsMobileVersion } from "../../hooks/use-is-mobile-version";
import { BottomPillsRoot, Inner, Outer } from "./styled";

const ProgressPills = ({ game, activeNode, ...rest }) => {
  const debug = useDebug();
  const isMobileVersion = useIsMobileVersion();
  return (
    <BottomPillsRoot {...rest} $hidden={!debug && isMobileVersion}>
      {game?.children.map((question) => {
        const color = question.parent.wantedText("Color")?.trim();
        return (
          <Outer disabled={!Config.testing} key={question.id} color={color} navigate={{ node: question }}>
            <Inner color={color} active={question.id === activeNode?.id} />
          </Outer>
        );
      })}
    </BottomPillsRoot>
  );
};

ProgressPills.propTypes = {
  game: PropTypes.object,
  activeNode: PropTypes.object,
};

export default ProgressPills;
