import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Audio, DragPreview, resource, useData } from "ripple";
import { GoodImage, WrongImage } from "../../../common";
import { useIsPortrait } from "../../../hooks/use-is-portrait";
import {
  Card,
  CardBar,
  CardDropContainer,
  CardDropzone,
  CardDropzoneContainer,
  CardImage,
  CardSpeakerButton,
  CardText,
  CompletedOverlay,
  Content,
  DragQuestionRoot,
  DraggableContainer,
  DraggingDroppableImage,
  DroppableCard,
  DroppableCardImage,
  Droppables,
  QuestionMarkImage,
} from "./styled";

const DragQuestion = ({ onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const isPortrait = useIsPortrait();

  const [droppedNode, setDroppedNode] = useState(null);
  const [wrongDrop, setWrongDrop] = useState(false);
  const [goodDrop, setGoodDrop] = useState(false);
  const [dragging, setDragging] = useState(false);

  const audio = node.wantedMedia("Audio", "Audio");
  const color = node.parent.wantedText("Color")?.trim();

  const goodAnswer = node.children.find((node) => node.settings.Answer === "good");

  const onDragStart = useCallback(() => {
    setDragging(true);
  }, []);

  const onTouch = useCallback(() => Audio.discrete("effects").play(resource("audio/ClicDrag.mp3")), []);

  const onDragEnd = useCallback(() => {
    Audio.discrete("effects").play(resource("audio/DragRelease.mp3"));
    setDragging(false);
  }, []);

  const createRenderDroppableForegroundLayer = (item) => () => (
    <DroppableCardImage src={item.wantedMedia("DragImage", "DragImage")} color={color} />
  );

  const createRenderDroppableEmptyLayer = (item) => () => (
    <DroppableCardImage src={item.wantedMedia("DragImage", "DragImage")} color={color} dragging={true} />
  );

  const renderDragPreview = useCallback(
    (item) => <DraggingDroppableImage src={item.wantedMedia("DragImage", "DragImage")} color={color} />,
    [color],
  );

  const onDrop = useCallback(
    (dropzoneId, droppable) => {
      setDroppedNode(droppable);

      if (dropzoneId === droppable.id) {
        Audio.discrete("effects").play(resource("audio/ThumbUp01.mp3"));
        setGoodDrop(true);

        setTimeout(() => {
          onComplete();
        }, 1500);
      } else {
        Audio.discrete("effects").play(resource("audio/ThumbDown01.mp3"));
        setWrongDrop(true);
        setTimeout(() => {
          setWrongDrop(false);
          setDroppedNode(null);
          setGoodDrop(false);
        }, 2100);
      }
    },
    [onComplete],
  );

  useEffect(() => {
    setGoodDrop(false);
    setDroppedNode(null);
  }, [node]);

  const renderDraggableItem = (item) => {
    const dropped = droppedNode === item;

    return (
      <DroppableCard
        key={item.id}
        id={item}
        onDragStart={onDragStart}
        onTouch={onTouch}
        onDragEnd={onDragEnd}
        hasDropped={dropped}
        renderForegroundLayer={createRenderDroppableForegroundLayer(item)}
        renderEmptyLayer={createRenderDroppableEmptyLayer(item)}
      ></DroppableCard>
    );
  };

  const createRenderDropzoneForegroundLayer = () => () => {
    return (
      droppedNode && (
        <CardDropContainer>
          <CardImage src={droppedNode.wantedMedia("DragImage", "DragImage")} />
        </CardDropContainer>
      )
    );
  };

  const createRenderDropzoneEmptyLayer = () => () => {
    return (
      !droppedNode && (
        <CardDropContainer>
          <QuestionMarkImage />
        </CardDropContainer>
      )
    );
  };

  return (
    <DragQuestionRoot {...rest}>
      <Content>
        {!isPortrait && <DraggableContainer>{node.children.map(renderDraggableItem)}</DraggableContainer>}
        <Card color={color} dragging={dragging} empty={!droppedNode}>
          <CardDropzoneContainer>
            <CardDropzone
              id={goodAnswer.id}
              onDrop={onDrop}
              hasDropped={Boolean(droppedNode)}
              renderEmptyLayer={createRenderDropzoneEmptyLayer()}
              renderForegroundLayer={createRenderDropzoneForegroundLayer()}
            ></CardDropzone>
            {goodDrop && <GoodImage />}
            {wrongDrop && <WrongImage />}
          </CardDropzoneContainer>

          <CardBar color={color}>
            <CardText>{node.optionalText("Text")}</CardText>
            {audio && <CardSpeakerButton src={audio} />}
          </CardBar>
        </Card>
        {isPortrait && <Droppables>{node.children.map(renderDraggableItem)}</Droppables>}
      </Content>

      <DragPreview render={renderDragPreview} />
      {goodDrop && <CompletedOverlay />}
    </DragQuestionRoot>
  );
};

DragQuestion.propTypes = {
  onComplete: PropTypes.func,
};

export default DragQuestion;
