/* global SafariViewController */
import Analytics from "./analytics";
import Env from "./env";
import Log from "./log";

export default class Link {
  /** Opens an external URL in a device-appropriate manner */
  static open(url) {
    Log.info(`Opening external URL: ${url}`);
    Analytics.track("external-link", { url });

    if (Env.isIos || Env.isAndroid) {
      // On iOS, use SFSafariViewController as an in-app browser for maximum performance and notch-compatibility
      // The plugin also supports Android with Chrome Custom Tabs, even if its name implies it targets iOS only.
      // Reference: https://github.com/EddyVerbruggen/cordova-plugin-safariviewcontroller
      SafariViewController?.isAvailable((available) => {
        if (!available) return;
        SafariViewController.show({ url: url });
      });
    } else {
      window.open(url, "_blank");
    }
  }
}
