import PropTypes from "prop-types";
import { memo } from "react";
import AnimateHeight from "react-animate-height";
import Classes from "../../../helpers/classes";

const Collapsible = memo(({ className, renderHeader, children, open, ...rest }) => {
  return (
    <div className={Classes.build("ripple-collapsible", className)} {...rest}>
      <AnimateHeight duration={300} height={open ? `auto` : 0}>
        <div className="collapsible-content">{children}</div>
      </AnimateHeight>
    </div>
  );
});

Collapsible.propTypes = {
  className: PropTypes.string,
  renderHeader: PropTypes.func.isRequired,
  children: PropTypes.node,
  open: PropTypes.bool,
};

export default Collapsible;
