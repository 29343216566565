import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import dataReducer from "../local/data-reducer";
import downloadFilesReducer from "../local/download-files-reducer";
import downloadNodeIdReducer from "../local/download-node-id-reducer";
import downloadProgressReducer from "../local/download-progress-reducer";
import flagsReducer from "../local/flags-reducer";
import interactionReducer from "../local/interaction-reducer";
import languageReducer from "../local/language-reducer";
import remoteReducer from "../local/remote-reducer";
import shortcutsReducer from "../local/shortcuts-reducer";
import startupErrorReducer from "../local/startup-error-reducer";
import startupStatusReducer from "../local/startup-status-reducer";
import timeoutReducer from "../local/timeout-reducer";

const RootLocalReducerFactory = {
  create: (appReducer, history) => {
    return combineReducers({
      language: languageReducer,
      data: dataReducer,
      local: appReducer,
      remote: remoteReducer,
      timeout: timeoutReducer,
      interaction: interactionReducer,
      startup: combineReducers({
        error: startupErrorReducer,
        status: startupStatusReducer,
      }),
      download: combineReducers({
        nodeId: downloadNodeIdReducer,
        progress: downloadProgressReducer,
        files: downloadFilesReducer,
      }),
      flags: flagsReducer,
      shortcuts: shortcutsReducer,
      router: connectRouter(history),
    });
  },
};

export default RootLocalReducerFactory;
