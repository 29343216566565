import { differenceInMilliseconds } from "date-fns";
import _ from "lodash";
import Iterate from "../../../../helpers/iterate";
import AnalyticsHelper from "../../analytics-helper";

function calculate(preprocessed) {
  if (preprocessed.sessions.length <= 1) return 0;
  const intervals = Iterate.mapPairwise(preprocessed.sessions, (sessionEventsA, sessionEventsB) => {
    const lastEventTimeInA = _.last(sessionEventsA).time;
    const firstEventTimeInB = _.first(sessionEventsB).time;
    return differenceInMilliseconds(firstEventTimeInB, lastEventTimeInA);
  });
  return Math.round(_.mean(intervals));
}

export default function (preprocessed) {
  this.name = "AverageTimeBetweenSessions";
  this.format = AnalyticsHelper.formatAsDuration;
  this.value = calculate(preprocessed);
}
