import styled from "styled-components";
import SpeakerButton from "../speaker-button";

export const SpeakerButtonLayerRoot = styled.div`
  pointer-events: none;
`;

export const StyledSpeakerButton = styled(SpeakerButton)`
  position: absolute;
  top: ${(props) => (props.$corner === 1 || props.$corner === 2 ? props.$inset : "unset")};
  bottom: ${(props) => (props.$corner === 3 || props.$corner === 4 ? props.$inset : "unset")};
  left: ${(props) => (props.$corner === 2 || props.$corner === 3 ? props.$inset : "unset")};
  right: ${(props) => (props.$corner === 1 || props.$corner === 4 ? props.$inset : "unset")};
  pointer-events: auto;

  /* Prevent it from being too big in small containers */
  max-width: 25%;
  max-height: 25%;

  .hotspot {
    margin: -1vmin;
  }
`;
