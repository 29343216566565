import { memo, useEffect } from "react";
import { useParams } from "react-router";
import { Localization, useData } from "ripple";
import { renderResourceChild } from "../../common";
import {
  ContentScroller,
  Header,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
  Page,
  StyledBackButton,
  StyledLanguageSwitcher,
  Title,
} from "./styled";

const WebResourcePage = memo(() => {
  const { id } = useParams();
  const resource = useData((data) => data.requiredNode(id));
  const isFirstResourcesSection = resource.parent.parent.children.indexOf(resource.parent) === 0;

  // Switch to default language when exiting the resource page
  // to prevent the document language from affecting the menu on the home page
  // (a better solution might be to have the language switching localized to
  // the resources page by asking for resources in the current language explicitly,
  // but this'll do for now.)
  useEffect(() => {
    return () => setTimeout(() => Localization.switchToDefaultLanguage(), 250);
  }, []);

  return (
    <Page>
      <Header>
        <HeaderLeft>
          <StyledBackButton action="go-back" />
        </HeaderLeft>
        <HeaderCenter>
          <Title>{resource.wantedText("Title")}</Title>
        </HeaderCenter>
        <HeaderRight>{isFirstResourcesSection && <StyledLanguageSwitcher />}</HeaderRight>
      </Header>
      <ContentScroller disableGestures>{resource.children.map(renderResourceChild)}</ContentScroller>
    </Page>
  );
});

export default WebResourcePage;
