import _ from "lodash";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Subject, auditTime, distinctUntilChanged } from "rxjs";
import Classes from "../../../helpers/classes";
import Styles from "../../../helpers/styles";
import { Maths } from "../../../ripple";

const createDebouncedSubject = (interval) => new Subject().pipe(auditTime(interval), distinctUntilChanged());

const Slider = memo(
  ({
    className,
    style,
    orientation = "horizontal",
    disabled = false,
    animated,
    value,
    onChange,
    debounceTime: debounceTimeInterval = 0,
    optimistic: isOptimisticEnabled = true,
    optimisticKeepDelay = 100, // This avoids glitching when updating the actual value after input, most of the time. Make this higher if necessary.
    handle,
  }) => {
    const trackRef = useRef(null);
    const [optimisticValue, setOptimisticValue] = useState(null);
    const displayedValue = isOptimisticEnabled ? optimisticValue ?? value : value;

    const getValueForEvent = useCallback(
      (event) => {
        const nativeEvent = event.nativeEvent;
        const trackRect = trackRef.current.getBoundingClientRect();
        const trackPosition = orientation === "horizontal" ? trackRect.x : trackRect.y;
        const eventPositionX = !_.isUndefined(nativeEvent.changedTouches)
          ? nativeEvent.changedTouches[0].clientX
          : nativeEvent.clientX;
        const eventPositionY = !_.isUndefined(nativeEvent.changedTouches)
          ? nativeEvent.changedTouches[0].clientY
          : nativeEvent.clientY;
        const eventPosition = orientation === "horizontal" ? eventPositionX : eventPositionY;
        const length = orientation === "horizontal" ? trackRect.width : trackRect.height;
        const ratio = (eventPosition - trackPosition) / length;
        return Maths.clamp(orientation === "horizontal" ? ratio : 1 - ratio, 0, 1);
      },
      [orientation],
    );

    const debouncedValueSubjectRef = useRef(createDebouncedSubject(debounceTimeInterval));
    const downRef = useRef(false);

    const handleDown = useCallback(
      (event) => {
        downRef.current = true;
        if (disabled) return;
        const newValue = getValueForEvent(event);
        setOptimisticValue(newValue);
        onChange?.(newValue);
      },
      [getValueForEvent, disabled, onChange],
    );

    const handleMove = useCallback(
      (event) => {
        if (disabled || !downRef.current) return;
        const newValue = getValueForEvent(event);
        setOptimisticValue(newValue);
        debouncedValueSubjectRef.current.next(newValue);
      },
      [getValueForEvent, disabled],
    );

    const optimisticKeepTimeoutRef = useRef(null);
    const handleUp = useCallback(
      (event) => {
        downRef.current = false;
        if (disabled) return;
        clearTimeout(optimisticKeepTimeoutRef);
        optimisticKeepTimeoutRef.current = setTimeout(() => setOptimisticValue(null), optimisticKeepDelay);
        onChange?.(getValueForEvent(event));
      },
      [disabled, getValueForEvent, onChange, optimisticKeepDelay],
    );

    useEffect(() => {
      debouncedValueSubjectRef.current = createDebouncedSubject(debounceTimeInterval);
      const subscription = debouncedValueSubjectRef.current.subscribe((value) => {
        onChange?.(value);
      });
      return () => subscription.unsubscribe();
    }, [onChange, debounceTimeInterval]);

    return (
      <div
        className={Classes.build(
          "ripple-slider",
          { horizontal: orientation === "horizontal", vertical: orientation === "vertical" },
          className,
        )}
        style={Styles.merge(style)}
      >
        <div className="track" ref={trackRef}>
          <div
            className={Classes.build("fill", { animated })}
            style={{
              width: orientation === "horizontal" ? `${displayedValue * 100}%` : "100%",
              height: orientation === "horizontal" ? "100%" : `${displayedValue * 100}%`,
            }}
          ></div>
        </div>
        <div
          className="handle"
          style={{
            left: orientation === "horizontal" ? `${displayedValue * 100}%` : "0%",
            bottom: orientation === "horizontal" ? "0%" : `${displayedValue * 100}%`,
            width: orientation === "horizontal" ? 0 : "100%",
            height: orientation === "horizontal" ? "100%" : 0,
          }}
        >
          {handle}
        </div>
        <div
          className={Classes.build("hotspot", "debug-show-hotspot", { disabled })}
          onPointerDown={handleDown}
          onPointerMove={handleMove}
          onPointerUp={handleUp}
        />
      </div>
    );
  },
);

Slider.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  disabled: PropTypes.bool, // Whether the user can interact with the slider or not
  animated: PropTypes.bool, // Whether the slider animates to the current value or updates instantly
  value: PropTypes.number, // The current value (from 0 to 1)
  onChange: PropTypes.func, // Tells the user that a value change has occured, but lets the parent provide the new value if applicable
  debounceTime: PropTypes.number, // The minimum number of milliseconds between onChange calls (useful to avoid spamming when controlling video playback)
  optimistic: PropTypes.bool, // If true, the value displayed in the bar follows the pointer during input, regardless of the actual value
  optimisticKeepDelay: PropTypes.number, // The number of milliseconds to keep the optimistic value after stopping manual input. Useful to avoid visual glitches when the actual value might take a few hundred milliseconds to update.
  handle: PropTypes.node, // Render a custom handle aligned with the end of the thumb
};

export default Slider;
