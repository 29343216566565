import { useEffect, useState } from "react";
import Fetch from "../../logic/fetch/fetch";

export const useNodeMediasDownloaded = (node) => {
  const [downloaded, setDownloaded] = useState(Fetch.hasNodeBeenDownloadedAtLeastOnce(node));

  useEffect(() => {
    const func = (e) => setDownloaded(Fetch.hasNodeBeenDownloadedAtLeastOnce(node));
    document.addEventListener("fetch-node-medias-downloaded", func);
    return () => document.removeEventListener("fetch-node-medias-downloaded", func);
  }, [node]);

  return downloaded;
};
