import queryString from "query-string";
import { memo, useCallback, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import Classes from "../../helpers/classes";
import LocationHelper from "../../helpers/internal/location-helper";
import Navigator from "../../helpers/navigator";
import FlagsActions from "../../redux/actions/local/flags-actions";
import { usePrevious } from "../hooks/use-previous";

function toNavigationObject(url) {
  const components = url.split("?");
  const path = components[0];
  const query = components[1] ? queryString.parse(components[1]) : {};
  return {
    path: path,
    replaceQuery: query,
  };
}

const PathBar = memo(() => {
  const inputRef = useRef(null);

  const location = useLocation();
  const dispatch = useDispatch();
  const active = useSelector((store) => store.flags.pathBar);
  const previousActive = usePrevious(active);
  const url = LocationHelper.urlFromLocation(location);

  const onKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter" || event.key === "ù") {
        Navigator.navigate(toNavigationObject(event.target.value));
        dispatch(FlagsActions.togglePathBar());
      }
    },
    [dispatch],
  );

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        dispatch(FlagsActions.togglePathBar());
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (active && !previousActive) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    } else if (!active && previousActive) {
      inputRef.current.blur();
    }
  }, [active, previousActive]);

  return (
    <div className={Classes.build("path-bar", { active })}>
      <input
        ref={inputRef}
        key={url}
        type="text"
        defaultValue={url}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        disabled={!active}
        autoComplete="off"
        autoCapitalize="none"
      />
    </div>
  );
});

const mapStateToProps = (state) => ({ flags: state.flags, location: state.router.location });

export default connect(mapStateToProps)(PathBar);
