import { Button } from "ripple";
import styled from "styled-components";
import { standardGap } from "../../../common";
import Box from "../../../components/box";
import Question from "../../../components/question";

export const Type15QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BigImageBox = styled(Box)`
  aspect-ratio: 1;
  @media screen and (orientation: landscape) {
    width: 50vh;
  }
`;

export const RoundButtonsRow = styled.div`
  margin-top: ${standardGap};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${standardGap};
`;

export const RoundButton = styled(Button)`
  width: 5vh;
  height: 5vh;
  .hotspot {
    margin: calc(-${standardGap} / 2);
  }
`;

export const RoundButtonBox = styled(Box).attrs((props) => ({ appearance: "active", borderRadius: "50%" }))`
  width: 100%;
  height: 100%;
`;

export const ButtonFill = styled.div`
  width: 100%;
  height: 100%;
  color: black;
  background-color: ${(props) => props.$color};
  transition: all 250ms ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
`;
