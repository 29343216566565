import PropTypes from "prop-types";
import { CustomSpeakerButton, WordButtonRoot } from "./styled";

const WordButton = ({ item, color, active, disabled, children, ...rest }) => {
  const audio = item.optionalMedia("Audio", "Audio");
  return (
    <WordButtonRoot color={color} active={active} {...rest}>
      {audio && <CustomSpeakerButton disabled={!audio} src={audio} />}
      <>{children}</>
    </WordButtonRoot>
  );
};

WordButton.propTypes = {
  src: PropTypes.string,
  item: PropTypes.object,
  color: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onAudioEnd: PropTypes.func,
  children: PropTypes.node,
};

export default WordButton;
