import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useRef } from "react";
import Button from "../button";

/**
 * A button that performs its action only when it's clicked
 * a certain number of times in the specified timeout duration.
 * */
const MultiClickButton = memo(({ children, clicks, timeout, onMultiClick, ...rest }) => {
  const actualTimeout = timeout ?? clicks * 300; // 300ms per click towards the total timeout duration unless overridden

  const timeoutRef = useRef(null);
  const clickCountRef = useRef(0);

  const validate = useCallback(() => {
    if (clickCountRef.current >= clicks && onMultiClick) onMultiClick();
    clickCountRef.current = 0;
  }, [clicks, onMultiClick]);

  const startTimeout = useCallback(() => {
    timeoutRef.current = setTimeout(validate, actualTimeout);
  }, [actualTimeout, validate]);

  const onClick = useCallback(() => {
    if (clickCountRef.current === 0) startTimeout();
    clickCountRef.current += 1;
  }, [startTimeout]);

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  return <Button {...rest} onClick={onClick} muted />;
});

MultiClickButton.propTypes = {
  children: PropTypes.node,
  clicks: PropTypes.number.isRequired,
  timeout: PropTypes.number,
  onMultiClick: PropTypes.func,
};

export default MultiClickButton;
