import _ from "lodash";
import InteractionActions from "../../actions/local/interaction-actions";

const defaultState = {
  block: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case InteractionActions.BLOCK_INTERACTION:
      return _.assign({}, state, { block: true });
    case InteractionActions.UNBLOCK_INTERACTION:
      return _.assign({}, state, { block: false });
    default:
      return state;
  }
};
