import { Button } from "ripple";
import styled from "styled-components";
import { unnamedPanelColor } from "../../../common";
import { bottomBarContentHeight } from "../styled";

export const InfoButtonRoot = styled(Button)`
  width: ${bottomBarContentHeight};
  height: ${bottomBarContentHeight};
  border-radius: 50%;
  border: 2px solid white;
  background-color: ${unnamedPanelColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;

  .hotspot {
    margin: calc(-${bottomBarContentHeight} / 2);
  }

  .ripple-image {
    width: 100%;
    height: 100%;
  }
`;
