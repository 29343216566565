import { toast } from "react-hot-toast";

const defaultDuration = 3000;

export default class Toast {
  static _store = null; // Must be set early on so that we can dispatch actions

  /**
   * Displays a toast-style message with "info" level at the top of the screen
   */
  static info(message, duration = defaultDuration) {
    toast(message, { icon: "ℹ️", duration, style: { backgroundColor: "#ECF5FF" } });
  }

  /**
   * Displays a toast-style message with "warn" level at the top of the screen
   */
  static warn(message, duration = defaultDuration) {
    toast(message, { icon: "⚠️", duration, style: { backgroundColor: "#FFF9E9" } });
  }

  /**
   * Displays a toast-style message with "error" level at the top of the screen
   */
  static error(message, duration = defaultDuration) {
    toast(message, { icon: "⛔️", duration, style: { backgroundColor: "#FFF1F1" } });
  }
}
