import { useCallback } from "react";
import { Audio } from "ripple";
import { create } from "zustand";

const getUrl = (src) => {
  if (!src) return null;
  return typeof src === "string" ? src : src.url;
};

export const useGlobalAudioPlayerStore = create((set) => ({
  src: null,
  setSrc: (src) => set(() => ({ src })),
  playing: false,
  setPlaying: (playing) => set(() => ({ playing })),
}));

export const useGlobalAudioPlayer = () => {
  const { src, setSrc } = useGlobalAudioPlayerStore();
  const { playing, setPlaying } = useGlobalAudioPlayerStore();

  const play = useCallback(
    (newSrc, owner = null) => {
      Audio.track("global").play(newSrc, {
        onEnd: () => {
          setPlaying(false);
          setSrc(null);
        },
      });
      setPlaying(true);
      setSrc(newSrc);
    },
    [setPlaying, setSrc],
  );

  const stop = useCallback(() => {
    setPlaying(false);
    setSrc(null);
    Audio.track("global").stop();
  }, [setPlaying, setSrc]);

  const toggle = useCallback(
    (newSrc, owner) => {
      if (playing && getUrl(newSrc) === getUrl(src)) stop();
      else play(newSrc, owner);
    },
    [play, playing, src, stop],
  );

  return { src, playing, play, stop, toggle };
};
