/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Audio, resource, useData } from "ripple";
import { GoodImage, LandscapeCardGrid, PortraitCardGrid, WrongImage, textSize } from "../../../common";
import { useIsPortrait } from "../../../hooks/use-is-portrait";
import {
  Card,
  CardBar,
  CardImage,
  CardText,
  CardTransitioner,
  FlipQuestionRoot,
  QuestionMarkImage,
  SmallerCardTransitioner,
} from "./styled";

const FlipQuestion = ({ onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const flipItems = node.children;
  const color = node.parent.wantedText("Color")?.trim().toLowerCase();
  const isPortrait = useIsPortrait();

  const [freezeCards, setFreezeCards] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);

  // Count of good answer.
  const count = flipItems.filter((item) => item.settings.Answer === "good").length;
  const [flippedCards, setFlippedCards] = useState([]);

  const createOnCardClick = (item, index) => () => {
    const card = flippedCards.find((card) => card.id === item.id);
    if (card) {
      Audio.discrete("effects").play(resource("audio/CardFlipOut.wav"));
      setFlippedCards((prevCards) => prevCards.filter((card) => card.id !== item.id));
    } else {
      Audio.discrete("effects").play(resource("audio/CardFlipIn.mp3"));
      Audio.discrete("effects").play(item.wantedMedia("Audio", "Audio"));
      setFlippedCards((prevCards) => [...prevCards, { index, id: item.id }]);
    }
  };

  useEffect(() => {
    const flippedCount = flippedCards.length;
    if (flippedCount >= count) setFreezeCards(true);
  }, [flippedCards.length]);

  useEffect(() => {
    if (!freezeCards) return;

    setTimeout(() => {
      setShowAnswer(true);
    }, 500);
  }, [freezeCards]);

  useEffect(() => {
    if (!showAnswer) return;

    let good = true;
    flippedCards.forEach((item) => {
      if (!good) return;
      const card = flipItems[item.index];
      good = card.settings.Answer === "good";
    });

    good
      ? Audio.discrete("effects").play(resource("audio/ThumbUp01.mp3"))
      : Audio.discrete("effects").play(resource("audio/ThumbDown01.mp3"));

    setTimeout(() => {
      if (good) onComplete();
      else {
        setFlippedCards([]);
        setFreezeCards(false);
        setShowAnswer(false);
      }
    }, 2500);
  }, [showAnswer]);

  const renderCard = (item, index) => {
    const hasText = !!item.optionalText("Text");
    const showItem = flippedCards.some((item) => item.index === index);

    const size = textSize(item.optionalText("Text"));

    return (
      <Card key={item.id} color={color} onClick={createOnCardClick(item, index)} disabled={freezeCards}>
        <CardImage src={item.wantedMedia("FlipImage", "FlipImage")} show={showItem} $partialHeight={hasText} />
        {hasText && showItem && (
          <CardBar show={hasText} color={color}>
            <CardText size={size}>{item.optionalText("Text")}</CardText>
          </CardBar>
        )}
        {showAnswer && showItem && (item.settings.Answer === "good" ? <GoodImage /> : <WrongImage />)}
        <QuestionMarkImage show={!showItem} />
      </Card>
    );
  };

  const renderFlipCard = (item, index) => {
    const showBar = !!item.optionalText("Text");
    const showItem = flippedCards.some((item) => item.index === index);

    return showBar ? (
      <CardTransitioner key={item.id} transitionKey={showItem} classNames="flip-left">
        {renderCard(item, index)}
      </CardTransitioner>
    ) : (
      <SmallerCardTransitioner key={item.id} transitionKey={showItem} classNames="flip-left">
        {renderCard(item, index)}
      </SmallerCardTransitioner>
    );
  };

  return (
    <FlipQuestionRoot {...rest}>
      {isPortrait ? (
        <PortraitCardGrid>{flipItems.map(renderFlipCard)}</PortraitCardGrid>
      ) : (
        <LandscapeCardGrid>{flipItems.map(renderFlipCard)}</LandscapeCardGrid>
      )}
    </FlipQuestionRoot>
  );
};

FlipQuestion.propTypes = {
  onComplete: PropTypes.func,
};

export default FlipQuestion;
