import styled from "styled-components";
import { standardGap, standardQuestionMaxContentSize } from "../../../common";
import Box from "../../../components/box";
import Question from "../../../components/question";

export const Type3QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BigBox = styled(Box).attrs((props) => ({ appearance: "active", dashed: true }))`
  aspect-ratio: 1;
  @media screen and (orientation: landscape) {
    width: ${standardQuestionMaxContentSize};
  }
`;

export const ItemsLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: ${standardGap};
  gap: calc(${standardGap} / 2);
`;
