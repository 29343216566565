import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import Classes from "../../../helpers/classes";
import { useClickOutside } from "../../hooks/use-click-outside";
import Button from "../button";
import Scroller from "../scroller";

const Dropdown = memo(
  ({
    className,
    style,
    children,
    items = [],
    initialItem,
    placeholder = <div className="dropdown-default-placeholder">[Set placeholder or initial item!]</div>,
    onChange,
    ...rest
  }) => {
    const rootRef = useRef(null);

    const [showPicker, setShowPicker] = useState(false);
    const [selection, setSelection] = useState(initialItem);
    const [maxPickerHeight, setMaxPickerHeight] = useState(999999);

    const clickOutsideRef = useClickOutside(showPicker, () => setShowPicker(false));

    const createOnPickerItemClick = (item) => () => {
      setSelection(item);
      setShowPicker(false);
      onChange?.(item);
    };

    const onDropdownClick = useCallback(() => {
      const viewRect = rootRef.current.getBoundingClientRect();
      const availableVerticalSpace = window.innerHeight - (viewRect.y + viewRect.height);
      setMaxPickerHeight(availableVerticalSpace - 20);
      setShowPicker(!showPicker);
    }, [showPicker]);

    const onOutsideClick = useCallback(() => {
      setShowPicker(false);
    }, []);

    useClickOutside(rootRef, onOutsideClick);

    const actualChildren = children ?? ((anything) => anything);

    return (
      <div ref={rootRef} style={style} className={Classes.build("ripple-dropdown", className)}>
        <Button className="dropdown-box" onClick={onDropdownClick}>
          <div className="dropdown-item">{(selection && actualChildren(selection)) ?? placeholder}</div>
          <div className="dropdown-arrow"></div>
        </Button>
        <div
          ref={clickOutsideRef}
          className={Classes.build("dropdown-picker", showPicker ? "visible" : "hidden")}
          style={{ maxHeight: maxPickerHeight }}
        >
          <Scroller className="dropdown-scroller" scrollbarInset={6}>
            {items &&
              items.map((item) => (
                <Button key={item.id ?? item} className="dropdown-item" onClick={createOnPickerItemClick(item)}>
                  {actualChildren(item)}
                </Button>
              ))}
          </Scroller>
        </div>
      </div>
    );
  },
);

Dropdown.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.func.isRequired,
  items: PropTypes.array,
  initialItem: PropTypes.any,
  placeholder: PropTypes.any,
  onChange: PropTypes.func,
};

export default Dropdown;
