import PropTypes from "prop-types";
import { ColorLayerRoot } from "./styled";

const ColorLayer = ({ className, style, color, ...rest }) => {
  return <ColorLayerRoot {...rest} className={className} style={style} $color={color}></ColorLayerRoot>;
};

ColorLayer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
};

export default ColorLayer;
