import _ from "lodash";
import PropTypes from "prop-types";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import Audio from "../../../helpers/audio";
import Classes from "../../../helpers/classes";
import Env from "../../../helpers/env";
import Interaction from "../../../helpers/interaction";
import Navigator from "../../../helpers/navigator";
import resource from "../../../helpers/resource";
import Strings from "../../../helpers/strings";
import Timeout from "../../../helpers/timeout";
import Hook from "../../../logic/hook";
import { MediaSrcPropType } from "../../../logic/prop-types";
import { usePhidgetDigitalInputAsPushButton } from "../../../ripple";
import Point from "../../../types/point";
import { useKeyboardShortcut } from "../../hooks/use-keyboard-shortcut";
import Text from "../text";

const getScreenPosition = (event) => {
  const nativeEvent = event.nativeEvent;
  if (!_.isUndefined(nativeEvent.changedTouches)) {
    // Touch
    return new Point(nativeEvent.changedTouches[0].screenX, nativeEvent.changedTouches[0].screenY);
  } else {
    // Mouse
    return new Point(nativeEvent.screenX, nativeEvent.screenY);
  }
};

/**
 * A very fancy button that performs routing-aware
 * navigation and many other useful things.
 * */
const Button = memo(
  forwardRef(
    (
      {
        id,
        children,
        className,
        style,
        disabled = false,
        disableAfterClick = false,
        disableAfterClickFor = null,
        onClick,
        delay = 0,
        clickMode = "up",
        clickDuration = 100, // In practice, this allows 100ms for transition-in and 100ms for transition-out
        clickCancelDragThreshold = 20,
        blockInteractionFor,
        onClickStateChange,
        onPressStateChange,
        sound,
        muted = false,
        inputPriority = 0,
        inputDescription,
        keyEquivalent,
        phidgetEquivalent,
        navigate,
        action,
        localized,
        preventDefault = false,
      },
      ref,
    ) => {
      const [internallyDisabled, setInternallyDisabled] = useState(false);
      const [pressed, setPressed] = useState(false);
      const [clicked, setClicked] = useState(false);

      const downScreenPositionRef = useRef(null);
      const isClickingRef = useRef(false);
      const disableClickForTimeoutRef = useRef(null);
      const clickFeedbackTimeoutTokenRef = useRef(null);
      const clickTimeoutTokenRef = useRef(null);

      const renderChildren = () => {
        if (localized) return <Text>{Strings.localized(localized)}</Text>;
        if (typeof children === "undefined" || children === null) return null;
        if (typeof children === "string") return <Text>{children}</Text>;
        return children;
      };

      const changeClickedState = useCallback(
        (clicked) => {
          setClicked(clicked);
          if (onClickStateChange) onClickStateChange(clicked);
        },
        [onClickStateChange],
      );

      const changePressedState = useCallback(
        (pressed) => {
          setPressed(pressed);
          if (onPressStateChange) onPressStateChange(pressed);
        },
        [onPressStateChange],
      );

      const performAction = useCallback(
        (event) => {
          // The `onClick` handler can decide whether the navigation
          // proceeds or not by returning a boolean. It always runs,
          // regardless of if the action or navigate props are set or not.
          const hook = new Hook("navigate", onClick);

          if (hook.run(event)) {
            // First, run a built-in action if specified
            switch (action) {
              case "go-back":
                Navigator.goBack();
                break;
              case "timeout":
                Timeout.force();
                break;
              default: {
                // If no built-in action was specified, navigate
                if (navigate) Navigator.navigate(navigate);
              }
            }
          }
        },
        [action, navigate, onClick],
      );

      const performClick = useCallback(
        (event) => {
          if (isClickingRef.current) return; // Prevent multi-clicks when delay is non-zero
          if (disabled || internallyDisabled) return; // When called through the API, we must also respect the `enabled` prop
          if (Interaction.blocked()) return; // When called through the API, we must not perform if interaction is blocked

          if (blockInteractionFor) Interaction.blockFor("button click", blockInteractionFor);

          if (disableAfterClick || disableAfterClickFor) setInternallyDisabled(true);
          if (disableAfterClickFor) {
            disableClickForTimeoutRef.current = setTimeout(() => setInternallyDisabled(false), disableAfterClickFor);
          }

          const actualSound = sound ?? resource("audio/ripple-button.mp3");
          if (actualSound)
            Audio.discrete(Env.isDesktop ? "buttons" : "mobileButtons").play(actualSound, { muted: muted });

          changeClickedState(true);
          clickFeedbackTimeoutTokenRef.current = setTimeout(() => changeClickedState(false), clickDuration);

          if (delay === 0) {
            performAction(event);
            return;
          }

          isClickingRef.current = true;
          clickTimeoutTokenRef.current = setTimeout(() => {
            performAction(event);
            isClickingRef.current = false;
          }, delay);
        },
        [
          blockInteractionFor,
          changeClickedState,
          clickDuration,
          delay,
          disableAfterClick,
          disableAfterClickFor,
          disabled,
          internallyDisabled,
          muted,
          performAction,
          sound,
        ],
      );

      const exceedsClickCancelDragThreshold = useCallback(
        (downPosition, upPosition) => {
          if (!upPosition || !downPosition) return true;
          return Math.abs(upPosition.distanceTo(downPosition)) > clickCancelDragThreshold;
        },
        [clickCancelDragThreshold],
      );

      const click = useCallback(
        (event) => {
          event.preventDefault(); // Prevent multi-click caused by mouse click event being fired after a touch end

          const downScreenPosition = downScreenPositionRef.current;
          downScreenPositionRef.current = undefined; // Important to reset the "downOutsideButton" logic

          // When pressing down outside a button and releasing inside it, don't click
          if (typeof downScreenPosition === "undefined") return;

          // When exceeding the maximum allowed distance between the pointer down and up positions, don't click
          if (exceedsClickCancelDragThreshold(downScreenPosition, getScreenPosition(event))) return;

          performClick(event);
        },
        [exceedsClickCancelDragThreshold, performClick],
      );

      const handleDown = useCallback(
        (event) => {
          downScreenPositionRef.current = getScreenPosition(event);
          changePressedState(true);
        },
        [changePressedState],
      );

      const handleUp = useCallback(() => {
        changePressedState(false);
      }, [changePressedState]);

      const onPointerDown = useCallback(
        (event) => {
          // Necessary to prevent losing input field focus when typing
          // BUT prevents dragging by grabbing buttons in Scrollers.
          if (preventDefault) event.preventDefault();

          handleDown(event);
          if (clickMode === "down") click(event);
        },
        [click, clickMode, handleDown, preventDefault],
      );

      const onPointerUp = useCallback(
        (event) => {
          handleUp(event);
          if (clickMode === "up") click(event);
        },
        [click, clickMode, handleUp],
      );

      const onPointerOut = useCallback(
        (event) => {
          handleUp(event);
        },
        [handleUp],
      );

      useImperativeHandle(
        ref,
        () => ({
          performClick, // Perform a click, including the button animation and delay if applicable
          performAction, // Perform the button's action, regardless of the click animation and delay, but still considering the button's hook (if applicable)
        }),
        [performAction, performClick],
      );

      const actualInputDescription = inputDescription || "[no description provided]";
      useKeyboardShortcut(actualInputDescription, keyEquivalent, () => performClick(), {
        priority: inputPriority,
      });
      usePhidgetDigitalInputAsPushButton(actualInputDescription, phidgetEquivalent, () => performClick());

      useEffect(() => {
        return () => {
          clearTimeout(clickTimeoutTokenRef.current);
          clearTimeout(clickFeedbackTimeoutTokenRef.current);
          clearTimeout(disableClickForTimeoutRef.current);
        };
      }, []);

      return (
        <div
          id={id}
          className={Classes.build(
            "ripple-button",
            { pressed },
            { clicked },
            { disabled: disabled || internallyDisabled },
            { enabled: !disabled && !internallyDisabled },
            className,
          )}
          style={style}
        >
          {renderChildren()}
          <div
            className={Classes.build("hotspot", "debug-show-hotspot", { disabled })}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onPointerOut={onPointerOut}
          />
          <div className={Classes.build("bounds", "debug-show-button", { disabled })}></div>
        </div>
      );
    },
  ),
);

Button.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  disableAfterClick: PropTypes.bool, // Force-disable the button after it's clicked once (useful to prevent multiple clicks before navigating, among other things)
  disableAfterClickFor: PropTypes.number, // Force-disable the button for a certain duration after it's clicked, then enable it again
  onClick: PropTypes.func, // Register a hook to be run when the button is clicked (if navigation props are specified, you can cancel navigation by returning false from the handler)
  delay: PropTypes.number, // Delay the click's action by a certain duration in milliseconds (for example, to wait for click feedback to finish before navigating)
  clickMode: PropTypes.oneOf(["down", "up"]), // When to trigger the click event (on pointer up or down)
  clickDuration: PropTypes.number, // The amount of time, in milliseconds, that the button will have the "clicked" class after being clicked
  clickCancelDragThreshold: PropTypes.number, // Distance (in pixels) after which the click will be cancelled when the pointer is moved between "down" and "up" events (cancels clicks in scrollers, among other uses)
  blockInteractionFor: PropTypes.number, // Blocks app-wide interaction for the speficied number of milliseconds after the button is clicked
  onClickStateChange: PropTypes.func, // Called when the button's click state changes
  onPressStateChange: PropTypes.func, // Called when the button's press state changes
  sound: PropTypes.oneOfType([PropTypes.string, MediaSrcPropType]), // The path to a custom click sound (else falls back to the default click sound unless muted)
  muted: PropTypes.bool, // Set to true to prevent the "click" sound from being played (set the "buttons" context's "mute" setting to "true" to mute all buttons!)
  inputPriority: PropTypes.number, // If multiple buttons have the same key equivalent, specifying an input priority prevents buttons with lower priority from being clicked
  inputDescription: PropTypes.string, // A human-readable description for this input (will be displayed in the I/O debug panel)
  keyEquivalent: PropTypes.string, // A keyboard shortcut that will trigger the button (ex: "1", "a", "ctrl|cmd+g", "ctrl|cmd+shift+h", etc.). If an input equivalent is specified, this overrides the key equivalent.
  phidgetEquivalent: PropTypes.object, // A Phidget digital input push button equivalent (provide a Phidget identification).
  navigate: PropTypes.any, // Navigation options, as provided to `Navigator.navigate`. If provided, the button will perform navigation on click.
  action: PropTypes.oneOf(["go-back", "timeout"]), // An optional built-in action for the button to perform
  localized: PropTypes.string, // A shorthand for <Button>{Strings.localized("MyStringsKey")}</Button>
  preventDefault: PropTypes.bool,
};

export default Button;
