let field;

if (document.querySelector("#clipboard-hidden-field") === null) {
  field = document.createElement("textarea");
  field.id = "clipboard-hidden-field";
  field.style.setProperty("position", "absolute", "important");
  field.style.setProperty("top", "-1000px", "important");
  field.style.setProperty("left", "-1000px", "important");
  field.style.setProperty("-webkit-touch-callout", "default", "important");
  field.style.setProperty("-webkit-user-select", "initial", "important");
  field.style.setProperty("-khtml-user-select", "initial", "important");
  field.style.setProperty("-moz-user-select", "initial", "important");
  field.style.setProperty("-ms-user-select", "initial", "important");
  field.style.setProperty("user-select", "initial", "important");

  document.body.appendChild(field);
}

export default class Clipboard {
  /** Copies the provided text into the clipboard */
  static copy(text) {
    field.value = text;
    field.select();
    return document.execCommand("copy");
  }
}
