import _ from "lodash";

class Size {
  constructor(width, height) {
    this.width = width;
    this.height = height;
  }
  static parse(json) {
    if (json === null || typeof json === "undefined") return null;
    const obj = JSON.parse(json);
    if (obj === null) throw new Error("Size.parse: Could not parse JSON");
    if (_.isUndefined(obj.width)) throw new Error("Size.parse: width is not defined");
    if (_.isUndefined(obj.height)) throw new Error("Size.parse: height is not defined");
    return new Size(obj.width, obj.height);
  }
  static tryParse(json) {
    try {
      return this.parse(json);
    } catch (error) {
      // eslint-disable-next-line no-empty
    }
  }
  negated() {
    return new Size(-this.width, -this.height);
  }
  scaledBy(number) {
    return new Size(this.width * number, this.height * number);
  }
  plus(size) {
    return new Size(this.width + size.width, this.height + size.height);
  }
  minus(size) {
    return new Size(this.width - size.width, this.height - size.height);
  }
  scaleFactorToFitProportionallyIn(size) {
    const horizontalScaleToMatch = size.width / this.width;
    const verticalScaleToMatch = size.height / this.height;
    return Math.min(horizontalScaleToMatch, verticalScaleToMatch);
  }
  scaleFactorToFillProportionallyIn(size) {
    const horizontalScaleToMatch = size.width / this.width;
    const verticalScaleToMatch = size.height / this.height;
    return Math.max(horizontalScaleToMatch, verticalScaleToMatch);
  }
}

export default Size;
