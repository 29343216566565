import localforage from "localforage";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import Log from "../helpers/log";

/**
 * PersistentQueue stores operations in persistent storage to ensure that they eventually succeed.
 * For example, it is used in the Mail helper to store a queue of emails to send. When the network
 * is available, items are dequeued almost instantly and sent, but when the network is down (or send
 * fails for some other reason), emails stay in the queue until the network comes back or the server
 * succeeds in sending the email. Then the queue is processed gradually until empty.
 */
export default class PersistentQueue {
  constructor(name, maxRetries = 10) {
    if (!name) throw new Error("PersistentQueue cannot be used without a queue name!");
    this.store = localforage.createInstance({ name });
    this.name = name;
    this.maxRetries = maxRetries;
  }

  saveToStorage(id, entry) {
    return this.store.setItem(`${this.name}-${id}`, JSON.stringify(entry)).catch((error) => Log.error(error));
  }

  loadFromStorage(id) {
    return this.store
      .getItem(`${this.name}-${id}`)
      .then((value) => JSON.parse(value))
      .catch((error) => Log.error(error));
  }

  deleteFromStorage(id) {
    return this.store.removeItem(`${this.name}-${id}`).catch((error) => Log.error(error));
  }

  loadQueue() {
    const rawQueue = localStorage.getItem(`${this.name}-queue`);
    return rawQueue ? JSON.parse(rawQueue) : [];
  }

  saveQueue(queue) {
    localStorage.setItem(`${this.name}-queue`, JSON.stringify(queue));
  }

  removeFromQueue(id) {
    this.saveQueue(this.loadQueue().filter((i) => i !== id));
  }

  requeue(id) {
    this.removeFromQueue(id);
    const queue = this.loadQueue();
    queue.push(id);
    this.saveQueue(queue);
  }

  push(entry) {
    const id = uuidv4();

    // 1. Store the actual entry
    this.saveToStorage(id, { ...entry, retry: 0 }).then(() => {
      // 2. Store the ID of this entry in the queue
      const queue = this.loadQueue();
      queue.push(id);
      this.saveQueue(queue);
    });
  }

  startProcessing(operation, cleanupEntryForLogging = (entry) => entry) {
    let processingEntry = false;
    const persistentQueue = this; // Fixes scoping issue
    setInterval(() => {
      if (processingEntry) return;

      const queue = persistentQueue.loadQueue();
      const id = _.first(queue);
      if (!id) return;

      Log.info(`Queue '${persistentQueue.name}': Processing ${id} (${queue.length} remaining in queue)...`);
      persistentQueue.loadFromStorage(id).then((entry) => {
        if (!entry) {
          persistentQueue.removeFromQueue(id);
          return;
        }

        processingEntry = true;
        operation(
          entry,
          () => {
            processingEntry = false;
            persistentQueue.removeFromQueue(id);
            persistentQueue.deleteFromStorage(id); // Async
            Log.info(`Queue '${persistentQueue.name}': Processed ${id} successfully`);
          },
          (error, retry = false) => {
            processingEntry = false;
            if (entry.retry >= this.maxRetries) {
              persistentQueue.removeFromQueue(id);
              persistentQueue.deleteFromStorage(id); // Async
              Log.warn(
                `Queue '${persistentQueue.name}': Entry removed from queue because it failed after ${this.maxRetries} tries!`,
                JSON.stringify(cleanupEntryForLogging(entry)),
              );
            } else if (retry) {
              Log.warn(
                `Queue '${persistentQueue.name}': Failed to process ${id} (${error.message}) -- Attempt ${entry.retry}/${this.maxRetries})`,
              );
              persistentQueue
                .saveToStorage(id, { ...entry, retry: entry.retry + 1 })
                .then(() => persistentQueue.requeue(id));
            } else {
              Log.warn(`Queue '${persistentQueue.name}': Failed to process ${id} (${error.message})`);
              persistentQueue.requeue(id); // We retry failed entries last
            }
          },
        );
      });
    }, 1000);
  }
}
