const math = require("mathjs");
import { fromLonLat } from "ol/proj";
import { Maths } from "../ripple";
import Point from "../types/point";
import Size from "../types/size";

function webMercatorPositionFromGeographicalPosition(latitude, longitude) {
  // Convert from latitude and longitude to Spherical ("Web") Mercator
  // From EPSG:4326 (http://www.spatialreference.org/ref/epsg/4326/)
  // To EPSG:3857 (http://www.spatialreference.org/ref/epsg/3785/)
  const webMercatorPoint = new Point(...fromLonLat([longitude, latitude], "EPSG:3857"));

  // EPSG:3857 coordinates are centered in a viewport with bounds:
  // -20037508.3428, -19971868.8804, 20037508.3428, 19971868.8804
  // We offset the center to get a coordinate from the corner of the projection,
  // then invert it vertically to get something closer to what we need for layout.
  const size = new Size(40075016.6856, 39943737.7608);
  const webMercatorPointFromBottomLeft = webMercatorPoint.plus(size.scaledBy(0.5));
  const webMercatorPointFromTopLeft = new Point(
    webMercatorPointFromBottomLeft.x,
    size.height - webMercatorPointFromBottomLeft.y,
  );

  return webMercatorPointFromTopLeft;
}

function convertPositionToGeolocation(rawPosition) {
  return rawPosition
    ? {
        latitude: rawPosition.coords.latitude,
        longitude: rawPosition.coords.longitude,
        altitude: rawPosition.coords.altitude,
      }
    : null;
}

// Watch out! Each sub-array represents a matrix COLUMN!
// That's why the matrix does not look like the mathematical notation.

const makeTranslationMatrix = (tx, ty) =>
  math.matrix([
    [1, 0, 0],
    [0, 1, 0],
    [tx, ty, 1],
  ]);

const makeRotationMatrix = (angleInRadians) => {
  return math.matrix([
    [Math.cos(angleInRadians), Math.sin(angleInRadians), 0],
    [-Math.sin(angleInRadians), Math.cos(angleInRadians), 0],
    [0, 0, 1],
  ]);
};

const multiplyMatrices = (...matrices) => {
  return matrices.reduce((acc, matrix) => {
    if (acc === null) return matrix;
    return math.multiply(acc, matrix);
  }, null);
};

export default class Geolocation {
  static parseCoordinates(string) {
    const components = string?.split(",").map((s) => parseFloat(s.trim()));
    if (components?.length !== 2) return null;
    return {
      latitude: components[0],
      longitude: components[1],
    };
  }

  static getCurrentGeolocation(onUpdate, onError) {
    const position = navigator.geolocation.getCurrentPosition(onUpdate, onError);
    return convertPositionToGeolocation(position);
  }

  static checkIfAllowed(onResult) {
    navigator.geolocation.getCurrentPosition(
      () => {
        onResult(true);
      },
      (error) => {
        onResult(error.PERMISSION_DENIED !== 1);
      },
    );
  }

  static geolocationSubscribe(onUpdate, onError) {
    return navigator.geolocation.watchPosition(
      (position) => onUpdate(convertPositionToGeolocation(position)),
      onError,
      {
        enableHighAccuracy: true,
        maximumAge: 5000,
      },
    );
  }

  static geolocationUnsubscribe(id) {
    navigator.geolocation.clearWatch(id);
  }

  /**
   * You want to map a geographic position to a rectangular area cut from the full web mercator projection.
   * You want a local map and position the user or a pin on it using a latitude and longitude. You use this.
   *
   * Input accepts geographic coordinates (latitude and longitude) exclusively.
   * Output is normalized cartesian coordinates (0...1) in a flat map cut from the full web mercator projection.
   *
   * Example usage:
   *
   *     // Given the rectangular area between those two geographical coordinates:
   *     // TOP LEFT (45.406618, -73.579428), BOTTOM RIGHT (45.383287, -73.548711)
   *
   *     const userPosition = { latitude: 45.397384, longitude: -73.571796 };
   *     const region = {
   *       topLatitude: 45.406618,
   *       leftLongitude: -73.579428,
   *       bottomLatitude: 45.383287,
   *       rightLongitude: -73.548711
   *     };
   *
   *     const { x, y } = Geolocation.normalizedPositionInFrame(userPosition, region);
   *     // Where `x` and `y` are normalized (0...1) positions on the rectangular image
   *     // corresponding to the provided region.
   *
   * An approach to generate an image for a rectangular region of the full Web Mercator projection:
   *
   * 1. Download and install *Google Maps Downloader* and activate it (we have a licence for it).
   *    Can be run on macOS with Wine if necessary.
   *
   * 2. Enter the latitude and longitude defining the rectangular region of interest (WATCH OUT!
   *    WE WON'T USE THOSE COORDINATES DIRECTLY IN THE CODE BECAUSE OF THE WAY THE MAP IS DOWNLOADED
   *    AS TILES OF FIXED SIZE AND COUNT! SEE BELOW FOR DETAILS.)
   *
   * 3. Set the zoom level to an appropriately high value (app license must be activated). This is important
   *    to get tiles small enough for the final image to more closely match the wanted region's rectangular area.
   *
   * 4. Start the task and output files on disk somewhere.
   *
   * 5. From the `Tools` menu of *Google Maps Downloader*, open the `Map Combiner` tool and open the
   *    task generated in the previous step. This will create a single image of the map.
   *
   * 6. Open the log file that can be found in the root folder of the task. Take note of the following:
   *
   *        Left Longitude  download=...
   *        Right Longitude download=...
   *        Top Latitude    download=...
   *        Bottom Latitude download=...
   *
   * 7. When mapping a geographical position to the generated map image in code (by calling this function),
   *    use the "download" bounds, NOT the original bounds. If the original bounds are used the pins will
   *    be slighly (or very) offset from their intended position, depending on the zoom level and region
   *    selected when downloading the map. If the "download" bounds are used, the pins will be perfectly
   *    positioned on the map.
   */
  static normalizedPointInRegion(geolocation, { topLatitude, leftLongitude, bottomLatitude, rightLongitude }) {
    if (!geolocation) return null;

    const webMercatorPosition = webMercatorPositionFromGeographicalPosition(
      geolocation.latitude,
      geolocation.longitude,
    );

    const regionTopLeftWebMercatorPosition = webMercatorPositionFromGeographicalPosition(topLatitude, leftLongitude);
    const regionBottomRightWebMercatorPosition = webMercatorPositionFromGeographicalPosition(
      bottomLatitude,
      rightLongitude,
    );

    const x = Maths.ratio(
      webMercatorPosition.x,
      regionTopLeftWebMercatorPosition.x,
      regionBottomRightWebMercatorPosition.x,
    );
    const y = Maths.ratio(
      webMercatorPosition.y,
      regionTopLeftWebMercatorPosition.y,
      regionBottomRightWebMercatorPosition.y,
    );

    return new Point(isNaN(x) ? 0.5 : x, isNaN(y) ? 0.5 : y);
  }

  /**
   * This transforms a point from a "full map" to an angled rectangle cutout taken from that full map.
   * This allows positioning things with GPS coordinates on a map that is not a straight export from
   * Google Maps Downloader, rather an angled rectangle chunk of it.
   * See `doc/geolocation/geolocation-angled-map-cutout-transform.md` for a detailed explanation.
   */
  static transformPointFromFullMapToMapCutout(
    pointInPixels,
    cutoutTopLeftXInPixels,
    cutoutTopLeftYInPixels,
    cutoutAngleInRadians,
  ) {
    if (!pointInPixels) return null;

    const combinedMatrix = multiplyMatrices(
      makeTranslationMatrix(-cutoutTopLeftXInPixels, -cutoutTopLeftYInPixels),
      makeRotationMatrix(cutoutAngleInRadians),
    );

    const pointMatrix = math.matrix([pointInPixels.x, pointInPixels.y, 1]);
    const result = math.multiply(pointMatrix, combinedMatrix);

    const transformedX = result.subset(math.index(0));
    const transformedY = result.subset(math.index(1));

    return new Point(transformedX, transformedY);
  }
}
