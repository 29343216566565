import styled from "styled-components";
import { standardGap } from "../../../common";
import Box from "../../../components/box";
import Question from "../../../components/question";

export const Type14QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: calc(${standardGap} * 6);
`;

export const LinesCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${standardGap};
`;

export const LeftColumn = styled(Column)``;

export const RightColumn = styled(Column)``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ItemBox = styled(Box)`
  height: ${(props) => props.$size};
  width: ${(props) => props.$size};
`;

export const ConnectionCircle = styled(Box).attrs((props) => ({ appearance: "active", borderRadius: "50%" }))`
  width: 3vh;
  height: 3vh;
  margin: ${standardGap};
`;
