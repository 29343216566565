import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Audio, DragPreview, resource, useData } from "ripple";
import { GoodImage, WrongImage } from "../../../common";
import ResponsiveContent from "../../../components/responsive-content";
import { DroppableContainer } from "../drag-word-complexe-question/styled";
import {
  BoldWordSentence,
  ButtonContentPreview,
  Card,
  CardImage,
  CardImageContainer,
  CardSpeakerButton,
  ChoiceWordButton,
  DragWordSimpleQuestionRoot,
  DroppableText,
  DropzoneContainer,
  DropzoneText,
  LandscapeButtonContainer,
  Line,
  PortraitButtonContainer,
  QuestionMarkImage,
  SentenceBar,
  WordButtonContainer,
  WordDroppable,
  WordDropzone,
  WordSentence,
  WordText,
  WordTextDroppable,
} from "./styled";

const DragWordSimpleQuestion = ({ onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const buttonItems = node.children.find((x) => x.semantic === "DragWordSimpleChoices").children;
  const items = node.children.find((x) => x.semantic === "DragWordSimpleWords").children;
  const client = useData((data) => data.root);
  const color = node.parent.wantedText("Color")?.trim();
  const audio = node.wantedMedia("Audio", "Audio");

  const [goodAnswer, setGoodAnswer] = useState(false);
  const [wrongAnswer, setWrongAnswer] = useState(false);

  const line1 = _.takeWhile(items, (item) => item.semantic === "WordItem");
  let line2 = null;
  if (line1.length !== items.length) line2 = _.takeRightWhile(items, (item) => item.semantic === "WordItem");

  const onDragStart = useCallback(() => {}, []);

  const onTouch = useCallback(() => Audio.discrete("effects").play(resource("audio/ClicDrag.mp3")), []);

  const onDragEnd = useCallback(() => {
    Audio.discrete("effects").play(resource("audio/DragRelease.mp3"));
  }, []);

  const onDrop = useCallback((dropzoneId, droppableId) => {
    if (droppableId.settings.Answer === "good") {
      setGoodAnswer(true);
    }

    if (droppableId.settings.Answer === "bad") {
      setWrongAnswer(true);
    }
  }, []);

  useEffect(() => {
    if (!goodAnswer) return;
    Audio.discrete("effects").play(resource("audio/ThumbUp01.mp3"));
    setTimeout(() => {
      onComplete();
    }, 1500);
  }, [client, goodAnswer, onComplete]);

  useEffect(() => {
    if (!wrongAnswer) return;
    Audio.discrete("effects").play(resource("audio/ThumbDown01.mp3"));
    setTimeout(() => {
      setWrongAnswer(false);
    }, 2000);
  }, [client, wrongAnswer]);

  const createRenderDropzoneForegroundLayer = (item) => () => {
    return (
      <div>
        <DropzoneText color={color} key={item.id}>
          {item.wantedText("Text")}
        </DropzoneText>
        <QuestionMarkImage />
      </div>
    );
  };

  const renderWordButton = (word) => {
    const isDropzone = word.settings.Answer === "good";

    return !isDropzone ? (
      <WordText show={true} color={color} key={word.id}>
        {word.wantedText("Text")}
      </WordText>
    ) : (
      <DropzoneContainer key={word.id}>
        {/* HACK Add text invisible require to determine the size of the dropzone. */}
        <WordText show={false} color={color} key={word.id}>
          {word.wantedText("Text")}
        </WordText>
        <WordDropzone
          color={color}
          id={word.id}
          onDrop={onDrop}
          hasDropped={false}
          renderEmptyLayer={createRenderDropzoneForegroundLayer(word)}
          renderForegroundLayer={createRenderDropzoneForegroundLayer(word)}
        />
      </DropzoneContainer>
    );
  };

  const renderWordSentence = (word) => {
    const answer = word.settings.Answer === "good";
    return answer ? (
      <BoldWordSentence>{word.wantedText("Text")}</BoldWordSentence>
    ) : (
      <WordSentence>{word.wantedText("Text")}</WordSentence>
    );
  };

  const createRenderDroppableForegroundLayer = (item) => () => <DroppableText>{item.wantedText("Text")}</DroppableText>;

  const createRenderDroppableEmptyLayer = (item) => () => <DroppableText>{item.wantedText("Text")}</DroppableText>;

  const renderChoiceWordButton = (item, index) => {
    return (
      <ChoiceWordButton item={item} key={item.id} color={color} disabled={false}>
        <DroppableContainer>
          <WordTextDroppable show={false}>{item.wantedText("Text")}</WordTextDroppable>
          <WordDroppable
            key={item.id}
            id={item}
            onTouch={onTouch}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            hasDropped={false}
            renderForegroundLayer={createRenderDroppableForegroundLayer(item)}
            renderEmptyLayer={createRenderDroppableEmptyLayer(item)}
          ></WordDroppable>
        </DroppableContainer>
      </ChoiceWordButton>
    );
  };

  const renderDragPreview = useCallback(
    (item) => (
      <ButtonContentPreview key={item.id} color={color}>
        <WordText show={true} color={color}>
          {item.wantedText("Text")}
        </WordText>
      </ButtonContentPreview>
    ),
    [color],
  );

  return (
    <DragWordSimpleQuestionRoot {...rest} node={node}>
      <ResponsiveContent
        sidePanelContent={
          <WordButtonContainer>
            <LandscapeButtonContainer>{buttonItems.map(renderChoiceWordButton)}</LandscapeButtonContainer>
          </WordButtonContainer>
        }
        bottomPanelContent={
          <PortraitButtonContainer>{buttonItems.map(renderChoiceWordButton)}</PortraitButtonContainer>
        }
      >
        <Card color={color}>
          <CardImage src={node.wantedMedia("CardImage", "CardImage")} />
          <CardImageContainer color={color}>
            <CardSpeakerButton src={audio} />
            {goodAnswer && <GoodImage />}
            {wrongAnswer && <WrongImage />}
          </CardImageContainer>

          <SentenceBar color={color}>
            {!goodAnswer && (
              <>
                <Line>{line1.map(renderWordButton)}</Line>
                {line2 && <Line>{line2.map(renderWordButton)}</Line>}
              </>
            )}
            {goodAnswer && (
              <>
                <Line>{line1.map(renderWordSentence)}</Line>
                {line2 && <Line>{line2.map(renderWordSentence)}</Line>}
              </>
            )}
          </SentenceBar>
        </Card>
      </ResponsiveContent>
      <DragPreview render={renderDragPreview} />
    </DragWordSimpleQuestionRoot>
  );
};

DragWordSimpleQuestion.propTypes = { onComplete: PropTypes.func };

export default DragWordSimpleQuestion;
