import _ from "lodash";
import { Subject } from "rxjs";

const eventStream$ = new Subject();

const AnalyticsObservables = {
  started: false, // Events will only be emitted in the stream when started
  backlog: [], // Keeps events to emit later when the observable is started
  initialize: function () {
    return {
      eventStream$: eventStream$,

      // Used to emit an analytics event.
      // Do not use the eventStream$ Subject externally.
      // Use Analytics.track() to emit events, not this directly.
      emit: (event) => {
        this.started ? eventStream$.next(event) : this.backlog.push(event);
      },

      // Start emitting events in the stream, and instantly emit any
      // events that were put in the backlog while stopped.
      // This ensures that all events (including those that were received
      // very early at app startup) are received by the observable's subscribers.
      start: () => {
        this.started = true;
        _.each(this.backlog, (event) => eventStream$.next(event)); // Emit all backlog events instantly
        this.backlog = []; // Clean after ourselves
      },
    };
  },
};

export default AnalyticsObservables;
