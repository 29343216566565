import { useRef, useEffect, useState } from "react";

/** Keeps the last value that passes the provided validation test. */
export const useLastValid = (value, validation) => {
  if (!validation) throw new Error("useLastValid hook requires a validation function!");

  const [refreshUpdateCounter, setRefreshUpdateCounter] = useState(0);
  const previousValidRef = useRef(validation(value) ? value : undefined);
  useEffect(() => {
    if (!validation(value)) return;
    previousValidRef.current = value;
  }, [validation, value]);

  const reset = (resetValue) => {
    previousValidRef.current = resetValue || undefined;
    setRefreshUpdateCounter(refreshUpdateCounter + 1); // Force refresh the user only when resetting
  };

  const lastValidValue = validation(value) ? value : previousValidRef.current;

  return [lastValidValue, reset];
};
