import _ from "lodash";
import { useRef } from "react";

export class VideoSharedPlaybackState {
  isInitialized = false;
  isPlaying = false;
  progress = 0;
  volume = 1;
}

/**
 * Use this and pass the resulting state to multiple video instances to
 * share playback state (resume playback from previous location, etc.).
 * Mostly useful to preserve state when maximizing.
 * See the `Video` component for more information.
 */
export const useVideoSharedPlaybackState = () => {
  const sharedVideoPlaybackStateRef = useRef(new VideoSharedPlaybackState());
  return sharedVideoPlaybackStateRef.current;
};

export const useVideoSharedPlaybackStates = (count) => {
  const sharedVideoPlaybackStatesRef = useRef(_.map(_.range(0, count), () => new VideoSharedPlaybackState()));
  return sharedVideoPlaybackStatesRef.current;
};
