export default class Random {
  /** Generate a random integer between min (inclusive) and max (inclusive) */
  static int(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  /** Generate a random float between min (inclusive) and max (inclusive) */
  static float(min, max) {
    return Math.random() * (max - min) + min;
  }

  /** Returns a random string identifier (for less-than-critical stuff!) */
  static identifier(length = 10) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let identifier = "";

    for (let i = 0; i < length; i++) identifier += characters.charAt(Math.floor(Math.random() * characters.length));

    return identifier;
  }

  /** Returns a random item from the array */
  static fromArray(array) {
    return array[Random.int(0, array.length - 1)];
  }
}
