import { memo, useCallback, useState } from "react";
import { useParams } from "react-router";
import { Config, useData, useLastTruthy } from "ripple";
import { useProgressStorage } from "../../hooks/use-progress-storage";
import InfoButton from "./info-button";
import ResourcesSheet from "./resources-sheet";
import {
  BottomBar,
  Page,
  PageContent,
  ProgressArea,
  ProgressBar,
  ProgressText,
  StyledThemeButton,
  ThemeTransitioner,
  ThemesRow,
} from "./styled";
import ThemeContent from "./theme-content";

const MobileMainPage = memo(() => {
  const client = useData((data) => data.root);

  const { id: idParam } = useParams();
  const id = idParam ?? client.children.filter((c) => c.semantic === "Theme")[0].id;

  const currentTheme = useData((data) => data.requiredNode(id));
  const themes = (Config.testing ? client.allChildren : client.children).filter((c) => c.semantic === "Theme");
  const resources = client.children.find((c) => c.semantic === "Resources").children[0].children;

  const { getCompletedTotal } = useProgressStorage();
  const completedQuestions = getCompletedTotal();

  const [resource, setResource] = useState(null);
  const [lastTruthyResource] = useLastTruthy(resource);

  const totalQuestionsInAllThemes = themes
    .flatMap((theme) => (Config.testing ? theme.allChildren : theme.children))
    .flatMap((activity) => (Config.testing ? activity.allChildren : activity.children))
    .flatMap((game) => (Config.testing ? game.allChildren : game.children));

  const onInfoButtonClick = useCallback(() => {
    setResource(resources[0]);
  }, [resources]);

  const onResourcesSheetResourceClick = useCallback((resource) => setResource(resource), []);

  const onResourcesSheetCloseClick = useCallback(() => {
    setResource(null);
  }, []);

  return (
    <Page rootBackground={client.wantedMedia("MainBackgroundMobile", "FullscreenImage")}>
      <PageContent>
        <ThemesRow>
          {themes.map((theme) => {
            return (
              <StyledThemeButton
                key={theme.id}
                theme={theme}
                active={theme === currentTheme}
                navigate={{ node: theme }}
              />
            );
          })}
        </ThemesRow>
        <ThemeTransitioner transitionKey={id}>
          <ThemeContent theme={currentTheme} />
        </ThemeTransitioner>
        <BottomBar>
          <InfoButton onClick={onInfoButtonClick} />
          <ProgressArea>
            <ProgressBar value={completedQuestions / totalQuestionsInAllThemes.length} />
            <ProgressText>
              {completedQuestions}/{totalQuestionsInAllThemes.length}
            </ProgressText>
          </ProgressArea>
        </BottomBar>
      </PageContent>
      <ResourcesSheet
        show={!!resource}
        resources={resources}
        resource={lastTruthyResource}
        onResourceClick={onResourcesSheetResourceClick}
        onCloseClick={onResourcesSheetCloseClick}
      />
    </Page>
  );
});

export default MobileMainPage;
