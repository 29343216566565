import PropTypes from "prop-types";
import { forwardRef, memo, useCallback, useEffect, useRef, useState } from "react";
import Audio from "../../../helpers/audio";
import Classes from "../../../helpers/classes";
import { Styles } from "../../../ripple";
import Sequencer from "../../../sequencer";
import { useStagger } from "../../hooks/specialized/use-stagger";
import { useKeyboardShortcut } from "../../hooks/use-keyboard-shortcut";
import { usePrevious } from "../../hooks/use-previous";
import { StaggerContext } from "../stagger";

const Revealer = memo(
  forwardRef(
    (
      {
        className,
        style,
        children,
        staggerOptions = {},
        show = true,
        showPointerEvents = "inherit",
        transition,
        timeout = 1000,
        disableExitStagger = false,
        preserveChildren,
        debugKeyEquivalent,
        enterSound,
        exitSound,
        onEnter,
        onExit,
        ...rest
      },
      ref,
    ) => {
      staggerOptions = { delay: 150, ...staggerOptions };

      const sequencerRef = useRef(new Sequencer());
      const currentStateRef = useRef(show);
      const stagger = useStagger({ ...staggerOptions, automatic: false });
      const actualTransition = transition || "fade-in-over";

      const [mountChildren, setMountChildren] = useState(show);

      const previousShow = usePrevious(show);
      const [transitionClassName, setTransitionClassName] = useState(show ? null : `${actualTransition}-enter`);

      const onToggleStaggerShortcut = useCallback(() => stagger.toggle(), [stagger]);
      useKeyboardShortcut("Toggle Revealer Stagger", debugKeyEquivalent, onToggleStaggerShortcut);

      const exitTimeoutRef = useRef(null);

      const enter = useCallback(() => {
        const sequencer = sequencerRef.current;
        sequencer.clear({ cancelCurrentTask: false });
        sequencer.do(() => {
          // Cancel the current exit timeout if any, so that children don't get unmounted
          clearTimeout(exitTimeoutRef.current);
          setMountChildren(true);
        });
        sequencer.doSequence((s) => {
          if (currentStateRef.current === "enter") return;
          s.do(() => (currentStateRef.current = "enter"));
          s.doWaitForRelease((release) => {
            if (enterSound) Audio.discrete("revealers").play(enterSound);
            setTransitionClassName(`${actualTransition}-enter`);
            setTimeout(() => setTransitionClassName(`${actualTransition}-enter ${actualTransition}-enter-active`), 50);
            stagger.enter({ onComplete: release });
          });
        });
      }, [actualTransition, enterSound, stagger]);

      const exit = useCallback(() => {
        const sequencer = sequencerRef.current;
        sequencer.clear({ cancelCurrentTask: false });

        const runExitTransition = () => {
          if (exitSound) Audio.discrete("revealers").play(exitSound);
          setTransitionClassName(`${actualTransition}-exit`);
          setTimeout(() => setTransitionClassName(`${actualTransition}-exit ${actualTransition}-exit-active`), 50);
        };

        sequencer.doSequence((s) => {
          if (currentStateRef.current === "exit") return;
          s.do(() => {
            exitTimeoutRef.current = setTimeout(() => setMountChildren(false), timeout);
          });
          s.do(() => (currentStateRef.current = "exit"));

          if (disableExitStagger) {
            runExitTransition();
            return;
          }

          s.doWaitForRelease((release) => {
            stagger.exit({
              sort: "reverse",
              onComplete: () => {
                runExitTransition();
                release();
              },
            });
          });
        });
      }, [exitSound, actualTransition, disableExitStagger, timeout, stagger]);

      useEffect(() => {
        if (!previousShow && show) {
          enter();
          if (onEnter) onEnter();
        } else if (previousShow && !show) {
          exit();
          if (onExit) onExit();
        }
      }, [enter, exit, onEnter, onExit, previousShow, show]);

      // Delay setting pointer-events to none on close to avoid an unwanted click
      // _behind_ the Revealer when clicking something inside it to close it (such as a close button
      // in the Revealer panel).
      const [pointerEvents, setPointerEvents] = useState(show ? showPointerEvents : "none");
      useEffect(() => {
        if (show) {
          setPointerEvents(showPointerEvents);
          return;
        }
        const timeout = setTimeout(() => setPointerEvents("none"), 50);
        return () => clearTimeout(timeout);
      }, [show, showPointerEvents]);

      return (
        <div
          ref={ref}
          className={Classes.build("ripple-revealer", className, transitionClassName)}
          style={Styles.merge(style, { pointerEvents })}
          {...rest}
        >
          <StaggerContext.Provider value={stagger}>
            {preserveChildren ? children : mountChildren ? children : null}
          </StaggerContext.Provider>
        </div>
      );
    },
  ),
);

Revealer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  staggerOptions: PropTypes.object,
  show: PropTypes.bool,
  showPointerEvents: PropTypes.oneOf(["inherit", "auto", "none"]), // In some cases, we don't want to inherit the parent's pointer-events. Use "auto" then.
  transition: PropTypes.string,
  timeout: PropTypes.number, // The transition timeout after which the children will be removed, unless `preserveChildren` is set to `true`
  disableExitStagger: PropTypes.bool,
  preserveChildren: PropTypes.bool, // Whether to keep the children mounted when the revealer is hidden
  debugKeyEquivalent: PropTypes.string,
  enterSound: PropTypes.string,
  exitSound: PropTypes.string,
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
};

export default Revealer;
