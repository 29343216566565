import { lighten } from "polished";
import { Button, Image, staggerStep } from "ripple";
import styled from "styled-components";
import { OldQuestionRoot } from "../../../common";
import SpeakerButton from "../../../components/speaker-button";

export const ClickwordQuestionRoot = styled(OldQuestionRoot)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Card = staggerStep({ transition: "flip-left" })(styled(Button)`
  isolation: isolate;
  width: ${(props) => (props.shrink ? "15vh" : "18vh")};
  height: ${(props) => (props.shrink ? "21vh" : "25.3vh")};
  margin: 0.5vh;

  background-color: ${(props) => lighten(0.4, props.color)};

  transition: all 150ms ease-in-out;

  ${(props) => {
    if (props.active) {
      return `
        box-shadow: 0 0 2vmin 2vmin ${props.color};
    `;
    } else {
      return `
        box-shadow: 0 0 0 0.4vmin ${props.color};
    `;
    }
  }};

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 2vh;
  overflow: hidden;
`);

export const CardImage = styled(Image).attrs((props) => ({ scaling: "fill", fadeIn: "always" }))`
  height: auto;
  width: 100%;
  transition: opacity 150ms ease-in-out;
  opacity: 1;

  border-radius: 2vh 2vh 0 0;
`;

export const CardBar = styled(Button)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 12%;
  width: 100%;

  transition: opacity 150ms ease-in-out;
  background-color: ${(props) => lighten(0.3, props.color)};
`;

export const CardSpeakerButton = styled(SpeakerButton)`
  min-width: 3vh;
  width: 3vh;
  height: 3vh;
  margin-right: 1.5vh;
  z-index: 1;

  .hotspot {
    margin-left: -10vmin;
  }
`;
