import _ from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Image, Selector, resource, useConstant, useData, usePrevious, useQueryString } from "ripple";
import { ActivityPageTitle, GameTopBarContainer } from "../../common";
import GamePills from "../../components/game-pills";
import GameProgressBar from "../../components/game-progress-bar";
import { useActivityBackButtonCallback } from "../../hooks/use-activity-back-button-callback";
import { useGlobalAudioPlayer } from "../../hooks/use-global-audio-player";
import { useIsMobileVersion } from "../../hooks/use-is-mobile-version";
import { useProgressStorage } from "../../hooks/use-progress-storage";
import { useTwoStepGameRootBackground } from "../../hooks/use-two-step-game-root-background";
import {
  Card,
  CardBar,
  CardImage,
  CardProgressPills,
  CardSpeakerButton,
  CardText,
  CardsSelector,
  Content,
  LeftSelectorArrow,
  Overlay,
  Page,
  RightSelectorArrow,
  SelectorContainer,
  StyledBackButton,
} from "./styled";

const CardsActivityPage = memo(() => {
  const isMobileVersion = useIsMobileVersion();
  const { id } = useParams();

  // Having both of those be null will lead to undefined behavior
  const { initialGameId, initialCardId } = useQueryString();

  const initialGame = useData((data) => data.optionalNode(initialGameId));
  const initialCard = useData((data) => data.optionalNode(initialCardId) ?? initialGame?.children[0]);

  const activity = useData((data) => data.requiredNode(id));
  const games = activity.children;
  const cards = useConstant(
    () =>
      isMobileVersion
        ? initialCard.parent.children // On mobile, only show the cards within the current game
        : activity.children.flatMap((game) => game.children), // On web, show all cards in a flat list
  );

  const rawInitialCardIndex = cards.indexOf(initialCard);
  const initialCardIndex = rawInitialCardIndex === -1 ? 0 : rawInitialCardIndex;

  const selectorRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(initialCardIndex);
  const [currentCard, setCurrentCard] = useState(initialCard);
  const currentGame = currentCard?.parent;
  const previousIndex = usePrevious(currentIndex);

  const { addCompleted } = useProgressStorage();

  useTwoStepGameRootBackground(currentGame);

  const { toggle, stop } = useGlobalAudioPlayer();

  const createOnCardClick = (audio) => () => {
    toggle(audio);
  };

  const onGameClick = useCallback(
    (game) => {
      const index = _.indexOf(cards, game.children[0]);
      if (index !== -1) selectorRef.current.jumpTo(index);
    },
    [cards],
  );

  const onIndexChange = useCallback(
    (index) => {
      const card = cards[index];
      setCurrentIndex(index);
      setCurrentCard(card);
      addCompleted(card);
    },
    [addCompleted, cards],
  );
  const onPreviousClick = useCallback(() => selectorRef.current.previous(), []);
  const onNextClick = useCallback(() => selectorRef.current.next(), []);
  const onBackButtonClick = useActivityBackButtonCallback(activity);

  // Stop Current audio on Selector navigation
  useEffect(() => {
    if (previousIndex === undefined || previousIndex === null) return;
    stop();
  }, [previousIndex, currentIndex, stop]);

  return (
    <Page>
      <ActivityPageTitle>{activity.wantedText("Title")}</ActivityPageTitle>
      <StyledBackButton activity={activity} onClick={onBackButtonClick} />
      <Content>
        <GameTopBarContainer>
          {isMobileVersion ? (
            <GameProgressBar game={currentGame} currentChild={currentCard} />
          ) : (
            <GamePills games={games} selectedGame={currentGame} onClick={onGameClick}></GamePills>
          )}
        </GameTopBarContainer>
        <SelectorContainer>
          <LeftSelectorArrow disabled={currentIndex === 0} onClick={onPreviousClick}>
            <Image src={resource("images/Button_Back_Symbol.svg")} />
          </LeftSelectorArrow>
          <CardsSelector
            ref={selectorRef}
            items={cards}
            sideItemCount={2}
            initialIndex={initialCardIndex}
            itemDynamicRotation={Selector.rotation.off()}
            itemDynamicScale={Selector.scale.unfocused(0.8)}
            itemDynamicOpacity={Selector.opacity.unfocused(0.5)}
            onIndexChange={onIndexChange}
          >
            {(card) => {
              const color = card.parent.wantedText("Color")?.trim();
              const audio = card.wantedMedia("Audio", "Audio");
              const showContent = card === currentCard;
              return (
                <Card disabled={!showContent} color={color} onClick={createOnCardClick(audio)}>
                  <CardImage src={card.wantedMedia("CardImage", "CardImage")} show={showContent} />
                  <CardBar $show={showContent}>
                    <CardText>{card.optionalText("Text")}</CardText>
                    {audio && <CardSpeakerButton src={audio} />}
                  </CardBar>
                </Card>
              );
            }}
          </CardsSelector>
          <RightSelectorArrow disabled={currentIndex === cards.length - 1} onClick={onNextClick}>
            <Image src={resource("images/Button_Back_Symbol.svg")} />
          </RightSelectorArrow>
        </SelectorContainer>
        <CardProgressPills game={currentGame} activeNode={currentCard}></CardProgressPills>
      </Content>
      <Overlay src={activity.optionalMedia("Overlay", "PreserveSizeWithAlpha")} />
    </Page>
  );
});

export default CardsActivityPage;
