import _ from "lodash";
import { memo, useEffect, useState } from "react";

import Config from "../../../helpers/config";
import Strings from "../../../helpers/strings";
import AnalyticsServerApi from "../../../logic/analytics/analytics-server-api";
import Button from "../../components/button";
import Page from "../../components/page";
import { useConstant } from "../../hooks/use-constant";
import { useQueryString } from "../../hooks/use-query-string";

const PageComponent = memo(() => {
  const { password } = useQueryString();

  // This is far from good security, but it's sufficient to prevent
  // most people from accessing the admin page. Worst case, the analytics
  // information does not contain sensitive data anyway.
  if (password !== "5549") throw new Error("You are not authorized to access this page");

  const api = useConstant(
    () =>
      new AnalyticsServerApi(
        Config.analytics.server.url,
        Config.analytics.server.readUsername,
        Config.analytics.server.readPassword,
      ),
  );

  const [apps, setApps] = useState(null);

  useEffect(() => {
    if (Config.analytics.source === "server") api.getApps().then((apps) => setApps(apps));
  }, [api]);

  const renderInactive = () => <h2>{`${Strings.localized("AnalyticsAdminInactive")} ${Config.analytics.source}`}</h2>;

  const renderLoading = () => <div className="loading">{Strings.localized("AnalyticsAdminLoadingApps")}</div>;

  const renderApp = (app) => (
    <Button
      key={app.id}
      className="app"
      navigate={{ path: "/analytics", replaceQuery: { appId: app.id } }}
    >{`${app.id} (${app.eventCount})`}</Button>
  );

  const renderApps = () => {
    const actualApps = apps || [];
    return (
      <>
        <ul>
          <li>
            <span className="label">{Strings.localized("AnalyticsAdminSource")}:&nbsp;</span>
            <span className="value">{Config.analytics.server.url}</span>
          </li>
          <li>
            <span className="label">{Strings.localized("AnalyticsAdminTotalEvents")}:&nbsp;</span>
            <span className="value">{_.sumBy(actualApps, (app) => app.eventCount)}</span>
          </li>
        </ul>
        <div className="apps">{_.map(actualApps, renderApp)}</div>
      </>
    );
  };

  return (
    <Page className="analytics-admin ripple-dashboard" pauseTimeout="reset">
      <h1>{Strings.localized("AnalyticsAdminTitle")}</h1>
      {Config.analytics.source === "server" ? (apps ? renderApps() : renderLoading()) : renderInactive()}
    </Page>
  );
});

export default PageComponent;
