import Sequencer from "../sequencer";

class DoWaitForReleasesTask {
  constructor(count, action) {
    this.count = count;
    this.action = action;
    this.count = count;
  }

  perform(release) {
    const decrementAndCheck = () => {
      this.count -= 1;
      if (this.count === 0) release();
    };
    this.action(decrementAndCheck);
  }

  cancel(release) {
    release();
  }
}

Sequencer.prototype.doWaitForReleases = function (count, action) {
  this.push(new DoWaitForReleasesTask(count, action));
  return this;
};
