import YAML from "yaml";
import YamlResource from "../logic/yaml-resource";
import resource from "./resource";

const yamlResource = new YamlResource();

export const getInstanceConfig = () => {
  const str = localStorage.getItem("instance-config.yml");
  return str ? YAML.parse(str) : {};
};

export const setInstanceConfig = (object) => {
  localStorage.setItem("instance-config.yml", object ? YAML.stringify(object, { lineWidth: 0 }) : "");
};

export const loadConfig = async () => {
  await yamlResource.loadFromURLs(
    resource("data/core-config.yml"),
    resource("data/custom-config.yml"),
    resource("data/local-config.yml[optional]"),
  );

  yamlResource.mergeValues(getInstanceConfig());
};

export const mergeValuesIntoConfig = (...args) => yamlResource.mergeValues(...args);

export default yamlResource.values;

window.ripple = { ...(window.ripple || {}), config: yamlResource.values };
