import { useEffect, useRef } from "react";
import { useLatestRef } from "./internal/use-latest-ref";

export const useTimeout = (callback, delay) => {
  const timeoutRef = useRef(null);
  const mostRecentCallbackRef = useLatestRef(callback);

  useEffect(() => {
    if (typeof delay !== "number") return;
    timeoutRef.current = window.setTimeout(() => {
      const mostRecentCallback = (...args) => mostRecentCallbackRef.current(...args);
      mostRecentCallback?.();
    }, delay);
    return () => window.clearTimeout(timeoutRef.current);
  }, [delay, mostRecentCallbackRef]);
  return timeoutRef;
};
