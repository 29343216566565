import _ from "lodash";

export default class Props {
  /** Compares two "children" props and returns true if the children are the
   * same. Use this to determine if the children prop has changed in
   * componentDidUpdate. */
  static areImmediateChildrenEqual(children1, children2) {
    // Handle various cases of null
    if (!children1 && !children2) return true;
    if (!children1 && children2) return false;
    if (children1 && !children2) return false;

    // If they are of a different type, then they are necessarily different
    if (typeof children1 !== typeof children2) return false;

    const childrenArray1 = _.map(Array.isArray(children1) ? children1 : [children1], (c) => c && c.key);
    const childrenArray2 = _.map(Array.isArray(children2) ? children2 : [children2], (c) => c && c.key);

    return _.isEqual(childrenArray1, childrenArray2);
  }
}
