import { Text } from "ripple";
import styled from "styled-components";
import { standardQuestionMaxContentSize } from "../../../common";
import Box from "../../../components/box";
import Layers from "../../../components/layers";
import Question from "../../../components/question";

export const Type9QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SentenceBox = styled(Box)`
  @media screen and (orientation: landscape) {
    width: ${standardQuestionMaxContentSize};
  }

  line-height: 7vh;

  .box-content {
    display: inline;
    font-size: 3vh;
  }

  & > .box-content {
    padding: 4vh;
  }
`;

export const TextSegment = styled(Text)`
  display: inline;
  padding: 0 0.4vh;
  font-family: "ComicNeue-Regular";

  b,
  strong {
    font-family: "ComicNeue-Bold";
  }

  &:first-child {
    padding-left: 0;
  }
`;

export const DropzoneSegment = styled.div`
  font-family: "ComicNeue-Regular";
  display: inline;
`;

export const DropzoneBox = styled(Box).attrs((props) => ({ appearance: "active", dashed: true }))`
  display: inline;
  padding: 1vh;
`;

export const DropzoneWord = styled(Text)`
  display: inline;
  opacity: ${(props) => (props.$show ? 1 : 0)};
`;

export const StyledLayers = styled(Layers)`
  pointer-events: none;
`;
