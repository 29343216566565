import _ from "lodash";

function calculate(preprocessed) {
  const languagesBySession = _.map(preprocessed.sessions, (session) => {
    const languageChanges = _.filter(session, (event) => event.name === "language-change");

    // If there is no language change event, the language of the session is marked as "default",
    // because we can't know if the default language was always the same and because when
    // aggregating stats from multiple apps we don't know the default languages of the apps in question.
    // Also, it's interesting to see if users changed the language or kept the default one.
    if (languageChanges.length === 0) return "default";

    // If there is one or more language change events, the language of the session is the last language of the app
    return _.last(languageChanges).data.language;
  });

  return _.countBy(languagesBySession, (language) => language);
}

export default function (preprocessed) {
  this.name = "Languages";
  this.keyName = "Language";
  this.valueName = "Sessions";
  this.format = (value) => value;
  this.value = calculate(preprocessed);
}
