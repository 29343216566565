/* eslint-disable ripple/no-img */
import PropTypes from "prop-types";
import { PureComponent } from "react";
import { connect } from "react-redux";
import Status from "../../../core/status";
import Classes from "../../../helpers/classes";
import Clipboard from "../../../helpers/clipboard";
import Config from "../../../helpers/config";
import Env from "../../../helpers/env";
import resource from "../../../helpers/resource";
import Strings from "../../../helpers/strings";
import Toast from "../../../helpers/toast";
import Page from "../../components/page";
import Slider from "../../components/slider";
import { StaggerStep } from "../../components/stagger";

class PageComponent extends PureComponent {
  static propTypes = {
    startup: PropTypes.object.isRequired,
    download: PropTypes.object.isRequired,
    server: PropTypes.object,
  };

  createOnFileClick = (file) => () => {
    Clipboard.copy(file.url);
    Toast.info(Strings.localized(`StartupFileUrlCopiedToClipboard`));
  };

  render() {
    return (
      <Page className="startup" background={resource("images/startup-background.png")}>
        <div className="center">
          <StaggerStep>
            <img className="logo" src={resource("images/startup-logo.png")} />
          </StaggerStep>
          <StaggerStep>
            <div className="status">{this.renderStatus()}</div>
          </StaggerStep>
          <StaggerStep>
            <Slider
              className={Classes.build({
                global: true,
                active: this.props.startup.status === Status.DOWNLOADING_MEDIAS,
              })}
              value={this.props.download.progress ?? 0}
            />
          </StaggerStep>
          {Env.isREC &&
            this.props.startup.status === Status.DOWNLOADING_MEDIAS &&
            this.props.download.files?.length > 0 &&
            Config.fetch.displayDetailedProgress && (
              <StaggerStep>
                <div className="files">
                  <div className="column progress">
                    {this.props.download.files.map((file) => {
                      const progress = file.loaded / file.total;
                      return (
                        <div key={file.url} className="column-item progress">
                          <Slider key={file.url} className="active" value={progress} />
                        </div>
                      );
                    })}
                  </div>
                  <div className="column name">
                    {this.props.download.files.map((file) => (
                      <div key={file.url} className="column-item" onClick={this.createOnFileClick(file)}>
                        <span className="size" title={file.url}>
                          {(file.loaded / 1_000_000).toFixed(2)} / {(file.total / 1_000_000).toFixed(2)} MB
                        </span>
                        <span className="name" title={file.url}>
                          {file.name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </StaggerStep>
            )}
        </div>
      </Page>
    );
  }

  renderStatus = () => {
    switch (this.props.startup.status) {
      case Status.SERVER_UNREACHABLE:
        return (
          <>
            <span>{Strings.localized("StartupServerUnreachable")}</span>
            {this.renderCopyErrorButton()}
            {this.renderRetryButton()}
          </>
        );
      case Status.DATA_ERROR:
        return (
          <>
            <span>{Strings.localized("StartupDataError")}</span>
            {this.renderCopyErrorButton()}
            {this.renderRetryButton()}
          </>
        );
      case Status.DOWNLOADING_MEDIAS:
        return Strings.localized("StartupFetchingMedias");
      case Status.DATA_REQUESTED:
        return Strings.localized("StartupDownloadingData");
      case Status.DATA_RECEIVED:
        return Strings.localized("StartupDataReceived");
      case Status.DATA_LOCAL:
        return Strings.localized("StartupDataLocalMode");
      case Status.DATA_STATIC:
        return Strings.localized("StartupDataStaticMode");
      default:
        return Strings.localized("StartupLoading");
    }
  };

  onCopyErrorButtonClick = () => {
    Clipboard.copy(this.props.startup.error);
    Toast.info(Strings.localized(`StartupErrorMessageCopiedToClipboard`));
  };

  renderRetryButton = () => (
    <a href="/">{Strings.localized(`StartupServerUnreachable${Env.isMobile ? "Mobile" : "Desktop"}Retry`)}</a>
  );

  renderCopyErrorButton = () => (
    <a onClick={this.onCopyErrorButtonClick}>{Strings.localized(`StartupCopyErrorButton`)}</a>
  );
}

const mapStateToProps = function (state) {
  return { language: state.language, startup: state.startup, download: state.download };
};

export default connect(mapStateToProps)(PageComponent);
