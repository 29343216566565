import { Subject } from "rxjs";

const interactions$ = new Subject();

const InteractionObservables = {
  initialize: function () {
    return { interactions$: interactions$ };
  },
};

export default InteractionObservables;
