import _ from "lodash";
import PropTypes from "prop-types";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Strings from "../../helpers/strings";
import { Classes } from "../../ripple";

class Flags extends PureComponent {
  static propTypes = {
    flags: PropTypes.object.isRequired,
  };

  render() {
    // Only keep active flags
    const active = _.pickBy(this.props.flags, (f) => f === true);

    // Filter flags to exclude some special ones
    const filtered = _.pickBy(active, (_value, key) => key !== "debug" && key !== "bounds" && key !== "pathBar");

    // Build flag objects for each property
    const flags = _.map(filtered, (_value, key) => {
      return {
        name: key,
        title: Strings.localized(`${_.upperFirst(key)}FlagTitle`),
      };
    });

    // Ensure the flags with the longest title are at the top,
    // to minimize overlap with the app's content
    const orderedFlags = _.orderBy(flags, (n) => n.title.length, "desc");

    return (
      <TransitionGroup className="flags">
        {_.map(orderedFlags, (flag) => this.renderFlag(flag.name, flag.title))}
      </TransitionGroup>
    );
  }

  renderFlag = (name, title) => {
    const destructive = name === "modified"; // Might be extended to encompass other flags someday...
    return (
      <CSSTransition
        className={Classes.build("flag", { destructive })}
        key={name}
        classNames="fade-out-fade-in"
        timeout={500}
      >
        <div>{title}</div>
      </CSSTransition>
    );
  };
}

const mapStateToProps = (state) => ({ flags: state.flags });

export default connect(mapStateToProps)(Flags);
