import PropTypes from "prop-types";
import { useParams } from "react-router";
import { useData } from "ripple";
import { isGoodAnswer } from "../../../../logic/logic";
import { textColor } from "../../../common";
import AudioTextButton from "../../../components/audio-text-button";
import ResponsiveContent from "../../../components/responsive-content";
import { useFeedback } from "../../../hooks/use-feedback";
import { QuestionPanelContent } from "../styled";
import { BigImage, BigImageBox, Type2QuestionRoot } from "./styled";

const Type2Question = ({ className, style, onGoodAnswer, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));

  const { feedbacks, addPermanentFeedback, addMomentaryFeedback } = useFeedback();

  const createOnItemClick = (item) => () => {
    if (isGoodAnswer(item)) {
      addPermanentFeedback(item.id);
      onComplete();
    } else {
      addMomentaryFeedback(item.id, "bad");
    }
  };

  const renderPanelContent = (location) => {
    return (
      <QuestionPanelContent $location={location}>
        {node.children.map((item) => (
          <AudioTextButton key={item.id} node={item} onClick={createOnItemClick(item)} feedback={feedbacks[item.id]} />
        ))}
      </QuestionPanelContent>
    );
  };

  return (
    <Type2QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent
        sidePanelContent={renderPanelContent("side")}
        bottomPanelContent={renderPanelContent("bottom")}
      >
        <BigImageBox color={textColor}>
          <BigImage src={node.wantedMedia("Image", "Image")} />
        </BigImageBox>
      </ResponsiveContent>
    </Type2QuestionRoot>
  );
};

Type2Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onGoodAnswer: PropTypes.func,
  onComplete: PropTypes.func,
};

export default Type2Question;
