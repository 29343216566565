import { Grid } from "ripple";
import styled from "styled-components";
import { standardGap, standardQuestionMaxContentSize } from "../../common";

export const CardsGridRoot = styled(Grid)`
  align-self: center;
  gap: ${standardGap};
  width: ${(props) => props.$width};
  .grid-row {
    gap: ${standardGap};
  }
  @media screen and (orientation: landscape) {
    width: calc(${standardQuestionMaxContentSize} / ${(props) => (props.$columns === 2 ? 1.5 : 1)});
  }
`;

export const Card = styled.div`
  position: relative;
  aspect-ratio: 230/300;
  width: calc(100% / ${(props) => props.$columns});
`;
