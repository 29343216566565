import { Parser } from "xml2js";
import { Promise } from "es6-promise";

let XmlParser = {
  parse: function (xml) {
    return new Promise(function (resolve, reject) {
      let options = {
        explicitArray: false,
        normalizeTags: true,
      };
      let parser = new Parser(options);
      parser.parseString(xml, function (err, result) {
        if (err !== null) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  },
};

export default XmlParser;
