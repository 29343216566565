import FetchInternal from "../logic/fetch/fetch";

/** This helper just re-exposes the "safe" internal fetch API for use in apps */
class Fetch {
  static async downloadMedias(...args) {
    return await FetchInternal.downloadMedias(...args);
  }

  static hasNodeBeenDownloadedAtLeastOnce(node) {
    return FetchInternal.hasNodeBeenDownloadedAtLeastOnce(node);
  }

  static hasNodeBeenModifiedSinceLastDownload(node) {
    return FetchInternal.hasNodeBeenModifiedSinceLastDownload(node);
  }
}

export default Fetch;
