import { useEffect } from "react";
import Keyboard from "../../helpers/keyboard";
import { useLatestRef } from "./internal/use-latest-ref";

/**
 * Register a keyboard shortcut to run some code.
 * Ideally, the callback must be memoized to
 * avoid needless re-subscriptions at each render.
 */
export const useKeyboardShortcut = (description, keyEquivalent, onPress, options) => {
  const mostRecentCallbackRef = useLatestRef(onPress);

  useEffect(() => {
    if (!keyEquivalent) return; // Passing null to the keyEquivalent parameter disables the shortcut
    const mostRecentCallback = (...args) => mostRecentCallbackRef.current(...args);
    const subscription = Keyboard.shortcutSubscribe(description, keyEquivalent, mostRecentCallback, options);
    return () => Keyboard.shortcutUnsubscribe(subscription);
  }, [mostRecentCallbackRef, description, keyEquivalent, options]);
};
