import { lighten, transparentize } from "polished";
import { Button, Grid, Image, Paragraphs, Page as RipplePage, Scroller, Text, resource, staggerStep } from "ripple";
import styled from "styled-components";

export const simbiozBlue = "#00a5e3";
export const backgroundColor = "#000000";
export const titleColor = "#4C4C4C";
export const lightTitleColor = "#FFFFF";

export const unnamedPanelColor = "#9c8ef5";

export const textColor = "#3F3F3F";
export const yellowColor = "#F5C060";

export const standardGap = "2vmin";
export const standardTopBarHeight = "3vmax";
export const standardQuestionLandscapeContentPadding = "4vmin";
export const standardQuestionPortraitContentPadding = "4vmin";
export const standardQuestionPortraitTighterContentPadding = "14vw";
export const standardQuestionLandscapeMainContentWidth = "44vw";
export const standardQuestionMaxContentSize = "70vh";
export const standardButtonDistanceFromBoxCorner = "0.8vmin";

export const CustomPage = styled(RipplePage)`
  display: flex;
  flex-direction: column;
  touch-action: none;
`;

export const StandardScroller = styled(Scroller).attrs((props) => ({
  scrollbarInset: "1.5vmin",
  scrollbarStartInset: "3vmin",
  scrollbarEndInset: "3vmin",
}))`
  .scroller-track {
    width: 4px;
  }
  .scroller-thumb {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
  }
`;

export const HomeTitle = styled(Text)`
  color: ${yellowColor};
  text-shadow: 0 2px 30px ${transparentize(0.8, yellowColor)};
  padding: 20px 35px;
  font-size: 8vmin;
  font-weight: 900;
`;

export const Title = styled(Text)`
  font-family: "ComicNeue-Bold";
  align-self: center;
  overflow: visible;

  @media screen and (orientation: portrait) {
    font-size: 5vw;
  }

  @media screen and (orientation: landscape) {
    font-size: 4vh;
  }
`;

export const OldQuestionRoot = styled.div`
  margin-top: ${standardQuestionLandscapeContentPadding};
`;

export const ActivityPageTitle = staggerStep({ transition: "slide-in-and-fade-from-top", order: 0 })(styled(Title)`
  margin-top: calc(env(safe-area-inset-top) + 2vmax);
  margin-left: 12vmin;
  margin-right: 12vmin;
  color: ${titleColor};
  text-align: center;
`);

export const StandardButton = styled(Button)`
  color: ${textColor};
`;

export const YellowButton = styled(StandardButton)`
  background-color: ${yellowColor};
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.clicked {
    transform: scale3d(0.9, 0.9, 1);
  }

  &.disabled {
    opacity: 0.3;
  }
`;

export const RoundYellowButton = styled(YellowButton)`
  border-radius: 50%;
`;

export const RectangularYellowButton = styled(StandardButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${yellowColor};
  border-radius: 2vh;
  padding: 2vh;
  color: ${textColor};

  &.clicked {
    transform: scale3d(0.9, 0.9, 1);
  }
`;

export const CardGrid = styled(Grid)`
  .grid-row {
    justify-content: center;
    margin-bottom: 1vh;
    gap: 1vh;
  }
`;

export const LandscapeCardGrid = styled(CardGrid).attrs((props) => ({
  layout: (count) => {
    return Grid.layout.manual({
      4: [2, 2],
      6: [3, 3],
    })(count);
  },
}))``;

export const PortraitCardGrid = styled(CardGrid).attrs((props) => ({
  layout: (count) => {
    return Grid.layout.manual({
      4: [2, 2],
      6: [2, 2, 2],
    })(count);
  },
}))`
  .grid-row {
  }
`;

export const WrongImage = styled(Image).attrs((props) => ({
  src: resource("images/wrong-answer-card-anim.svg"),
  scaling: "fill",
  fadeIn: "always",
}))`
  position: absolute;
  height: auto;
  width: 100%;
`;

export const GoodImage = styled(Image).attrs((props) => ({
  src: resource("images/good-answer-card-anim.svg"),
  scaling: "fill",
  fadeIn: "always",
}))`
  position: absolute;
  height: auto;
  width: 100%;
`;

export const GameTopBarContainer = styled.div`
  width: 100%;
  margin-top: 2vmin;
  height: ${standardTopBarHeight};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const WordContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1vmin;

  border-radius: 1.5vh;
  background-color: ${(props) => lighten(0.3, props.color)};
  transition: all 150ms ease-in-out;

  &.disabled {
    opacity: 0.3;
  }

  @media screen and (orientation: portrait) {
    height: 6vh;
  }

  @media screen and (orientation: landscape) {
    height: 8vh;
  }
`;

// Algorite pour déterminer la taille du texte.
export const textSize = (text) => {
  const textSize = text?.length - 7 ?? 0;

  let size = 3;
  if (textSize >= 12 && textSize < 15) size = 2.5;
  if (textSize >= 15 && textSize < 18) size = 2;
  if (textSize >= 18) size = 1.5;

  return size;
};

export const ResourceText = styled(Paragraphs).attrs((props) => ({ enableLinks: true }))`
  font-size: 2vh;
  color: ${textColor};
  margin: 2vh 0;

  font-family: "ComicNeue-Regular";

  &.rtl td,
  th {
    direction: rtl !important;
  }

  a {
    color: #e09304 !important;
  }

  table {
    /* We disable RTL for tables in this app because we already set them up using
       custom classes and we don't want to have to redo all that data entry. */
    direction: ltr !important;

    align-self: flex-start;
    text-align: unset;

    p {
      margin: 2vh 0;
    }

    td,
    th {
      vertical-align: top;

      p:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    td.header,
    tr.header td {
      background-color: #44545d;
      color: white;
      font-family: "ComicNeue-Bold";
    }
  }

  a {
    color: ${yellowColor};
  }

  b,
  strong {
    font-family: "ComicNeue-Bold";
  }

  img {
    height: unset !important;
  }
`;

export const ResourceImage = styled(Image)`
  margin: 2vmin 0;
  width: 100%;
`;

export const renderResourceChild = (node) => {
  if (node.semantic === "ResourceText")
    return <ResourceText key={node.id}>{node.wantedText("Description")}</ResourceText>;
  if (node.semantic === "ResourceImage")
    return <ResourceImage key={node.id} src={node.wantedMedia("ResourceImage", "ResourceImage")} />;
};
