import PropTypes from "prop-types";
import { useCallback, useEffect, useRef } from "react";
import { Image, MediaSrcPropType, TextSrcPropType, resource, usePrevious } from "ripple";
import { useGlobalAudioPlayer } from "../../hooks/use-global-audio-player";
import Mascot from "../mascot";
import QuestionMascotContainer from "../question-mascot-container";
import { CloseButton, CustomSpeakerButton, Text, TopButtons } from "./styled";

const MascotInstruction = ({ show, image, audio, text, onShouldHide, autoPlay, ...rest }) => {
  const { play, stop, src: globalSrc } = useGlobalAudioPlayer();
  const previousGlobalSrc = usePrevious(globalSrc);
  const lastSpeakerButtonClickDateRef = useRef(new Date());

  const onCloseButtonClick = useCallback(() => {
    stop();
    onShouldHide();
  }, [onShouldHide, stop]);

  const onSpeakerButtonClick = useCallback(() => {
    lastSpeakerButtonClickDateRef.current = new Date();
  }, []);

  useEffect(() => {
    if (show && autoPlay) {
      setTimeout(() => play(audio), 750);
    } else {
      stop();
    }
    return () => stop();
  }, [show, audio, autoPlay, play, stop]);

  // Auto-hide mascot when the audio finishes playing and it isn't the the result of
  // the user clicking on the speaker button to stop the audio manually.
  useEffect(() => {
    if (new Date() - lastSpeakerButtonClickDateRef.current < 100) return;
    if (previousGlobalSrc === audio && globalSrc === null) onShouldHide();
  }, [audio, globalSrc, onShouldHide, previousGlobalSrc]);

  return (
    <QuestionMascotContainer show={show}>
      <Mascot show={show} disableExitStagger={true} preserveChildren src={image} {...rest}>
        <TopButtons>
          <CustomSpeakerButton src={audio} onClick={onSpeakerButtonClick} />
          <CloseButton onClick={onCloseButtonClick}>
            <Image src={resource("images/Button_Close.svg")} />
          </CloseButton>
        </TopButtons>
        {<Text>{text}</Text>}
      </Mascot>
    </QuestionMascotContainer>
  );
};

MascotInstruction.propTypes = {
  show: PropTypes.bool,
  audio: MediaSrcPropType,
  image: MediaSrcPropType,
  text: TextSrcPropType,
  onShouldHide: PropTypes.func,
  autoPlay: PropTypes.bool,
};

export default MascotInstruction;
