import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useData } from "ripple";
import { isGoodAnswer } from "../../../../logic/logic";
import Box from "../../../components/box";
import ButtonLayer from "../../../components/button-layer";
import FeedbackLayer from "../../../components/feedback-layer";
import ImageLayer from "../../../components/image-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import SpeakerButtonLayer from "../../../components/speaker-button-layer";
import SquareItemGridPanelContent from "../../../components/square-item-grid-panel-content";
import { useFeedback } from "../../../hooks/use-feedback";
import { QuestionPanelContent } from "../styled";
import { MainBox, Type12QuestionRoot } from "./styled";

const Type12Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const items = node.children;

  const [correctAnswers, setCorrectAnswers] = useState([]);
  const { feedbacks, addPermanentFeedback, addMomentaryFeedback } = useFeedback();

  const createOnItemClick = (item) => () => {
    if (isGoodAnswer(item)) {
      addPermanentFeedback(item.id);
      setCorrectAnswers([...correctAnswers, item]);
    } else {
      addMomentaryFeedback(item.id, "bad");
    }
  };

  useEffect(() => {
    const goodItems = items.filter((i) => i.settings.Answer === "good");
    const allGood = goodItems.every((i) => correctAnswers.includes(i));
    if (!allGood) return;
    onComplete();
  }, [correctAnswers, items, onComplete]);

  const renderPanelContent = (location) => {
    return (
      <QuestionPanelContent $location={location}>
        <SquareItemGridPanelContent items={items} location={location}>
          {(item) => (
            <Box appearance="active">
              <Layers>
                <ImageLayer src={item.wantedMedia("Image", "Image")} />
                <ButtonLayer onClick={createOnItemClick(item)} />
                <SpeakerButtonLayer src={item.optionalMedia("Audio", "Audio")} />
                <FeedbackLayer feedback={feedbacks[item.id]} />
              </Layers>
            </Box>
          )}
        </SquareItemGridPanelContent>
      </QuestionPanelContent>
    );
  };

  return (
    <Type12QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent
        sidePanelContent={renderPanelContent("side")}
        bottomPanelContent={renderPanelContent("bottom")}
      >
        <MainBox>
          <Layers>
            <ImageLayer src={node.wantedMedia("Image", "Image")} />
            <SpeakerButtonLayer src={node.optionalMedia("Audio", "Audio")} />
          </Layers>
        </MainBox>
      </ResponsiveContent>
    </Type12QuestionRoot>
  );
};

Type12Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type12Question;
