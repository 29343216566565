import Env from "./env";

/** Provides orientation controls when running in RCC. */
export default class Orientation {
  /**
   * See this for possible orientations:
   * https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-screen-orientation/index.html#supported-orientations
   */
  static lock(orientation) {
    if (!Env.isRCC) return;
    window.screen.orientation.lock(orientation);
  }

  static unlock() {
    if (!Env.isRCC) return;
    window.screen.orientation.unlock();
  }
}
