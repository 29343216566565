import PropTypes from "prop-types";
import { useContext } from "react";
import { MediaSrcPropType, TextSrcPropType } from "ripple";
import { QuestionContext } from "../question";
import { AudioTextRowRoot, StyledSpeakerButton, Title, VerticalSplitter } from "./styled";

const AudioTextRow = ({ className, style, audio, text, ...rest }) => {
  const context = useContext(QuestionContext);
  return (
    <AudioTextRowRoot {...rest} className={className} style={style}>
      {audio && (
        <>
          <StyledSpeakerButton src={audio} />
          <VerticalSplitter $color={context.color} />
        </>
      )}
      <Title>{text}</Title>
    </AudioTextRowRoot>
  );
};

AudioTextRow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  audio: MediaSrcPropType,
  text: TextSrcPropType,
};

export default AudioTextRow;
