import PropTypes from "prop-types";
import { TextSrcPropType } from "ripple";
import { TextDragPreviewRoot, Word } from "./styled";

const TextDragPreview = ({ className, style, text, ...rest }) => {
  return (
    <TextDragPreviewRoot {...rest} className={className} style={style}>
      <Word>{text}</Word>
    </TextDragPreviewRoot>
  );
};

TextDragPreview.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  text: TextSrcPropType,
};

export default TextDragPreview;
