import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { usePrevious } from "ripple";
import Sequencer from "sequencer.js";
import { useFeedback } from "./use-feedback";

export const useFindPairsQuestionLogic = (cards, onComplete) => {
  const { feedbacks, addPermanentFeedback, addMomentaryFeedback } = useFeedback();

  const [chosenCards, setChosenCards] = useState([]); // Currently flipped cards
  const [foundCards, setFoundCards] = useState([]); // Card pairs that have been confirmed

  const groups = _.groupBy(cards, (c) => c.identifier);
  const maxGroupSize = _.max(_.map(groups, (g) => g.length));

  const createOnCardClick = (card) => () => {
    if (!chosenCards.includes(card)) {
      setChosenCards(chosenCards.length < maxGroupSize ? [...chosenCards, card] : chosenCards);
    } else {
      setChosenCards(chosenCards.filter((c) => c !== card));
    }
  };

  const sequencerRef = useRef(new Sequencer());
  const previousChosenCards = usePrevious(chosenCards);
  useEffect(() => {
    if (chosenCards.length !== maxGroupSize) return;
    if (_.isEqual(chosenCards, previousChosenCards)) return;

    const s = sequencerRef.current;

    if (chosenCards.every((c) => c.identifier === chosenCards[0].identifier)) {
      const newFoundCards = [...foundCards, ...chosenCards];

      // Move the cards from chosen cards to the found cards, with a delay in between
      s.do(() => setFoundCards(newFoundCards));
      s.doWait(500);
      s.do(() => setChosenCards([]));
      s.doWait(500);
      s.do(() => newFoundCards.forEach((c) => addPermanentFeedback(c.id)));

      // If all cards with an identifier are in the found cards, the game is completed
      if (cards.filter((c) => !!c.identifier).every((c) => newFoundCards.includes(c))) onComplete();
    } else {
      s.doWait(1000);
      s.do(() => chosenCards.forEach((c) => addMomentaryFeedback(c.id, "bad")));
      s.doWait(2000);
      s.do(() => setChosenCards([]));
    }
  }, [
    addMomentaryFeedback,
    addPermanentFeedback,
    cards,
    chosenCards,
    foundCards,
    maxGroupSize,
    onComplete,
    previousChosenCards,
  ]);

  return { feedbacks, chosenCards, foundCards, createOnCardClick, maxGroupSize };
};
