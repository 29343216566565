import styled from "styled-components";
import { standardQuestionMaxContentSize } from "../../../common";
import Box from "../../../components/box";
import Question from "../../../components/question";

export const Type12QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MainBox = styled(Box)`
  aspect-ratio: 1;
  @media screen and (orientation: landscape) {
    width: ${standardQuestionMaxContentSize};
  }
`;
