import { Grid } from "ripple";
import styled from "styled-components";
import { standardGap } from "../../../common";
import Question from "../../../components/question";

export const Type1QuestionRoot = styled(Question)``;

export const CustomGrid = styled(Grid)`
  max-height: 100%;
  align-self: center;
  gap: ${standardGap};
  .grid-row {
    gap: ${standardGap};
  }
`;

export const Item = styled.div`
  aspect-ratio: 1;
  height: ${(props) => props.$height};
  & > * {
    width: 100%;
    height: 100%;
  }
`;
