import PropTypes from "prop-types";
import { FeedbackLayerRoot } from "./styled";

const FeedbackLayer = ({ className, style, ...rest }) => {
  return <FeedbackLayerRoot {...rest} className={className} style={style} />;
};

FeedbackLayer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default FeedbackLayer;
