import { useEffect, useLayoutEffect, useRef, useState } from "react";

/**
 * Original from: https://github.com/uidotdev/usehooks/blob/main/index.js (as "useClickAway")
 * Modified to prevent the originating click from triggering the callback instantly when the
 * click occurs outside of the element we're watching.
 *
 * Usage for a button located outside of the element to hide and show (and which is our outside
 * click tracking element):
 *
 *    const [showPopup, setShowPopup] = useState(false);
 *
 *    // By passing `showPopup`, we disable watching for outside clicks when our target element is not visible
 *    const ref = useClickOutside(showPopup, () => setShowPopup(false));
 *
 *    // By passing `disabled={showPopup}`, we disable the button while the popup is open. This means that clicking
 *    // anywhere but in the target element, including on the popup opening button, will result in the popup closing.
 *    // Without this, both the outside click handler and the button's onClick event will fire resulting in the popup
 *    // opening back up almost instantly.
 *    <PopupOpeningButton disabled={showPopup} onClick={() => setShowPopup(true)} />
 *
 */
export const useClickOutside = (enabled, callback) => {
  const ref = useRef(null);
  const refCallback = useRef(callback);
  const [actuallyEnabled, setActuallyEnabled] = useState(enabled);

  useLayoutEffect(() => {
    refCallback.current = callback;
  });

  // Enable the handler only after a few milliseconds, to make sure it won't
  // fire at the same time as the logic that shows the thing we want to show,
  // hiding it before it can be shown.
  const actuallyEnabledTimeoutRef = useRef(null);
  useEffect(() => {
    if (enabled) {
      actuallyEnabledTimeoutRef.current = setTimeout(() => setActuallyEnabled(true), 100);
    } else {
      setActuallyEnabled(false);
    }
    return () => clearTimeout(actuallyEnabledTimeoutRef.current);
  }, [enabled]);

  useEffect(() => {
    const handler = (e) => {
      if (!actuallyEnabled) return;
      const element = ref.current;
      if (element && !element.contains(e.target)) {
        refCallback.current(e);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [actuallyEnabled]);

  return ref;
};
