import { Droppable } from "ripple";
import styled from "styled-components";
import AudioTextRow from "../audio-text-row";
import Box from "../box";
import Layers from "../layers";

export const AudioTextDroppableRoot = styled.div`
  pointer-events: ${(props) => (props.$disabled ? "none" : "unset")};
  opacity: ${(props) => (props.$disabled ? 0.3 : 1)};
`;

export const StyledBox = styled(Box).attrs((props) => ({ appearance: "active" }))`
  width: 100%;
  height: 100%;
  .box-content {
    align-items: flex-start;
  }
`;

export const StyledLayers = styled(Layers)``;

export const StyledAudioTextRow = styled(AudioTextRow)`
  margin-right: 1.5vh;
`;

export const StyledDroppable = styled(Droppable)`
  position: absolute;
  right: 0;
  height: 100%;
  width: calc(100% - ${(props) => (props.$hasAudio ? "6vh" : "0vh")});
`;
