import { v4 as uuidv4 } from "uuid";

export default class DownloadEntry {
  constructor(source, destination, onStart, onProgress, onFinish) {
    this.id = uuidv4();
    this.source = source;
    this.destination = destination;
    this.onStart = onStart;
    this.onProgress = onProgress;
    this.onFinish = onFinish;
  }
}
