class Sequencer {
  sequence = [];
  currentTask = null;
  currentRelease = null;
  processing = false;

  push(task) {
    this.sequence.push(task);
    this.resumeProcessing();
  }

  clear(options) {
    options = { cancelCurrentTask: true, ...options };
    this.sequence = [];
    this.currentTask?.cancel?.(this.currentRelease);
  }

  isEmpty() {
    return this.sequence.length === 0;
  }

  resumeProcessing() {
    if (this.processing) return;
    this.processing = true;
    this.process();
  }

  finishProcessing() {
    if (this.sequence.length !== 0) return false;
    this.processing = false;
    return true;
  }

  process() {
    if (this.finishProcessing()) return;

    // When released, we'll process the next task
    const release = () => {
      this.currentTask = null;
      this.currentHandle = null;
      this.process();
    };

    // Perform the next task, providing it with the function it can
    // call to signify that its job is done
    const task = this.sequence.shift();
    this.currentTask = task;
    this.currentRelease = release;
    task.perform(release);
  }
}

export default Sequencer;
