import { lighten } from "polished";
import { Button, Image, staggerStep, Text } from "ripple";
import styled from "styled-components";
import { OldQuestionRoot, textColor } from "../../../common";
import SpeakerButton from "../../../components/speaker-button";

export const ClickWordSentenceQuestionRoot = styled(OldQuestionRoot)``;

export const Content = staggerStep({ transition: "appear-floating" })(styled.div`
  display: flex;
  align-items: center;

  @media screen and (orientation: portrait) {
    flex-direction: column;
  }

  @media screen and (orientation: landscape) {
    flex-direction: row;
  }
`);

export const Card = styled.div`
  position: relative;
  @media screen and (orientation: landscape) {
    width: 45vh;
    height: 63vh;
  }

  @media screen and (orientation: portrait) {
    width: 37.5vh;
    height: 52.7vh;
  }

  box-shadow: 0 0 0 0.8vh ${(props) => props.color};
  border-radius: 2vh;
  transition: all 150ms ease-in-out;
  background-color: ${(props) => lighten(0.3, props.color)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
`;

export const CardImageButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 80%;
  width: 100%;
  &.clicked {
    transform: scale3d(0.98, 0.98, 1);
  }
`;

export const CardImage = styled(Image).attrs((props) => ({ scaling: "fill", fadeIn: "always" }))`
  height: 80%;
  width: 100%;
  transition: opacity 150ms ease-in-out;
  opacity: 1;
`;

export const SentenceBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  height: 20%;
  width: 100%;
  background-color: ${(props) => lighten(0.4, props.color)};
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.3vh 2vh;
  gap: 1vh;
`;

export const WordButton = staggerStep({ transition: "" })(styled(Button)`
  background-color: ${(props) => lighten(0.3, props.color)};
  border: 0.4vh solid ${(props) => props.color};
  border-radius: 0.8vh;
  color: ${textColor};
  font-size: 3vh;
  line-height: 3.5vh;
  padding: 0 0.5vh;
  transition: all 150ms ease-in-out;
  opacity: ${(props) => (props.inactive ? 0.5 : 1)};

  &.clicked {
    transform: scale3d(0.9, 0.9, 1);
  }
`);

export const WordSentence = styled(Text)`
  font-family: "ComicNeue-Light";
  color: ${textColor};
  font-size: 3.3vh;
`;

export const BoldWordSentence = styled(WordSentence)`
  font-family: "ComicNeue-Bold";
`;

export const CardSpeakerButton = styled(SpeakerButton)`
  position: absolute;

  width: 7vh;
  height: 7vh;

  right: 2.5vh;
  bottom: 2.5vh;
  &.clicked {
    transform: scale3d(0.9, 0.9, 1);
  }
`;
