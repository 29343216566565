import Config from "../../helpers/config";
import Env from "../../helpers/env";
import DownloadEntry from "./download-entry";

export default class DownloadQueue {
  // This is an informed magic number!
  // See `doc/concurrent-fetch-speed-tests.md`
  static MAX_CONCURRENT_DOWNLOADS = 10;

  queue = [];
  ongoing = {};

  enqueue(source, destination, onStart, onProgress, onFinish) {
    const entry = new DownloadEntry(source, destination, onStart, onProgress, onFinish);
    this.queue.push(entry);
  }

  download(allDownloadsCompleted) {
    this.undownloadedCount = this.queue.length;
    this.process(allDownloadsCompleted);
  }

  process(allDownloadsCompleted) {
    if (this.queue.length === 0) return;

    const entry = this.queue.shift();
    this.ongoing[entry.id] = entry;

    const downloaded = (result) => {
      delete this.ongoing[entry.id];
      if (result.error) result.error += ` (${entry.source})`;
      entry.onFinish(result);
      this.process(allDownloadsCompleted);

      this.undownloadedCount -= 1;
      if (this.undownloadedCount === 0) {
        allDownloadsCompleted();
      }
    };

    if (Env.isContained) {
      entry.onStart();
      Env.downloadFile(entry.source, entry.destination, entry.onProgress).then(downloaded);
    } else throw new Error("Can't download files outside of a container! This state should never be reached.");

    // If there can be more concurrent downloads, start another one immediately
    if (Object.keys(this.ongoing).length < Config.fetch.maxConcurrentDownloads) this.process(allDownloadsCompleted);
  }
}
