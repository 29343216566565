import { Image, VideoPlayer, resource } from "ripple";
import styled from "styled-components";
import { RoundYellowButton } from "../../../common";

export const VideoBlockRoot = styled(VideoPlayer).attrs((props) => ({
  showControls: false,
  showPausedOverlay: true,
}))`
  background-color: transparent;
`;

export const VideoPausedOverlay = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
