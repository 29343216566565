import { ModelBuilder, XmlParser } from "mediaclient.js";
import YAML from "yaml";
import Config from "../../helpers/config";
import Env from "../../helpers/env";
import Load from "../../helpers/load";
import Log from "../../helpers/log";
import Data from "../../model/data";
import DataActions from "../../redux/actions/local/data-actions";
import DownloadFilesActions from "../../redux/actions/local/download-files-actions";
import DownloadNodeIdActions from "../../redux/actions/local/download-node-id-actions";
import DownloadProgressActions from "../../redux/actions/local/download-progress-actions";
import { resource } from "../../ripple";
import Sequencer from "../../sequencer";
import DownloadQueue from "./download-queue";

const collectNodes = (node, exclude) => {
  const nodes = [];

  // We only download medias for discoverable nodes to reduce download time
  // and avoid downloading unneeded / test medias, etc.
  const isExcluded = exclude(node) || !node.isActuallyDiscoverable;

  if (!isExcluded) {
    nodes.push(node);
    nodes.push(...node.children.flatMap((c) => collectNodes(c, exclude)));
  }

  return nodes;
};

const downloadAndBuildServerData = async (apiClient, clientId) => {
  const xml = await apiClient.fetchClientXml(clientId);
  const raw = await XmlParser.parse(xml);
  const built = await ModelBuilder.buildFromRaw(raw);
  const data = new Data(built.nodes, built.rootId);
  return { xml, built, data };
};

const makeServerBuildMediaUrl =
  (apiClient, formatMetadatas) =>
  (...args) =>
    apiClient.buildMediaUrl(formatMetadatas, ...args);

const makeLocalBuildMediaUrl = (built) => (media, format) => {
  const formatMetadata = built.formatMetadatas.find((fm) => fm.name === format);
  const extension = formatMetadata ? formatMetadata.extension : "unk";
  const filePath = `resources/media/medias/${media.id}/${media.revision}/${formatMetadata.id}.${extension}`;

  if (Env.isRCC) {
    // On iOS, the base URL points to our cordova-plugin-httpd built-in server
    // which we use instead of `window.WkWebView.convertFilePath()` because video
    // requests to the custom Cordova protocol (`ripple://`, in our case) result
    // in non-playing videos, choppy playback and other performance issues. Loading
    // the video from a local HTTP server fixes that. For simplicity, we load every
    // offline file from the server in question.

    // On Android, fetch requests performed by certain packages such as @react-three/fiber
    // fail because the `file://` protocol is not supported by `fetch`. We also use the built-in
    // HTTP server to access media.

    return `http://127.0.0.1:8973/${filePath}`;
  } else if (Env.isREC) {
    return "../../" + filePath;
  } else {
    return `/${filePath}`;
  }
};

const makeLocalDestination = (built) => (media, format) => {
  const formatMetadata = built.formatMetadatas.find((fm) => fm.name === format);
  const extension = formatMetadata ? formatMetadata.extension : "unk";
  return `resources/media/medias/${media.id}/${media.revision}/${formatMetadata.id}.${extension}`;
};

const modifyNodesToLoadMediasFromServer = (built, apiClient) => {
  built.nodes.forEach((n) => (n.addons.buildMediaUrl = makeServerBuildMediaUrl(apiClient, built.formatMetadatas)));
  return built;
};

const modifyNodesToLoadMediasFromLocal = (built) => {
  built.nodes.forEach((n) => (n.addons.buildMediaUrl = makeLocalBuildMediaUrl(built)));
  return built;
};

class Fetch {
  // Set during app initialization
  static _dispatch = null;
  static _apiClient = null;
  static _excludeNodeFromInitialFetch = null;
  static _excludeMediaFromInitialFetch = null;
  static _excludeFormatFromInitialFetch = null;

  // Set during initial fetch. Required so that downloads performed after
  // the initial fetch can have all the required information at hand.
  static _initialBuilt = null;

  static _downloadingMedias = false;

  // #region Loading

  static async loadServer(clientId) {
    try {
      this._dispatch(DataActions.requested());
      const { built, data } = await downloadAndBuildServerData(this._apiClient, clientId);
      this._initialBuilt = built;
      modifyNodesToLoadMediasFromServer(built, this._apiClient);
      this._dispatch(DataActions.received(data));
    } catch (error) {
      Log.error(error);
      this._dispatch(DataActions.error(error));
    }
  }

  static async downloadFromServerAndLoadLocal(clientId, done) {
    this._dispatch(DataActions.requested());

    try {
      const { xml, built, data } = await downloadAndBuildServerData(this._apiClient, clientId);
      this._initialBuilt = built;

      // Download the media files, taking initial fetch exclusions into account
      await Fetch.downloadMedias(
        data.root,
        this._excludeNodeFromInitialFetch,
        this._excludeMediaFromInitialFetch,
        this._excludeFormatFromInitialFetch,
      );

      // Write the XML data to disk

      const file = Env.isRCC
        ? resource("media/client.xml")
        : // Because we're using Env to write to disk, we can write outside of the `www` root
          // and into an ancestor directory outside of the ASAR archive.
          "../../" + resource(`media/client-instance-${Config.instanceNumber}.xml`);

      await Env.writeFile(file, xml);

      done();

      // After everything is fetched and the XML is written to disk, load the locally-downloaded data
      this.loadLocal();
    } catch (error) {
      // This can occur at least in the following scenario:
      // 1. The server responds to the ping request properly, leading to the fetch being performed
      // 2. The server throws when data is requested
      // This results in a CORS error because the error page returned from the server does not send CORS headers.
      // This clause catches those errors and tries to load local data as a last resort.
      Log.error("Fetch: Error", error);
      Log.info("Fetch: Trying to load local data assuming we might already have a previous version");
      this.loadLocal();
    }
  }

  static async loadLocal(errorAction = null) {
    try {
      const xml = Env.isRCC
        ? await Env.readFile(resource("media/client.xml"))
        : await Load.text(resource(`media/client-instance-${Config.instanceNumber}.xml`));
      const raw = await XmlParser.parse(xml);
      const built = await ModelBuilder.buildFromRaw(raw);
      this._initialBuilt = built;
      modifyNodesToLoadMediasFromLocal(built);
      const data = new Data(built.nodes, built.rootId);
      this._dispatch(DataActions.loadLocal(data));
    } catch (error) {
      this._dispatch(errorAction || DataActions.error(error));
    }
  }

  static async loadStatic(url) {
    try {
      const text = Load.text(url, { credentials: "include" });
      const data = (() => {
        if (url.endsWith(".json")) return JSON.parse(text) || {};
        if (url.endsWith(".yml") || url.endsWith(".yaml")) return YAML.parseDocument(text).toJS() || {};
        throw new Error(`Unsupported static data type at '${url}'`);
      })();
      this._dispatch(DataActions.loadStatic(data));
    } catch (error) {
      Log.error(error);
      this._dispatch(DataActions.error(error));
    }
  }

  static async loadEmpty() {
    this._dispatch(DataActions.loadNone());
  }

  // #endregion

  // #region Media Downloading

  static async downloadMedias(
    node,
    excludeNode = () => false,
    excludeMedia = () => false,
    excludeFormat = () => false,
  ) {
    if (this._downloadingMedias) {
      Log.warn("Download: Can't download medias while a download is already ongoing");
      return;
    }

    if (!(await this._apiClient.ping())) {
      Log.warn("Download: Can't download medias because it seems like we're offline");
      return;
    }

    if (!this.hasNodeBeenModifiedSinceLastDownload(node)) {
      Log.info(`Download: No need to download anything because node ${node.id} wasn't modified`);
      return;
    }

    this._downloadingMedias = true;
    this._dispatch(DownloadNodeIdActions.setId(node.id));
    this._dispatch(DataActions.downloadingMedias());
    this._dispatch(DownloadProgressActions.updateProgress(0));

    const built = this._initialBuilt;
    const formatMetadatas = built.formatMetadatas;

    // Create the functions that we need to build media URLs
    const serverBuildMediaUrlFunc = makeServerBuildMediaUrl(this._apiClient, formatMetadatas);
    const localBuildMediaUrlFunc = makeLocalBuildMediaUrl(built);
    const localDestinationFunc = makeLocalDestination(built);

    // Prepare a list of downloads by digging for nodes from the root node and extracting medias
    const nodes = collectNodes(node, excludeNode);
    const mediasToDownload = nodes.flatMap((n) => n.medias).filter((m) => !excludeMedia(m));
    const downloads = mediasToDownload.flatMap((media) => {
      const formatIds = media.formats;
      const formatNames = formatIds
        .map((formatId) => {
          const f = formatMetadatas.find((fm) => fm.id === formatId);
          return f?.name ?? null;
        })
        .filter((name) => name !== null);

      return formatNames.filter((fn) => !excludeFormat(fn)).map((formatName) => ({ media, formatName }));
    });

    Log.info(`Download: Downloading medias for node ${node.id} (${downloads.length} files)...`);

    return await new Promise((resolve) => {
      const sequencer = new Sequencer();

      const results = { success: 0, failure: 0, skipped: 0 };

      sequencer.doWaitForRelease((release) => {
        const downloadManager = new DownloadQueue();
        let downloadCount = 0;

        if (downloads.length === 0) {
          release();
          return;
        }

        // FIXME: Remove slice!
        downloads.forEach((download) => {
          const media = download.media;
          const formatName = download.formatName;
          const source = serverBuildMediaUrlFunc(media, formatName);

          const destination = Env.isRCC
            ? localDestinationFunc(media, formatName)
            : localBuildMediaUrlFunc(media, formatName);

          downloadManager.enqueue(
            source,
            destination,
            () => {
              const progress = downloadCount / downloads.length;
              const percentage = Math.ceil(progress * 100);
              Log.info(`Download: [${percentage}%] Started '${source}' (R${media.revision})`);
              this._dispatch(DownloadFilesActions.addFile(source, `${media.semantic} (${formatName})`));
            },
            ({ loaded, total }) => {
              this._dispatch(DownloadFilesActions.updateFileProgress(source, loaded, total));
            },
            ({ result, error }) => {
              downloadCount += 1;

              const progress = downloadCount / downloads.length;
              const percentage = Math.ceil(progress * 100);

              this._dispatch(DownloadProgressActions.updateProgress(progress));
              this._dispatch(DownloadFilesActions.removeFile(source));

              if (result === "success") {
                Log.info(`Download: [${percentage}%] Finished '${source}' (R${media.revision})`);
                results.success += 1;
              } else if (result === "failure") {
                Log.error(`Download: [${percentage}%] Error: ${error}`);
                results.failure += 1;
              } else if (result === "skipped") {
                results.skipped += 1;
              }
            },
          );
        });

        downloadManager.download(release);
      });

      sequencer.do(() => {
        Log.info(
          `Download: [100%] Done (${results.success} downloaded, ${results.skipped} skipped, ${results.failure} errored, ${downloads.length} total)`,
        );

        if (results.failure > 0) {
          Log.warn("Download: Failed to download all media, make sure all files are availaible on the server");
        } else {
          Log.info("Download: Finished without errors");
          this.#setDownloadedNodeModifiedDate(node.id, node.modified);
        }

        this._downloadingMedias = false;
        this._dispatch(DownloadNodeIdActions.setId(null));
        resolve();
      });
    });
  }

  // #endregion

  // #region Downloaded Node Modified Date Storage (for checking if downloaded media is outdated or not)

  static #DOWNLOADED_NODE_MODIFIED_DATES_KEY = "fetch-downloaded-node-modified-dates";

  static hasNodeBeenDownloadedAtLeastOnce(node) {
    const downloadedModified = this.#getDownloadedNodeModifiedDate(node.id);
    return downloadedModified !== null;
  }

  static hasNodeBeenModifiedSinceLastDownload(node) {
    const downloadedModified = this.#getDownloadedNodeModifiedDate(node.id);
    return !downloadedModified || downloadedModified?.getTime() !== node.modified?.getTime();
  }

  static #setDownloadedNodeModifiedDate(id, date) {
    const raw = localStorage.getItem(this.#DOWNLOADED_NODE_MODIFIED_DATES_KEY);
    const dates = JSON.parse(raw ?? "{}");
    dates[id] = date;
    localStorage.setItem(this.#DOWNLOADED_NODE_MODIFIED_DATES_KEY, JSON.stringify(dates));

    const e = new Event("fetch-node-medias-downloaded");
    e.id = id;
    document.dispatchEvent(e);
  }

  static #getDownloadedNodeModifiedDate(id) {
    const raw = localStorage.getItem(this.#DOWNLOADED_NODE_MODIFIED_DATES_KEY);
    const dates = JSON.parse(raw ?? "{}");
    return dates[id] ? new Date(dates[id]) : null;
  }

  // #endregion
}

export default Fetch;
