import PropTypes from "prop-types";
import { ButtonLayerRoot } from "./styled";

const ButtonLayer = ({ className, style, ...rest }) => {
  return <ButtonLayerRoot {...rest} className={className} style={style} />;
};

ButtonLayer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ButtonLayer;
