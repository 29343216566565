import { lighten } from "polished";
import { Text } from "ripple";
import styled from "styled-components";
import {
  RoundYellowButton,
  standardButtonDistanceFromBoxCorner,
  standardQuestionMaxContentSize,
  textColor,
} from "../../../common";
import Box from "../../../components/box";
import Question from "../../../components/question";

export const Type8QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WordBox = styled(Box)`
  isolation: isolate;
  aspect-ratio: 2.5/1;
  @media screen and (orientation: landscape) {
    width: ${standardQuestionMaxContentSize};
  }
`;

export const WordLayer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Word = styled(Text)`
  font-size: 12vmin;
  font-family: "ComicNeue-Regular";
  padding: 2vh;

  strong {
    transition: all 250ms ease-in-out;
    font-weight: normal;
    color: ${(props) => (props.$expand ? lighten(0.5, textColor) : textColor)};
  }

  span {
    transition: all 250ms ease-in-out;
    margin: ${(props) => (props.$expand ? "3vmin" : 0)};
    background-color: unset !important;
  }
`;

export const SplitButtonLayer = styled.div``;

export const SplitButton = styled(RoundYellowButton)`
  position: absolute;
  bottom: ${standardButtonDistanceFromBoxCorner};
  left: ${standardButtonDistanceFromBoxCorner};
  width: 3vh;
  height: 3vh;
  font-size: 1.6vh;
  font-family: "ComicNeue-Bold";
`;
