import styled from "styled-components";
import { standardGap, standardQuestionMaxContentSize } from "../../../common";
import Question from "../../../components/question";

export const Type6QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImagesCssGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${standardGap};
  @media screen and (orientation: landscape) {
    width: ${standardQuestionMaxContentSize};
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 360/210;
  flex: 1;
  & > * {
    width: 100%;
    height: 100%;
  }
`;
