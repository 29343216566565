import PropTypes from "prop-types";
import { ConfirmButtonRoot, NextSymbol } from "./styled";

const ConfirmButton = ({ className, style, ...rest }) => {
  return (
    <ConfirmButtonRoot {...rest} className={className} style={style}>
      <NextSymbol />
    </ConfirmButtonRoot>
  );
};

ConfirmButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ConfirmButton;
