import { Html } from "@react-three/drei";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Coordinates from "../../../../helpers/coordinates";
import { useDebug } from "../../../hooks/use-debug";
import { useKeyPress } from "../../../hooks/use-key-press";

const DISTANCE = 400; // It's a constant because the panorama dome size never changes

const Pin = ({
  theta = -(Math.PI / 2), // Equator angle in radians around the y (up) axis
  phi = 0, // Polar angle in radians from the y (up) axis
  children = (
    <mesh>
      <sphereGeometry attach="geometry" args={[8, 32, 16]}>
        <meshBasicMaterial attach="material" color="red" />
      </sphereGeometry>
    </mesh>
  ),
}) => {
  const [position, setPosition] = useState([0, 0, 0]);
  const debug = useDebug();

  const altKeyPressed = useKeyPress("alt");

  useEffect(() => {
    const cartesianCoordinates = Coordinates.sphericalToCartesian(DISTANCE, theta, phi);
    setPosition([cartesianCoordinates.x, cartesianCoordinates.y, cartesianCoordinates.z]);
  }, [phi, theta]);

  return (
    <group position={position}>
      {React.Children.map(children, (c) =>
        React.cloneElement(c, {
          style: {
            ...(c.props?.style ?? {}),
            pointerEvents: altKeyPressed ? "none" : "inherit",
          },
        }),
      )}
      {debug && (
        <group>
          <mesh>
            <sphereGeometry attach="geometry" args={[4, 32, 16]}>
              <meshBasicMaterial attach="material" color="red" />
            </sphereGeometry>
          </mesh>
          <Html
            center
            style={{
              width: 200,
              pointerEvents: "none",
              backgroundColor: "rgba(0,0,0,0.8)",
              borderRadius: "6px",
              padding: "4px",
            }}
          >
            <p className="debug">
              <b>Theta:</b> {theta.toFixed(4)} <b>Phi:</b> {phi.toFixed(4)}
            </p>
            <p className="debug">
              <b>X:</b> {position[0].toFixed(4)} <b>Y:</b> {position[1].toFixed(4)} <b>Z:</b> {position[2].toFixed(4)}
            </p>
          </Html>
        </group>
      )}
    </group>
  );
};

Pin.propTypes = {
  theta: PropTypes.number,
  phi: PropTypes.number,
  children: PropTypes.node,
};

export default Pin;
