import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useRef } from "react";
import Classes from "../../../helpers/classes";
import Config from "../../../helpers/config";
import Env from "../../../helpers/env";
import { MediaSrcPropType } from "../../../logic/prop-types";
import { Orientation } from "../../../ripple";
import { useLastTruthy } from "../../hooks/use-last-truthy";
import Button from "../button";
import Revealer from "../revealer";
import VideoPlayer from "../video-player";

/**
 * A standalone fullscreen video player that looks native-ish on mobile while still supporting desktop,
 * and that handles orientation unlock/lock for free on mobile.
 *
 * Use with a react portal from any relevant part of the custom app. Setting the src to null will hide the player.
 *
 *     const [videoMedia, setVideoMedia] = useState(null);
 *     return (
 *       <Page>
 *         {ReactDOM.createPortal(
 *           <FullscreenVideoPlayer src={videoMedia} onClose={onFullscreenVideoPlayerClose} />,
 *           document.getElementById("app-overlay-portal")
 *         )}
 *       </Page>
 *     );
 */
const FullscreenVideoPlayer = memo(({ className, transition, src, onClose, ...rest }) => {
  const [lastTruthySrc] = useLastTruthy(src);

  const videoApiRef = useRef(null);
  const getVideoApi = useCallback((api) => (videoApiRef.current = api), []);

  const show = !!src;

  useEffect(() => {
    if (show) Orientation.unlock();
  }, [show]);

  const onCloseButtonClick = useCallback(() => {
    Orientation.lock(Config.initialOrientationLock);
    videoApiRef.current.pause();
    onClose?.();
  }, [onClose]);

  return (
    <Revealer
      className={Classes.build("ripple-fullscreen-video-player", className)}
      show={show}
      revealPointerEvents="auto"
    >
      <VideoPlayer
        getApi={getVideoApi}
        src={lastTruthySrc}
        {...rest}
        controlsMode={Env.isRCC ? "auto-hide" : "visible-always"}
        autoPlay
      />
      <Button className="close-button" onClick={onCloseButtonClick} />
    </Revealer>
  );
});

FullscreenVideoPlayer.propTypes = {
  className: PropTypes.string,
  transition: PropTypes.string,
  src: MediaSrcPropType,
  onClose: PropTypes.func,
};

export default FullscreenVideoPlayer;
