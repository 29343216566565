import { transparentize } from "polished";
import { Button, Grid, Image, Text, resource, staggerStep } from "ripple";
import styled from "styled-components";
import { CustomPage, RoundYellowButton, StandardScroller, textColor, yellowColor } from "../../common";
import MascotHome from "../../components/mascot-home";
import ResourcesMenu from "../../components/resources-menu";

export const Page = styled(CustomPage)``;

export const PageScroller = styled(StandardScroller).attrs((props) => ({
  scrollbarInset: "0.5vw",
  disableGestures: true,
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .scroller-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Section = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const SectionBackground = styled(Image).attrs((props) => ({ scaling: "fill" }))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const MascotsContainer = styled.div`
  position: absolute;
  bottom: 12%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 6vw;
`;

export const MoukaMascot = styled(MascotHome)``;

export const RaliMascot = styled(MascotHome)``;

export const SectionContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Text)`
  color: ${yellowColor};
  text-shadow: 0 2px 30px ${transparentize(0.8, yellowColor)};
  padding: 20px 35px;
  font-size: 8vmin;
  font-weight: 900;
  pointer-events: none;
`;

export const ThemesColumn = staggerStep({ transition: "slide-in-and-out-from-left" })(styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 8vw;
  gap: 1.8vw;
  padding: 1.8vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`);

export const ThemeMenuButton = staggerStep({ transition: "scale-up-fade" })(styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 2px solid white;
`);

export const SectionThemeIcon = styled(Image)`
  margin-top: 6vh;
  width: 12vh;
  height: 12vh;
  border: 1vh solid rgba(255, 255, 255, 0.6);
  border-radius: 50%;
`;

export const SectionThemeTitle = styled(Text)`
  margin-top: 40px;
  font-size: 26px;
  padding: 18px 32px;
  padding-top: 22px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 13px;
`;

export const ActivitiesGrid = styled(Grid)`
  margin-top: 4vh;
  gap: 2vh;
  .grid-row {
    gap: 2vh;
  }
`;

export const Activity = styled.div`
  width: 20vmax;
  height: 14vmax;
`;

export const ActivityTitle = styled(Text)`
  text-align: left;
  color: ${textColor};
  font-size: 2vmin;
`;

export const ActivityButton = styled(RoundYellowButton)`
  margin: -1vh;
  margin-left: auto;
  transform: rotate3d(0, 0, 1, 180deg);

  width: 5vmin;
  min-width: 5vmin;
  height: 5vmin;
  min-height: 5vmin;

  & > .ripple-image {
    transform: translate3d(0.2vh, 0, 0);
  }
`;

export const Footer = styled.div`
  position: relative;
  height: 10vmin;
  width: 100%;
  background-color: ${textColor};
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FooterText = styled(Text)`
  position: absolute;
  left: 10vw;
  font-size: 2.2vmin;
`;

export const FooterLogo = styled(Image).attrs((props) => ({ src: resource("images/Splash_Logo.png"), scaling: "fit" }))`
  height: 50%;
`;

export const DownloadApp = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5vmin;
  gap: 2vmax;
`;

export const DownloadAppText = styled(Text)`
  margin-bottom: 6vmin;
  font-size: 5vmin;
  text-align: center;
`;

export const DownloadAppStoreImage = styled(Image)`
  width: 45vmin;
`;

export const StyledResourcesMenu = styled(ResourcesMenu)`
  position: absolute;
  top: 0;
  right: 0;
`;
