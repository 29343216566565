import { useThree } from "@react-three/fiber";
import PropTypes from "prop-types";
import { useCallback, useRef } from "react";
import * as THREE from "three";
import Maths from "../../../../helpers/maths";
import { MediaSrcPropType } from "../../../../logic/prop-types";
import { Point, useAnimationFrame } from "../../../../ripple";
import { useDebug } from "../../../hooks/use-debug";
import Dome from "./dome";

const Scene = ({ src, muted, initialFov = 50, minFov = 20, maxFov = 50, children }) => {
  const debug = useDebug();
  const { camera, invalidate } = useThree();

  const previousLatRef = useRef(null);
  const latRef = useRef(0);

  const previousLonRef = useRef(null);
  const lonRef = useRef(0);

  const previousFovRef = useRef(null);
  const fovRef = useRef(initialFov);

  const isDraggingRef = useRef(false);
  const pointerDownPositionRef = useRef(null);
  const pointerDownLonRef = useRef(null);
  const pointerDownLatRef = useRef(null);

  const onPointerDown = useCallback((event) => {
    if (!event.isPrimary) return;
    isDraggingRef.current = true;
    pointerDownPositionRef.current = new Point(event.clientX, event.clientY);
    pointerDownLonRef.current = lonRef.current;
    pointerDownLatRef.current = latRef.current;
  }, []);

  const onPointerMove = useCallback((event) => {
    if (!event.isPrimary) return;
    if (!isDraggingRef.current) return;
    lonRef.current = pointerDownLonRef.current + (pointerDownPositionRef.current.x - event.clientX) * 0.05;
    latRef.current = pointerDownLatRef.current + (event.clientY - pointerDownPositionRef.current.y) * 0.05;
  }, []);

  const onPointerUp = useCallback((event) => {
    if (!event.isPrimary) return;
    isDraggingRef.current = false;
  }, []);

  const onMouseWheel = useCallback(
    (event) => (fovRef.current = Maths.clamp(fovRef.current + 0.01 * event.deltaY, minFov, maxFov)),
    [maxFov, minFov],
  );

  // We use `useAnimationFrame` rather than react-three-fiber's `useFrame` because
  // `useFrame` is not called for each animation frame when the frame loop is in "demand" mode.
  // (it only gets called once after frame invalidation, which is not what we need here)
  useAnimationFrame(
    async () => {},
    (result) => {
      if (
        fovRef.current === previousFovRef.current &&
        lonRef.current === previousLonRef.current &&
        latRef.current === previousLatRef.current
      ) {
        return;
      }

      previousFovRef.current = fovRef.current;
      previousLonRef.current = lonRef.current;
      previousLatRef.current = latRef.current;

      // Update rotation
      const phi = THREE.MathUtils.degToRad(90 - Maths.clamp(latRef.current, -90, 90));
      const theta = THREE.MathUtils.degToRad(lonRef.current - 180);
      const x = Math.sin(phi) * Math.cos(theta);
      const y = Math.cos(phi);
      const z = Math.sin(phi) * Math.sin(theta);
      camera.lookAt(x, y, z);

      // Update FOV
      camera.fov = fovRef.current;
      camera.updateProjectionMatrix();

      // Invalidate the frame so that the canvas in `frameloop="demand"` mode renders the updated visual state.
      // We're not doing it every frame because we check if the rotation and FOV have changed at the start of
      // this hook.
      invalidate();
    },
  );

  return (
    <group onPointerDown={onPointerDown} onPointerMove={onPointerMove} onPointerUp={onPointerUp} onWheel={onMouseWheel}>
      {debug && <axesHelper />}
      <Dome src={src} muted={muted} />
      {children}
    </group>
  );
};

Scene.propTypes = {
  src: MediaSrcPropType,
  muted: PropTypes.bool,
  children: PropTypes.node,
  initialFov: PropTypes.number,
  minFov: PropTypes.number, // Controls how far we can "zoom"
  maxFov: PropTypes.number, // Controls how far back we can "unzoom"
};

export default Scene;
