import yuri from "yuri";

let ServerInfo = function (url, apiVersion) {
  this.url = url;
  this.apiVersion = apiVersion === "latest" ? 0 : apiVersion;

  let that = this;

  function buildBaseUrl(path) {
    return ["api", `v${that.apiVersion}`].concat(path);
  }

  function buildUrl(path) {
    return yuri(that.url).pathname(buildBaseUrl(path)).format();
  }

  this.buildPingUrl = function () {
    return buildUrl(["ping"]);
  };

  this.buildClientUrl = function (id) {
    return buildUrl(["client", id]);
  };

  this.buildClientsUrl = function () {
    return buildUrl(["clients"]);
  };

  this.buildMediaUrl = function (formatMetadatas, media, format) {
    return buildUrl(["file", media.id, media.revision, format]);
  };

  this.buildUpdateTextsUrl = function () {
    return buildUrl(["update", "texts"]);
  };
};

export default ServerInfo;
