import styled, { css } from "styled-components";
import { textColor } from "../../common";

export const BoxRoot = styled.div`
  isolation: isolate;
  position: relative;
  border-radius: ${(props) => props.$borderRadius};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: ${textColor};
  box-shadow: ${(props) => (props.$glow ? css`0 0 4vh ${props.$color}` : `none`)};
`;

export const Background = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: ${(props) => props.$borderRadius};
  background-color: white;
`;

export const Border = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 100000; /* Border is always above everything in the context of the box. */
`;

export const ActiveBorder = styled(Border)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.$borderRadius};
  box-shadow:
    0 0 0 2px white inset,
    0 0 0 6px ${(props) => (props.$dashed ? "white" : props.$color)} inset,
    0 0 0 8px white inset;
`;

export const DashedBorder = styled(Border)`
  top: 2px;
  left: 2px;
  width: 100%;
  height: 100%;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border: 4px dashed ${(props) => props.$color};
  border-radius: calc(${(props) => props.$borderRadius} / 1.05);
`;

export const Content = styled.div`
  position: relative;
  margin: ${(props) => props.$borderWidth}px;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InactiveBorder = styled(Border)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.$borderRadius};
  box-shadow: 0 0 0 4px ${textColor} inset;
`;
