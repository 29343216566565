import PropTypes from "prop-types";
import { useContext } from "react";
import { Classes } from "ripple";
import { QuestionContext } from "../question";
import { ActiveBorder, Background, BoxRoot, Content, DashedBorder, InactiveBorder } from "./styled";

const Box = ({
  className,
  style,
  children,
  dashed = false,
  appearance = "inactive",
  borderRadius = "2vh",
  glow,
  ...rest
}) => {
  const context = useContext(QuestionContext);
  return (
    <BoxRoot
      {...rest}
      className={Classes.build("app-box", className)}
      style={style}
      $borderRadius={borderRadius}
      $color={context?.color}
      $glow={glow}
    >
      <Background $borderRadius={borderRadius} />
      <Content
        className="box-content"
        $borderWidth={(() => {
          if (appearance === "active") return 8;
          if (appearance === "inactive") return 4;
          if (appearance === "borderless") return 0;
          throw new Error("Unsupported Box appearance");
        })()}
      >
        {children}
      </Content>
      {appearance === "active" && <ActiveBorder $color={context.color} $borderRadius={borderRadius} $dashed={dashed} />}
      {dashed && <DashedBorder $color={context.color} $borderRadius={borderRadius} />}
      {appearance === "inactive" && <InactiveBorder $borderRadius={borderRadius} />}
    </BoxRoot>
  );
};

Box.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  dashed: PropTypes.bool,
  backgroundColor: PropTypes.string,
  appearance: PropTypes.oneOf(["active", "inactive", "borderless"]),
  borderRadius: PropTypes.string,
  glow: PropTypes.bool,
};

export default Box;
