import Log from "../helpers/log";

const Hook = function (description, hook) {
  this.description = description;
  this.hook = hook;

  this.run = function (event) {
    // If no hook is specified, instant success!
    if (!this.hook) return true;

    const result = this.hook(event);

    // If the hook returns nothing, we treat that as a success, too
    if (typeof result === "undefined") return true;

    if (!result) {
      Log.info(`Hook rejected the '${this.description}' operation`);
      return false;
    }

    return true;
  };
};

export default Hook;
