import { Button, Image, Revealer, Text, resource } from "ripple";
import styled from "styled-components";
import { RoundYellowButton, yellowColor } from "../../common";

export const ResourcesMenuRoot = styled.div`
  min-width: 25vmax;
`;

export const ContentRevealer = styled(Revealer).attrs((props) => ({ transition: "slide-in-and-out-from-top" }))`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2vmax;
  display: flex;
  flex-direction: column;
  gap: 1.2vmax;
`;

export const Title = styled(Text)`
  margin-top: 2vmax;
  font-size: 1.2vmax;
  color: ${yellowColor};
`;

export const ResourceButton = styled(Button)`
  font-size: 1.5vmax;
  .hotspot {
    margin: -0.5vmax;
  }
`;

export const ToggleButton = styled(RoundYellowButton)`
  position: absolute;
  top: 1.5vmax;
  right: 1.5vmax;
  width: 3vmax;
  height: 3vmax;
`;

export const Symbol = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: opacity 250ms ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
`;

export const MenuSymbol = styled(Symbol).attrs((props) => ({ src: resource("images/Button_Menu_Symbol.svg") }))``;

export const CloseSymbol = styled(Symbol).attrs((props) => ({ src: resource("images/Button_Close_Symbol.svg") }))``;
