class Iterate {
  /**
   * Iterates an array in pairs, similar to "pairwise" in RX.
   * Iteration can be stopped by returning false from the operation function.
   * Here's what happens for an array containing 4 items:
   * operation(array[0], array[1]), operation(array[1], array[2]), operation(array[2], array[3])
   */
  static eachPairwise(array, operation) {
    if (array.length === 0) return;
    if (array.length === 1) operation(null, array[0]);

    for (let i = 0; i < array.length - 1; i++) {
      const result = operation(array[i], array[i + 1]);
      if (result === false) break;
    }
  }

  /**
   * Iterates an array in pairs and accumulates what's returned
   * from each pairwise operation into an array.
   */
  static mapPairwise(array, operation) {
    const results = [];
    Iterate.eachPairwise(array, (r1, r2) => results.push(operation(r1, r2)));
    return results;
  }
}

export default Iterate;
