import { Text } from "ripple";
import styled from "styled-components";
import Box from "../box";

export const TextDragPreviewRoot = styled(Box).attrs((props) => ({ borderRadius: "2vh" }))`
  .box-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Word = styled(Text)`
  width: 100%;
  height: 100%;
  font-size: 3vh;
  text-align: center;
  padding: 1vh 2vh;
`;
