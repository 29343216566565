import PropTypes from "prop-types";
import { cloneElement, createContext, memo, useCallback } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Classes from "../../../helpers/classes";
import { Stagger } from "../stagger";

export const TransitionContext = createContext();

const Transitioner = memo(
  ({ style, className, children, classNames = "fade-out-fade-in", transitionKey, timeout = 550, staggerOptions }) => {
    // Providing a child factory allows updating the outgoing child's `classNames` which allows changing transition dynamically.
    // HOWEVER, keep in mind that changing on the transition based on the location on render is too late in the transition
    // cycle for the new transition to be picked up (exhibits various issues). An alternative is to specify transitions explicitly
    // through navigation state:
    //
    //     // On navigation:
    //     Navigator.navigate({ pathname: "/new/path", state: { bottomTransition: "slide-in-and-fade-from-right" }})
    //
    //     // Obtain the actual transition to perform:
    //     <Transitioner classNames={location.state.bottomTransition || "slide-in-and-fade-from-left"}/>
    //
    // Issue: https://github.com/reactjs/react-transition-group/issues/182
    // Details: https://medium.com/lalilo/dynamic-transitions-with-react-router-and-react-transition-group-69ab795815c9
    const childFactory = useCallback((child) => cloneElement(child, { classNames, timeout }), [classNames, timeout]);

    return (
      <TransitionGroup
        style={style}
        className={Classes.build("ripple-transitioner", className)}
        childFactory={childFactory}
      >
        <CSSTransition key={transitionKey} classNames={classNames} timeout={timeout}>
          {(state) => (
            <div className="transitioner-content" style={{ position: "absolute", width: "100%", height: "100%" }}>
              {/* Wrapping the transitioner's content in a Stagger enables automatic staggering
                  of any stagger-enabled components in the children or their descendants. */}
              <Stagger options={staggerOptions}>
                <TransitionContext.Provider value={{ state }}>{children || <div />}</TransitionContext.Provider>
              </Stagger>
            </div>
          )}
        </CSSTransition>
      </TransitionGroup>
    );
  },
);

Transitioner.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  transitionKey: PropTypes.any, // No transition will occur unless a key is provided
  classNames: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // The transition class names
  timeout: PropTypes.number,
  staggerOptions: PropTypes.object,
};

export default Transitioner;
