import PropTypes from "prop-types";
import { Image, resource } from "ripple";
import { BackButtonRoot } from "./styled";

const BackButton = ({ activity, ...rest }) => {
  return (
    <BackButtonRoot {...rest}>
      <Image src={resource("images/Button_Back_Symbol.svg")} />
    </BackButtonRoot>
  );
};

BackButton.propTypes = {
  activity: PropTypes.object,
};

export default BackButton;
