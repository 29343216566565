import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Audio, resource, useData } from "ripple";
import { GoodImage, LandscapeCardGrid, PortraitCardGrid, WrongImage } from "../../../common";
import { useIsPortrait } from "../../../hooks/use-is-portrait";
import { Card, CardBar, CardImage, CardSpeakerButton, ClickwordQuestionRoot } from "./styled";

const ClickwordQuestion = ({ onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const items = node.children;
  const isPortrait = useIsPortrait();
  const color = node.parent.wantedText("Color")?.trim();

  const [freezeCards, setFreezeCards] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);

  const has3Rows = isPortrait && items.length === 6;

  // Count of good answer.
  const count = items.filter((item) => item.settings.Answer === "good").length;

  const [clickedItems, setClickedItems] = useState([]);

  useEffect(() => {
    const clickedCount = clickedItems.length;
    if (clickedCount >= count) setFreezeCards(true);
  }, [clickedItems.length, count]);

  useEffect(() => {
    if (!freezeCards) return;

    setTimeout(() => {
      setShowAnswer(true);
    }, 1000);
  }, [freezeCards]);

  useEffect(() => {
    if (!showAnswer) return;

    let good = true;
    clickedItems.forEach((item) => {
      if (!good) return;
      const card = items[item.index];
      good = card.settings.Answer === "good";
    });

    good
      ? Audio.discrete("effects").play(resource("audio/ThumbUp01.mp3"))
      : Audio.discrete("effects").play(resource("audio/ThumbDown01.mp3"));

    setTimeout(() => {
      if (good) onComplete();
      else {
        setClickedItems([]);
        setFreezeCards(false);
        setShowAnswer(false);
      }
    }, 2500);
  }, [clickedItems, items, onComplete, showAnswer]);

  const createOnCardClick = (item, index) => () => {
    const card = clickedItems.find((card) => card.id === item.id);
    if (card) {
      setClickedItems((prevCards) => prevCards.filter((card) => card.id !== item.id));
    } else {
      setClickedItems((prevCards) => [...prevCards, { index, id: item.id }]);
    }
  };

  const renderCard = (item, index) => {
    const audio = item.wantedMedia("Audio", "Audio");
    const active = clickedItems.some((item) => item.index === index);
    return (
      <Card
        key={item.id}
        color={color}
        active={active}
        disabled={freezeCards}
        shrink={has3Rows}
        onClick={createOnCardClick(item, index)}
      >
        <CardImage src={item.wantedMedia("ClickImage", "FlipImage")} />
        {audio && (
          <CardBar color={color}>
            <CardSpeakerButton src={audio} />
          </CardBar>
        )}
        {showAnswer && active && (item.settings.Answer === "good" ? <GoodImage /> : <WrongImage />)}
      </Card>
    );
  };

  return (
    <ClickwordQuestionRoot {...rest}>
      {isPortrait ? (
        <PortraitCardGrid>{items.map(renderCard)}</PortraitCardGrid>
      ) : (
        <LandscapeCardGrid>{items.map(renderCard)}</LandscapeCardGrid>
      )}
    </ClickwordQuestionRoot>
  );
};

ClickwordQuestion.propTypes = {
  onComplete: PropTypes.func,
};

export default ClickwordQuestion;
