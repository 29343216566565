import { endOfDay, format as formatDate, isEqual, parse as parseDate, startOfDay, subDays } from "date-fns";
import _ from "lodash";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { connect } from "react-redux";

import Analytics from "../../../helpers/analytics";
import Config from "../../../helpers/config";
import Log from "../../../helpers/log";
import Navigator from "../../../helpers/navigator";
import Strings from "../../../helpers/strings";
import AnalyticsHelper from "../../../logic/analytics/analytics-helper";
import LocalSource from "../../../logic/analytics/event-sources/local-source";
import LanguageToggleButton from "../../components/language-toggle-button";
import Page from "../../components/page";
import Scroller from "../../components/scroller";

// Number Stats
import AppsNumberStat from "../../../logic/analytics/stats/number/apps-stat";
import AverageDistinctPagesPerSessionNumberStat from "../../../logic/analytics/stats/number/average-distinct-pages-per-session-stat";
import AverageNavigationsPerSessionNumberStat from "../../../logic/analytics/stats/number/average-navigations-per-session-stat";
import AverageSessionDurationNumberStat from "../../../logic/analytics/stats/number/average-session-duration-stat";
import AverageTimeBetweenSessionsNumberStat from "../../../logic/analytics/stats/number/average-time-between-sessions-stat";
import AverageTimePerPageNumberStat from "../../../logic/analytics/stats/number/average-time-per-page-stat";
import BounceRateNumberStat from "../../../logic/analytics/stats/number/bounce-rate-stat";
import InstancesNumberStat from "../../../logic/analytics/stats/number/instances-stat";
import MailSendStat from "../../../logic/analytics/stats/number/mail-send-stat";
import MailSubscribeStat from "../../../logic/analytics/stats/number/mail-subscribe-stat";
import TotalSessionsNumberStat from "../../../logic/analytics/stats/number/total-sessions-stat";
import UniqueUsersNumberStat from "../../../logic/analytics/stats/number/unique-users-stat";

// Donut Stats
import AverageTimeOnPageDonutStat from "../../../logic/analytics/stats/donut/average-time-on-page-stat";
import LanguagesDonutStat from "../../../logic/analytics/stats/donut/languages-stat";
import PageViewsDonutStat from "../../../logic/analytics/stats/donut/page-views-stat";
import PlatformsDonutStat from "../../../logic/analytics/stats/donut/platforms-stat";

// Bar Stats
import SessionsPerDayBarStat from "../../../logic/analytics/stats/bar/sessions-per-day-stat";

// Table Stats
import NamesTableStat from "../../../logic/analytics/stats/table/names-stat";
import NodesTableStat from "../../../logic/analytics/stats/table/nodes-stat";
import PathsTableStat from "../../../logic/analytics/stats/table/paths-stat";

// Boxes (components that display stats)
import Classes from "../../../helpers/classes";
import ServerSource from "../../../logic/analytics/event-sources/server-source";
import { useConstant } from "../../hooks/use-constant";
import { useIsMountedRef } from "../../hooks/use-is-mounted-ref";
import { useQueryString } from "../../hooks/use-query-string";
import BarBox from "./bar-box";
import DonutBox from "./donut-box";
import NumberBox from "./number-box";
import TableBox from "./table-box";

const numberOfNumberBoxColumns = 6;
const numberOfBoxColumns = 2;

function paddingRangeFor(number, multiple) {
  return _.range(0, Math.ceil(number / multiple) * multiple - number);
}

function toDayString(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

const AnalyticsPage = memo(() => {
  const { startDate: rawStartDate, endDate: rawEndDate, appId: rawAppId, instanceId: rawInstanceId } = useQueryString();

  const [events, setEvents] = useState(null);
  const [pickingDate, setPickingDate] = useState(false);
  const [loading, setLoading] = useState(true);

  const isMountedRef = useIsMountedRef();

  const source = useConstant(() => {
    const sourceName = Config.analytics.source;
    if (sourceName === "local") return new LocalSource();
    if (sourceName === "server")
      return new ServerSource(
        Config.analytics.server.url,
        Config.analytics.server.readUsername,
        Config.analytics.server.readPassword,
      );
    throw new Error(`Unsupported analytics source '${sourceName}'`);
  });

  const navigateToDateRange = (startDate, endDate) => {
    Navigator.navigate({
      mergeQuery: {
        startDate: toDayString(startDate),
        endDate: toDayString(endDate),
      },
    });
  };

  const previousAppIdRef = useRef(null);
  const appId = (() => {
    const id = rawAppId || previousAppIdRef.current || null;
    previousAppIdRef.current = id; // We keep track of this to avoid issues when navigating out of the page
    return id;
  })();

  const instanceId = rawInstanceId;

  const previousStartDateRef = useRef(null);
  const startDate = rawStartDate
    ? parseDate(rawStartDate, "yyyy-MM-dd", new Date())
    : subDays(startOfDay(new Date()), 30);

  const previousEndDateRef = useRef(null);
  const endDate = rawEndDate ? endOfDay(parseDate(rawEndDate, "yyyy-MM-dd", new Date())) : endOfDay(new Date());

  const currentRequestAbortControllerRef = useRef(null);
  const updateEvents = useCallback(
    (appId, instanceId, startDate, endDate) => {
      Log.info("Fetching analytics events for display");

      setLoading(true);
      currentRequestAbortControllerRef.current?.abort();
      currentRequestAbortControllerRef.current = new AbortController();
      source
        .getRange(appId, startDate, endDate, currentRequestAbortControllerRef.current)
        .then((events) => {
          if (!isMountedRef.current) return;
          setEvents(events || []);
          setLoading(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") return;
          throw error;
        });
    },
    [source, isMountedRef],
  );

  const onInstanceChange = useCallback((event) => {
    const value = event.target.value;
    Navigator.navigate({ mergeQuery: { instanceId: value === "all" ? null : value } });
  }, []);

  const onDateRangeChange = useCallback((o) => {
    const newStartDate = o.selection.startDate;
    const newEndDate = o.selection.endDate;
    if (!newStartDate && !newEndDate) return;
    navigateToDateRange(newStartDate, newEndDate);
  }, []);

  const onDateRangeFieldClick = useCallback(() => {
    setPickingDate(true);
  }, []);

  const onDateRangeOverlayClick = useCallback(() => {
    setPickingDate(false);
  }, []);

  useEffect(() => {
    // Avoid infinite render loop (two date instances are not strictly equal even if they contain the same date)
    if (isEqual(startDate, previousStartDateRef.current) && isEqual(endDate, previousEndDateRef.current)) return;
    previousStartDateRef.current = startDate;
    previousEndDateRef.current = endDate;

    updateEvents(appId, instanceId, startDate, endDate);
  }, [updateEvents, appId, instanceId, startDate, endDate]);

  const filteredEvents = (events ?? []).slice(0).filter((e) => !instanceId || e.instanceId === instanceId);

  // In addition to simplifying stat calculations, filtering by session ensures that stats
  // coming from multiple apps running in parallel will be distinguished even if the events are
  // mixed together in the raw events list.
  const sessions = AnalyticsHelper.getSessions(filteredEvents);

  const preprocessed = {
    startDate,
    endDate,
    events: filteredEvents,
    sessions,
  };

  const numberStats = [
    new AppsNumberStat(preprocessed),
    new InstancesNumberStat(preprocessed),
    new UniqueUsersNumberStat(preprocessed),
    new TotalSessionsNumberStat(preprocessed),
    new BounceRateNumberStat(preprocessed),
    new AverageNavigationsPerSessionNumberStat(preprocessed),
    new AverageDistinctPagesPerSessionNumberStat(preprocessed),
    new AverageSessionDurationNumberStat(preprocessed),
    new AverageTimeBetweenSessionsNumberStat(preprocessed),
    new AverageTimePerPageNumberStat(preprocessed),
    new MailSendStat(preprocessed),
    new MailSubscribeStat(preprocessed),
    ..._.map(Analytics._customStats.number || [], (f) => new f(preprocessed)),
  ];

  const donutStats = [
    new LanguagesDonutStat(preprocessed),
    new PlatformsDonutStat(preprocessed),
    new PageViewsDonutStat(preprocessed),
    new AverageTimeOnPageDonutStat(preprocessed),
    ..._.map(Analytics._customStats.donut || [], (f) => new f(preprocessed)),
  ];

  const barStats = [
    new SessionsPerDayBarStat(preprocessed),
    ..._.map(Analytics._customStats.bar || [], (f) => new f(preprocessed)),
  ];

  const tableStats = [
    new NodesTableStat(preprocessed),
    new PathsTableStat(preprocessed),
    new NamesTableStat(preprocessed),
    ..._.map(Analytics._customStats.table || [], (f) => new f(preprocessed)),
  ];

  const uniqueInstanceIds = _.uniq(_.map(events, (e) => e.instanceId || "No Instance")); // Pick from unfiltered events

  // In viewer mode, we prevent viewing of aggregate analytics (for performance and isolation).
  // This is security through obscurity, but it does the job as analytics do not contain sensitive data.
  if (!appId && Config.analytics.source !== "local" && Config.analytics.viewer) {
    throw new Error("Invalid Operation : An app ID is required to view analytics");
  }

  return (
    <Page className="analytics" pauseTimeout="reset">
      <div className="header">
        <div className="left">
          <h1>
            {Strings.localized("AnalyticsPageTitle") +
              (appId ? ` ${Strings.localized("AnalyticsPageTitleFor")} ` + appId : "")}
          </h1>
          <select className="instance-id" value={instanceId || ""} onChange={onInstanceChange}>
            <option value="all">{`${Strings.localized("AnalyticsAllInstances")} (${uniqueInstanceIds.length})`}</option>
            <option disabled>──────────</option>
            {_.map(uniqueInstanceIds, (f) => (
              <option key={f} value={f}>
                {f}
              </option>
            ))}
          </select>
        </div>
        <div className="right">
          <div className="date-range" onClick={onDateRangeFieldClick}>
            {formatDate(preprocessed.startDate, "yyyy-MM-dd")}
            <span className="to">-</span>
            {formatDate(preprocessed.endDate, "yyyy-MM-dd")}
          </div>
          <LanguageToggleButton />
          <DateRangePicker
            className={Classes.build("date-range-picker", { visible: pickingDate })}
            ranges={[{ startDate: preprocessed.startDate, endDate: preprocessed.endDate, key: "selection" }]}
            onChange={onDateRangeChange}
          />
        </div>
      </div>
      <Scroller
        className={Classes.build("bottom", { loaded: !!events })}
        startFadeRatio={0.01}
        endFadeRatio={0.05}
        showScrollbar={false}
      >
        <div className="number-boxes">
          {_.map(numberStats, (s) => (
            <NumberBox key={s.name} stat={s} />
          ))}
          {_.map(paddingRangeFor(numberStats.length, numberOfNumberBoxColumns), (i) => (
            <div key={`empty-number-box-${i}`} className="number-box empty" />
          ))}
        </div>
        <div className="graphs">
          {_.map(donutStats, (s) => (
            <DonutBox key={s.name} stat={s} />
          ))}
          {_.map(barStats, (s) => (
            <BarBox key={s.name} stat={s} />
          ))}
          {_.map(tableStats, (s) => (
            <TableBox key={s.name} stat={s} />
          ))}
          {_.map(paddingRangeFor(donutStats.length + barStats.length + tableStats.length, numberOfBoxColumns), (i) => (
            <div key={`empty-box-${i}`} className="box empty" />
          ))}
        </div>
      </Scroller>
      <div
        className={Classes.build("date-range-overlay", { visible: pickingDate })}
        onClick={onDateRangeOverlayClick}
      ></div>
      <div className={Classes.build("loading-indicator", { visible: loading })}>
        {Strings.localized("AnalyticsLoading")}
      </div>
    </Page>
  );
});

AnalyticsPage.propTypes = {
  query: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
  return { language: state.language, query: state.router.location.query };
};

export default connect(mapStateToProps)(AnalyticsPage);
