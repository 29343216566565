import Maths from "../../../helpers/maths";

// Rotation Functions : The item's rotation on all three axes can be
// controlled by providing one of the scaling functions below to `itemDynamicScale`
// or creating your own in a custom app.

export const off = () => (scrollIndex, focusedIndex) => {
  return { x: 0, y: 0, z: 0, angle: 0 };
};

/** Rotate the unfocused (non-current) items */
export const unfocused =
  (xVectorComponent = 0, yVectorComponent = 0, zVectorComponent = 0, angle = 0) =>
  (scrollIndex, focusedIndex) => {
    const distance = Math.min(1, Math.abs(scrollIndex - focusedIndex));
    return { x: xVectorComponent, y: yVectorComponent, z: zVectorComponent, angle: distance * angle };
  };

export const ascending =
  (xVectorComponent = 0, yVectorComponent = 0, zVectorComponent = 0, angle = 0, { clamp = true } = {}) =>
  (scrollIndex, focusedIndex) => {
    const distance = Maths.clamp(
      scrollIndex - focusedIndex,
      clamp ? -1 : Number.NEGATIVE_INFINITY,
      clamp ? 1 : Number.POSITIVE_INFINITY,
    );
    return { x: xVectorComponent, y: yVectorComponent, z: zVectorComponent, angle: distance * angle };
  };

export const descending =
  (xVectorComponent = 0, yVectorComponent = 0, zVectorComponent = 0, angle = 0) =>
  (scrollIndex, focusedIndex, { clamp = true } = {}) => {
    const distance = Maths.clamp(
      focusedIndex - scrollIndex,
      clamp ? -1 : Number.NEGATIVE_INFINITY,
      clamp ? 1 : Number.POSITIVE_INFINITY,
    );
    return { x: xVectorComponent, y: yVectorComponent, z: zVectorComponent, angle: distance * angle };
  };
