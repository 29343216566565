import _ from "lodash";
import Iterate from "../../../../helpers/iterate";
import AnalyticsHelper from "../../analytics-helper";

function mergeDurationInfosForConsecutiveNavigationsToTheSamePage(durationInfos) {
  if (durationInfos.length === 0) return [];

  const merged = [durationInfos[0]];
  Iterate.mapPairwise(durationInfos, (i1, i2) => {
    if (i2.path === i1.path) {
      _.last(merged).duration += i2.duration;
      return;
    }
    merged.push(i2);
  });

  return merged;
}

function calculate(preprocessed) {
  const navigationsAndTimeouts = _.filter(
    preprocessed.events,
    (e) =>
      (e.name === "navigation" && e.data.root === false) ||
      e.name === "timeout" || // Example: startup -> page1 -> page2 -> timeout (must consider timeout to know the duration in page2)
      e.name === "startup", // Example: startup -> page1 -> page2 -> startup (force-reloaded; must consider startup to know the duration in page2)
  );

  if (navigationsAndTimeouts.length < 2) return {};

  // For each pair of events, note the time between it and the next event
  const durationInfos =
    navigationsAndTimeouts.length < 2
      ? []
      : Iterate.mapPairwise(navigationsAndTimeouts, (e1, e2) => ({
          path: e1.data.path,
          duration: e2.time - e1.time,
        }));

  if (durationInfos.length < 2) return {};

  // Merge successive navigations to the same path (navigation within a page with query changes)
  // to avoid messing up stats by lowering the average for a page when there are in-page navigations.
  const mergedDurationInfos = mergeDurationInfosForConsecutiveNavigationsToTheSamePage(durationInfos);

  // Calculate a mean for each path
  const groupedByPath = _.groupBy(mergedDurationInfos, (i) => AnalyticsHelper.firstPathComponent(i.path));
  const meansByPath = _.mapValues(groupedByPath, (i) => _.mean(_.map(i, (i) => i.duration)));

  // Remove timeout durations from the data set
  // (timeouts are in the null group because they have no path)
  delete meansByPath[null];

  return meansByPath;
}

export default function (preprocessed) {
  this.name = "AverageTimeOnPage";
  this.keyName = "Page";
  this.valueName = "Average (in milliseconds)";
  this.format = AnalyticsHelper.formatAsDuration;
  this.value = calculate(preprocessed);
}
