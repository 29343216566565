import { staggerStep } from "ripple";
import styled from "styled-components";
import { RoundYellowButton } from "../../common";

export const CloseButtonRoot = staggerStep({ transition: "slide-in-and-fade-from-right", order: 2 })(styled(
  RoundYellowButton,
)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .hotspot {
    margin: -3vmin;
  }
`);
