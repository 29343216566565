import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Config, Fetch, Grid, Navigator, Strings, useTimeout } from "ripple";
import ActivityCard from "../../../components/activity-card";
import SongActivityCardContent from "../../../components/song-activity-card-content";
import Star from "../../../components/star";
import { useProgressStorage } from "../../../hooks/use-progress-storage";
import {
  ActivitiesGrid,
  ActivitiesScroller,
  Activity,
  ActivityStarButton,
  ActivityStars,
  ActivityTitle,
  ActivityWhiteBoxContent,
  BackgroundMediaTransitioner,
  HeaderBottom,
  HeaderBox,
  HeaderProgressBar,
  HeaderSubtitle,
  HeaderSubtitleIcon,
  HeaderSubtitleText,
  HeaderTitle,
  ThemeContentRoot,
} from "./styled";

const ThemeContent = ({ className, style, theme, ...rest }) => {
  const [backgroundImage, setBackgroundImage] = useState(theme.wantedMedia("Background", "FullscreenImage"));
  const download = useSelector((state) => state.download);
  const downloadingCurrentTheme = download.nodeId === theme.id;
  const downloadingAnotherTheme = !!download.nodeId;
  const firstDownload = !Fetch.hasNodeBeenDownloadedAtLeastOnce(theme);
  const downloadOutdated = Fetch.hasNodeBeenModifiedSinceLastDownload(theme);

  const isPhoneWidth = useMediaQuery({ query: "(max-width: 700px)" });

  const { getCompleted, getGameCompleted } = useProgressStorage();

  useTimeout(
    () => setBackgroundImage(theme.wantedMedia("BackgroundBlurred", "FullscreenImageHighQuality")),
    Config.blurredBackgroundTransitionDelay,
  );

  const createOnActivityClick = (activity) => () => {
    const firstNonCompletedQuestion = activity.children.flatMap((game) => game.children).find((q) => !getCompleted(q));
    Navigator.navigate({ node: firstNonCompletedQuestion ?? activity });
  };

  const createOnStarClick = (game) => () => {
    Navigator.navigate({ node: game });
  };

  const onHeaderBoxClick = useCallback(() => {
    Fetch.downloadMedias(theme);
  }, [theme]);

  const renderSongActivityCardContent = (activity) => {
    return <SongActivityCardContent activity={activity} />;
  };

  const renderSongActivityWhiteBoxContent = (activity) => {
    return null;
  };

  const renderActivityWhiteBoxContent = (activity) => {
    return (
      <ActivityWhiteBoxContent>
        <ActivityTitle>{activity.wantedText("Title")}</ActivityTitle>
        <ActivityStars>
          {activity.children.map((game) => (
            <ActivityStarButton key={game.id} onClick={createOnStarClick(game)}>
              <Star color={game.wantedText("Color")?.trim()} active={getGameCompleted(game)} />
            </ActivityStarButton>
          ))}
        </ActivityStars>
      </ActivityWhiteBoxContent>
    );
  };

  return (
    <ThemeContentRoot {...rest} className={className} style={style}>
      <BackgroundMediaTransitioner src={backgroundImage} />
      <HeaderBox disabled={!downloadOutdated} onClick={onHeaderBoxClick}>
        <HeaderTitle>{theme.wantedText("Title")}</HeaderTitle>
        <HeaderBottom $show={downloadOutdated}>
          <HeaderSubtitle $show={!downloadingCurrentTheme}>
            {!downloadingAnotherTheme ? (
              <>
                <HeaderSubtitleIcon />
                <HeaderSubtitleText>
                  {Strings.localized(firstDownload ? "ThemeDownloadCallToAction" : "ThemeUpdateCallToAction")}
                </HeaderSubtitleText>
              </>
            ) : (
              <HeaderSubtitleText>{Strings.localized("ThemeDownloadWait")}</HeaderSubtitleText>
            )}
          </HeaderSubtitle>
          <HeaderProgressBar $show={downloadingCurrentTheme} value={download.progress} />
        </HeaderBottom>
      </HeaderBox>
      <ActivitiesScroller>
        <ActivitiesGrid layout={Grid.layout.columns(isPhoneWidth ? 1 : 2)}>
          {theme.children.map((activity) => (
            <Activity key={activity.id} $columns={isPhoneWidth ? 1 : 2}>
              <ActivityCard
                activity={downloadOutdated ? null : activity}
                key={activity.id}
                onClick={createOnActivityClick(activity)}
                cardContent={activity.semantic === "SongActivity" ? renderSongActivityCardContent(activity) : null}
                disabled={downloadOutdated || activity.semantic === "SongActivity"}
              >
                {!downloadOutdated &&
                  (activity.semantic === "SongActivity"
                    ? renderSongActivityWhiteBoxContent(activity)
                    : renderActivityWhiteBoxContent(activity))}
              </ActivityCard>
            </Activity>
          ))}
        </ActivitiesGrid>
      </ActivitiesScroller>
    </ThemeContentRoot>
  );
};

ThemeContent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.object,
};

export default ThemeContent;
