import { memo } from "react";
import { useLanguage } from "ripple";
import { Underlay } from "./styled";

const AppUnderlay = memo(() => {
  useLanguage();
  return (
    <Underlay>
      {
        // NOTE: If you want a persistent background across pages,
        // use the `rootBackground` prop on `Page` instead of adding one here!
      }
    </Underlay>
  );
});

export default AppUnderlay;
