import PropTypes from "prop-types";
import { PureComponent } from "react";
import Classes from "../../../helpers/classes";
import Strings from "../../../helpers/strings";
import Styles from "../../../helpers/styles";

class NumberBox extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    stat: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className={Classes.build("number-box", this.props.className)} style={Styles.merge(this.props.style)}>
        <p className="name">
          {Strings.localized(`Analytics${this.props.stat.name}`)}&nbsp;
          <i className="info fa fa-info-circle" title={Strings.localized(`Analytics${this.props.stat.name}Tooltip`)} />
        </p>
        <span className="value">{this.props.stat.format(this.props.stat.value)}</span>
      </div>
    );
  }
}

export default NumberBox;
