import { useRef, useEffect } from "react";

/**
 * Use this hook when you must know if the component is unmounted to
 * avoid updating the component's state after it has been unmounted.
 * This is useful when (for example) setting up multiple timeouts and
 * ignoring those timeouts' callbacks if the component has been
 * unmounted, without needing to track each timeout's id to clear them
 * manually. If possible, cancel asynchronous operations instead of
 * using this hook.
 */
export const useIsMountedRef = () => {
  const isMountedRef = useRef(true);
  useEffect(() => () => (isMountedRef.current = false), []);
  return isMountedRef;
};
