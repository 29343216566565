import PropTypes from "prop-types";
import { Fragment, useCallback, useState } from "react";
import { useData } from "ripple";
import {
  CloseSymbol,
  ContentRevealer,
  MenuSymbol,
  ResourceButton,
  ResourcesMenuRoot,
  Title,
  ToggleButton,
} from "./styled";

const ResourcesMenu = ({ className, style, ...rest }) => {
  const client = useData((data) => data.root);
  const sections = client.children.filter((c) => c.semantic === "Resources")[0].children;
  const [isOpen, setIsOpen] = useState(false);

  const onToggleClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <ResourcesMenuRoot {...rest} className={className} style={style}>
      <ContentRevealer show={isOpen}>
        {sections.map((section) => {
          return (
            <Fragment key={section.id}>
              <Title>{section.wantedText("Title")}</Title>
              {section.children.map((r) => (
                <ResourceButton key={r.id} navigate={{ node: r }}>
                  {r.wantedText("Title")}
                </ResourceButton>
              ))}
            </Fragment>
          );
        })}
      </ContentRevealer>
      <ToggleButton onClick={onToggleClick}>
        <MenuSymbol $show={!isOpen} />
        <CloseSymbol $show={isOpen} />
      </ToggleButton>
    </ResourcesMenuRoot>
  );
};

ResourcesMenu.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ResourcesMenu;
