import PropTypes from "prop-types";
import { MediaSrcPropType } from "ripple";
import { Children, ChildrenContainer, MascotImage, MascotRoot, PhylacteryArrow, PhylacteryContainer } from "./styled";

const Mascot = ({ src, children, mode, hideBackground, flip, wideInPortrait, ...rest }) => {
  return (
    <MascotRoot {...rest} $mode={mode} $background={hideBackground && "none"} $wideInPortrait={wideInPortrait}>
      <PhylacteryContainer $show={!!children}>
        <ChildrenContainer>
          <Children>{children}</Children>
        </ChildrenContainer>
        <PhylacteryArrow />
      </PhylacteryContainer>
      <MascotImage src={src} $flip={flip} />
    </MascotRoot>
  );
};

Mascot.propTypes = {
  show: PropTypes.bool,
  src: MediaSrcPropType,
  children: PropTypes.node,
  mode: PropTypes.oneOf(["centered", "side"]),
  hideBackground: PropTypes.bool,
  flip: PropTypes.bool,
  wideInPortrait: PropTypes.bool,
};

export default Mascot;
