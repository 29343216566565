import _ from "lodash";
import { ModelBuilder, XmlParser } from "mediaclient.js";
import PropTypes from "prop-types";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import Config, { getInstanceConfig, setInstanceConfig } from "../../../helpers/config";
import Env from "../../../helpers/env";
import Strings from "../../../helpers/strings";
import Toast from "../../../helpers/toast";
import Button from "../../components/button";
import Dropdown from "../../components/dropdown";
import Page from "../../components/page";
import RippleContext from "../../core/ripple-context";

const PageComponent = memo(() => {
  const apiClient = useContext(RippleContext).apiClient;

  const [clients, setClients] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  const onDropdownChange = useCallback((client) => setSelectedClient(client), []);

  const onTemporaryButtonClick = useCallback(() => {
    window.location.href = `/?config=%7B%22server%22:%7B%22clientId%22:%22${selectedClient.id}%22%7D%7D`;
  }, [selectedClient]);

  const onPermanentButtonClick = useCallback(() => {
    const updatedConfig = {
      server: { clientId: selectedClient?.id ?? Config.server.clientId },
    };

    if (Env.isREC) {
      // In REC, save to local config (on disk)
      Env._updateLocalConfig(updatedConfig);
    } else {
      // In all other cases, save to instance config
      const instanceConfig = getInstanceConfig();
      const newConfig = _.merge({}, instanceConfig, updatedConfig);
      setInstanceConfig(newConfig);
    }

    // Notification or reload based on environment
    if (Env.isContained) {
      Toast.info(Strings.localized("ClientPageSaveToastNotification"), 10000);
    } else {
      window.location.href = "/";
    }
  }, [selectedClient]);

  useEffect(() => {
    if (clients !== null) return;
    apiClient
      .fetchClientsXml()
      .then((xml) => XmlParser.parse(xml).then(ModelBuilder.buildFromRaw))
      .then((data) => {
        setClients(data.nodes);
        setSelectedClient(clients.filter((c) => c.id === Config.server.clientId)[0]);
      });
  }, [apiClient, clients]);

  return (
    <Page className="client ripple-dashboard" pauseTimeout="reset">
      <h1>{Strings.localized("ClientPageTitle")}</h1>
      {clients ? (
        <Dropdown
          className="clients"
          items={clients}
          initialItem={clients.filter((c) => c.id === Config.server.clientId)[0]}
          onChange={onDropdownChange}
        >
          {(client) => <div className="item">{client.name}</div>}
        </Dropdown>
      ) : (
        <div className="loading">{Strings.localized("ClientPageLoading")}</div>
      )}
      <div className="buttons">
        {/* Disabled in containers for now because truly restarting the app (inclugin going through the startup
            page) is harder than it seems and does not behave exacly the same in all containers and platforms.
            It doesn't react like in the browser, reloading the same page instead of loading the site root. */}
        {!Env.isContained && (
          <Button className="standard" onClick={onTemporaryButtonClick} disableAfterClick>
            {Strings.localized("ClientPageTemporaryButton")}
          </Button>
        )}
        <Button className="standard" onClick={onPermanentButtonClick} disableAfterClickFor={1000}>
          {Strings.localized(Env.isContained ? "ClientPagePermanentButton" : "ClientPagePermanentButtonWithRestart")}
        </Button>
      </div>
    </Page>
  );
});

PageComponent.propTypes = {
  data: PropTypes.object,
};

export default PageComponent;
