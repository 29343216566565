import PropTypes from "prop-types";
import { createContext } from "react";
import { QuestionRoot } from "./styled";

export const QuestionContext = createContext();

const Question = ({ className, style, node, children, ...rest }) => {
  const color = node.wantedInheritedText("Color")?.trim();
  return (
    <QuestionRoot {...rest} className={className} style={style}>
      <QuestionContext.Provider value={{ color }}>{children}</QuestionContext.Provider>
    </QuestionRoot>
  );
};

Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  node: PropTypes.object,
  children: PropTypes.node,
};

export default Question;
