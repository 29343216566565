import { unfocused as scaleUnfocused } from "./scale-functions";

// Opacity Functions : The item's opacity can be controlled by providing
// one of the functions below to `itemDynamicOpacity`.

export const off = () => (scrollIndex, focusedIndex) => {
  return { x: 0, y: 0, z: 0, angle: 0 };
};

/** Set the interpolated opacity of the unfocused (non-current) items */
export const unfocused = (opacity) => (scrollIndex, focusedIndex) => {
  return scaleUnfocused(opacity)(scrollIndex, focusedIndex);
};
