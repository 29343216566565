import { Image, Revealer, Scroller, Text } from "ripple";
import styled from "styled-components";
import { RoundYellowButton } from "../../common";
import Box from "../box";
import SpeakerButton from "../speaker-button";
import VideoBlock from "./video-block";

export const HelpPanelRoot = styled(Revealer)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 1vmin;

  /* Above the back button (which needs to always be active
    EXCEPT in case the help panel is open over it.) */
  z-index: 2;

  @media screen and (orientation: landscape) {
    justify-content: flex-end;
    width: 30vw;
  }
  @media screen and (orientation: portrait) {
    padding: 0;
  }
`;

export const PanelBox = styled(Box)`
  height: 100%;
  width: 100%;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  .box-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 2vh 0;
    padding-top: calc(env(safe-area-inset-top) + 2vh);
  }
`;

export const TopButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2vh;
`;

export const LeftSpeakerButton = styled(SpeakerButton)`
  width: 5vh;
  height: 5vh;
  margin-right: auto;
  justify-self: flex-start;
`;

export const CloseButton = styled(RoundYellowButton)`
  width: 5vh;
  height: 5vh;
  margin-left: auto;
  justify-self: flex-end;
`;

export const ContentScroller = styled(Scroller).attrs((props) => ({
  scrollbarInset: "0.8vh",
  fadeRatio: 0.01,
  disableGestures: true,
}))`
  margin-top: 2vh;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;

  .scroller-content {
    padding: 0 2vh;
    padding-bottom: 2vh;
  }
`;

export const HelpText = styled(Text)`
  margin-top: 2vh;
  font-size: 1.8vh;
  font-family: "ComicNeue-Regular";

  b,
  strong {
    font-family: "ComicNeue-Bold";
  }

  i {
    font-family: "ComicNeue-Italic";
  }
`;

export const HelpImage = styled(Image)`
  margin-top: 2vh;
  width: 100%;
`;

export const StyledVideoBlock = styled(VideoBlock)`
  margin-top: 2vh;
  width: 100%;
`;
