import _ from "lodash";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { v4 as uuidv4 } from "uuid";
import Classes from "../../../helpers/classes";
import { MediaSrcPropType } from "../../../logic/prop-types";
import MediaFile from "../../../model/media-file";
import Media from "../media";

function getUrl(src) {
  return src instanceof MediaFile ? src.url : src ?? null;
}

/**
 * This component transitions smoothly between Media instances, taking
 * the media load into account. We can't use a simple Transitioner because
 * the Media component is not ready for display until its `onLoad` callback
 * fires. MediaTransitioner waits for Medias to finish loading before
 * transitioning them in. It does so by using `CSSTransition` directly instead
 * of using a `TransitionGroup` like we usually do.
 */
const MediaTransitioner = memo(
  ({
    className,
    classNames = "fade-out-fade-in",
    timeout = 1000,
    style,
    src,
    instantExit = false,
    mediaProps = {},
  }) => {
    const lastUrlRef = useRef(null);

    const loadsRef = useRef([]); // THE source of truth
    const [displayedLoads, setDisplayedLoadsInternal] = useState(loadsRef.current);
    const updateDisplayedLoads = (operation) => {
      operation(loadsRef.current);
      setDisplayedLoadsInternal(_.cloneDeep(loadsRef.current));
    };

    const enter = useCallback((loadedId) => {
      updateDisplayedLoads((loads) => {
        const justLoaded = _.find(loads, (l) => l.id === loadedId);
        justLoaded.in = true;
        justLoaded.loaded = true;
      });
    }, []);

    const exitOthers = useCallback(
      (loadedId) => {
        const others = _.filter(loadsRef.current, (l) => l.id !== loadedId);

        // First, transition out
        updateDisplayedLoads(() => _.each(others, (o) => (o.in = false)));

        // Then remove the transitioned-out components after timeout elapses
        const otherIds = _.map(others, (l) => l.id);
        setTimeout(() => {
          updateDisplayedLoads((loads) => _.remove(loads, (l) => otherIds.includes(l.id)));
        }, timeout);
      },
      [timeout],
    );

    const createOnLoad = (loadId) => () => {
      const load = _.find(loadsRef.current, (l) => l.id === loadId);
      if (!load) return;

      // This ensures that only the most recently added load
      // (regardless of completion order) triggers the transition logic.
      if (load.cancelled) return;

      enter(load.id);
      exitOthers(load.id);
    };

    // When the external `src` changes, add a new Media and let it load
    useEffect(() => {
      // Avoid running the effect if the actual URL doesn't change.
      // We only use the URL for this check and rather pass the actual `src`
      // to be displayed in the appropriate `Media`.
      const url = getUrl(src);
      if (url === lastUrlRef.current) return;
      lastUrlRef.current = url;

      const newLoadId = uuidv4();

      updateDisplayedLoads((loads) => {
        _.each(loads, (l) => (l.cancelled = true)); // Cancel any ongoing loads so that they are ignored when they complete
        if (url) loads.push({ id: newLoadId, src, loaded: false, cancelled: false, in: false }); // Add our new load
      });

      // Optionally instantly transition out the current media(s) while waiting for the new load to complete
      if (instantExit || !src) exitOthers(newLoadId);
    }, [src, instantExit, exitOthers]);

    return (
      <div className={Classes.build("ripple-media-transitioner", className)} style={style}>
        {_.map(displayedLoads, (load) => (
          <CSSTransition
            key={load.id}
            in={load.in}
            classNames={classNames}
            timeout={999999} // Keep the transition class indefinitely (we remove the element ourselves)
          >
            <div className="media-wrapper">
              <Media
                autoPlay
                {...mediaProps}
                style={{ display: load.loaded ? "block" : "none" }}
                src={load.src}
                fadeInOnPlay={false} // We already transition the video ourselves
                onLoad={createOnLoad(load.id)}
              />
            </div>
          </CSSTransition>
        ))}
      </div>
    );
  },
);

MediaTransitioner.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.string,
  timeout: PropTypes.number,
  style: PropTypes.object,
  src: MediaSrcPropType,
  instantExit: PropTypes.bool, // Set to true to instantly transition out the current media while waiting for a new one to load
  mediaProps: PropTypes.object, // Props to set on the inner Media instance
};

export default MediaTransitioner;
