import styled from "styled-components";
import { standardGap } from "../../../common";
import Box from "../../../components/box";
import Question from "../../../components/question";

export const Type13QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Targets = styled.div`
  display: flex;
  align-self: center;
  flex-direction: row;
  gap: ${standardGap};
  align-items: flex-start;
`;

export const Target = styled(Box).attrs((props) => ({ appearance: "active", dashed: true }))`
  aspect-ratio: 1;
  width: ${(props) => {
    if (props.$count === 1) return props.$isPortrait ? "90vw" : "50vh";
    if (props.$count === 2) return props.$isPortrait ? "45vw" : "30vh";
    if (props.$count === 3) return props.$isPortrait ? "30vw" : "18vh";
  }};
`;

export const ItemsLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: ${standardGap};
  gap: calc(${standardGap} / 2);
`;
