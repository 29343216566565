import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { Grid } from "ripple";
import { useIsPortrait } from "../../hooks/use-is-portrait";
import { Card, CardsGridRoot } from "./styled";

const CardsGrid = ({ className, style, cards, children, ...rest }) => {
  const isPortrait = useIsPortrait();
  const columns = isPortrait ? 2 : cards.length <= 4 ? 2 : 3;
  const isVeryPortrait = useMediaQuery({ query: "(max-aspect-ratio: 1/1.5)" });
  const gridWidth = `${columns === 2 ? (isVeryPortrait ? 100 : 60) : 100}%`;
  return (
    <CardsGridRoot
      {...rest}
      className={className}
      style={style}
      layout={Grid.layout.columns(columns)}
      $width={gridWidth}
      $columns={columns}
    >
      {cards.map((card) => (
        <Card key={card.id} $columns={columns}>
          {children(card)}
        </Card>
      ))}
    </CardsGridRoot>
  );
};

CardsGrid.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  cards: PropTypes.array,
  children: PropTypes.func,
};

export default CardsGrid;
