import Sequencer from "../sequencer";

class DoTask {
  constructor(action) {
    this.action = action;
  }

  perform(release) {
    const result = this.action();
    if (!!result && typeof result.then === "function") {
      // If the action returns a promise (or the action is an async function),
      // only release when the promise has executed.
      result.then(release);
    } else {
      // Otherwise release instantly
      release();
    }
  }
}

Sequencer.prototype.do = function (action) {
  this.push(new DoTask(action));
  return this;
};
