import PropTypes from "prop-types";
import { Image, resource } from "ripple";
import { InfoButtonRoot } from "./styled";

const InfoButton = ({ className, style, ...rest }) => {
  return (
    <InfoButtonRoot {...rest} className={className} style={style}>
      <Image src={resource("images/Button_Info_Symbol.svg")} />
    </InfoButtonRoot>
  );
};

InfoButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default InfoButton;
