import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Audio, resource, useData } from "ripple";
import { useIsPortrait } from "../../../hooks/use-is-portrait";
import {
  ButtonText,
  Card,
  CardImage,
  ChoiceWordButton,
  ClickwordImageQuestionRoot,
  Content,
  GoodImage,
  LandscapeButtonContainer,
  PortraitButtonContainer,
  WordButtonContainer,
  WrongImage,
} from "./styled";

const ClickwordImageQuestion = ({ onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const color = node.parent.wantedText("Color")?.trim();
  const isPortrait = useIsPortrait();
  const [clickedItem, setclickedItem] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [freeze, setFreeze] = useState(false);
  const buttonContainerRef = useRef(null);
  const [offset, setOffset] = useState(0);

  const createOnWordClick = (item) => () => {
    setclickedItem(item);
  };

  useEffect(() => {
    setTimeout(() => {
      setOffset(buttonContainerRef?.current?.clientWidth);
    }, 10);
  }, []);

  useEffect(() => {
    if (!clickedItem) return;
    setFreeze(true);
    setTimeout(() => {
      setShowAnswer(true);
    }, 1000);
  }, [clickedItem]);

  useEffect(() => {
    if (!clickedItem) return;

    const good = clickedItem.settings.Answer === "good";
    good
      ? Audio.discrete("effects").play(resource("audio/ThumbUp01.mp3"))
      : Audio.discrete("effects").play(resource("audio/ThumbDown01.mp3"));
    setTimeout(() => {
      if (good) onComplete();
      else {
        setclickedItem(null);
        setShowAnswer(false);
        setFreeze(false);
      }
    }, 2500);
  }, [clickedItem, onComplete]);

  const renderWordButton = (item, index) => {
    const active = item.id === clickedItem?.id;
    return (
      <ChoiceWordButton item={item} key={item.id} color={color} active={active} disabled={freeze}>
        <ButtonText onClick={createOnWordClick(item)} disabled={freeze}>
          {item.wantedText("Text")}
        </ButtonText>
        {showAnswer && active && (item.settings.Answer === "good" ? <GoodImage /> : <WrongImage />)}
      </ChoiceWordButton>
    );
  };

  return (
    <ClickwordImageQuestionRoot {...rest}>
      <Content $offset={-offset}>
        {!isPortrait && (
          <WordButtonContainer ref={buttonContainerRef}>
            <LandscapeButtonContainer>{node.children.map(renderWordButton)}</LandscapeButtonContainer>
          </WordButtonContainer>
        )}
        <Card color={color}>
          <CardImage src={node.wantedMedia("CardImage", "CardImage")} />
        </Card>
        {isPortrait && <PortraitButtonContainer>{node.children.map(renderWordButton)}</PortraitButtonContainer>}
      </Content>
    </ClickwordImageQuestionRoot>
  );
};

ClickwordImageQuestion.propTypes = {
  onComplete: PropTypes.func,
};

export default ClickwordImageQuestion;
