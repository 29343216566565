import { Image, Text, resource } from "ripple";
import styled from "styled-components";
import { RectangularYellowButton, textColor } from "../../common";

export const Top = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 16vh;
`;

export const SuccessText = styled(Text)`
  font-size: ${(props) => props.$fontSize};
  text-align: center;
  color: ${textColor};
  padding: 4vh 2vh;
`;

export const NextText = styled(Text)`
  font-size: 2.9vh;
`;

export const StarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StarImage = styled(Image)`
  transform: translate3d(0, 2.1vh, 0);
  height: 100%;
  overflow: visible;
`;

export const NextYellowButton = styled(RectangularYellowButton)`
  margin: 2vh;
  margin-top: 0;
  height: 8vh;
  font-size: 4vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .hotspot {
    margin: -3vmin;
  }
`;

export const NextIcon = styled(Image).attrs((props) => ({ src: resource("images/Button_Back_Symbol.svg") }))`
  transform: rotate(180deg);
  height: 5vh;
  margin-top: 0.5vh;
  width: 5vh;
`;
