import _ from "lodash";
import Maths from "../helpers/maths";
import Size from "./size";

function getOffsetX(obj) {
  // eslint-disable-next-line no-use-before-define
  if (obj instanceof Point) return obj.x;
  if (obj instanceof Size) return obj.width;
  if (obj) return obj.x;
  throw new Error(`Unsupported parameter '${obj}'`);
}

function getOffsetY(obj) {
  // eslint-disable-next-line no-use-before-define
  if (obj instanceof Point) return obj.y;
  if (obj instanceof Size) return obj.height;
  if (obj) return obj.y;
  throw new Error(`Unsupported parameter '${obj}'`);
}

class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
  static parse(json) {
    if (json === null || typeof json === "undefined") return null;
    const obj = JSON.parse(json);
    if (obj === null) throw new Error("Point.parse: Could not parse JSON");
    if (_.isUndefined(obj.x)) throw new Error("Point.parse: x is not defined");
    if (_.isUndefined(obj.y)) throw new Error("Point.parse: y is not defined");
    return new Point(obj.x, obj.y);
  }
  static tryParse(json) {
    try {
      return this.parse(json);
    } catch (error) {
      // eslint-disable-next-line no-empty
    }
  }
  get negated() {
    return new Point(-this.x, -this.y);
  }
  get extent() {
    return this.distanceTo(new Point(0, 0));
  }
  plus(obj) {
    const offsetX = getOffsetX(obj);
    const offsetY = getOffsetY(obj);
    return new Point(this.x + offsetX, this.y + offsetY);
  }
  minus(obj) {
    const offsetX = getOffsetX(obj);
    const offsetY = getOffsetY(obj);
    return new Point(this.x - offsetX, this.y - offsetY);
  }
  scaledBy(factor) {
    return new Point(this.x * factor, this.y * factor);
  }
  distanceTo(point) {
    const xDistance = point.x - this.x;
    const yDistance = point.y - this.y;
    return Math.sqrt(xDistance * xDistance + yDistance * yDistance);
  }
  offsetBy(size) {
    return new Point(this.x + size.width, this.y + size.height);
  }
  isAlmostEqualTo(point) {
    if (!point) return false;
    return this.distanceTo(point) < 0.0001;
  }
  convertNormalizedToAbsoluteIn(targetRect) {
    return new Point(targetRect.x + this.x * targetRect.width, targetRect.y + this.y * targetRect.height);
  }
  convertAbsoluteToNormalizedIn(sourceRect) {
    return new Point(
      Maths.ratio(this.x, sourceRect.x, sourceRect.x + sourceRect.width),
      Maths.ratio(this.y, sourceRect.y, sourceRect.y + sourceRect.height),
    );
  }
  transformFromTo(fromCoordinateSystemRect, toCoordinateSystemRect) {
    const normalized = this.convertAbsoluteToNormalizedIn(fromCoordinateSystemRect);
    return normalized.convertNormalizedToAbsoluteIn(toCoordinateSystemRect);
  }
}

export default Point;
