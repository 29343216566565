import _ from "lodash";

export default class AnalyticsServerApi {
  constructor(url, username, password) {
    this.url = url;
    this.username = username;
    this.password = password;
  }

  // NOTE: Dates are converted to UTC when calling `toISOString()`,
  // which is important because we only work with UTC dates on the server!

  async _authenticatedFetch(url, options) {
    const authOptions = {
      headers: { Authorization: `Basic ${btoa(this.username + ":" + this.password)}` },
    };

    return fetch(url, _.merge({}, authOptions, options)).then((response) => {
      if (!response.ok) throw new Error("Analytics server request failed");
      return response;
    });
  }

  _serializeEvents(events) {
    return _.map(events, (e) =>
      _.merge(e, {
        data: JSON.stringify(e.data),
        time: e.time.toISOString(),
      }),
    );
  }

  _deserializeEvents(serializedEvents) {
    return _.map(serializedEvents, (se) =>
      _.merge(se, {
        data: JSON.parse(se.data),
        time: new Date(se.time),
      }),
    );
  }

  async getLatestEventDate(appId, instanceId) {
    return this._authenticatedFetch(`${this.url}/events/latest/${appId}/${instanceId}`)
      .then((response) => response.text())
      .then((text) => new Date(JSON.parse(text)));
  }

  async getApps() {
    return this._authenticatedFetch(`${this.url}/apps/load`)
      .then((response) => response.text())
      .then((text) => JSON.parse(text));
  }

  async load(appId, startDate, endDate, abortController) {
    return this._authenticatedFetch(
      `${this.url}/events/load/${appId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
      { signal: abortController?.signal },
    )
      .then((response) => response.text())
      .then((text) => this._deserializeEvents(JSON.parse(text)));
  }

  async save(events) {
    return this._authenticatedFetch(`${this.url}/events/save`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this._serializeEvents(events)),
    });
  }
}
