import { Paragraphs } from "ripple";
import styled from "styled-components";
import { RoundYellowButton, textColor } from "../../common";
import SpeakerButton from "../speaker-button";

export const Text = styled(Paragraphs)`
  margin: 1.5vmin;
  font-size: 2.5vh;
  color: ${textColor};
  font-family: "ComicNeue-Regular";

  b,
  strong {
    font-family: "ComicNeue-Bold";
  }

  i {
    font-family: "ComicNeue-Italic";
  }
`;

export const TopButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5vh;
`;

export const CustomSpeakerButton = styled(SpeakerButton)`
  min-width: 4.5vh;
  width: 4.5vh;
  height: 4.5vh;

  .hotspot {
    margin: -3vmin;
  }
`;

export const CloseButton = styled(RoundYellowButton)`
  margin: 0;
  min-width: 4.5vh;
  width: 4.5vh;
  height: 4.5vh;

  .hotspot {
    margin: -3vmin;
  }
`;
