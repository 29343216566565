import PropTypes from "prop-types";
import { Image, resource } from "ripple";
import { StarRoot } from "./styled";

const Star = ({ className, style, color, active, ...rest }) => {
  return (
    <StarRoot {...rest} className={className} style={style} $active={active} $color={color}>
      <Image src={resource("images/Button_Star_Symbol.svg")} />
    </StarRoot>
  );
};

Star.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  active: PropTypes.bool,
};

export default Star;
