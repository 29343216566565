import { Slider, Text, Transitioner } from "ripple";
import styled from "styled-components";
import { CustomPage, unnamedPanelColor } from "../../common";
import ThemeButton from "./theme-button";

export const bottomBarContentHeight = "3vmax";

export const Page = styled(CustomPage)``;

export const PageContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ThemesRow = styled.div`
  margin-top: env(safe-area-inset-top);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 12vmax;
  padding: 0 3vmin;
  gap: 3vmin;
`;

export const StyledThemeButton = styled(ThemeButton)`
  .hotspot {
    margin: -2vmin;
  }
`;

export const ThemeTransitioner = styled(Transitioner)`
  margin-left: 3vmin;
  margin-right: 3vmin;
  border-radius: 1.4vmax;
  background-color: ${unnamedPanelColor};
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  overflow: hidden;
  box-shadow: 0 0 4vmin rgba(0, 0, 0, 0.5);

  .transitioner-content {
    & > * {
      width: 100%;
      height: 100%;
    }
  }
`;

export const BottomBar = styled.div`
  margin-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6vmax;
  padding: 0 3vmin;
`;

export const ProgressArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin-left: 3vmin;
`;

export const ProgressBar = styled(Slider)`
  flex: 1;
  .track {
    height: ${bottomBarContentHeight};
    border-radius: calc(${bottomBarContentHeight} / 2);
    background-color: ${unnamedPanelColor};
  }
  .fill {
    background-color: #342b6e;
  }
`;

export const ProgressText = styled(Text)`
  position: absolute;
  right: 1.5vmax;
  font-size: 1.8vmax;
`;
