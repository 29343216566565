import PropTypes from "prop-types";
import { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import Classes from "../../../helpers/classes";
import { Styles } from "../../../ripple";
import { useConstant } from "../../hooks/use-constant";
import { useDebug } from "../../hooks/use-debug";
import { MapContentContext } from "./map-content";

/**
 * Pixel-perfect layout on the map content.
 * Same result as not wrapping the thing in MapElement at all.
 */
const scaleFixed = () => () => 1;

/**
 * Layout in content coordinates, but stay the same visual size after initial display.
 * For example, if we have a 2000x2000 map and a 100x100 item and the map needs to be
 * scaled by 0.5 to fit in the viewport, the item will now be displayed as 50x50. With
 * `scaleToContent`, the item will always measure 50x50 on the screen.
 */
const scaleToContent = () => (contentScale, viewportScale) => 1 / contentScale;

/**
 * Same as `scaleToContent`, but the element will never zoom past the specified factor.
 * For example, with a `maxElementZoom` of 2, the pin will scale with the map until it
 * reaches twice its size, then it will "snap" to that max size while the map continues zooming.
 */
const scaleToContentWithMaxZoom = (maxElementZoom) => (contentScale, viewportScale) =>
  contentScale < maxElementZoom ? 1 : (1 / contentScale) * maxElementZoom;

/**
 * Layout in content coordinates, then scale so that the item has the pixel size it would
 * have had it been inserted in the DOM in screen coordinates (outside of the map).
 * For example, if we have a 2000x2000 map and a 100x100 item and the map needs to be
 * scaled by 0.5 to fit in the viewport, the item will always be displayed at 100x100
 * on the screen but will still be positioned relative to the MapContent's coordinate system.
 */
const scaleToViewport = () => (contentScale, viewportScale) => 1 / viewportScale;

/**
 * Watches MapContent scale changes and transforms itself proportionally based on the specified scaling mode.
 * Anything that changes size based on zoom must be positioned using this component. Use the `position` prop
 * to specify where the thing should be and the
 */
const MapElement = memo(
  ({ children, style, className, anchor = null, position: rawPosition = null, scaling = scaleFixed, ...rest }) => {
    const debug = useDebug();

    const contentRef = useRef(null);
    const lastKnownElementScaleRef = useRef(1);

    const defaultAnchor = useConstant(() => ({ x: 0.5, y: 0.5 }));
    const actualAnchor = anchor ?? defaultAnchor;

    const mapContentContext = useContext(MapContentContext);

    const position = (() => {
      if (!rawPosition) return { top: "50%", left: "50%" };
      if (rawPosition.top && rawPosition.left) return rawPosition;
      if (!isNaN(rawPosition.x) && !isNaN(rawPosition.y))
        return { left: `${rawPosition.x * 100}%`, top: `${rawPosition.y * 100}%` };
      throw new Error(`Unsupported Map.Element position: ${JSON.stringify(rawPosition)}`);
    })();

    const updateTransform = useCallback(() => {
      const scale = lastKnownElementScaleRef.current;
      const translateTransform = `translate3d(${-actualAnchor.x * 100}%, ${-actualAnchor.y * 100}%, 0)`;
      const scaleTransform = `scale3d(${scale}, ${scale}, 1)`;
      contentRef.current.style.transform = `${translateTransform} ${scaleTransform}`;
      contentRef.current.style.transformOrigin = `${actualAnchor.x * 100}% ${actualAnchor.y * 100}%`;
    }, [actualAnchor.x, actualAnchor.y]);

    const [scales, setScales] = useState({
      elementScale: lastKnownElementScaleRef.current,
      contentScale: 1,
      viewportScale: 1,
    });
    const onZoom = useCallback(
      (contentScale, viewportScale) => {
        const elementScale = scaling(contentScale, viewportScale);
        if (typeof children === "function") setScales({ elementScale, contentScale, viewportScale });
        lastKnownElementScaleRef.current = elementScale;
        updateTransform();
      },
      [children, scaling, updateTransform],
    );

    useEffect(() => {
      const subscription = mapContentContext.zoomSubscribe(onZoom);
      return () => mapContentContext.zoomUnsubscribe(subscription);
    }, [mapContentContext, onZoom]);

    // Update the transforms
    useEffect(() => {
      updateTransform();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualAnchor, position]);

    return (
      <div
        {...rest}
        ref={contentRef}
        className={Classes.build("map-element", className)}
        style={Styles.merge(style, {
          top: isFinite(position.top) ? `${position.top}px` : position.top,
          left: isFinite(position.left) ? `${position.left}px` : position.left,
        })}
      >
        {typeof children === "function"
          ? children(scales.elementScale, scales.contentScale, scales.viewportScale)
          : children}
        {debug && (
          <div
            className="map-element-anchor"
            style={{ top: `${actualAnchor.y * 100}%`, left: `${actualAnchor.x * 100}%` }}
          >
            <div className="map-element-anchor-crosshair-horizontal" />
            <div className="map-element-anchor-crosshair-vertical" />
          </div>
        )}
      </div>
    );
  },
);

MapElement.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  style: PropTypes.object,
  scaling: PropTypes.func,
  anchor: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }), // The point around which to scale (Ex: {x: 0.5, y: 1} means to snap the bottom center on the `position` and to scale around that point)
  position: PropTypes.oneOfType([
    PropTypes.shape({
      left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  ]), // The position, in CSS, where the element should be located. Use the anchor to snap and scale the visuals around this point.
};

MapElement.scaleFixed = scaleFixed;
MapElement.scaleToContent = scaleToContent;
MapElement.scaleToContentWithMaxZoom = scaleToContentWithMaxZoom;
MapElement.scaleToViewport = scaleToViewport;

export default MapElement;
