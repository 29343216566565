import { useLoader } from "@react-three/fiber";
import PropTypes from "prop-types";
import * as THREE from "three";

const ImageMaterial = ({ url }) => {
  const texture = useLoader(THREE.TextureLoader, url);
  return <meshBasicMaterial attach="material" map={texture} side={THREE.BackSide} />;
};

ImageMaterial.propTypes = {
  url: PropTypes.string,
};

export default ImageMaterial;
