import { differenceInMilliseconds } from "date-fns";
import _ from "lodash";
import AnalyticsHelper from "../../analytics-helper";

function calculate(preprocessed) {
  if (preprocessed.sessions.length === 0) return 0;

  const sessionWithRelevantNavigations = _.reduce(
    preprocessed.sessions,
    (acc, session) => {
      // Remove navigations to root pages
      const cleanSession = _.filter(
        session,
        (e) => e.name !== "navigation" || (e.name === "navigation" && !e.data.root),
      );

      // Only consider sessions that contain navigations after cleanup
      const remainingNavigations = _.filter(cleanSession, (e) => e.name === "navigation");
      if (remainingNavigations.length >= 1) acc.push(cleanSession);

      return acc;
    },
    [],
  );

  if (sessionWithRelevantNavigations.length === 0) return 0;

  const sessionDurations = _.map(sessionWithRelevantNavigations, (session) => {
    const firstNavigation = _.find(session, (e) => e.name === "navigation");
    const sessionStart = firstNavigation.time;
    const sessionEnd = _.last(session).time;
    return differenceInMilliseconds(sessionEnd, sessionStart);
  });

  return _.mean(sessionDurations);
}

export default function (preprocessed) {
  this.name = "AverageSessionDuration";
  this.format = AnalyticsHelper.formatAsDuration;
  this.value = calculate(preprocessed);
}
