import PropTypes from "prop-types";
import { useCallback } from "react";
import { Audio, Droppable, resource } from "ripple";
import ImageLayer from "../image-layer";
import Layers from "../layers";
import SpeakerButtonLayer from "../speaker-button-layer";
import TagLayer from "../tag-layer";
import { ImageAudioDroppableRoot } from "./styled";

const ImageAudioDroppable = ({ className, style, node, disabled, ...rest }) => {
  const onTouch = useCallback(() => {
    Audio.discrete("effects").play(resource("audio/ClicDrag.mp3"));
  }, []);

  const onDragEnd = useCallback(() => {
    Audio.discrete("effects").play(resource("audio/DragRelease.mp3"));
  }, []);

  return (
    <ImageAudioDroppableRoot {...rest} className={className} style={style} appearance="active" $disabled={disabled}>
      <Layers>
        <ImageLayer src={node.wantedMedia("Image", "Image")} />
        <TagLayer icon="drag" />
        <Droppable id={node} onTouch={onTouch} onDragEnd={onDragEnd} />
        <SpeakerButtonLayer src={node.optionalMedia("Audio", "Audio")} />
      </Layers>
    </ImageAudioDroppableRoot>
  );
};

ImageAudioDroppable.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  node: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ImageAudioDroppable;
