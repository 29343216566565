import AnalyticsServerApi from "../analytics-server-api";

export default class ServerSource {
  constructor(url, username, password) {
    this.api = new AnalyticsServerApi(url, username, password);
  }

  getRange(appId, startDate, endDate, abortController) {
    return this.api.load(appId, startDate, endDate, abortController);
  }
}
