/* global StatusBar */
import { ConnectedRouter } from "connected-react-router";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import IpcClient from "ripple.ipc-client";
import { empty, Observable, Subject } from "rxjs";
import { distinctUntilChanged, pairwise } from "rxjs/operators";
import WebFont from "webfontloader";
import API from "../helpers/api";
import Audio from "../helpers/audio";
import Config, { loadConfig, mergeValuesIntoConfig } from "../helpers/config";
import Env from "../helpers/env";
import LocationHelper from "../helpers/internal/location-helper";
import Keyboard from "../helpers/keyboard";
import Localization from "../helpers/localization";
import Log from "../helpers/log";
import Mail from "../helpers/mail";
import MIDI from "../helpers/midi";
import Orientation from "../helpers/orientation";
import Phidgets from "../helpers/phidgets";
import resource from "../helpers/resource";
import Scanner from "../helpers/scanner";
import Signal from "../helpers/signal";
import Strings, { loadStrings } from "../helpers/strings";
import AnalyticsUploader from "../logic/analytics/analytics-uploader";
import Fetch from "../logic/fetch/fetch";
import Root from "../react/root";
import RemoteActions from "../redux/actions/local/remote-actions";
import TimeoutActions from "../redux/actions/local/timeout-actions";
import FingerprintActions from "../redux/actions/master/fingerprint-actions";
import MasterActions from "../redux/actions/master/master-actions";

const showBootstrapError = (error) => {
  Log.error("Failed to start app!");
  Log.error(error.stack);

  Env.isRCC
    ? navigator.notification.alert(
        `Failed to start app: ${error.message}`,
        () => navigator.app.exitApp(),
        "Error",
        "OK",
      )
    : alert(`Failed to start app: ${error.message}`);
};

const tryParseConfigOverrides = () => {
  try {
    const string = LocationHelper.getValue("config");
    if (!string) return; // No overrides specified
    return JSON.parse(string);
  } catch (error) {
    showBootstrapError("Invalid config overrides format! Please provide a valid JSON object.");
  }
};

// Technically all Android phones have the Android System WebView installed
// but when it's disabled it will default to the version shipped with the phone,
// which might be too old for our app.
// Reference: https://github.com/NoNameProvided/cordova-plugin-webview-checker
const checkWebViewVersion = (proceed) => {
  // eslint-disable-next-line no-undef
  plugins.webViewChecker
    .getCurrentWebViewPackageInfo()
    .then((packageInfo) => {
      if (packageInfo.versionCode >= 349710065 /* WebView Version 69 */) {
        proceed();
        return;
      }

      navigator.notification.confirm(
        Strings.localized("AndroidWebViewCheckerMessage", Localization.getDefaultLanguage()),
        (result) => {
          switch (result) {
            case 1:
              // eslint-disable-next-line no-undef
              plugins.webViewChecker
                .openGooglePlayPage("com.google.android.webview")
                .then(() => {
                  navigator.app.exitApp();
                })
                .catch((error) => {
                  showBootstrapError(error);
                });
              break;
            default:
              navigator.app.exitApp();
              break;
          }
        },
        "Attention",
        [
          Strings.localized("AndroidWebViewCheckerUpdate", Localization.getDefaultLanguage()),
          Strings.localized("AndroidWebViewCheckerCancel", Localization.getDefaultLanguage()),
        ],
      );
    })
    .catch((error) => {
      showBootstrapError(error);
    });
};

const initializePushNotificationService = () => {
  if (!window.plugins.OneSignal) return;

  if (__DEV__) window.plugins.OneSignal.Debug.setLogLevel(6);
  window.plugins.OneSignal.Debug.setAlertLevel(0); // Show nothing as alerts

  const onNotificationClick = (jsonData) => {
    Log.info("OneSignal: Notification opened!", JSON.stringify(jsonData));
  };

  window.plugins.OneSignal.initialize(Config.push.appId);
  window.plugins.OneSignal.Notifications.addEventListener("click", onNotificationClick);

  window.plugins.OneSignal.Notifications.requestPermission(true).then((accepted) => {
    Log.info(`OneSignal: ${accepted ? "User accepted notifications" : "User rejected notifications"}`);
  });
};

const initializeApp = async (options) => {
  Log.info("Starting up Ripple..."); // The first call to `Log.xyz()` must occur after the config is loaded

  // Note: We require most Ripple code in here because this allows use to assume that `Config`
  // is fully loaded (including value overrides) within the various source files, simplifying setup a great deal.

  const buildIdentifier = Config.buildIdentifier;
  Log.info(`App build is ${buildIdentifier ? buildIdentifier : "undefined (not built by CI)"}`);

  // Apply dynamic config overrides.
  // It is possible to override any config values by providing JSON through the `config` query string key:
  // http://myserver.com/somepage?config={"timeout":0}
  // The navigation logic automatically preserves the config overrides on URL changes.
  const overrides = tryParseConfigOverrides();
  if (overrides) mergeValuesIntoConfig(overrides);

  // Log the final merged config values for easy diagnosis from the console
  /* eslint-disable no-console */
  if (console) console.log("Final config: ", Config);
  /* eslint-enable no-console */

  // Load web fonts synchronously using webfontloader, which ensures that
  // fonts are preloaded when performing the occasional UI measurement in components.
  // https://github.com/typekit/webfontloader

  // Set RCC environment information as a class on #app for simple conditional styles
  if (Env.isRCC) document.querySelector("#app").classList.add("rcc");

  // Core fonts
  WebFont.load({
    custom: {
      families: ["open_sansregular"],
      urls: [resource("fonts/opensans/stylesheet.css"), resource("fonts/fontawesome-free-6.5.1-web/css/all.min.css")],
    },
  });

  // Custom fonts
  const fonts = options.fonts;
  if (!_.isUndefined(fonts) && !_.isNull(fonts) && !_.isEmpty(fonts)) WebFont.load(fonts);

  // Media Client
  const MediaClient = require("mediaclient.js");
  require("../extensions/node-extensions"); // Add useful capabilities to all Node objects

  // Analytics

  const AnalyticsObservables = require("../observables/analytics-observables").default;
  const analyticsObservables = AnalyticsObservables.initialize();

  const AnalyticsDb = require("../logic/analytics/analytics-db").default;
  AnalyticsDb.initialize((db) => {
    analyticsObservables.eventStream$.subscribe(db.put); // Save all events to the local analytics DB
    analyticsObservables.start(); // See comments in AnalyticsObservables
  });

  const Analytics = require("../helpers/analytics").default;
  Analytics._setup(analyticsObservables.emit);
  Analytics.track("startup", { platform: Env.platform, fingerprint: Analytics._fingerprint });

  if (options.stats) Analytics._customStats = options.stats;

  const host = window.location.host;
  const hostShouldBeExcluded =
    _.filter(Config.analytics.upload.excludedHosts, (exclusion) => host.includes(exclusion)).length > 0;

  if (Config.dev.forceAnalyticsUpload || (Config.analytics.upload.enabled && !__DEV__ && !hostShouldBeExcluded)) {
    const uploader = new AnalyticsUploader(
      Config.analytics.appId,
      Config.analytics.server.url,
      Config.analytics.server.writeUsername,
      Config.analytics.server.writePassword,
    );

    setInterval(
      uploader.sync.bind(uploader),
      (() => {
        if (Env.isREC) return Config.analytics.upload.interval.kiosk;
        return Config.analytics.upload.interval.default;
      })(),
    );

    // Sync analytics at startup
    // - To instantly track unique users and platform distribution on launch
    // - In case there were unsent events from a previous run
    uploader.sync();

    // Sync events when the system puts the app into the background
    if (Env.isRCC) document.addEventListener(Env.isIos ? "resign" : "pause", () => uploader.sync());
  }

  // Local Store
  const localReducer = options.localReducer ? options.localReducer() : () => null; // Represents the app-specific local state
  const localEpic = options.localEpic ? options.localEpic() : () => empty(); // The root epic that reacts to local actions
  const LocalStore = require("../redux/local-store").default;
  const { localStore, history } = LocalStore.createStore(localReducer, localEpic);

  // Master Store (dormant on IPC slaves)
  const masterReducer = options.masterReducer ? options.masterReducer() : () => null; // Represents the state shared by all IPC clients
  const masterEpic = options.masterEpic ? options.masterEpic() : () => empty(); // The root epic that reacts to IPC actions
  const MasterStore = require("../redux/master-store").default;
  const masterStore = MasterStore.createStore(masterReducer, masterEpic, localStore);

  const masterStoreState$ = Observable.create((observer) => {
    masterStore.subscribe(() => observer.next(masterStore.getState()));
  });
  masterStoreState$
    .pipe(
      distinctUntilChanged((s1, s2) => s1.fingerprint === s2.fingerprint),
      pairwise(), // Consider the previous state
    )
    .subscribe((states) => IpcClient.updateRemoteState(states[1].shared));

  // Provide the store dispatch function to interested parties
  // (so that helpers can send Redux actions on behalf of components)
  const Localization = require("../helpers/localization").default;
  Localization._store = localStore;
  const Toast = require("../helpers/toast").default;
  Toast._store = localStore;

  // Routes
  const getHome = () => options.home?.() ?? Config.home;
  const checkIfRootPage = (fullUrl) => fullUrl === "/" || fullUrl === getHome();

  // Configure the page mapping (page mappings are optional)
  const NavConfigurator = require("./nav-configurator").default;
  NavConfigurator.configure(options.nav);

  // Navigator
  const Navigator = require("../helpers/navigator").default;
  Navigator._store = localStore;
  Navigator._checkIfRootPage = checkIfRootPage;
  Navigator._getHome = getHome;
  const navigateToHome = () => {
    Navigator.navigate({ path: getHome() });

    // The status bar is hidden during startup and this is
    // the place where we show it if the config tells us to.
    if (Env.isRCC && !Config.initialStatusBarHidden) {
      setTimeout(() => StatusBar.show(), 250);
    }
  };
  Env._subscribeToReceiveFromContainer("navigate", (payload) => Navigator.navigate(payload));
  Env._subscribeToReceiveFromContainer("go-back", Navigator.goBack);
  Env._subscribeToReceiveFromContainer("go-forward", Navigator.goForward);
  Env.addContainerNavigationItem("Go to Home", { path: "/" });
  Env.addContainerNavigationItem("Go to Config", { path: "/config" });
  Env.addContainerNavigationItem("Go to Analytics", { path: "/analytics" });
  Env.addContainerNavigationItem("Go to Demos", { path: "/demos" });
  if (Env.isAndroid) {
    document.addEventListener("backbutton", (event) => {
      event.preventDefault();

      // Add common behavior on Android app, when we press on device's back button.
      // When we are on home page and click on device button, put app in background.
      if (checkIfRootPage(localStore.getState().router.location.pathname)) window.plugins.appMinimize.minimize();
      else Navigator.goBack();
    });
  }

  // Interactions
  const interactionObservables = require("../observables/interaction-observables").default.initialize();
  const Interaction = require("../helpers/interaction").default;
  Interaction._store = localStore;
  Interaction._interactions$ = interactionObservables.interactions$;
  window.addEventListener("message", (m) => {
    if (m.data === "interaction") Interaction.emit(m.data);
  }); // Emit an interaction on global interaction message (`postMessage()` call)
  _.each([...("PointerEvent" in window ? ["pointerdown"] : ["mousedown", "touchstart"]), "keydown", "drop"], (name) =>
    document.addEventListener(name, (e) => Interaction.emit(e.type)),
  ); // Emit on direct interaction

  // Store observations
  let autoReloadIntervalToken;
  const storeObservables = require("../observables/store-observables").default.initialize(localStore);
  storeObservables.dataUpdateWhileOnStartupPage$.subscribe(() => setTimeout(() => navigateToHome(), 1000));
  storeObservables.navigationToStartupPageAfterAppStart$.subscribe(() => {
    // If navigating synchronously, the navigation does not work,
    // probably because the navigation occurs in the same "frame" as
    // the navigation to the startup page that we're trying to avoid.
    setTimeout(() => navigateToHome());
  });
  storeObservables.dataAutoReloadFlagChanged$.subscribe((flag) => {
    if (flag) {
      // eslint-disable-next-line no-use-before-define
      autoReloadIntervalToken = setInterval(() => loadDataBasedOnFetchDownloadMode(), Config.fetch.autoReloadInterval);
    } else {
      clearInterval(autoReloadIntervalToken);
    }
  });

  // Timeout
  const timeoutObservables = require("../observables/timeout-observables").default.initialize(
    interactionObservables.interactions$,
  );
  timeoutObservables.grace$.subscribe((remaining) => localStore.dispatch(TimeoutActions.updateGrace(remaining)));
  timeoutObservables.timeout$.subscribe((info) => {
    const location = localStore.getState().router.location;
    const fullPath = LocationHelper.urlFromLocation(location);
    const shouldTimeout = options.timeout?.shouldTimeout || (() => true);

    // Do not timeout if the timeout override says we shoudn't.
    // We run shouldTimeout before the "root page" check
    // so that it's always called regardless of the page we're on.
    if (!shouldTimeout(localStore)) return;

    // Always switch back to the default language regardless of the page
    // we're on *except* if we shouldn't timeout at all (above) or if
    // reset on timeout is disabled. NOTE: Adding a delay here might
    // cause issues when the rootBackground of the outgoing page changes
    // on language change (clashes with home page background change) so
    // a better solution might be necessary to avoid timeout language
    // flash!
    if (Config.language.resetOnTimeout) {
      // Assuming a page transition duration of 500ms, at about 250ms no page should be
      // visible when using the default "fade-out-fade-in" transition, which is better
      // than switching the language instantly.
      setTimeout(() => Localization.switchToDefaultLanguage(), 250);
    }

    // What happens by default when timing out
    const defaultActions = () => {
      // Do not do anything if we're on the startup page or home page
      if (checkIfRootPage(fullPath)) return;
      navigateToHome();
      Log.info(`Timed out`);
      Analytics.track("timeout", { forced: info.forced }); // Important: Before navigation, for analytics event sequence coherence!
    };

    const overrideActions = options.timeout?.actions;
    if (overrideActions) {
      // Provide the default actions so the override can optionally run them
      overrideActions(defaultActions);
    } else {
      defaultActions();
    }

    // Start a new session last, after other actions have had the
    // opportunity to add final analytics events in the current session
    Analytics._newSession();
  });
  const Timeout = require("../helpers/timeout").default;
  Timeout._observables = timeoutObservables;

  // Setup the API client
  const apiClient = new MediaClient.ApiClient({
    dataSource: Config.fetch.dataSource,
    mediaSource: Config.fetch.mediaSource,
    server: {
      url: Config.server.url,
      apiVersion: Config.server.apiVersion,
      username: Config.server.username,
      password: Config.server.password,
    },
    cloud: {
      url: Config.cloud.url,
      namespace: Config.cloud.namespace,
    },
  });

  // Update client connection info in the store
  const DataActions = require("../redux/actions/local/data-actions").default;

  // Log media client requests
  const mediaClientObservables = require("../observables/mediaclient-observables").default.initialize(apiClient);
  mediaClientObservables.request$.subscribe((m) => Log.info("MediaClient: " + m));

  // Fetch

  Fetch._apiClient = apiClient;
  Fetch._dispatch = localStore.dispatch;
  Fetch._excludeNodeFromInitialFetch = options.excludeNodeFromInitialFetch ?? (() => false);
  Fetch._excludeMediaFromInitialFetch = options.excludeMediaFromInitialFetch ?? (() => false);
  Fetch._excludeFormatFromInitialFetch = options.excludeFormatFromInitialFetch ?? (() => false);

  const throwIfServerUnreachable = (displayError = false) => {
    const pingTimeout = 30000;
    return apiClient.ping(pingTimeout).catch((error) => {
      if (displayError) localStore.dispatch(DataActions.serverUnreachable(error));
      throw new Error("Server is unreachable");
    });
  };

  const loadData = (performDownload, done = () => {}) => {
    const dataSource = Config.fetch.dataSource;
    const clientId = Config.server.clientId;
    if ((dataSource === "server" || dataSource === "cloud") && !performDownload) {
      throwIfServerUnreachable(/* Display Error */ true).then(() => Fetch.loadServer(clientId));
    } else if ((dataSource === "server" || dataSource === "cloud") && performDownload) {
      // If this throws, the fetch is skipped and we go straight to local data load
      throwIfServerUnreachable()
        // However, if *this* throws, it won't be caught because we're catching internally
        .then(() => Fetch.downloadFromServerAndLoadLocal(clientId, done))
        // We only get here if the server is unreachable, thus we can consider that the "real" cause for
        // local data load failure is a failure to fetch the data from the server a first time.
        .catch(() => Fetch.loadLocal(DataActions.serverUnreachable()));
    } else if (dataSource === "local") {
      Fetch.loadLocal();
    } else if (dataSource === "none") {
      Fetch.loadEmpty();
    } else {
      // Assumes that the dataSource is the url to a static JSON file
      Fetch.loadStatic(dataSource);
    }
  };

  let fetchDownloadMode = Config.fetch.download;

  if (!Env.isRCC && fetchDownloadMode === "ask") {
    fetchDownloadMode = "always";
    Log.info("Fetch: Falling back to 'always' download mode because 'ask' can't be used outside of RCC");
  }

  if (fetchDownloadMode === "always" && !Env.isContained) {
    fetchDownloadMode = "never";
    Log.info("Fetch: Falling back to 'never' download mode because 'always' can't be used outside of a container");
  }

  if (
    !Config.dev.forceFetch &&
    (Env.isREC || Env.isRCC) &&
    (fetchDownloadMode === "always" || fetchDownloadMode === "ask") &&
    __DEV__
  ) {
    // The "fetch" mode can't be used in REC dev mode because `webpack-dev-server` hosts everything in memory,
    // which causes the fetched data not to be picked up because it's created after the server is launched.
    // Plus it would fill our dev machines with data pretty fast, which is bad!

    // The "fetch" mode also can't be used in RCC in dev mode, but for another reason: when the app is
    // packaged, both the app itself and the special paths to access native directories (which are generated in
    // `data-actions.js makeLocalBuildMediaUrl`) have the same origin, which works perfectly fine.
    // However, when the app is loaded from a dev server, the app's origin is "http://dev_machine_ip:port" and the
    // local paths are not on the same origin, which awakens CORS the Destroyer of Worlds and breaks everything!

    fetchDownloadMode = "never";
    Log.info("Fetch: Falling back to 'never' download mode because 'always' can't be used in dev mode");
  }

  const loadDataBasedOnFetchDownloadMode = () => {
    if (fetchDownloadMode === "always") {
      loadData(true);
    } else if (fetchDownloadMode === "never") {
      loadData(false);
    } else if (fetchDownloadMode === "ask") {
      const key = "fetch-download-performed-at-least-once";
      const downloadedAtLeastOnce = JSON.parse(localStorage.getItem(key)) || false;

      if (downloadedAtLeastOnce) {
        // Workaround: If we already downloaded once, always download from now on, assuming that subsequent downloads will be quick.
        // This is to prevent the user from choosing "no" which puts the app in "server" mode and always makes requests to
        // the server, ignoring local data. This is necessary for now because we don't have a way to download, cache and use
        // files locally on-the-fly (on request) yet, like we do in Muzeus.
        loadData(true);
      } else {
        navigator.notification.confirm(
          Strings.localized("FetchDownloadAskMessage"),
          (result) => {
            switch (result) {
              case 1:
                loadData(true, () => localStorage.setItem(key, JSON.stringify(true)));
                break;
              default:
                loadData(false);
                break;
            }
          },
          Strings.localized("FetchDownloadAskTitle"),
          [Strings.localized("FetchDownloadAskYes"), Strings.localized("FetchDownloadAskNo")],
        );
      }
    }
  };

  loadDataBasedOnFetchDownloadMode();

  // Global keyboard shortcuts
  const FlagsActions = require("../redux/actions/local/flags-actions").default;
  Keyboard._store = localStore;

  if (!Config.disableAdminShortcuts) {
    Keyboard.shortcutSubscribe(
      "Navigate to Config Page",
      "ctrl|cmd+shift+,",
      () => Navigator.navigate({ path: "/config" }),
      {
        type: "builtin",
      },
    );

    Keyboard.shortcutSubscribe(
      "Navigate to Client Page",
      "ctrl|cmd+shift+y",
      () => Navigator.navigate({ path: "/client" }),
      {
        type: "builtin",
      },
    );

    Keyboard.shortcutSubscribe(
      "Undo Remote State Update",
      "ctrl|cmd+shift+z",
      () => IpcClient.undoLastRemoteStateUpdate(),
      {
        type: "builtin",
      },
    );
  }

  Keyboard.shortcutSubscribe(
    "Toggle Debug Mode",
    "ctrl|cmd+shift+d",
    () => localStore.dispatch(FlagsActions.toggleDebugMode()),
    { type: "hidden" },
  );

  Keyboard.shortcutSubscribe(
    "Toggle Bounds Mode",
    "ctrl|cmd+shift+b",
    () => localStore.dispatch(FlagsActions.toggleBoundsMode()),
    { type: "builtin" },
  );

  Keyboard.shortcutSubscribe("Switch to Next Language", "ctrl|cmd+shift+l", () => Localization.switchToNextLanguage(), {
    type: "builtin",
  });

  Keyboard.shortcutSubscribe("Force Timeout", ["ctrl|cmd+shift+backspace"], () => timeoutObservables.forceTimeout(), {
    type: "builtin",
  });

  Keyboard.shortcutSubscribe(
    "Reload App",
    ["ctrl|cmd+r"],
    // eslint-disable-next-line no-self-assign
    () => window.location.reload(),
    {
      type: "builtin",
    },
  );

  Keyboard.shortcutSubscribe("Go Back", ["ctrl|cmd+left"], Navigator.goBack);

  Keyboard.shortcutSubscribe("Go Forward", ["ctrl|cmd+right"], Navigator.goForward);

  Keyboard.shortcutSubscribe(
    "Reload Data",
    "ctrl|cmd+shift+enter",
    () => {
      Toast.info("Reloading Data...");
      loadDataBasedOnFetchDownloadMode();
    },
    {
      type: "builtin",
    },
  );

  Keyboard.shortcutSubscribe(
    "Toggle Data Auto Reload",
    "ctrl|cmd+shift+alt+enter",
    () => localStore.dispatch(FlagsActions.toggleDataAutoReload()),
    { type: "builtin" },
  );

  Keyboard.shortcutSubscribe(
    "Toggle Path bar",
    "ctrl|cmd+shift+p",
    () => localStore.dispatch(FlagsActions.togglePathBar()),
    {
      type: "builtin",
    },
  );

  // Enable or disable socket.io logging
  if (Config.dev.debugSocketIO) {
    localStorage["debug"] = "*";
  } else {
    delete localStorage["debug"];
  }

  // Setup an Observable for IPC actions
  const ipcActions$ = new Subject();

  // Connect to IPC server if applicable
  if (Config.ipc.enabled) {
    const url = Config.ipc.url;
    const isMaster = Config.ipc.role === "master";

    IpcClient.connect(url, Config.id, {
      onConnect: () => {
        if (!isMaster) return;

        // Will automatically be rejected by the server if the initial
        // state has already been received once.
        Log.info("Master: Sending initial remote state (will be ignored by the server if already initialized)");
        IpcClient.sendInitialRemoteState(masterStore.getState().shared);
      },
      onClients: (clients) => {
        // This handles updating `remote.clients` in local state
        // with the new clients list as clients connect and disconnect.
        localStore.dispatch(RemoteActions.updateClients(clients));
      },
      onRestoreSharedState: (state) => {
        if (!isMaster) return;

        Log.info("Master: Restoring shared state");
        masterStore.dispatch(MasterActions.restoreSharedState(state));
      },
      onAction: (data) => {
        const dispatchAction = (action) => {
          // Allow the master store to update the shared state (master only).
          if (isMaster) masterStore.dispatch(action);

          // Allow the local store to respond to IPC actions
          localStore.dispatch(action);

          // Let interested parties observe incoming IPC actions
          ipcActions$.next(action);
        };

        // Dispatching an action will trigger a remote state update to be
        // broadcast to all IPC clients. We support dispatching single
        // actions or multiple actions at once.
        if (Array.isArray(data.payload)) {
          _.each(data.payload, dispatchAction);
        } else {
          dispatchAction(data.payload);
        }

        // The fingerprint update is what triggers the actual IPC shared state update.
        // Waiting for the fingerprint to change has the effect of batching all
        // previous dispatches into a single remote state update.
        if (isMaster) masterStore.dispatch(FingerprintActions.updateSharedFingerprint());
      },
      onUpdateRemoteState: (state) => {
        // This handles updating `remote.state` with new shared state
        // provided by the server. Here we send the action to the local
        // store because we want to update our local copy of the remote
        // state, not the master's shared state store (if we're the master).
        localStore.dispatch(RemoteActions.updateState(state));
      },
    });
  }

  // Connect to the Phidgets Network Server
  if (Env.isREC) Phidgets.initialize(Config.phidgets.servers);

  // Initialize MIDI
  MIDI.initialize();

  // Initialize Audio
  Audio.initialize();

  // Initialize Scanner
  Scanner.initialize();

  // Start the HTTP API
  const httpApiPortNumber = Config.api.port;
  if (httpApiPortNumber) API._start(httpApiPortNumber);

  // Connect to the signaling server
  if (Config.signaling.enabled) Signal._connect(Config.signaling.server.url);

  // Start the email processing queue
  Mail._startProcessing();

  // Keep the device awake in DEV mode on mobile devices
  if (Env.isRCC && __DEV__) window.plugins.insomnia.keepAwake();

  if (Env.isRCC && Config.push.enabled) initializePushNotificationService();

  // Define context
  const rippleContext = {
    store: localStore,
    apiClient,
    observables: {
      timeout: timeoutObservables,
      interaction: interactionObservables,
      store: storeObservables,
      ipc: { actions$: ipcActions$ },
    },
  };

  // Setup the app root
  const RippleContext = require("../react/core/ripple-context").default;
  const app = (
    <ReduxProvider store={localStore}>
      <RippleContext.Provider value={rippleContext}>
        <ConnectedRouter history={history}>
          <Root appRoot={options.appRoot} />
        </ConnectedRouter>
      </RippleContext.Provider>
    </ReduxProvider>
  );

  // Render the React DOM
  const root = createRoot(options.element);
  root.render(app);

  // Call back when the Ripple boostrap is done
  options.onBootstrap?.(rippleContext);

  // Call back when the data has been fetched and the app is fully ready
  storeObservables.dataUpdateWhileOnStartupPage$.subscribe(() => options.onStart?.(rippleContext));
};

const App = {
  bootstrap: async (options) => {
    Log.initialize();

    await loadConfig();
    await loadStrings();

    try {
      if (Env.isRCC) {
        // On iOS, we use a cordova-httpd built-in server to host data and medias
        // because video requests to the custom Cordova protocol (`ripple://`, in our case) result
        // in non-playing videos, choppy playback and other performance issues. The server must be
        // stopped and restarted on app pause and resume, respectively, otherwise the server stops
        // responding as soon as the app's webview is unloaded from memory (after being backgrounded
        // for a few minutes, potentially after opening a couple other apps).

        const stopServer = () => {
          if (!Env.isRCC) return;
          cordova?.plugins.CorHttpd.stopServer(
            () => {
              Log.info(`cordova-httpd: Server stopped`);
            },
            (error) => {
              Log.error(`cordova-httpd: Failed to stop server (${error})`);
            },
          );
        };

        const startServer = () => {
          if (!Env.isRCC) return;
          cordova?.plugins.CorHttpd.startServer(
            {
              www_root: "__documents__",
              port: 8973,
              localhost_only: true,
            },
            (url) => Log.info(`cordova-httpd: Server started (${url})`),
            (error) => Log.error(`cordova-httpd: Failed to start server (${error})`),
          );
        };

        document.addEventListener("pause", () => {
          Log.info("Cordova: Native app is being paused");
          stopServer();
        });

        document.addEventListener("resume", () => {
          Log.info("Cordova: Native app is being resumed");
          startServer();
        });

        // Wait until Cordova is ready (plugins ready to use) before initializing the app,
        // which simplifies usage of Cordova plugins in the codebase.
        document.addEventListener("deviceready", () => {
          if (Config.initialOrientationLock !== null) Orientation.lock(Config.initialOrientationLock);

          if (Env.isAndroid) {
            checkWebViewVersion(() => initializeApp(options));
          } else {
            initializeApp(options);
          }

          startServer();
        });
      } else {
        // Initialize straight away
        initializeApp(options);
      }
    } catch (error) {
      showBootstrapError(error);
    }
  },
};

export default App;
