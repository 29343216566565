import { memo } from "react";
import PropTypes from "prop-types";
import Classes from "../../../helpers/classes";
import Paragraphs from "../paragraphs";
import { TextSrcPropType } from "../../../logic/prop-types";

/**
 * A component optimized to display simple HTML markup as a single block of text. While not technically limited to a
 * single multi-line string, you should use `Paragraphs` instead as soon as the text to display is composed of paragraphs
 * (for example: a description area with one or more paragraphs, lists, etc.)
 */
const Text = memo(({ className, children, ...rest }) => {
  const actualClassName = Classes.build("ripple-text", className);
  return (
    <Paragraphs {...rest} className={actualClassName}>
      {children}
    </Paragraphs>
  );
});

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([TextSrcPropType, PropTypes.node]),
};

export default Text;
