import { Button, staggerStep } from "ripple";
import styled from "styled-components";
import { standardTopBarHeight } from "../../common";

export const GamePillsRoot = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Pill = staggerStep({ transition: "scale-up-fade" })(styled(Button)`
  margin: 0 2vmin;
  height: ${standardTopBarHeight};
  width: ${standardTopBarHeight};
  border: ${(props) => (props.active ? "solid 0.4vmin white" : "none")};
  border-radius: 50%;
  background-color: ${(props) => props.color};
  transition: all 150ms ease-in-out;

  .hotspot {
    margin: -2vmin;
  }
`);
