import PropTypes from "prop-types";
import AudioTextRow from "../audio-text-row";
import ButtonLayer from "../button-layer";
import FeedbackLayer from "../feedback-layer";
import Layers from "../layers";
import { AudioTextButtonRoot, StyledBox, StyledLayers } from "./styled";

const AudioTextButton = ({ className, style, node, onClick, feedback, ...rest }) => {
  const audio = node.optionalMedia("Audio", "Audio");
  const text = node.wantedText("Title");
  return (
    <AudioTextButtonRoot {...rest} className={className} style={style}>
      <StyledBox>
        <Layers>
          <ButtonLayer onClick={onClick} />
        </Layers>
        <AudioTextRow audio={audio} text={text} />
        <StyledLayers>
          <FeedbackLayer feedback={feedback} />
        </StyledLayers>
      </StyledBox>
    </AudioTextButtonRoot>
  );
};

AudioTextButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  node: PropTypes.object,
  onClick: PropTypes.func,
  feedback: PropTypes.any,
};

export default AudioTextButton;
