import Log from "./log";

export default class UserMedia {
  static async getVideoDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter((device) => device.kind === "videoinput");
    return videoDevices;
  }

  static async logVideoDevices() {
    const videoDevices = await UserMedia.getVideoDevices();
    videoDevices.forEach((vd) => {
      Log.info(`Video Device: ${vd.label}, Device ID: ${vd.deviceId}`);
    });
  }

  static async getVideoStream(constraints) {
    return await navigator.mediaDevices.getUserMedia(constraints);
  }
}
