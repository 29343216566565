import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { MediaSrcPropType, useConstant, useUnmount } from "ripple";
import { v4 as uuidv4 } from "uuid";
import { useGlobalAudioPlayer } from "../../hooks/use-global-audio-player";
import { AudioImage, SpeakerButtonRoot } from "./styled";

const SpeakerButton = memo(({ src, onClick: onClickExternal, ...rest }) => {
  const id = useConstant(() => uuidv4());
  const { toggle, stop, playing, src: globalAudioSrc } = useGlobalAudioPlayer();

  const onClick = useCallback(() => {
    toggle(src, id);
    onClickExternal?.();
  }, [id, onClickExternal, src, toggle]);

  useUnmount(() => {
    if (src === globalAudioSrc) stop();
  });

  return (
    <SpeakerButtonRoot {...rest} onClick={onClick}>
      <AudioImage $animate={playing && src === globalAudioSrc} />
    </SpeakerButtonRoot>
  );
});

SpeakerButton.propTypes = {
  src: MediaSrcPropType,
  onClick: PropTypes.func,
};

export default SpeakerButton;
