const InteractionActions = {
  BLOCK_INTERACTION: "@@local/BLOCK_INTERACTION",
  blockInteraction: () => {
    return { type: InteractionActions.BLOCK_INTERACTION };
  },
  UNBLOCK_INTERACTION: "@@local/UNBLOCK_INTERACTION",
  unblockInteraction: () => {
    return { type: InteractionActions.UNBLOCK_INTERACTION };
  },
};

export default InteractionActions;
