/* eslint-disable no-console */

import Env from "./env";

// ########## Console Polyfill ##########

// Avoid `console` errors in browsers that lack a console
// https://stackoverflow.com/a/16916941/167983

{
  const console = (window.console = window.console || {});
  const noop = () => {};
  [
    "assert",
    "clear",
    "count",
    "debug",
    "dir",
    "dirxml",
    "error",
    "exception",
    "group",
    "groupCollapsed",
    "groupEnd",
    "info",
    "log",
    "markTimeline",
    "profile",
    "profileEnd",
    "table",
    "time",
    "timeEnd",
    "timeStamp",
    "trace",
    "warn",
  ].forEach((method) => {
    if (!console[method]) console[method] = noop;
  });
}

// ########## Log Helper ##########

function logToContainer(level, ...args) {
  const sanitizedArgs = args
    .map((arg) => {
      if (typeof arg === "function") return arg.prototype?.toString();
      if (typeof arg === "object") return JSON.stringify(arg);
      return arg;
    })
    .filter((value) => value !== null && typeof value !== "undefined");
  Env._sendToContainer("log-to-file", { method: level, messages: sanitizedArgs });
}

const originalTrace = console.trace;
const newTrace = __DEV__
  ? console.trace
  : (...args) => {
      originalTrace(...args);
      logToContainer("trace", ...args);
    };
console.trace = newTrace;

const originalInfo = console.info;
const newInfo = __DEV__
  ? console.info
  : (...args) => {
      originalInfo(...args);
      logToContainer("info", ...args);
    };
console.info = newInfo;

const originalLog = console.log;
const newLog = __DEV__
  ? console.log
  : (...args) => {
      originalLog(...args);
      logToContainer("debug", ...args);
    };
console.log = newLog;

const originalWarn = console.warn;
const newWarn = __DEV__
  ? console.warn
  : (...args) => {
      originalWarn(...args);
      logToContainer("warn", ...args);
    };
console.warn = newWarn;

const originalError = console.error;
const newError = __DEV__
  ? console.error
  : (...args) => {
      originalError(...args);
      logToContainer("error", ...args);
    };
console.error = newError;

export default class Log {
  static trace = newTrace;
  static debug = newLog;
  static info = newInfo;
  static warn = newWarn;
  static error = newError;

  static initialize() {
    // Grab global errors if running in a container (in the browser they are already
    // displayed and can't be silenced and logged by us like we do with the console
    // logging functions).
    if (Env.isContained) {
      window.onerror = function (message, source, lineno, colno, event) {
        logToContainer("error", message, `(source: ${source} line: ${lineno}, col: ${colno})`);
      };
    }
  }
}
