import PropTypes from "prop-types";
import { forwardRef, memo, useCallback, useImperativeHandle, useRef } from "react";
import Classes from "../../../helpers/classes";
import { MediaSrcPropType } from "../../../logic/prop-types";
import InteractionHint from "../internal/interaction-hint";

/**
 * A mostly dumb container (with the proper CSS) for MapContent and friends.
 * Always wrap map contents in a Map element.
 */
const Map = memo(
  forwardRef(({ className, children, hint, animateHint, hintShowDelay, hintHideDelay, ...rest }, ref) => {
    const hintRef = useRef(null);
    const hideHint = useCallback(() => hintRef.current.hide(), []);
    useImperativeHandle(ref, () => ({ hideHint }), [hideHint]);
    return (
      <div {...rest} className={Classes.build("ripple-map", className)} onPointerDown={hideHint}>
        {children}
        <InteractionHint ref={hintRef} animate={animateHint} showDelay={hintShowDelay} hideDelay={hintHideDelay}>
          {hint}
        </InteractionHint>
      </div>
    );
  }),
);

Map.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  hint: PropTypes.oneOfType([PropTypes.node, MediaSrcPropType]),
  animateHint: PropTypes.bool,
  hintShowDelay: PropTypes.number,
  hintHideDelay: PropTypes.number,
};

Map.Content = require("./map-content").default;
Map.ImageContent = require("./map-image-content").default;
Map.VideoContent = require("./map-video-content").default;
Map.Element = require("./map-element").default;

export default Map;
