import { lighten } from "polished";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { useData } from "ripple";
import ButtonLayer from "../../../components/button-layer";
import CardsGrid from "../../../components/cards-grid";
import ColorLayer from "../../../components/color-layer";
import FeedbackLayer from "../../../components/feedback-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import TagLayer from "../../../components/tag-layer";
import { useFindPairsQuestionLogic } from "../../../hooks/use-find-pairs-question-logic";
import {
  CardBox,
  CardImage,
  CardTitle,
  CardTitleArea,
  ContentLayer,
  MascotImage,
  MascotLayer,
  Type18QuestionRoot,
} from "./styled";

const Type18Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const color = node.wantedInheritedText("Color")?.trim();
  const cards = node.children;

  const { maxGroupSize, chosenCards, foundCards, createOnCardClick, feedbacks } = useFindPairsQuestionLogic(
    cards,
    onComplete,
  );

  return (
    <Type18QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent>
        <CardsGrid cards={cards}>
          {(card) => {
            const flipped = chosenCards.includes(card) || foundCards.includes(card);
            return (
              <>
                <CardBox $show={!flipped}>
                  <Layers>
                    <ColorLayer color={lighten(0.5, color)} />
                    <MascotLayer>
                      <MascotImage
                        $tint={color}
                        src={node.wantedInheritedMedia("MascotImage", "PreserveSizeWithAlpha")}
                      />
                    </MascotLayer>
                    <ButtonLayer
                      disabled={flipped || chosenCards.length >= maxGroupSize}
                      onClick={createOnCardClick(card)}
                    />
                    <TagLayer icon="flip" />
                  </Layers>
                </CardBox>
                <CardBox $show={flipped}>
                  <Layers>
                    <ContentLayer>
                      <CardImage src={card.wantedMedia("Image", "Image")} />
                      <CardTitleArea>
                        <CardTitle>{card.wantedText("Title")}</CardTitle>
                      </CardTitleArea>
                    </ContentLayer>
                    <ButtonLayer
                      disabled={!flipped || chosenCards.length >= maxGroupSize}
                      onClick={createOnCardClick(card)}
                    />
                    <FeedbackLayer feedback={feedbacks[card.id]} />
                  </Layers>
                </CardBox>
              </>
            );
          }}
        </CardsGrid>
      </ResponsiveContent>
    </Type18QuestionRoot>
  );
};

Type18Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type18Question;
