import PropTypes from "prop-types";
import { Fragment, PureComponent, createRef } from "react";
import Classes from "../../../helpers/classes";
import resource from "../../../helpers/resource";
import Strings from "../../../helpers/strings";
import Timeout from "../../../helpers/timeout";
import Button from "../button";

class ErrorBoundary extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
    renderError: PropTypes.func,
  };

  state = {
    error: null,
  };

  reset() {
    this.setState({ error: null });
  }

  render() {
    return (
      <div className={Classes.build("ripple-error-boundary", this.props.className)} style={this.props.style}>
        {this.state.error ? this.renderError() : this.props.children}
      </div>
    );
  }

  renderError() {
    return <div className="error">{this.renderActualError()}</div>;
  }

  renderActualError() {
    if (this.props.renderError) return this.props.renderError(this.state.error);
    return this.renderDefaultError(this.state.error.message);
  }

  renderDefaultError = (message) => {
    return (
      <Fragment>
        {/* eslint-disable-next-line ripple/no-img */}
        <img src={resource("images/error.png")} />
      </Fragment>
    );
  };

  componentDidCatch = (error, info) => {
    this.setState({ error: error });
  };
}

export class RootErrorBoundary extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.errorBoundaryRef = createRef();
  }

  render() {
    return (
      <ErrorBoundary
        {...this.props}
        ref={this.errorBoundaryRef}
        className={Classes.build("core", this.props.className)}
        renderError={this.renderError}
      >
        {this.props.children}
      </ErrorBoundary>
    );
  }

  renderError = (error) => {
    // Force english at dev time for consistency
    // and to avoid having to provide those strings
    // in all of the languages that the app supports
    const language = __DEV__ ? "en" : null;

    // If we forgot to provide localized strings for languages
    // other than `en` or `fr` (both of which are provided in core strings
    // by default), fallback to this language instead of displaying nothing.
    const fallbackLanguage = "en";

    return (
      <>
        <h1>{Strings.localized("PageErrorTitle", language, fallbackLanguage)}</h1>
        <h2>{Strings.localized("PageErrorSubtitle", language, fallbackLanguage)}</h2>
        {__DEV__ && <code className="error">{error.message}</code>}
        <div className="error-boundary-buttons">
          {__DEV__ && (
            <Button className="resume" onClick={this.onResumeClick}>
              {Strings.localized("PageErrorResume", language, fallbackLanguage)}
            </Button>
          )}
          {__DEV__ && (
            <Button className="reload" onClick={this.onReloadClick}>
              {Strings.localized("PageErrorReload", language, fallbackLanguage)}
            </Button>
          )}
          <Button className="restart" onClick={this.onRestartClick}>
            {Strings.localized("PageErrorRestart", language, fallbackLanguage)}
          </Button>
        </div>
      </>
    );
  };

  onResumeClick = () => {
    this.errorBoundaryRef.current.reset();
  };

  onReloadClick = () => {
    window.location.reload();
  };

  onRestartClick = () => {
    // Navigating to the home (using `Timeout.force()`) works on all platforms,
    // but changing window location to `/` doesn't work in REC and RCC, which is
    // why we're not reloading from the startup page.
    Timeout.force();
    window.location.reload();
  };
}

export default ErrorBoundary;
