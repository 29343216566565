export default class Maths {
  /** Returns the normalized (0..1) ratio of given value within the [min, max] interval. */
  static ratio(value, min, max) {
    if (min === max) return 1;
    return (value - min) / (max - min);
  }

  /** Interpolates between min and max according to ratio. */
  static interpolate(ratio, min, max) {
    return min + (max - min) * ratio;
  }

  /** Wrap the integer value within the [min, max] range (inclusive). */
  static wrap(value, min, max) {
    const a = value - min;
    const b = max - min + 1;
    return min + (a >= 0 ? a % b : (b + (a % b)) % b);
  }

  /** Ensure the value is never less than min nor more than max */
  static clamp(value, min, max) {
    if (value < min) return min;
    if (value > max) return max;
    return value;
  }

  /* Parse a float or returns null if there is an exception (the input value is null, etc.) */
  static parseFloat(value) {
    try {
      const result = parseFloat(value);
      return isNaN(result) ? null : result;
    } catch {
      return null;
    }
  }

  /* Parse an int or returns null if there is an exception (the input value is null, etc.) */
  static parseInt(value) {
    try {
      const result = parseInt(value);
      return isNaN(result) ? null : result;
    } catch {
      return null;
    }
  }

  /* Round to a given number of decimals (https://stackoverflow.com/a/42368487/167983) */
  static round(num, decimals) {
    if (!decimals) return Math.round(num);
    num = Math.round(num + "e" + decimals);
    return Number(num + "e-" + decimals);
  }

  static degreesToRadians(degrees) {
    return Maths.interpolate(Maths.ratio(degrees, 0, 360), 0, Math.PI * 2);
  }

  static radiansToDegrees(radians) {
    return Maths.interpolate(Maths.ratio(radians, 0, Math.PI * 2), 0, 360);
  }
}
