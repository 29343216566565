import { Button } from "ripple";
import styled from "styled-components";

export const BottomPillsRoot = styled.div`
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  visibility: ${(props) => (props.$hidden ? "hidden" : "visible")};
  pointer-events: ${(props) => (props.$hidden ? "none" : "inherit")};
`;

export const Outer = styled(Button)`
  margin: 0 1vmin;
  width: 2vmin;
  height: 2vmin;
  border-radius: 50%;
  border: 0.2vmin solid ${(props) => props.color};
  display: flex;

  .hotspot {
    margin: -1vmin;
  }
`;

export const Inner = styled.div`
  flex: 1;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin: 0.2vmin;
  transition: opacity 150ms ease-in-out;
  opacity: ${(props) => (props.active ? 1 : 0)};
`;
