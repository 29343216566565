import PropTypes from "prop-types";
import { MediaSrcPropType } from "ripple";
import ImageLayer from "../image-layer";
import Layers from "../layers";
import { ImageDragPreviewRoot } from "./styled";

const ImageDragPreview = ({ className, style, image, ...rest }) => {
  return (
    <ImageDragPreviewRoot {...rest} className={className} style={style}>
      <Layers>
        <ImageLayer src={image} />
      </Layers>
    </ImageDragPreviewRoot>
  );
};

ImageDragPreview.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  image: MediaSrcPropType,
};

export default ImageDragPreview;
