/* global phidget22 */

import _ from "lodash";
import Config from "./config";
import Interaction from "./interaction";
import Log from "./log";

const sleep = (duration) => {
  return new Promise((resolve) => setTimeout(resolve, duration));
};

const waitUntilNoException = async (action) => {
  try {
    return action();
  } catch (error) {
    await sleep(10);
    return waitUntilNoException(action);
  }
};

// Creates a unique key string given an identification object
const makeKey = (identification) => {
  const orderedKeys = Object.keys(identification)
    .map((key) => key.toString())
    .sort();
  const orderedIdentifiers = orderedKeys.map((key) => `${key}:${identification[key]}`);
  return orderedIdentifiers.join(",");
};

// Provides as many identification values as we have to let the
// phidget API figure out what actual hardware to talk to
const identify = (phidget, identification) => {
  if (!_.isNil(identification.deviceSerialNumber)) phidget.setDeviceSerialNumber(identification.deviceSerialNumber);
  if (!_.isNil(identification.isHubPortDevice)) phidget.setIsHubPortDevice(identification.isHubPortDevice);
  if (!_.isNil(identification.hubPort)) phidget.setHubPort(identification.hubPort);
  if (!_.isNil(identification.channel)) phidget.setChannel(identification.channel);
};

export default class Phidgets {
  static _connections = [];

  static _registrations = {};

  static validateIdentification(deviceSerialNumber, isHubPortDevice, hubPort, channel) {
    const oneOrTwoDigitsRegex = /^\d\d?$/;

    // If all values are nil, silently return
    if (_.isNil(deviceSerialNumber) && _.isNil(isHubPortDevice) && _.isNil(hubPort) && _.isNil(channel)) return false;

    // Device serial number is provided but isn't in the correct format
    if (!_.isNil(deviceSerialNumber) && !/^\d\d\d\d\d\d$/.test(deviceSerialNumber)) {
      Log.error(`Phidgets: Invalid device serial number '${deviceSerialNumber}'`);
      return false;
    }

    // isHubPortDevice is provided but isn't in the correct format
    if (!_.isNil(isHubPortDevice) && !(isHubPortDevice === true || isHubPortDevice === false)) {
      Log.error(`Phidgets: Invalid isHubPortDevice '${isHubPortDevice}'`);
      return false;
    }

    // Hub port is provided but isn't in the correct format
    if (!_.isNil(hubPort) && !oneOrTwoDigitsRegex.test(hubPort)) {
      Log.error(`Phidgets: Invalid hubPort '${hubPort}'`);
      return false;
    }

    // Channel is provided but isn't in the correct format
    if (!_.isNil(channel) && !oneOrTwoDigitsRegex.test(channel)) {
      Log.error(`Phidgets: Invalid channel '${channel}'`);
      return false;
    }

    return true;
  }

  static initialize(servers) {
    // It's fine to connect once at startup like this, because
    // the Phidgets API automatically reconnects and the phidgets
    // themselves also re-attach when the connection is restored. Nice! :)
    servers.forEach((server, index) => {
      this._connect(server.hostname, server.port, () =>
        Log.info(`Phidgets: Connected to server ${index + 1} of ${servers.length} (${server.hostname}:${server.port})`),
      );
    });
  }

  static _connect(hostname, port, onConnect) {
    const connection = new phidget22.NetworkConnection({ hostname, port });
    connection.connect(/* retryOnFail */ true).then(onConnect).catch(Log.error);
    this._connections.push(connection);
  }

  static _setupPhidget(type, description, identification, onAttach = () => {}) {
    const phidget = new type();
    const channelClass = phidget22.ChannelClass[phidget.channelClass];
    identify(phidget, identification);
    phidget.onError = (code, message) => {
      Log.error(`Phidgets: Error '${description}' (${channelClass}) : ${message}`, identification);
    };
    phidget.rippleOnAttachCallbacks = [onAttach]; // This is NOT Phidgets API, rather we store our own data in the existing Phidget object!
    phidget.onAttach = () => {
      Log.info(`Phidgets: Attach '${description}' (${channelClass})`, identification);
      phidget.rippleOnAttachCallbacks.forEach((c) => c(phidget));
    };
    phidget.onDetach = () => {
      // No need to do anything other than logging here, as the phidget will re-attach when the physical channel becomes available again
      Log.warn(`Phidgets: Detached`, identification);
    };
    Log.debug(`Phidgets: Open '${description}' (${channelClass})`, identification);
    phidget.open();
    return phidget;
  }

  static _cleanupIdentification(identification) {
    return Object.keys(identification).reduce((acc, key) => {
      const value = identification[key];
      if (value === null || value === undefined) return acc;
      acc[key] = value;
      return acc;
    }, {});
  }

  static _subscribe({
    description, // A textual description of what we're trying to subscribe to (app-specific)
    identification, // Phidget identification parameters to target the proper hardware.
    type, // The JavaScript type to instantiate (ex: `phidget22.DigitalInput`).
    onAttach = () => {}, // A function to run when the phidget is attached and ready to provide I/O.
    onSetupCallback = null, // A function to run to setup callbacks! See implementation for details.
    externalCallback = null, // The actual callback to call for this open
  }) {
    identification = this._cleanupIdentification(identification);

    const key = makeKey(identification);

    const existingInfo = this._registrations[key];

    // If there's already a registration for the identification, we can't register any
    // other type of phidget on that same indentification
    if (existingInfo?.type && type !== existingInfo.type) {
      throw new Error(
        `Can't dynamically change type for Phidget device ${JSON.stringify(
          identification,
        )}. Restart the app if physical connections have changed. Otherwise, make sure there are no double registrations for the same identification.`,
      );
    }

    // Get the existing info or create a new one
    let info = existingInfo;

    const onAttachInternal = (phidget) => {
      onAttach?.(phidget);
      // Set the actual phidget events that will end up calling our stored callbacks.
      // Idempotent because `onSetupCallback` (see implementation in specific widget type "open" functions below)
      // simply overwrites phidget events with the same function every time it's called.
      onSetupCallback?.(phidget, (...args) => {
        info.callbacks.forEach((callback) => callback?.(...args));
      });
    };

    if (!info) {
      info = {
        type,
        phidget: this._setupPhidget(type, description, identification, onAttachInternal),
        callbacks: [],
      };
    }

    // When opening an already-attached Phidget,
    // need to tell the caller that the Phidget is ready to use (for
    // the first open request it's called on Phidget attach, see above).
    if (existingInfo) {
      const phidget = existingInfo.phidget;
      if (phidget.attached) {
        onAttachInternal?.(phidget);
      } else {
        // Otherwise, add it to our array of callbacks that will be called once the phidget is attached.
        phidget.rippleOnAttachCallbacks.push(onAttach);
      }
    }

    // Store the info (redundant but OK if already existing)
    this._registrations[key] = info;

    // Add the new callback to the info, whether it already existed or is new
    if (externalCallback) info.callbacks.push(externalCallback);

    // Provide a way to unsubscribe
    return {
      unsubscribe: async () => {
        // Remove the callback from the appropriate info
        if (externalCallback) _.remove(info.callbacks, (callback) => callback === externalCallback);

        // NOTE: We keep the info (and never close the phidget) even if
        // there aren't any callbacks left, because we can assume that
        // the physical connections won't change dynamically while the
        // app runs. E.g. there never should be an encoder or an output
        // where an input was, etc. Also, closing and opening phidgets
        // in quick succession causes exceptions, so it's more stable
        // that way.
      },
    };
  }

  // ########## Encoders ##########

  static encoderSubscribe(description, identification, callback) {
    return this._subscribe({
      description,
      identification,
      type: phidget22.Encoder,
      onSetupCallback: (encoder, executeCallbacks) => {
        encoder.onPositionChange = (positionChange, timeChange, indexTriggered) => {
          if (positionChange !== 0) Interaction.emit("Phidget Encoder");
          executeCallbacks(positionChange, timeChange, indexTriggered);
        };
      },
      onAttach: (encoder) => {
        encoder.setDataInterval(Config.phidgets.encoderDataInterval);
      },
      externalCallback: callback,
    });
  }

  // ########## Digital Input ##########

  static digitalInputSubscribe(description, identification, callback) {
    return this._subscribe({
      description,
      identification,
      type: phidget22.DigitalInput,
      onSetupCallback: (digitalInput, executeCallbacks) => {
        // Execute callbacks ASAP with the initial state (NOT idempotent,
        // but OK because we want to get the initial state for each subscription)
        waitUntilNoException(() => executeCallbacks(digitalInput.state, { initial: true }));

        // Setup callbacks for subsequent state changes (idempotent)
        digitalInput.onStateChange = (state) => {
          Interaction.emit("Phidget Digital Input");
          executeCallbacks(state, { initial: false });
        };
      },
      externalCallback: callback,
    });
  }

  // ########## Digital Output ##########

  static digitalOutputSubscribe(description, identification, onAttachCallback) {
    return this._subscribe({
      description,
      identification,
      type: phidget22.DigitalOutput,
      onAttach: (digitalOutput) =>
        onAttachCallback(
          (on) => {
            if (!digitalOutput.attached) return Promise.resolve();
            return digitalOutput.setState(on);
          },
          (frequency) => {
            if (!digitalOutput.attached) return Promise.resolve();
            return digitalOutput.setFrequency(frequency);
          },
          (dutyCycle) => {
            if (!digitalOutput.attached) return Promise.resolve();
            return digitalOutput.setDutyCycle(dutyCycle);
          },
        ),
    });
  }

  // ########## RFID ##########

  static rfidReaderSubscribe(description, identification, callback) {
    return this._subscribe({
      description,
      identification,
      type: phidget22.RFID,
      onSetupCallback: (rfid, executeCallbacks) => {
        rfid.onTag = (tag) => {
          Interaction.emit("Phidget RFID Tag Read");
          Log.info(`Phidgets: RFID Tag Read '${tag}'`);
          executeCallbacks(tag);
        };
        rfid.onTagLost = (tag) => {
          Interaction.emit("Phidget RFID Tag Lost");
          Log.info(`Phidgets: RFID Tag Lost`);
          executeCallbacks(null);
        };
      },
      externalCallback: callback,
    });
  }

  // ########## Voltage Input ##########

  static voltageInputSubscribe(description, identification, options, callback) {
    return this._subscribe({
      description,
      identification,
      type: phidget22.VoltageInput,
      onAttach: (voltageInput) => {
        voltageInput.setDataInterval(options.dataInterval);
        voltageInput.setVoltageChangeTrigger(options.voltageChangeTrigger);
      },
      onSetupCallback: (voltageInput, executeCallbacks) => {
        voltageInput.onVoltageChange = (voltage) => {
          executeCallbacks(voltage);
        };
      },
      externalCallback: callback,
    });
  }
}
