import { useCallback, useEffect, useState } from "react";

export const useKeyPress = (targetKey) => {
  const [pressed, setPressed] = useState(false);

  const match = (event, key) => {
    return event.key.toLowerCase() === key;
  };

  const downHandler = useCallback(
    (event) => {
      if (match(event, targetKey)) setPressed(true);
    },
    [targetKey],
  );

  const upHandler = useCallback(
    (event) => {
      if (match(event, targetKey)) setPressed(false);
    },
    [targetKey],
  );

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [downHandler, upHandler]);

  return pressed;
};
