import { memo } from "react";
import { Config, Env, Grid, Image, Navigator, Strings, resource, useData } from "ripple";
import { checkIfMobileVersion } from "../../../logic/mobile";
import ActivityCard from "../../components/activity-card";
import SongActivityCardContent from "../../components/song-activity-card-content";
import {
  ActivitiesGrid,
  Activity,
  ActivityButton,
  ActivityTitle,
  DownloadApp,
  DownloadAppStoreImage,
  DownloadAppText,
  Footer,
  FooterLogo,
  FooterText,
  MascotsContainer,
  MoukaMascot,
  Page,
  PageScroller,
  RaliMascot,
  Section,
  SectionBackground,
  SectionContent,
  SectionThemeIcon,
  SectionThemeTitle,
  StyledResourcesMenu,
  ThemeMenuButton,
  ThemesColumn,
  Title,
} from "./styled";

const WebHomePage = memo(() => {
  const client = useData((data) => data.root);
  const themes = (Config.testing ? client.allChildren : client.children).filter((node) => node.semantic === "Theme");

  const createOnThemeClick = (theme) => () => {
    const element = document.getElementById(`section-${theme.id}`);
    element.scrollIntoView({ behavior: "smooth" });
  };

  const createOnActivityClick = (activity) => () => {
    Navigator.navigate({ node: activity });
  };

  const renderThemeMenuButton = (theme) => {
    return (
      <ThemeMenuButton key={theme.id} node={theme} onClick={createOnThemeClick(theme)}>
        <Image src={theme.wantedMedia("Icon", "PreserveSizeWithAlpha")} />
      </ThemeMenuButton>
    );
  };

  const renderSongActivityCardContent = (activity) => {
    return <SongActivityCardContent activity={activity} />;
  };

  const renderSongActivityWhiteBoxContent = (activity) => {
    return null;
  };

  const renderActivityWhiteBoxContent = (activity) => {
    return (
      <>
        <ActivityTitle>{activity.wantedText("Title")}</ActivityTitle>
        <ActivityButton>
          <Image src={resource("images/Button_Back_Symbol.svg")} />
        </ActivityButton>
      </>
    );
  };

  const renderActivity = (activity) => {
    return (
      <Activity key={activity.id}>
        <ActivityCard
          activity={activity}
          key={activity.id}
          onClick={createOnActivityClick(activity)}
          cardContent={activity.semantic === "SongActivity" ? renderSongActivityCardContent(activity) : null}
          disabled={activity.semantic === "SongActivity"}
        >
          {activity.semantic === "SongActivity"
            ? renderSongActivityWhiteBoxContent(activity)
            : renderActivityWhiteBoxContent(activity)}
        </ActivityCard>
      </Activity>
    );
  };

  const renderSection = (theme) => {
    return (
      <Section key={theme.id} id={`section-${theme.id}`}>
        <SectionBackground $blur src={theme.wantedMedia("BackgroundBlurred", "FullscreenImageHighQuality")} />
        <SectionContent>
          <SectionThemeIcon src={theme.wantedMedia("Icon", "PreserveSizeWithAlpha")} />
          <SectionThemeTitle>{theme.wantedText("Title")}</SectionThemeTitle>
          <ActivitiesGrid layout={Grid.layout.columns(3)}>{theme.children.map(renderActivity)}</ActivitiesGrid>
        </SectionContent>
      </Section>
    );
  };

  const renderNormalContent = () => {
    return (
      <PageScroller id="home-page-scroller">
        <Section>
          <SectionBackground src={client.wantedMedia("Background", "FullscreenImage")} />
          <Title>{client.wantedText("HomeTitle")}</Title>
          <ThemesColumn>{themes.map(renderThemeMenuButton)}</ThemesColumn>
          <MascotsContainer>
            <MoukaMascot
              image={client.wantedMedia("MascotImage", "PreserveSizeWithAlpha")}
              audio={client.wantedMedia("HomeWelcomeWebMoukaAudio", "Audio")}
              text={client.wantedText("HomeWelcomeWebMouka")}
              flip
            />
            <RaliMascot
              image={client.wantedMedia("MascotImage2", "PreserveSizeWithAlpha")}
              audio={client.wantedMedia("HomeWelcomeWebRaliAudio", "Audio")}
              text={client.wantedText("HomeWelcomeWebRali")}
            />
          </MascotsContainer>
          <StyledResourcesMenu />
        </Section>
        {themes.map(renderSection)}
        <Footer>
          <FooterText>{client.wantedText("FooterTextWeb")}</FooterText>
          <FooterLogo />
        </Footer>
      </PageScroller>
    );
  };

  const renderPhoneContent = () => {
    return (
      <DownloadApp>
        <DownloadAppText>{Strings.localized("DownloadApp")}</DownloadAppText>
        {/* eslint-disable-next-line ripple/enforce-button-component */}
        <a href="https://apps.apple.com/ca/app/mouka-te-parle/id1613801547">
          <DownloadAppStoreImage src={resource("images/Download_on_the_App_Store_Badge_FR_RGB_blk_100517.svg")} />
          {/* eslint-disable-next-line ripple/enforce-button-component */}
        </a>
        {/* eslint-disable-next-line ripple/enforce-button-component */}
        <a
          href="https://play.google.com/store/apps/details?id=com.cheneliere.MEO&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          style={{ width: "50%" }}
        >
          {/* eslint-disable-next-line ripple/no-img */}
          <img
            alt="Disponible sur Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/fr-ca_badge_web_generic.png"
            style={{ width: "100%" }}
          />
          {/* eslint-disable-next-line ripple/enforce-button-component */}
        </a>
      </DownloadApp>
    );
  };

  return (
    <Page
      rootBackground={checkIfMobileVersion() ? client.wantedMedia("MainBackgroundMobile", "FullscreenImage") : null}
    >
      {Env.isPhone ? renderPhoneContent() : renderNormalContent()}
    </Page>
  );
});

export default WebHomePage;
