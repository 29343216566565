import { ClientScanJob, JSPrintManager, ScannerImageFormatOutput, WSStatus } from "jsprintmanager";
import Config from "./config";
import Log from "./log";

/**
 * Allows scanning documents using JSPrintManager (purchased license).
 * Requires the client app to be installed on the local machine: https://www.neodynamic.com/downloads/jspm/
 * On Windows, you probably need the 32-bit version for scanners to be detected properly (even on 64-bit Windows)
 *
 * Because of how offline license validation works (makes a request to the REC AppServer),
 * license validation can only work when packaged and the app is running from the AppServer
 * (license_url has the same origin as the app). At dev time, the license fails to load
 * (which is OK) and the JSPrintManager client "evaluation mode" dialog pops up before each
 * scan. This will not be the case when the app is packaged.
 */
export default class Scanner {
  static _initialized = false;

  static initialize() {
    if (!Config.scanner.enabled) return;
    if (this._initialized) return;
    this._initialized = true;
    JSPrintManager.license_url = `${window.location.origin}/jspm`; // Only works in packaged apps because it depends on the AppServer responding at `/jspm`
    JSPrintManager.auto_reconnect = true;
    JSPrintManager.start();
    // eslint-disable-next-line ripple/callback-naming
    JSPrintManager.WS.onError = (error) => {}; // We already have a websocket browser error if there is something wrong with the connection
    // eslint-disable-next-line ripple/callback-naming
    JSPrintManager.WS.onStatusChanged = function () {
      if (JSPrintManager.websocket_status === WSStatus.Open) {
        JSPrintManager.getScanners().then(function (e) {
          Log.info("Scanner: Detected Scanners", e);
        });
      }
    };
  }

  static scan(
    scannerName,
    jobOptions = {
      format: "jpg",
      resolution: 200,
    },
  ) {
    if (!Config.scanner.enabled) {
      Log.warn("Scanner: Ignoring scan job because the scanner is not enabled in config.");
      return;
    }
    return new Promise((resolve, reject) => {
      Log.info("Scanner: Scanning...");
      const job = new ClientScanJob();
      job.showProgressUI = false; // Disable progress UI on Windows
      job.scannerName = scannerName;
      job.imageFormat = ScannerImageFormatOutput[jobOptions.format.toUpperCase()];
      job.resolution = jobOptions.resolution;

      job.onUpdate = (data, last) => {
        if (!(data instanceof Blob)) {
          Log.info("Scanner: Other Output", data);
          return;
        }

        const blob = new Blob([data]);
        if (blob.size === 0) return; // Sometimes on Windows an empty data blob is received, skip it, the good one should come

        Log.info("Scanner: Scanned successfully");

        resolve(blob);
      };

      job.onError = (data, is_critical) => {
        const message = "Scanner: Job Error";
        Log.error(message, data);
        reject(message);
      };

      // Send scan job to scanner!
      job.sendToClient().then((data) => Log.info("Scanner: Job sent to scanner", data));
    });
  }
}
