const DownloadFilesActions = {
  ADD_FILE: "@@local/DOWNLOAD_FILES_ADD_FILE",
  addFile: (url, name) => {
    return { type: DownloadFilesActions.ADD_FILE, url, name };
  },
  UPDATE_FILE_PROGRESS: "@@local/DOWNLOAD_FILES_UPDATE_FILE_PROGRESS",
  updateFileProgress: (url, loaded, total) => {
    return { type: DownloadFilesActions.UPDATE_FILE_PROGRESS, url, loaded, total };
  },
  REMOVE_FILE: "@@local/DOWNLOAD_FILES_REMOVE_FILE",
  removeFile: (url) => {
    return { type: DownloadFilesActions.REMOVE_FILE, url };
  },
};

export default DownloadFilesActions;
