import { useEffect, useRef } from "react";

/**
 * Wraps the latest received value in a ref. Use this to prevent changing value identity (for example, a new function on
 * every render) from triggering a hook dependency change and causing hooks that perform subscriptions to unsubscribe
 * and resubscribe needlessly. Mostly for internal use; see usage examples in `useKeyboardShortcut` and `useInterval`,
 * among other places. For details on the thinking behind this hook, see this article:
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export const useLatestRef = (value) => {
  const savedRef = useRef();

  // Remember the latest value
  useEffect(() => {
    savedRef.current = value;
    // This is just to make it abundantly clear that we must return undefined!
    // Else, cleaning up the syntax to use an inline assign causes something to be returned,
    // which goes against the useEffect hook's requirements.
    return undefined;
  }, [value]);

  return savedRef;
};
