import { memo } from "react";
import Classes from "../../helpers/classes";
import InputOutput from "./input-output";

const DebugOverlay = memo(() => {
  return (
    <div className={Classes.build("debug-overlay", "debug-visible", "unclickable")}>
      <InputOutput />
    </div>
  );
});

export default DebugOverlay;
