/**
 * This layout displays items in a grid with a fixed amount of columns.
 * The number of rows expands to fit all children.
 */
export default (numberOfColumns) => (count) => {
  const rowCount = Math.ceil(count / numberOfColumns);

  const rows = [];
  for (let i = 0; i < rowCount; i++) {
    rows.push(numberOfColumns);
  }

  return rows;
};
