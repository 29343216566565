const DataActions = {
  DATA_REQUESTED: "@@local/DATA_REQUESTED",
  requested: () => ({ type: DataActions.DATA_REQUESTED }),
  DATA_RECEIVED: "@@local/DATA_RECEIVED",
  received: (data) => ({ type: DataActions.DATA_RECEIVED, data }),
  DATA_LOAD_LOCAL: "@@local/DATA_LOAD_LOCAL",
  loadLocal: (data) => ({ type: DataActions.DATA_LOAD_LOCAL, data }),
  DATA_LOAD_STATIC: "@@local/DATA_LOAD_STATIC",
  loadStatic: (data) => ({ type: DataActions.DATA_LOAD_STATIC, data }),
  DATA_ERROR: "@@local/DATA_ERROR",
  error: (error) => ({ type: DataActions.DATA_ERROR, error: error, description: error.message }),
  DATA_DOWNLOADING_MEDIAS: "@@local/DATA_DOWNLOADING_MEDIAS",
  downloadingMedias: () => ({ type: DataActions.DATA_DOWNLOADING_MEDIAS }),
  DATA_SERVER_UNREACHABLE: "@@local/DATA_SERVER_UNREACHABLE",
  serverUnreachable: (error) => ({ type: DataActions.DATA_SERVER_UNREACHABLE, error }),
  DATA_LOAD_NONE: "@@local/DATA_LOAD_NONE",
  loadNone: () => ({ type: DataActions.DATA_LOAD_NONE }),
};

export default DataActions;
