import Log from "../../helpers/log";

/** This logger only logs action types to avoid performance hits implied by the
 * full logger (which logs the full store state TWICE for each action). */
export default (name) => {
  return (store) => (next) => (action) => {
    if (action === null) throw new Error(`${name}: Received a null action! Most probably not what you want.`);
    if (typeof action === "undefined")
      throw new Error(`${name}: Received an undefined action! Most probably not what you want.`);

    const args = [`${name}: ${action.type}`];

    // Extract description from either the action directly or from `payload` (for actions created by redux-act)
    const descriptionEntry = action.description || (action.payload ? action.payload.description : undefined);

    // If the action object contains a description, log it too.
    // It's up to individual actions to determine if (and how) to
    // generate a description that makes sense for logging.
    if (descriptionEntry) {
      const description = typeof descriptionEntry === "function" ? descriptionEntry(action) : descriptionEntry;

      args.push("--");
      if (Array.isArray(description)) {
        args.push(...description);
      } else {
        args.push(description);
      }
    }

    Log.info(...args);
    return next(action);
  };
};
