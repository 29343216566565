import styled from "styled-components";
import { standardGap, standardQuestionMaxContentSize } from "../../../common";
import Box from "../../../components/box";
import Question from "../../../components/question";

export const Type4QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Targets = styled.div`
  @media screen and (orientation: landscape) {
    width: ${standardQuestionMaxContentSize};
  }
  display: flex;
  flex-direction: column;
  gap: ${standardGap};
`;

export const Target = styled(Box).attrs((props) => ({ appearance: "active", dashed: true }))`
  aspect-ratio: 750/218;
  width: 100%;
`;
