import { useFrame } from "@react-three/fiber";
import PropTypes from "prop-types";
import { memo, useRef } from "react";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { MediaSrcPropType } from "../../../logic/prop-types";
import MediaFile from "../../../model/media-file";

import ThreeFiberCube from "../three-fiber-cube";

const LoadingIndicator = ({ ...rest }) => {
  const cubeRef = useRef(null);
  useFrame(() => {
    cubeRef.current.rotation.x -= 0.02;
    cubeRef.current.rotation.y += 0.04;
    cubeRef.current.rotation.z += 0.02;
  });
  return <ThreeFiberCube ref={cubeRef} size={[0.2, 0.2, 0.2]} color={"lightgrey"} {...rest} />;
};

const ThreeFiberModel = memo(({ src, showLoadingIndicator, ...rest }) => {
  const url = src instanceof MediaFile ? src.url : src;
  const gltf = useLoader(GLTFLoader, url);
  return gltf?.scene ? <primitive object={gltf.scene} {...rest} /> : showLoadingIndicator && <LoadingIndicator />;
});

ThreeFiberModel.propTypes = {
  src: MediaSrcPropType,
  showLoadingIndicator: PropTypes.bool,
};

export default ThreeFiberModel;
