import { Image, resource } from "ripple";
import styled from "styled-components";
import { RoundYellowButton } from "../../common";

export const PlayButtonRoot = styled(RoundYellowButton)`
  width: 5vh;
  height: 5vh;
`;

export const PlaySymbol = styled(Image).attrs((props) => ({ src: resource("images/Button_Play_Symbol.svg") }))``;
