import { Chart } from "chart.js";
import _ from "lodash";
import PropTypes from "prop-types";
import { Component, createRef } from "react";
import { connect } from "react-redux";
import Classes from "../../../helpers/classes";
import Clipboard from "../../../helpers/clipboard";
import Download from "../../../helpers/download";
import CSV from "../../../helpers/internal/csv";
import Maths from "../../../helpers/maths";
import Strings from "../../../helpers/strings";
import Styles from "../../../helpers/styles";
import Toast from "../../../helpers/toast";
import Button from "../../components/button";

class DonutBox extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    stat: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.chartElement = createRef();
  }

  render() {
    return (
      <div className={Classes.build("box donut", this.props.className)} style={Styles.merge(this.props.style)}>
        <h1>
          {Strings.localized(`Analytics${this.props.stat.name}`)}
          &nbsp;
          <i className="info fa fa-info-circle" title={Strings.localized(`Analytics${this.props.stat.name}Tooltip`)} />
        </h1>
        <div className="top-right-buttons">
          <Button onClick={this.onClipboardClick}>
            <i className="fa fa-clipboard" />
          </Button>
          <Button onClick={this.onDownloadClick}>
            <i className="fa fa-file-download" />
          </Button>
        </div>
        <div className="chart-container">
          <canvas ref={this.chartElement} />
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (this.props.language !== prevProps.language) return true;
    if (JSON.stringify(this.props.stat) === JSON.stringify(prevProps.stat)) return false;
    return true;
  }

  update = () => {
    const proportions = this.props.stat.value;
    const paths = Object.keys(proportions);
    const data = _.map(paths, (path) => proportions[path]);
    const labels = _.map(paths, (path) => path);
    const colors = _.map(paths, (path, index) => this.getColor(index, paths.length));

    if (this.chart) this.chart.destroy();
    this.chart = new Chart(this.chartElement.current, {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: data,
            borderColor: "transparent",
            backgroundColor: colors,
            label: labels,
          },
        ],
        labels: labels,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: "rgba(50, 50, 50, 0.9)",
          callbacks: {
            label: (item, thing) => {
              let label = thing.datasets[item.datasetIndex].label[item.index] || "";
              if (label) label += ": ";

              const value = thing.datasets[item.datasetIndex].data[item.index];
              label += `${this.props.stat.format(value)} (${((value / _.sum(data)) * 100).toFixed(2)}%)`;

              return label;
            },
          },
        },
      },
    });
  };

  getColor = (index, length) => {
    return `hsl(${Maths.wrap((360 * index) / length + 80, 0, 360)}, 70%, 50%)`;
  };

  onClipboardClick = () => {
    Clipboard.copy(this.generateCSV());
    Toast.info("Data copied to clipboard!");
  };

  onDownloadClick = () => {
    Download.string(`${Strings.localized(`Analytics${this.props.stat.name}`)}.csv`, this.generateCSV());
  };

  generateCSV = () => {
    const stat = this.props.stat;
    const data = [[stat.keyName, stat.valueName], ..._.map(stat.value, (value, key) => [key, value])];
    return CSV.stringify(data);
  };
}

const mapStateToProps = (state) => ({ language: state.language });

export default connect(mapStateToProps)(DonutBox);
