import DataActions from "../../actions/local/data-actions";

export default (state = null, action) => {
  switch (action.type) {
    case DataActions.DATA_RECEIVED:
      return action.data;
    case DataActions.DATA_LOAD_LOCAL:
      return action.data;
    case DataActions.DATA_LOAD_STATIC:
      return action.data;
    case DataActions.DATA_LOAD_NONE:
      return {};
    default:
      return state;
  }
};
