import Sequencer from "../sequencer";

class DoWaitTask {
  timeout = null;

  constructor(duration) {
    this.duration = duration;
  }

  perform(release) {
    if (this.duration < 0.00001) {
      release();
      return;
    }
    this.timeout = setTimeout(release, this.duration);
  }

  cancel(release) {
    if (this.timeout !== null) clearTimeout(this.timeout);
    release();
  }
}

Sequencer.prototype.doWait = function (duration) {
  this.push(new DoWaitTask(duration));
  return this;
};
