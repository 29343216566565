import PropTypes from "prop-types";
import { Image, resource } from "ripple";
import { CloseButtonRoot } from "./styled";

const CloseButton = ({ activity, ...rest }) => {
  return (
    <CloseButtonRoot {...rest}>
      <Image src={resource("images/Button_Close_Symbol.svg")} />
    </CloseButtonRoot>
  );
};

CloseButton.propTypes = {
  activity: PropTypes.object,
};

export default CloseButton;
