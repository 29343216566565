import AnalyticsDb from "../analytics-db";

export default class LocalSource {
  getRange(appId, startDate, endDate) {
    return new Promise((resolve) => {
      AnalyticsDb.initialize((dbApi) => {
        dbApi.getRange(appId, startDate, endDate, (events) => resolve(events));
      });
    });
  }
}
