import { useEffect, useRef } from "react";

// Source: https://stackoverflow.com/a/72253074/167983
export const useEffectAfterMount = (effect, deps) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) return effect();
    else isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  // Reset on unmount; in React 18, components can mount again
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );
};
