import { v4 as uuidv4 } from "uuid";
import FingerprintActions from "../../actions/master/fingerprint-actions";

const defaultState = null; // `null` is expected as a default state for some logic

export default (state = defaultState, action) => {
  switch (action.type) {
    case FingerprintActions.UPDATE_SHARED_FINGERPRINT:
      return uuidv4();
    default:
      return state;
  }
};
