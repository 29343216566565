import _ from "lodash";
import TimeoutActions from "../../actions/local/timeout-actions";

const defaultState = {
  grace: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TimeoutActions.TIMEOUT_UPDATE_GRACE:
      return _.assign({}, state, { grace: action.grace });
    default:
      return state;
  }
};
