import PropTypes from "prop-types";
import { useCallback } from "react";
import { Audio, resource } from "ripple";
import TagLayer from "../tag-layer";
import { AudioTextDroppableRoot, StyledAudioTextRow, StyledBox, StyledDroppable, StyledLayers } from "./styled";

const AudioTextDroppable = ({ className, style, node, disabled, ...rest }) => {
  const audio = node.optionalMedia("Audio", "Audio");
  const text = node.wantedText("Title");

  const onTouch = useCallback(() => {
    Audio.discrete("effects").play(resource("audio/ClicDrag.mp3"));
  }, []);

  const onDragEnd = useCallback(() => {
    Audio.discrete("effects").play(resource("audio/DragRelease.mp3"));
  }, []);

  return (
    <AudioTextDroppableRoot {...rest} className={className} style={style} $disabled={disabled}>
      <StyledBox>
        <StyledLayers>
          <TagLayer icon="drag" />
        </StyledLayers>
        <StyledAudioTextRow audio={audio} text={text} />
        <StyledDroppable id={node} onTouch={onTouch} onDragEnd={onDragEnd} $hasAudio={!!audio} />
      </StyledBox>
    </AudioTextDroppableRoot>
  );
};

AudioTextDroppable.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  node: PropTypes.object,
  disabled: PropTypes.bool,
};

export default AudioTextDroppable;
