import _ from "lodash";
import PropTypes from "prop-types";
import { memo, useState } from "react";
import Classes from "../../../helpers/classes";
import resource from "../../../helpers/resource";
import Strings from "../../../helpers/strings";
import Button from "../button";

const defaultLayout = [
  [{ key: null, flex: 1 }, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, { key: "backspace", label: "⌫", flex: 1 }],
  [{ key: null, flex: 1 }, "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", { key: null, flex: 1 }],
  [{ key: null, flex: 1 }, "a", "s", "d", "f", "g", "h", "j", "k", "l", { key: null, flex: 1 }],
  [{ key: "shift", label: "⇧", flex: 1 }, "z", "x", "c", "v", "b", "n", "m", "-", "_", ".", { key: null, flex: 0 }],
  [{ key: null, flex: 1 }, { key: "space", flex: 6 }, "@", ".com"],
];

const specialKeys = ["shift", "backspace", "enter", "tab", "space", "clear"];

function checkIsTextInput(element) {
  return element instanceof HTMLInputElement && element.type === "text";
}

function checkIsTextArea(element) {
  return element instanceof HTMLTextAreaElement;
}

function isTextElement(element) {
  return checkIsTextInput(element) || checkIsTextArea(element);
}

function isLetter(key) {
  // The weird stuff is to support diacritics: https://stackoverflow.com/a/39134560/167983
  return /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]$/.test(key);
}

function isDigit(key) {
  return /^[0-9]$/.test(key);
}

function isSpecial(key) {
  return specialKeys.includes(key);
}

function insertText(text, shift = false) {
  text = shift ? text.toUpperCase() : text;

  const element = document.activeElement;
  if (!isTextElement(element)) return;
  const selectionStartBeforeAlteration = element.selectionStart;
  element.setRangeText(text, element.selectionStart, element.selectionEnd);
  element.selectionStart = selectionStartBeforeAlteration + text.length;
  element.selectionEnd = element.selectionStart;

  // This is necessary to have changes trigger the input's onChange handler
  // because React overrides the input's value setter to avoid an infinite loop
  // when it updates the value itself.
  // See this for details:
  // - https://stackoverflow.com/a/46012210/167983
  // - https://stackoverflow.com/a/61741796/167983
  element.dispatchEvent(new Event("change", { bubbles: true }));
}

function backspace() {
  const element = document.activeElement;
  if (!isTextElement(element)) return;
  const selectionStartBeforeAlteration = element.selectionStart;

  if (element.selectionStart !== element.selectionEnd) {
    // There is a selection, erase it
    element.setRangeText("", element.selectionStart, element.selectionEnd);
    element.selectionStart = selectionStartBeforeAlteration;
    element.selectionEnd = selectionStartBeforeAlteration;
  } else if (element.selectionStart !== 0) {
    // Erase one character backwards from the selection start
    element.setRangeText("", Math.max(element.selectionStart - 1, 0), element.selectionEnd);
    element.selectionStart = selectionStartBeforeAlteration - 1;
    element.selectionEnd = selectionStartBeforeAlteration - 1;
  }

  element.dispatchEvent(new Event("change", { bubbles: true }));
}

function enter() {
  insertText("\n");
}

function clear() {
  const element = document.activeElement;
  if (!isTextElement(element)) return;
  element.value = "";
  element.dispatchEvent(new Event("change", { bubbles: true }));
}

const VirtualKeyboard = memo(
  ({ className, layout = defaultLayout, sound = resource("audio/ripple-key.mp3"), muted = false, ...rest }) => {
    const [shift, setShift] = useState(false);

    const createOnKeyClick = (info) => () => {
      // When an action is provided, act as a simple button and type nothing
      if (info.action) {
        info.action();
        return;
      }

      // NOTE: This check is redundant, but it helps us notice that the special keys
      // array is outdated if we add a new built-in special key.
      if (isSpecial(info.key)) {
        if (info.key === "shift") {
          setShift(!shift);
          return;
        }

        switch (info.key) {
          case "backspace":
            backspace();
            break;
          case "enter":
            enter();
            break;
          case "clear":
            clear();
            break;
          case "space":
            insertText(" ");
            break;
          case "tab":
            insertText("\t");
            break;
          default:
            throw new Error("Unimplemented special key!");
        }
      } else {
        insertText(info.type || info.key, shift);
      }

      // At this point, the pressed key is NOT shift, so any key press turns shift off
      setShift(false);
    };

    const getKeyInfo = (keyDescriptor) => {
      // Reduce all possible key descriptor formats to a single universal format for rendering
      const defaultInfo = { key: null, type: null, action: null };

      const info = (() => {
        if (keyDescriptor === null || typeof keyDescriptor === "undefined") return defaultInfo;
        if (typeof keyDescriptor === "object") return { ...defaultInfo, ...keyDescriptor };
        if (typeof keyDescriptor === "string" || typeof keyDescriptor === "number")
          return { ...defaultInfo, key: keyDescriptor };

        throw new Error(`Invalid virtual keyboard key descriptor: ${JSON.stringify(keyDescriptor)}`);
      })();

      // We support raw numbers as keys in the key descriptor, but from now on we work only with strings
      if (typeof info.key === "number") info.key = info.key.toString();

      // Store whether the key is empty or not (*after* disabling it above!)
      info.isEmpty = info.key === null || typeof info.key === "undefined";

      return info;
    };

    const renderKey = (keyDescriptor, index) => {
      const info = getKeyInfo(keyDescriptor);
      const empty = info.isEmpty;
      const letter = isLetter(info.key);
      const digit = isDigit(info.key);
      const special = isSpecial(info.key);
      const other = !empty && !letter && !digit && !special;

      return (
        <Button
          key={`key-${index}`}
          disabled={info.isEmpty}
          className={Classes.build("virtual-keyboard-key", info.class, {
            empty,
            letter,
            digit,
            special,
            other,
          })}
          sound={sound}
          muted={muted}
          onClick={createOnKeyClick(info)}
          clickMode="down"
          preventDefault
          style={{ flex: info.flex }}
        >
          {typeof info.label === "string" ? info.label : Strings.localized(`${info.key}KeyboardKey`) || info.key}
        </Button>
      );
    };

    const renderRow = (row, index) => {
      return (
        <div key={`row-${index}`} className="virtual-keyboard-row">
          {_.map(row, renderKey)}
        </div>
      );
    };

    return (
      <div {...rest} className={Classes.build("ripple-virtual-keyboard", className, { shift })}>
        <div className="virtual-keyboard-column">{_.map(layout, renderRow)}</div>
      </div>
    );
  },
);

VirtualKeyboard.propTypes = {
  className: PropTypes.string,
  layout: PropTypes.arrayOf(PropTypes.array),
  sound: PropTypes.string,
  muted: PropTypes.bool,
};

export default VirtualKeyboard;
