import PropTypes from "prop-types";
import { Image, resource } from "ripple";
import { useIsPortrait } from "../../hooks/use-is-portrait";
import {
  CloseButton,
  ContentScroller,
  HelpImage,
  HelpPanelRoot,
  HelpText,
  LeftSpeakerButton,
  PanelBox,
  StyledVideoBlock,
  TopButtons,
} from "./styled";

const HelpPanel = ({ className, style, node, onCloseClick, ...rest }) => {
  const isPortrait = useIsPortrait();

  const renderHelpImage = (media) => {
    return <HelpImage src={media} />;
  };

  const renderHelpVideo = (media) => {
    return <StyledVideoBlock src={media} />;
  };

  const renderHelpMedia = (media) => {
    if (media.type === "image") return renderHelpImage(media);
    if (media.type === "video") return renderHelpVideo(media);
  };

  return (
    <HelpPanelRoot
      {...rest}
      className={className}
      style={style}
      transition={isPortrait ? "slide-in-and-out-from-bottom" : "slide-in-and-out-from-right"}
    >
      <PanelBox borderRadius={isPortrait ? 0 : undefined} appearance={isPortrait ? "borderless" : "inactive"}>
        <TopButtons>
          {node.ifInheritedMedia(
            "HelpAudio",
            "Audio",
          )((media) => (
            <LeftSpeakerButton src={node.optionalInheritedMedia("HelpAudio", "Audio")} />
          ))}
          <CloseButton onClick={onCloseClick}>
            <Image src={resource("images/Button_Close_Symbol.svg")} />
          </CloseButton>
        </TopButtons>
        <ContentScroller>
          {node.ifInheritedText("Help")((text) => (
            <HelpText>{text}</HelpText>
          ))}
          {node.ifInheritedMedia("Help1", { image: "HelpImage", video: "HelpVideo" })(renderHelpMedia)}
          {node.ifInheritedMedia("Help2", { image: "HelpImage", video: "HelpVideo" })(renderHelpMedia)}
        </ContentScroller>
      </PanelBox>
    </HelpPanelRoot>
  );
};

HelpPanel.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  node: PropTypes.object,
  onCloseClick: PropTypes.func,
};

export default HelpPanel;
