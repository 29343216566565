import styled from "styled-components";
import Box from "../box";
import Layers from "../layers";

export const AudioTextButtonRoot = styled.div``;

export const StyledBox = styled(Box).attrs((props) => ({ appearance: "active" }))`
  width: 100%;
  height: 100%;
  .box-content {
    align-items: flex-start;
  }
`;

export const StyledLayers = styled(Layers)`
  pointer-events: none;
`;
