import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import Classes from "../../../helpers/classes";
import Localization from "../../../helpers/localization";
import Strings from "../../../helpers/strings";
import { useLanguage } from "../../hooks/use-language";
import Button from "../button";

// A button that cycles between the available languages
// and updates its visual state to reflect the current language
const LanguageToggleButton = memo(({ className, renderContent, ...rest }) => {
  useLanguage(); // Update when the language changes even if our props don't change

  const onClick = useCallback(() => {
    Localization.switchToNextLanguage();
  }, []);

  const render = () => {
    if (renderContent) return renderContent(Localization.getCurrentLanguage(), Localization.getNextLanguage());
    return Strings.localized("LanguageToggleButtonTitle", Localization.getNextLanguage());
  };

  return (
    <Button {...rest} className={Classes.build("language-toggle", className)} onClick={onClick}>
      {render()}
    </Button>
  );
});

LanguageToggleButton.propTypes = {
  className: PropTypes.string,
  renderContent: PropTypes.func,
};

export default LanguageToggleButton;
