import PropTypes from "prop-types";
import { memo } from "react";
import Classes from "../../helpers/classes";

const DevBuildIndicator = memo(({ active }) => {
  return <div className={Classes.build("dev-build-indicator", "unclickable", { active })} />;
});

DevBuildIndicator.propTypes = {
  active: PropTypes.bool,
};

export default DevBuildIndicator;
