import { memo } from "react";
import { Route, useLocation } from "react-router";
import { Config, RouteTransitioner, useData, useDebug, useRemoteState } from "ripple";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import { backgroundColor } from "./common";
import AppOverlay from "./components/app-overlay";
import AppUnderlay from "./components/app-underlay";
import GracePopup from "./components/grace-popup";
import { useIsMobileVersion } from "./hooks/use-is-mobile-version";
import CardsActivityPage from "./pages/cards-activity-page";
import MobileHomePage from "./pages/mobile-home-page";
import MobileMainPage from "./pages/mobile-main-page";
import QuestionsActivityPage from "./pages/questions-activity-page";
import WebHomePage from "./pages/web-home-page";
import WebResourcePage from "./pages/web-resource-page";
/* APP_PAGE_IMPORTS_INSERTION_POINT */

const StyledRippleRoot = createGlobalStyle`
  #app .root {
    background-color: ${backgroundColor};
  }
`;

const StyledAppRoot = styled.div`
  /* Customize app defaults here (fonts, etc.) */
  font-family: "ComicNeue-Bold";
  color: white;
`;

export const AppRootRouteTransitioner = styled(RouteTransitioner)`
  width: 100%;
  height: 100%;
`;

const AppRoot = memo(() => {
  const location = useLocation();
  const debug = useDebug();
  const data = useData();
  const remote = useRemoteState();
  const isMobileVersion = useIsMobileVersion();

  const themeName = Config.theme;
  const themeObject = Config.themes[themeName];
  if (typeof themeObject === "undefined") throw new Error(`No theme named '${Config.theme}'`);

  const theme = {
    debug,
    name: themeName,
    ...themeObject,
  };

  // By default, transition only occurs when the first path component changes.
  // However, this might not make sense in all apps. Feel free to change this
  // to match your needs! For example, if you want a transition to occur every
  // time the URL changes (including the query string), use
  // `location.pathname + location.search` as a key.
  const transitionKey = location.pathname.split("/")[1];

  // Do not render pages as long as the data isn't available, as most pages depend on it heavily.
  const protect = (Component) => data && (!Config.ipc.enabled || !!remote) && <Component />;

  return (
    <ThemeProvider theme={theme}>
      <StyledRippleRoot />
      <StyledAppRoot style={{ width: "100%", height: "100%" }}>
        <AppUnderlay />
        <AppRootRouteTransitioner transitionKey={transitionKey} location={location}>
          {isMobileVersion ? (
            <Route path="/home">{protect(MobileHomePage)}</Route>
          ) : (
            <Route path="/home">{protect(WebHomePage)}</Route>
          )}
          <Route path="/mobile-main/:id">{protect(MobileMainPage)}</Route>
          <Route path="/mobile-main">{protect(MobileMainPage)}</Route>
          <Route path="/web-resource/:id">{protect(WebResourcePage)}</Route>
          <Route path="/cards-activity/:id">{protect(CardsActivityPage)}</Route>
          <Route path="/questions-activity">{protect(QuestionsActivityPage)}</Route>
          {/* APP_ROUTES_INSERTION_POINT */}
        </AppRootRouteTransitioner>
        <GracePopup />
        <AppOverlay />
      </StyledAppRoot>
    </ThemeProvider>
  );
});

export default AppRoot;
