import { useRef } from "react";
import { Interaction } from "../../../ripple";
import { usePhidgetDigitalInput } from "./use-phidget-digital-input";

export const usePhidgetDigitalInputAsPushButton = (description, identification, onPress) => {
  const previousStateRef = useRef(false);
  usePhidgetDigitalInput(description, identification, (state) => {
    if (previousStateRef.current === false && state === true && !Interaction.blocked()) onPress();
    previousStateRef.current = state;
  });
};
