import { Button } from "ripple";
import styled from "styled-components";
import { yellowColor } from "../../common";

export const LanguageSwitcherRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5vh;
`;

export const LanguageButton = styled(Button)`
  font-size: 3vmin;
  text-transform: uppercase;
  color: ${(props) => (props.$active ? yellowColor : props.$color ?? "white")};
  text-decoration: ${(props) => (props.$active ? "underline" : "unset")};
  .hotspot {
    margin: -1.5vh;
  }
`;

export const LanguageVerticalSplitter = styled.div`
  height: 2.5vmin;
  width: 2px;
  background-color: ${(props) => props.$color ?? "white"};
  &:last-child {
    display: none;
  }
`;
