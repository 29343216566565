import { Text } from "ripple";
import styled from "styled-components";
import { standardGap, textColor } from "../../common";
import Box from "../box";

export const TextContentBoxRoot = styled(Box)`
  isolation: isolate;
  .box-content {
    display: flex;
    flex-direction: column;
  }
`;

export const Top = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid ${textColor};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

export const TopText = styled(Text)`
  margin: ${standardGap};
  font-size: 2.5vh;
  font-family: "ComicNeue-Regular";

  b,
  strong {
    font-family: "ComicNeue-Bold";
  }
`;

export const Bottom = styled.div`
  width: 100%;
  & > * {
    width: 100%;
  }
`;
