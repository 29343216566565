import styled from "styled-components";
import { standardQuestionLandscapeContentPadding } from "../../common";

export const ResponsiveContentRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.$width};
  height: 100%;
`;

export const MainContent = styled.div`
  flex: 1 1 0;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 ${(props) => props.$horizontalPadding};
  outline: ${(props) => (props.$debug ? "1px dashed rgba(0, 0, 255, 0.3)" : "none")};
`;

export const SidePanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 26vw;
  margin-left: -26vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  outline: ${(props) => (props.$debug ? "1px dashed rgba(0, 0, 255, 0.3)" : "none")};
`;

export const BottomPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${standardQuestionLandscapeContentPadding};
  outline: ${(props) => (props.$debug ? "1px dashed rgba(0, 0, 255, 0.3)" : "none")};
`;
