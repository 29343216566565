import { Button, Text } from "ripple";
import styled from "styled-components";
import { standardGap } from "../../../common";
import Box from "../../../components/box";
import Question from "../../../components/question";
import SpeakerButton from "../../../components/speaker-button";
import StandardImage from "../../../components/standard-image";

export const Type19QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentLayer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardImage = styled(StandardImage)`
  aspect-ratio: 1;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

export const CardBox = styled(Box).attrs((props) => ({ appearance: "active" }))`
  width: 100%;
  height: 100%;
  transition: all 250ms ease-in-out;
`;

export const CardTitleArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${standardGap};
`;

export const CardTitle = styled(Text)`
  font-size: 1.8vmax;
`;

export const CardSpeakerButton = styled(SpeakerButton)`
  .hotspot {
    margin: -1vmin;
  }
  pointer-events: auto;
`;

export const CardButton = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
