import _ from "lodash";

export const PATHS = {
  // It's important to put all core route paths in here else
  // they won't be excluded from analytics!
  corePaths: {
    analyticsPath: "/analytics",
    analyticsAdminPath: "/analytics-admin",
    analyticsHomePath: "/analytics-home",
    configPath: "/config",
    clientPath: "/client",
  },
  isCorePath: function (path) {
    return _.includes(Object.values(this.corePaths), path);
  },
};

export const RTL_LANGUAGES = [
  "ar", // Arabic
  "he", // Hebrew
  "fa", // Persian
  "ur", // Urdu
  "ps", // Pashto
  "sd", // Sindhi
  "yi", // Yiddish
];
