import { transparentize } from "polished";
import { Button, Revealer, Text } from "ripple";
import styled from "styled-components";
import { simbiozBlue } from "../../common";

export const GracePopupRevealer = styled(Revealer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Popup = styled.div`
  position: relative;

  background-color: white;
  border-radius: 20px;
  min-width: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 7px 50px 0px rgba(0, 0, 0, 0.15);

  padding: 60px;
`;

export const Title = styled(Text)`
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
`;

export const Countdown = styled.span`
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 26px;
  color: #cccccc;
`;

export const PopupButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PopupButton = styled(Button)`
  color: ${simbiozBlue};
  font-size: 22px;
  margin: 10px;
  padding: 20px;
  background-color: ${transparentize(0.85, simbiozBlue)};
  border-radius: 6px;
`;
