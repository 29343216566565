import _ from "lodash";
import { useCallback, useEffect, useRef } from "react";
import Log from "../../../helpers/log";
import Sequencer from "../../../sequencer";
import { useLatestRef } from "../internal/use-latest-ref";

class Stagger {
  steps = [];
  state = null;

  constructor(name = "[unnamed]") {
    this.name = name;
  }

  add(step) {
    const existingStepIndex = _.findIndex(this.steps, (s) => s.id === step.id);

    if (existingStepIndex !== -1) {
      this.steps[existingStepIndex] = step;
    } else {
      this.steps.push(step);
    }

    if (this.state === "enter") {
      step.resetToExit();
    } else if (this.state === "exit") {
      step.resetToEnter();
    }
  }

  remove(step) {
    _.remove(this.steps, (s) => s.id === step.id);
  }
}

function maxValue(arr, predicate) {
  const maxObj = _.maxBy(arr, predicate);
  if (maxObj) return predicate(maxObj);
  return 0;
}

function createNode(step) {
  return {
    step: step,
    parent: null, // Will be filled on tree building
    children: {}, // Will be filled on tree building
  };
}

function sortAndGroupNodes(nodes, sort) {
  const nodesWithOrder = _.filter(nodes, (node) => !_.isNil(node.step.order));
  let nodesWithOrderSorted = _.sortBy(nodesWithOrder, (node) => node.step.order);
  if (sort === "reverse") nodesWithOrderSorted = _.reverse(nodesWithOrderSorted);
  const nodeGroupsWithOrder = Object.values(_.groupBy(nodesWithOrderSorted, (node) => node.step.order));

  let nodesWithoutOrder = _.filter(nodes, (node) => _.isNil(node.step.order));
  if (sort === "random") nodesWithoutOrder = _.shuffle(nodesWithoutOrder);
  if (sort === "reverse") nodesWithoutOrder = _.reverse(nodesWithoutOrder);
  const nodeGroupsWithoutOrder = _.map(nodesWithoutOrder, (node) => [node]);

  return [...nodeGroupsWithOrder, ...nodeGroupsWithoutOrder];
}

function buildNodeTree(steps, sort) {
  // We create special nodes to build our tree to avoid mutating
  // the steps and to keep the logic as lightweight as possible.
  const nodes = _.map(steps, createNode);

  // We use a single "fake" root node to keep the structure coherent for processing.
  // Any steps not contained in a parent are considered root steps.
  const nodesWithNoParent = _.filter(nodes, (node) => !node.step.parentId);
  const rootNode = { children: sortAndGroupNodes(nodesWithNoParent, sort) };

  // Actually build the tree
  const nodesByParentId = _.groupBy(nodes, (node) => node.step.parentId);
  _.each(nodes, (node) => {
    const rawChildren = nodesByParentId[node.step.id];
    node.children = sortAndGroupNodes(rawChildren, node.step.sort || sort);
  });

  return rootNode;
}

function runChildrenRecursively(node, stagger, options, onComplete) {
  const { interval, timeMultiplier, operation, debug } = options;

  const sequencer = new Sequencer();
  const cancels = [];

  // For each array in children (each of which corresponds to a batch of nodes to run simultaneously)
  _.each(node.children, (simultaneousNodes) => {
    // When multiple nodes in the same group specify a delay, we use the highest specified delay.
    // Also, when animating in "reverse" mode, use delays in reverse also, which better matches expectations
    // (observed delays between step animations are the same both ways). Disable this condition to see what I mean!
    const delayBeforeStep = maxValue(simultaneousNodes, (node) =>
      options.sort === "reverse" ? node.step.delayAfter : node.step.delayBefore,
    );
    const delayAfterStep = maxValue(simultaneousNodes, (node) =>
      options.sort === "reverse" ? node.step.delayBefore : node.step.delayAfter,
    );

    // Either take the step's specified interval or the stagger's interval if not specified
    const actualInterval = (node.step && node.step.interval) || interval;

    sequencer.doWait(actualInterval * timeMultiplier);
    sequencer.doWait(delayBeforeStep * timeMultiplier);

    // Run the group, which is composed of one or more nodes to run simultaneously
    sequencer.doWaitForRelease((release) => {
      // Track the number of completed "simultaneous" nodes
      // in the group and release when they have all completed
      let doneCount = 0;
      const done = () => {
        doneCount += 1;
        if (doneCount === simultaneousNodes.length) release();
      };

      _.each(simultaneousNodes, (node) => {
        // Only run the step if the step is still in our stagger.
        // This guards against a semi-rare case where the staggered components
        // are unmounted and there is a running sequencer operation that's referencing it.
        // This can occur because the sequencer's `clear()` method cannot always cancel the
        // currently running task depending on the task type.
        if (!stagger.steps.includes(node.step)) return;

        if (debug) Log.debug(`Stagger '${stagger.name}' running step '${node.step.name || "[unnamed]"}'`);

        // 1. Run one of the steps in the group
        operation(node.step);

        // 2. Run its children, and complete the group only when all steps' children finish running
        cancels.push(runChildrenRecursively(node, stagger, options, done));
      });
    });

    sequencer.doWait(delayAfterStep * timeMultiplier);
  });

  sequencer.do(onComplete);

  // So that the caller can stop all this madness!
  return () => {
    sequencer.clear();
    _.each(cancels, (c) => c());
  };
}

/**
 * Sets up a staggered animation, coordinating the successive appearance of
 * multiple components with specific animations.
 */
export const useStagger = ({
  name = "[unnamed]",
  automatic = true,
  sort = "sequential", // NOTE: Setting the sort order to "reverse" also reverses delays (which should match expectations)
  interval = 100, // The interval between each stagger step animation start
  delayBefore: delayBeforeStagger = 0, // An interval to wait before starting the whole stagger animation
  delayAfter: delayAfterStagger = 0, // An interval to wait before completing the whole stagger animation
  onEnter = () => {},
  onExit = () => {},
  debug = false,
} = {}) => {
  const staggerSequencerRef = useRef(new Sequencer());
  const childrenCancelRef = useRef(() => {});
  const staggerRef = useRef(new Stagger(name));
  const mostRecentOnEnterRef = useLatestRef(onEnter);
  const mostRecentOnExitRef = useLatestRef(onExit);

  const cancel = useCallback(() => {
    // Stop any currently ongoing stagger (if for example we're
    // toggling quickly between "enter" and "exit")
    staggerSequencerRef.current.clear();

    // Also stop the inner operations created by this just-cancelled stagger
    childrenCancelRef.current();
    childrenCancelRef.current = () => {};

    if (debug) Log.info(`Stagger '${name}' cancelled!`);
  }, [debug, name]);

  const animate = useCallback(
    (state, { sort: sortOverride, timeMultiplier = 1, onComplete }, resetOperation, operation) => {
      const stagger = staggerRef.current;
      const steps = stagger.steps;

      const actualSort = sortOverride || sort;

      const rootNode = buildNodeTree(steps, actualSort);
      if (debug) Log.debug(`Stagger '${name}' ${JSON.stringify(rootNode, null, 2)}`);

      const staggerSequencer = staggerSequencerRef.current;

      if (!staggerSequencer.isEmpty()) cancel();

      // Track the last requested state
      staggerSequencer.do(() => (stagger.state = state));

      // Notify observers of any state change
      staggerSequencer.do(() => {
        const onEnter = (...args) => mostRecentOnEnterRef.current(...args);
        const onExit = (...args) => mostRecentOnExitRef.current(...args);
        if (onEnter && state === "enter") onEnter();
        if (onExit && state === "exit") onExit();
      });

      // We reset the state of the steps themselves so that they all start in a "fresh" visual state. Without this, the
      // steps animate from their current visual state if the stagger exit is skipped and another enter is requested
      // (such as in `Revealer`).
      staggerSequencer.do(() => _.each(steps, resetOperation));

      // Wait before starting the stagger animation if a delay was specified
      const actualDelayBeforeStagger = actualSort === "reverse" ? delayAfterStagger : delayBeforeStagger;
      if (delayBeforeStagger) staggerSequencer.doWait(actualDelayBeforeStagger * timeMultiplier);

      // Run the child nodes from the root node recursively
      // (the root node itself is just a container and has nothing to animate)
      staggerSequencer.doWaitForRelease((release) => {
        childrenCancelRef.current = runChildrenRecursively(
          rootNode,
          stagger,
          { sort: actualSort, interval, timeMultiplier, operation, debug },
          release,
        );
      });

      // Wait before starting the stagger animation if a delay was specified
      const actualDelayAfterStagger = actualSort === "reverse" ? delayBeforeStagger : delayAfterStagger;
      if (delayAfterStagger) staggerSequencer.doWait(actualDelayAfterStagger * timeMultiplier);

      if (onComplete) staggerSequencer.do(onComplete);
      if (debug) staggerSequencer.do(() => Log.debug(`Stagger '${name}' completed!`));
    },
    [
      cancel,
      debug,
      delayBeforeStagger,
      delayAfterStagger,
      interval,
      mostRecentOnEnterRef,
      mostRecentOnExitRef,
      name,
      sort,
    ],
  );

  const enter = useCallback(
    (options = {}) =>
      animate(
        "enter",
        options,
        (step) => step.resetToEnter(),
        (step) => step.enter(),
      ),
    [animate],
  );

  const exit = useCallback(
    (options = {}) =>
      animate(
        "exit",
        options,
        (step) => step.resetToExit(),
        (step) => step.exit(),
      ),
    [animate],
  );

  const toggle = useCallback(
    (options) => (staggerRef.current.state === "enter" ? exit(options) : enter(options)),
    [enter, exit],
  );

  useEffect(() => {
    if (automatic) enter(); // On mount, enter (if automatic)
    return () => cancel(); // On unmount, cancel everything
  }, [automatic, enter, cancel]);

  // Add external API to the stagger instance so that we can manually control it if required
  staggerRef.current.enter = enter;
  staggerRef.current.exit = exit;
  staggerRef.current.toggle = toggle;

  return staggerRef.current;
};
