import PropTypes from "prop-types";
import { createContext, useCallback, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Route, useLocation } from "react-router";
import { PATHS } from "../core/constants";
import Classes from "../helpers/classes";
import Config from "../helpers/config";
import Env from "../helpers/env";
import Navigator from "../helpers/navigator";
import AnalyticsPage from "../react/pages/analytics-page";
import ClientPage from "../react/pages/client-page";
import ConfigPage from "../react/pages/config-page";
import StartupPage from "../react/pages/startup-page";
import { useLanguage } from "../ripple";
import { RootErrorBoundary } from "./components/error-boundary";
import MediaTransitioner from "./components/media-transitioner";
import MultiClickButton from "./components/multi-click-button";
import RouteTransitioner from "./components/route-transitioner";
import DebugOverlay from "./core/debug-overlay";
import DevBuildIndicator from "./core/dev-build-indicator";
import Flags from "./core/flags";
import PathBar from "./core/path-bar";
import TouchFeedback from "./core/touch-feedback";
import AnalyticsAdminPage from "./pages/analytics-admin-page";
import AnalyticsHomePage from "./pages/analytics-home-page";

export const RootBackgroundContext = createContext();

const Root = ({ appRoot }) => {
  const CustomAppRoot = appRoot;
  const location = useLocation();
  const language = useLanguage();
  const [background, setBackground] = useState(null);

  const flags = useSelector((store) => store.flags);
  const interaction = useSelector((store) => store.interaction);

  const lastBackgroundRef = useRef(null);
  const replaceBackground = useCallback((newBackground) => {
    // Redundant calls to `replaceBackground` are OK;
    // they are necessary to catch all relevant transition cases (see `Page`).
    // Here, we simply compare the current background with the new one to avoid
    // an infinite render loop when and if those redundant calls come in.
    if (newBackground === lastBackgroundRef.current) return;
    lastBackgroundRef.current = newBackground;
    setBackground(newBackground);
  }, []);

  const onAnalyticsButtonClick = useCallback(() => {
    if (location.pathname !== PATHS.corePaths.analyticsPath) {
      Navigator.navigate({ path: PATHS.corePaths.analyticsPath });
    } else {
      Navigator.goBack();
    }
  }, [location.pathname]);

  const renderNoRoute = () => {
    throw new Error(`No route matches path '${location.pathname}'`);
  };

  const renderContent = () => (
    <>
      <MediaTransitioner
        className="background-media-transitioner"
        src={background}
        classNames={Config.rootBackground.transition}
        instantExit={Config.rootBackground.instantExit}
        mediaProps={{
          autoPlay: true,
          loop: true,
          scaling: "fill",
          volume: Config.audio.global.volumeScale * Config.audio.groups.rootBackground.volumeScale,
          muted: Config.audio.groups.rootBackground.muted,
        }}
      />
      <RootBackgroundContext.Provider value={replaceBackground}>
        <RouteTransitioner
          className="page-container"
          transitionKey={PATHS.isCorePath(location.pathname) ? location.pathname : "app-root"}
          location={location}
        >
          <Route exact path="/">
            <StartupPage />
          </Route>
          {/** Core page routes */}
          <Route path={PATHS.corePaths.analyticsPath}>
            <AnalyticsPage />
          </Route>
          <Route path={PATHS.corePaths.analyticsAdminPath}>
            <AnalyticsAdminPage />
          </Route>
          <Route path={PATHS.corePaths.analyticsHomePath}>
            <AnalyticsHomePage />
          </Route>
          <Route path={PATHS.corePaths.configPath}>
            <ConfigPage />
          </Route>
          <Route path={PATHS.corePaths.clientPath}>
            <ClientPage />
          </Route>
          {/** Custom app root (which defines its own routes) */}
          <Route>
            <CustomAppRoot />
          </Route>
          <Route>{renderNoRoute}</Route>
        </RouteTransitioner>
      </RootBackgroundContext.Provider>
      <div id="maximizer-portal" className="maximizer-portal" />
    </>
  );

  return (
    <div
      className={Classes.build(
        "root",
        `language-${language}`,
        { "block-interaction": interaction.block },
        { "no-select": Config.interaction.mode === "kiosk" },
        { debug: flags.debug },
        { bounds: flags.bounds },
      )}
      style={{
        ...(() => {
          // Optional fixed content size
          if (!Config.contentSize) return {};
          const contentSize = Config.contentSize.split("x");
          const contentWidth = parseInt(contentSize[0]);
          const contentHeight = parseInt(contentSize[1]);
          return {
            width: contentWidth,
            minWidth: contentWidth,
            height: contentHeight,
            minHeight: contentHeight,
            transform: Config.rootTransform,
          };
        })(),
      }}
    >
      <RootErrorBoundary>{renderContent()}</RootErrorBoundary>
      {Env.isDesktop && (
        <MultiClickButton
          clicks={6}
          timeout={1000}
          className="analytics-hidden-button"
          onMultiClick={onAnalyticsButtonClick}
        />
      )}
      <PathBar />
      {Env.isDesktop && <TouchFeedback />}
      <Toaster />
      <Flags />
      <DevBuildIndicator active={__DEV__} />
      {Env.isDesktop && <DebugOverlay />}
    </div>
  );
};

Root.propTypes = {
  appRoot: PropTypes.object.isRequired,
};

export default Root;
