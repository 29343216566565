import { differenceInMilliseconds } from "date-fns";
import _ from "lodash";
import Iterate from "../../../../helpers/iterate";
import AnalyticsHelper from "../../analytics-helper";

function calculate(preprocessed) {
  if (preprocessed.sessions.length === 0) return 0;
  const meansWithinSessions = _.map(preprocessed.sessions, (events) => {
    // Calculate the time intervals between each pair of navigation events in a session
    const navigationEvents = _.filter(events, (e) => e.name === "navigation");
    if (navigationEvents.length < 2) return 0;
    return Iterate.mapPairwise(navigationEvents, (eventA, eventB) => {
      const interval = differenceInMilliseconds(eventB.time, eventA.time);
      // Ignore instant navigations (redirects). It's alright to use a large interval
      // because the stats aren't calculated with millisecond precision anyway.
      return interval > 1000 ? interval : null;
    });
  });
  return _.mean(_.flatMap(meansWithinSessions));
}

export default function (preprocessed) {
  this.name = "AverageTimePerPage";
  this.format = AnalyticsHelper.formatAsDuration;
  this.value = calculate(preprocessed);
}
