import PropTypes from "prop-types";
import { useCallback, useRef } from "react";
import { useParams } from "react-router";
import { Color, DragPreview, resource, useData } from "ripple";
import Dropzone from "ripple/scripts/react/components/drop/dropzone";
import ImageAudioDroppable from "../../../components/image-audio-droppable";
import ImageDragPreview from "../../../components/image-drag-preview";
import ImageLayer from "../../../components/image-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import SpeakerButtonLayer from "../../../components/speaker-button-layer";
import SquareItemGridPanelContent from "../../../components/square-item-grid-panel-content";
import { useDragItemsOnTargetsQuestionLogic } from "../../../hooks/use-drag-items-on-targets-question-logic";
import { QuestionPanelContent } from "../styled";
import {
  BorderImageLayer,
  ContentLayers,
  ItemsLayer,
  Quadrant,
  Quadrants,
  StyledFeedbackLayer,
  Type11QuestionRoot,
} from "./styled";

// Because we can't override feColorMatrix (which contains the glow's color)
// using CSS, we have to do it using JavaScript after the image loads.
const ColoredBorderImageLayer = ({ color, ...rest }) => {
  const divRef = useRef(null);

  const onLoad = useCallback(() => {
    const colorMatrix = divRef.current.querySelector("feColorMatrix");
    const { r, g, b } = Color.hexToRgb(color);
    colorMatrix.setAttribute("values", `0 0 0 0 ${r / 255}   0 0 0 0 ${g / 255}   0 0 0 0 ${b / 255}  0 0 0 1 0`);
  }, [color]);

  return (
    <div ref={divRef}>
      <BorderImageLayer {...rest} onLoad={onLoad} />
    </div>
  );
};

ColoredBorderImageLayer.propTypes = {
  color: PropTypes.string,
};

const Type11Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const color = node.wantedInheritedText("Color")?.trim();

  const targets = node.children.find((c) => c.semantic === "Type11QuestionQuadrants").children;
  const items = node.children.find((c) => c.semantic === "Type11QuestionItems").children;

  const { feedbacks, getItemTargets, onOver, overs, onDrop, drops } = useDragItemsOnTargetsQuestionLogic(
    targets,
    items,
    onComplete,
  );

  const renderPanelContent = (location) => {
    return (
      <QuestionPanelContent $location={location}>
        <SquareItemGridPanelContent items={items} location={location}>
          {(item) => (
            <ImageAudioDroppable
              node={item}
              disabled={
                Object.values(drops)
                  .flatMap((d) => d)
                  .filter((i) => i === item).length === getItemTargets(item).length
              }
            />
          )}
        </SquareItemGridPanelContent>
      </QuestionPanelContent>
    );
  };

  const renderMiniItem = (item) => <ImageDragPreview key={item.id} image={item.wantedMedia("Image", "Image")} />;

  const renderQuadrant = (targetNumber) => {
    const index = targetNumber - 1;
    const target = targets[index];
    return (
      <Quadrant
        style={{
          top: targetNumber === 1 || targetNumber === 2 ? 0 : "unset",
          left: targetNumber === 2 || targetNumber === 3 ? 0 : "unset",
          bottom: targetNumber === 3 || targetNumber === 4 ? 0 : "unset",
          right: targetNumber === 1 || targetNumber === 4 ? 0 : "unset",
        }}
      >
        <Layers>
          <ColoredBorderImageLayer
            src={resource("images/Quart-Frame-1.svg")}
            $index={index}
            $over={overs[target.id]}
            $color={color} /* For styled-component */
            color={color} /* For ColoredBorderImageLayer component */
          />
          <ContentLayers $mask={resource(`images/Quart-Mask-${targetNumber}.svg`)}>
            <ImageLayer src={target.wantedMedia("Image", "Image")} style={{ border: "2px solid red" }} />
            <ItemsLayer $quadrantNumber={targetNumber}>{drops[target.id].map(renderMiniItem)}</ItemsLayer>
            <Dropzone id={target} onOver={onOver} onDrop={onDrop} disabled={!!feedbacks[target.id]} />
            <StyledFeedbackLayer feedback={feedbacks[target.id]} $quadrantNumber={targetNumber} />
          </ContentLayers>
        </Layers>
        <SpeakerButtonLayer src={target.optionalMedia("Audio", "Audio")} corner={targetNumber} inset="5vmin" />
      </Quadrant>
    );
  };

  const renderDragPreview = useCallback(renderMiniItem, []);

  return (
    <Type11QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent
        sidePanelContent={renderPanelContent("side")}
        bottomPanelContent={renderPanelContent("bottom")}
      >
        <Quadrants>
          {renderQuadrant(1)}
          {renderQuadrant(2)}
          {renderQuadrant(3)}
          {renderQuadrant(4)}
        </Quadrants>
      </ResponsiveContent>
      <DragPreview render={renderDragPreview} />
    </Type11QuestionRoot>
  );
};

Type11Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type11Question;
