import Log from "./log";

const NOTE_ON = 0x90;
const NOTE_OFF = 0x80;

class MIDI {
  static output = null;

  static ensureOutput(description) {
    if (MIDI.output) return true;
    Log.warn(`Failed to send message '${description}' because there is no connected MIDI output`);
    return false;
  }

  static initialize() {
    navigator.requestMIDIAccess?.().then(function (access) {
      // For now, we assume that there will be a single connected MIDI device for simplicity
      MIDI.output = Array.from(access.outputs.values())[0];
    });
  }

  static noteOn(note) {
    if (!MIDI.ensureOutput(`NOTE ON ${note}`)) return;
    MIDI.output.send([NOTE_ON, note, 0x7f]);
  }

  static noteOff(note) {
    if (!MIDI.ensureOutput(`NOTE OFF ${note}`)) return;
    MIDI.output.send([NOTE_OFF, note, 0x40]);
  }
}

export default MIDI;

window.ripple = { ...(window.ripple || {}), midi: MIDI };
