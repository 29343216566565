import PropTypes from "prop-types";
import { MediaSrcPropType, TextSrcPropType } from "ripple";
import Mascot from "../mascot";
import { CustomSpeakerButton, Text, TopButtons } from "./styled";

const MascotHome = ({ image, audio, text, onShouldHide, autoPlay, ...rest }) => {
  return (
    <Mascot disableExitStagger={true} preserveChildren src={image} {...rest}>
      {text && (
        <>
          <TopButtons>
            <CustomSpeakerButton src={audio} />
          </TopButtons>
          {<Text>{text}</Text>}
        </>
      )}
    </Mascot>
  );
};

MascotHome.propTypes = {
  audio: MediaSrcPropType,
  image: MediaSrcPropType,
  text: TextSrcPropType,
  onShouldHide: PropTypes.func,
  autoPlay: PropTypes.bool,
};

export default MascotHome;
