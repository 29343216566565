import _ from "lodash";
import PropTypes from "prop-types";
import { forwardRef, memo, useLayoutEffect, useRef } from "react";
import { mergeRefs } from "react-merge-refs";
import Audio from "../../../helpers/audio";
import Classes from "../../../helpers/classes";
import Env from "../../../helpers/env";
import Link from "../../../helpers/link";
import resource from "../../../helpers/resource";
import Strings from "../../../helpers/strings";
import { TextSrcPropType } from "../../../logic/prop-types";
import { useIsCurrentLanguageRtl } from "../../hooks/internal/use-is-current-language-rtl";

/**
 * A component that renders HTML provided as a string.
 * Use this for "content" strings such as descriptions.
 */
const Paragraphs = memo(
  forwardRef(({ style, className, children, enableLinks, onLinkClick, localized }, ref) => {
    children = localized ? Strings.localized(localized) : children;

    const isCurrentLanguageRtl = useIsCurrentLanguageRtl();

    const divRef = useRef(null);

    const isString = children ? typeof children === "string" : false;
    const isReactNode = !isString;

    // Replace `<a href>` with an onclick handler that delegates links to custom logic
    useLayoutEffect(() => {
      const links = divRef.current.querySelectorAll("a");
      if (!links || links.length === 0) return;
      _.each(links, (a) => {
        if (!a.href) return;
        const href = a.href;
        a.removeAttribute("href");
        a.onclick = () => {
          // Playing an empty audio file before opening links works around a super-weird
          // issue on iOS where opening a SafariViewController without a sound would mute
          // all sound app-wide for about 30s.
          Audio.discrete("silence").play(resource("audio/silence.mp3"), { log: false });
          if (onLinkClick) {
            // It's more predictable to not get the origin automatically added by the
            // browser in front of the actual thing we typed in the CMS when handling
            // link clicks manually.
            const rawHref = href
              .replace(`${window.location.origin}/`, "") // Remove the base URL
              .replace(/^.*\/|\.[^.]*$/g, ""); // Remove the current path (AKA "basename")

            onLinkClick(rawHref);
          } else {
            Link.open(href);
          }
        };
      });
    }, [children, onLinkClick]);

    const actualClassName = Classes.build("ripple-paragraphs", className, {
      empty: !children,
      "links-disabled": !(enableLinks || Env.isRCC), // In RCC, links open in an in-app browser
      rtl: isCurrentLanguageRtl,
    });

    let actualChildren = undefined;
    if (isReactNode) actualChildren = children;

    return (
      <div
        className={actualClassName}
        style={style}
        ref={mergeRefs([ref, divRef])}
        {...(!isReactNode ? { dangerouslySetInnerHTML: { __html: children } } : {})}
      >
        {actualChildren}
      </div>
    );
  }),
);

Paragraphs.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([TextSrcPropType, PropTypes.node]),
  enableLinks: PropTypes.bool,
  onLinkClick: PropTypes.func,
  localized: PropTypes.string,
};

export default Paragraphs;
