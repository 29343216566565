import PropTypes from "prop-types";
import { useEffect } from "react";
import { Maths, MediaSrcPropType, TextSrcPropType } from "ripple";
import { useGlobalAudioPlayer } from "../../hooks/use-global-audio-player";
import Mascot from "../mascot";
import QuestionMascotContainer from "../question-mascot-container";
import { NextIcon, NextText, NextYellowButton, StarContainer, StarImage, SuccessText, Top } from "./styled";

const MascotSuccess = ({
  starImage,
  image,
  text,
  audio,
  nextButtonText,
  onShouldHide,
  onNextQuestionClick,
  show,
  ...rest
}) => {
  const { play, stop } = useGlobalAudioPlayer();

  useEffect(() => {
    if (show) {
      setTimeout(() => play(audio), 750);
    } else {
      stop();
    }
    return () => stop();
  }, [show, audio, play, stop]);

  const fontRatio = 1 - Maths.clamp(Maths.ratio(text?.length ?? 20, 40, 60), 0, 1);
  const fontSize = Maths.interpolate(fontRatio, 2.5, 4.5) + "vh";

  return (
    <QuestionMascotContainer show={show}>
      <Mascot disableExitStagger={true} preserveChildren src={image} {...rest}>
        <Top>
          <StarContainer>{show && <StarImage src={starImage} />}</StarContainer>
          <SuccessText $fontSize={fontSize}>{text}</SuccessText>
        </Top>
        <NextYellowButton onClick={onNextQuestionClick}>
          <NextText>{nextButtonText}</NextText>
          <NextIcon />
        </NextYellowButton>
      </Mascot>
    </QuestionMascotContainer>
  );
};

MascotSuccess.propTypes = {
  starImage: MediaSrcPropType,
  image: MediaSrcPropType,
  text: TextSrcPropType,
  audio: MediaSrcPropType,
  nextButtonText: TextSrcPropType,
  onShouldHide: PropTypes.func,
  onNextQuestionClick: PropTypes.func,
  show: PropTypes.bool,
};

export default MascotSuccess;
