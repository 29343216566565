import { Promise } from "es6-promise";

// IMPORTANT: In the various Load functions,
// pass { credentials: "include" } in fetchOptions to perform CORS requests.

export default class Load {
  /**
   * Load an image from the specified URL
   * @param {string} url - The URL to load the image from
   * @returns {Promise}
   */
  static image(url) {
    return new Promise(function (resolve, reject) {
      // eslint-disable-next-line no-restricted-globals
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.onload = () => resolve(image);
      image.onerror = (error) => reject(error);
      image.src = url;
    });
  }

  static imageData(url, type = null) {
    if (!url) return null;

    type =
      type ?? (url.endsWith(".jpg") || url.endsWith(".jpeg"))
        ? "image/jpeg"
        : url.endsWith(".png")
          ? "image/png"
          : url.endsWith(".webp")
            ? "image/webp"
            : null;

    if (!type) throw new Error("Could not infer image content type from URL, please provide content type explicitly!");

    return Load.image(url).then((img) => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const context = canvas.getContext("2d");
      context.drawImage(img, 0, 0, img.width, img.height);

      return canvas.toDataURL(type, 100);
    });
  }

  /**
   * Load text from the specified URL
   * @param {string} url - The URL to load the text from
   * @param {object} fetchOptions - Standard Fetch API options (optional)
   * @returns {Promise}
   */
  static text(url, fetchOptions) {
    return fetch(url, fetchOptions).then((response) => response.text());
  }

  /**
   * Loads and parses JSON into an object from the JSON at the specified URL
   * @param {string} url - The URL to load the JSON object from
   * @param {object} fetchOptions - Standard Fetch API options (optional)
   * @returns {Promise}
   */
  static json(url, fetchOptions) {
    return Load.text(url, fetchOptions).then((t) => JSON.parse(t));
  }
}
