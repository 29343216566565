import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { Route, Switch, useLocation } from "react-router";
import Transitioner from "../transitioner";

const RouteTransitioner = memo(({ children, ...rest }) => {
  const location = useLocation();
  const renderNoRoute = useCallback(() => {
    throw new Error(`No route matches path '${location.pathname}'`);
  }, [location.pathname]);
  return (
    <Transitioner transitionKey={location.pathname} {...rest}>
      {/* If we don't provide `location` to the Switch, `componentDidMount`
          is called twice for class components. Read this for details:
          https://github.com/reactjs/react-transition-group/issues/79#issuecomment-334915988
          https://github.com/reactjs/react-transition-group/issues/136 */}
      <Switch location={location}>
        {children}
        <Route>{renderNoRoute}</Route>
      </Switch>
    </Transitioner>
  );
});

RouteTransitioner.propTypes = {
  children: PropTypes.node, // Routes for the internal Switch
  // See `Transitioner` for additional required and optional props
};

export default RouteTransitioner;
