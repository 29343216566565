const FlagsActions = {
  TOGGLE_DEBUG_MODE: "@@local/TOGGLE_DEBUG_MODE",
  toggleDebugMode: () => {
    return { type: FlagsActions.TOGGLE_DEBUG_MODE };
  },
  TOGGLE_BOUNDS_MODE: "@@local/TOGGLE_BOUNDS_MODE",
  toggleBoundsMode: () => {
    return { type: FlagsActions.TOGGLE_BOUNDS_MODE };
  },
  TOGGLE_DATA_AUTO_RELOAD: "@@local/TOGGLE_DATA_AUTO_RELOAD",
  toggleDataAutoReload: () => {
    return { type: FlagsActions.TOGGLE_DATA_AUTO_RELOAD };
  },
  TOGGLE_PATH_BAR: "@@local/TOGGLE_PATH_BAR",
  togglePathBar: () => {
    return { type: FlagsActions.TOGGLE_PATH_BAR };
  },
  UPDATE_MODIFIED: "@@local/UPDATE_MODIFIED",
  setModified: (modified) => {
    return { type: FlagsActions.UPDATE_MODIFIED, modified };
  },
};

export default FlagsActions;
