import { addDays, formatISO, isEqual, parseISO, startOfDay, toDate } from "date-fns";
import _ from "lodash";

export default function (preprocessed) {
  const sessions = preprocessed.sessions;

  const sessionsGroupedByDay = _.groupBy(sessions, (s) => formatISO(startOfDay(s[0].time)));

  // Build an array of all days between the two dates
  const startDate = startOfDay(preprocessed.startDate);
  const endDate = startOfDay(preprocessed.endDate);
  const allDaysBetweenDates = (() => {
    const days = [];
    let currentDate = toDate(startDate);
    days.push(toDate(currentDate));
    while (!isEqual(currentDate, endDate)) {
      currentDate = addDays(currentDate, 1);
      days.push(toDate(currentDate));
    }
    return days;
  })();

  // Calculate the number of sessions for each day
  const counts = _.map(allDaysBetweenDates, (day) => {
    const session = _.find(sessionsGroupedByDay, (value, key) => isEqual(day, parseISO(key)));
    return session ? session.length : 0;
  });

  this.name = "SessionsPerDay";
  this.barLabel = "Sessions";
  this.value = _.map(counts, (count, index) => ({ label: "", value: count }));
}
