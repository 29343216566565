// A function that takes navigation options and returns new navigation options with at least a path
// (and optionally more) or nothing, in which case navigation falls back to the originally provided
// navigation options. See `Navigator.navigate()` and `PagesConfigurator` (in Ripple) for details.

const navDefinition = (nav) => {
  if (nav.node?.semantic === "Client") return { path: `/home` };

  // On mobile the information is displayed as a sheet in the main page, with no navigation
  if (nav.node?.semantic === "Resource") return { path: `/web-resource/${nav.node.id}` };

  // There is no navigation to themes in the web version
  if (nav.node?.semantic === "Theme") return { path: `/mobile-main/${nav.node.id}` };

  // When there is no stored progress (such as on the web or when nothing has been completed),
  // we navigate directly to a CardsActivity.
  if (nav.node?.semantic === "CardsActivity")
    return { path: `/cards-activity/${nav.node.id}`, replaceQuery: { initialGameId: nav.node.children[0].id } };

  // Direct navigation to Card (when resuming an activity on mobile)
  if (nav.node?.semantic === "Card")
    return { path: `/cards-activity/${nav.node.parent.parent.id}`, replaceQuery: { initialCardId: nav.node.id } };

  if (nav.node?.semantic === "QuestionsActivity") {
    // Navigate directly to the first question within the first game in the QuestionsActivity
    return navDefinition({ node: nav.node.children[0].children[0] });
  }

  if (nav.node?.semantic === "Game") {
    if (nav.node.parent.semantic === "CardsActivity") {
      return { path: `/cards-activity/${nav.node.parent.id}`, replaceQuery: { initialGameId: nav.node.id } };
    } else if (nav.node.parent.semantic === "QuestionsActivity") {
      // Navigate to the first question within the provided game
      return navDefinition({ node: nav.node.children[0] });
    }
  }

  if (nav.node?.semantic === "DragQuestion") return { path: `/questions-activity/drag/${nav.node.id}` };
  if (nav.node?.semantic === "FlipQuestion") return { path: `/questions-activity/flip/${nav.node.id}` };
  if (nav.node?.semantic === "ClickWordQuestion") return { path: `/questions-activity/click-word/${nav.node.id}` };
  if (nav.node?.semantic === "ClickWordImageQuestion")
    return { path: `/questions-activity/click-word-image/${nav.node.id}` };
  if (nav.node?.semantic === "ClickWordSentenceQuestion")
    return { path: `/questions-activity/click-word-sentence/${nav.node.id}` };
  if (nav.node?.semantic === "DragWordImageQuestion")
    return { path: `/questions-activity/drag-word-image/${nav.node.id}` };
  if (nav.node?.semantic === "DragWordSimpleQuestion")
    return { path: `/questions-activity/drag-word-simple/${nav.node.id}` };
  if (nav.node?.semantic === "DragWordComplexeQuestion")
    return { path: `/questions-activity/drag-word-complexe/${nav.node.id}` };

  const questionTypeMatch = nav.node?.semantic.match(/^Type(\d+)Question$/);
  if (questionTypeMatch) return { path: `/questions-activity/type-${questionTypeMatch[1]}/${nav.node.id}` };
};

export default navDefinition;
