import { useEffect, useRef } from "react";

// Note: This might not be necessary soon as the React team
// is considering adding this as a standard React hook:
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
