import * as faceapi from "face-api.js";
import { useEffect, useRef } from "react";
import { useAnimationFrame } from "../use-animation-frame";
import { useConstant } from "../use-constant";

import Env from "../../../helpers/env";
import Log from "../../../helpers/log";
import resource from "../../../helpers/resource";
import UserMedia from "../../../helpers/user-media";
import Point from "../../../types/point";
import Rect from "../../../types/rect";
import { useDebug } from "../use-debug";

function movingAverage(previousAverage, newValue, newValueTrustRatio) {
  return newValue * newValueTrustRatio + (previousAverage ?? newValue) * (1 - newValueTrustRatio);
}

function rectFromDetection(detection) {
  if (!detection) return null;
  return new Rect(detection.box.left, detection.box.top, detection.box.width, detection.box.height);
}

function drawRect(context, rect, color) {
  context.strokeStyle = color;
  context.beginPath();
  context.rect(rect.x, rect.y, rect.width, rect.height);
  context.stroke();
}

function getCameraImage(video) {
  if (video.videoHeight === 0) return;
  if (video.videoWidth === 0) return;

  const canvas = document.createElement("canvas");
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  // Render, but flip horizontally for a "mirror" effect, which
  // is closer to what we would expect for most use cases.
  const context = canvas.getContext("2d");
  context.translate(video.videoWidth, 0);
  context.scale(-1, 1);
  context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  context.scale(-1, 1);
  context.translate(-video.videoWidth, 0);

  return canvas;
}

function imageToData(image) {
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;
  const context = canvas.getContext("2d");
  context.drawImage(image, 0, 0, image.width, image.height);
  return context.getImageData(0, 0, image.width, image.height);
}

function dataToImage(imageData) {
  const canvas = document.createElement("canvas");
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  const context = canvas.getContext("2d");
  context.putImageData(imageData, 0, 0);
  return canvas;
}

export const useCamera = (
  getUserMediaOptions,
  outputCanvasRef,
  {
    onLoad = () => {},
    drawCompositeOperation = "source-over",
    beforeDraw = () => {},
    beforeDrawCompositeOperation = "source-over",
    afterDraw = () => {},
    afterDrawCompositeOperation = "source-over",
    alterImageData = null,
    snapFaceToNormalizedFrame = null,
  },
) => {
  const debug = useDebug();
  const actualGetUserMediaOptions = useConstant(() => getUserMediaOptions);
  const faceDetectionEnabled = !!snapFaceToNormalizedFrame;

  const video = useConstant(() => document.createElement("video"));
  video.muted = true;

  const faceApiLoadedRef = useRef(false);
  useEffect(() => {
    if (!faceDetectionEnabled) return;
    faceapi.nets.tinyFaceDetector.loadFromUri(resource("other")).then(() => (faceApiLoadedRef.current = true));
  }, [faceDetectionEnabled]);

  // Load the video
  const streamRef = useRef(null);
  useEffect(() => {
    if (Env.isRCC) return; // WKWebView does not support getUserMedia yet

    UserMedia.logVideoDevices(); // Helps to get a device ID for getUserMediaOptions at dev or deployment time

    UserMedia.getVideoStream(actualGetUserMediaOptions)
      .then((stream) => {
        streamRef.current = stream;
        video.srcObject = stream;
        video.onloadedmetadata = () => {
          onLoad();
          video.play();
        };
      })
      .catch((error) => Log.error(`Could not load camera! ${error.message}`));

    return () => streamRef.current?.getTracks().forEach((track) => track.stop());
  }, [actualGetUserMediaOptions, video, onLoad]);

  // For each frame, alter and draw the camera image on the canvas
  const faceFrameRef = useRef(null);
  const hysteresisFrameRef = useRef(null);
  useAnimationFrame(
    async () => {
      const cameraImage = getCameraImage(video);

      const faceDetection = await (async () => {
        if (!faceDetectionEnabled) return;
        if (!faceApiLoadedRef.current) return;
        if (!cameraImage) return;

        const result = await faceapi.detectSingleFace(cameraImage, new faceapi.TinyFaceDetectorOptions());
        if (!result) return;

        return faceapi.resizeResults(result, {
          width: outputCanvasRef.current.clientWidth,
          height: outputCanvasRef.current.clientHeight,
        });
      })();

      return { cameraImage, faceDetection };
    },
    ({ cameraImage, faceDetection } = {}) => {
      if (!outputCanvasRef.current) return; // Can occur on unmount if using async animation frame logic
      if (!cameraImage) return;

      const cameraInfo = {
        image: (() => {
          if (!alterImageData) return cameraImage; // Use the raw video if no image alteration is required
          return dataToImage(alterImageData(imageToData(cameraImage))); // Alter raw image data before display
        })(),
        width: video.videoWidth,
        height: video.videoHeight,
      };

      const rawFaceFrame = rectFromDetection(faceDetection);
      const newValueTrustRatio = 0.35;
      const faceFrame = rawFaceFrame
        ? new Rect(
            movingAverage(faceFrameRef.current?.x, rawFaceFrame.x, newValueTrustRatio),
            movingAverage(faceFrameRef.current?.y, rawFaceFrame.y, newValueTrustRatio),
            movingAverage(faceFrameRef.current?.width, rawFaceFrame.width, newValueTrustRatio),
            movingAverage(faceFrameRef.current?.height, rawFaceFrame.height, newValueTrustRatio),
          )
        : faceFrameRef.current;

      if (faceFrame) faceFrameRef.current = faceFrame; // Remember the last face frame to avoid jumps in case it disappears

      const hysteresisFrame = (() => {
        if (!faceFrame) return null;
        const minFaceScaleInFrame = 0.8;
        const maxFaceScaleInFrame = 0.9;

        const translationHysteresisRatio = 1 - maxFaceScaleInFrame;
        const verticalInset = -(faceFrame.height * translationHysteresisRatio);
        const horizontalInset = -(faceFrame.height * translationHysteresisRatio);
        const frame =
          hysteresisFrameRef.current ??
          faceFrame.insetBy(horizontalInset, verticalInset, horizontalInset, verticalInset);

        // Scale hysteresis
        const scaleX = faceFrame.width / frame.width;
        const scaleY = faceFrame.height / frame.height;
        if (scaleX > maxFaceScaleInFrame)
          frame.setWidthAround(faceFrame.width * (1 + (1 - maxFaceScaleInFrame)), frame.center.x);
        if (scaleX < minFaceScaleInFrame)
          frame.setWidthAround(faceFrame.width * (1 + (1 - minFaceScaleInFrame)), frame.center.x);
        if (scaleY > maxFaceScaleInFrame)
          frame.setHeightAround(faceFrame.height * (1 + (1 - maxFaceScaleInFrame)), frame.center.y);
        if (scaleY < minFaceScaleInFrame)
          frame.setHeightAround(faceFrame.height * (1 + (1 - minFaceScaleInFrame)), frame.center.y);

        // Translation hysteresis
        if (faceFrame.left < frame.left) frame.x = faceFrame.x;
        if (faceFrame.top < frame.top) frame.y = faceFrame.y;
        if (faceFrame.right > frame.right) frame.x += faceFrame.right - frame.right;
        if (faceFrame.bottom > frame.bottom) frame.y += faceFrame.bottom - frame.bottom;

        if (!hysteresisFrameRef.current) hysteresisFrameRef.current = frame;

        return frame;
      })();

      const outputCanvas = outputCanvasRef.current;
      const cameraRect = new Rect(0, 0, cameraInfo.width, cameraInfo.height);
      const canvasRect = new Rect(0, 0, outputCanvas.clientWidth, outputCanvas.clientHeight);

      const fillRect = (() => {
        // Proportionally fill the provided frame with the face (if any)
        if (snapFaceToNormalizedFrame && hysteresisFrame) {
          const absoluteTargetFrame = snapFaceToNormalizedFrame.convertNormalizedToAbsoluteIn(canvasRect);

          const zoom = hysteresisFrame.size.scaleFactorToFillProportionallyIn(absoluteTargetFrame);
          const zoomedRect = cameraRect.fillIn(canvasRect).scaledBy(zoom);
          const targetOffset = absoluteTargetFrame.center.minus(canvasRect.center);

          const rawCenterPoint = new Point(
            canvasRect.width / 2.0 - (hysteresisFrame.center.x - canvasRect.width / 2.0) * zoom,
            canvasRect.height / 2.0 - (hysteresisFrame.center.y - canvasRect.height / 2.0) * zoom,
          );

          return zoomedRect.centeredOn(rawCenterPoint.plus(targetOffset));
        } else {
          return cameraRect.fillIn(canvasRect);
        }
      })();

      const context = outputCanvas.getContext("2d");

      // Clear the frame to start anew
      context.clearRect(0, 0, canvasRect.width, canvasRect.height);

      // Draw something under the camera image
      context.globalCompositeOperation = beforeDrawCompositeOperation;
      beforeDraw(context);

      // Draw the camera image itself
      context.globalCompositeOperation = drawCompositeOperation;
      context.drawImage(cameraInfo.image, fillRect.x, fillRect.y, fillRect.width, fillRect.height);

      // Draw something over the camera image
      context.globalCompositeOperation = afterDrawCompositeOperation;
      afterDraw(context);

      // Draw face detection debug information
      if (debug && faceDetection) faceapi.draw.drawDetections(outputCanvas.getCanvasElement(), faceDetection);

      // Draw the smooth face frame
      if (debug && faceDetectionEnabled && faceFrame) drawRect(context, faceFrame, "#0088FF");

      // Draw the face hysteresis frame
      if (debug && faceDetectionEnabled && hysteresisFrame) drawRect(context, hysteresisFrame, "#FF0000");

      // Draw the face-snapping target frame
      if (debug && snapFaceToNormalizedFrame) {
        const frame = snapFaceToNormalizedFrame.convertNormalizedToAbsoluteIn(canvasRect);
        drawRect(context, frame, "#00FF00");
      }
    },
  );

  return { streamRef };
};
