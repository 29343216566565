import { lighten } from "polished";
import styled from "styled-components";

export const StarRoot = styled.div`
  aspect-ratio: 1/1;

  svg {
    g[id^="Card-Item-Star--inject"] {
      stroke: ${(props) => props.$color};
      fill: ${(props) => (props.$active ? lighten(0.25, props.$color) : "none")};
    }
  }
`;
