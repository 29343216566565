import Env from "./env";

export default (path) => {
  if (Env.isContained) return `resources/${path}`;
  return `/resources/${path}`;
};

export const isResourcePath = (path) => {
  // eslint-disable-next-line ripple/enforce-resource-helper
  return path.includes("resources/");
};
