/**
 * This helper sends a global custom event that can be received using
 * the `useMessage` hook. An alternative way to send a message is using the
 * `window.ripple.message.send()` global, which lends itself well to being
 * called using JavaScript injection.
 *
 * The goal is mostly to trigger things in Ripple apps using JavaScript
 * injection from containers like React-Native or native iOS / Android.
 * It can also be used to broadcast a message from inside the app, but
 * this should be avoided if possible. To affect other parts of the app,
 * prefer redux, react context or forwarding props from parents to children.
 */
export default class Message {
  static send(type, payload) {
    window.dispatchEvent(new CustomEvent(`ripple-message-${type}`, { detail: payload }));
  }
}

window.ripple = { ...(window.ripple || {}), message: Message };
