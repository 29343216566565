import { useThree } from "@react-three/fiber";
import PropTypes from "prop-types";
import { useCallback, useRef } from "react";
import * as THREE from "three";
import Clipboard from "../../../../helpers/clipboard";
import Coordinates from "../../../../helpers/coordinates";
import Toast from "../../../../helpers/toast";
import { MediaSrcPropType } from "../../../../logic/prop-types";
import MediaFile from "../../../../model/media-file";
import ImageMaterial from "../materials/image-material";
import VideoMaterial from "../materials/video-material";

const Dome = ({ src, muted }) => {
  const { gl, camera } = useThree();

  const url = src instanceof MediaFile ? src.url : src;
  const isVideo =
    (src instanceof MediaFile && src.type === "video") || (typeof src === "string" && src.endsWith("mp4"));

  const domeMeshRef = useRef(null);

  const onClick = useCallback(
    (event) => {
      const altKey = event.altKey;
      if (!altKey) return;

      const mouse = new THREE.Vector2(
        (event.clientX / gl.domElement.clientWidth) * 2 - 1,
        -(event.clientY / gl.domElement.clientHeight) * 2 + 1,
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObject(domeMeshRef.current);
      if (intersects.length === 0) return;

      const { theta, phi } = Coordinates.cartesianToSpherical(
        intersects[0].point.x,
        intersects[0].point.y,
        intersects[0].point.z,
      );

      Clipboard.copy(JSON.stringify({ theta, phi }));
      Toast.info("Position copied to clipboard!");
    },
    [camera, gl.domElement.clientHeight, gl.domElement.clientWidth],
  );

  const renderImageMaterial = () => {
    return <ImageMaterial url={url} />;
  };

  const renderVideoMaterial = () => {
    return <VideoMaterial url={url} muted={muted} />;
  };

  return (
    <mesh ref={domeMeshRef} onClick={onClick} scale={[-1, 1, 1]}>
      <sphereGeometry attach="geometry" args={[500, 60, 40]} />
      {isVideo ? renderVideoMaterial(url) : renderImageMaterial(url)}
    </mesh>
  );
};

Dome.propTypes = {
  src: MediaSrcPropType,
  muted: PropTypes.bool,
};

export default Dome;
