import _ from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import Classes from "../../../helpers/classes";
import Clipboard from "../../../helpers/clipboard";
import Download from "../../../helpers/download";
import CSV from "../../../helpers/internal/csv";
import Strings from "../../../helpers/strings";
import Styles from "../../../helpers/styles";
import Toast from "../../../helpers/toast";
import Button from "../../components/button";
import Scroller from "../../components/scroller";

class TableBox extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    stat: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className={Classes.build("box table", this.props.className)} style={Styles.merge(this.props.style)}>
        <h1>
          {Strings.localized(`Analytics${this.props.stat.name}`)}
          &nbsp;
          <i className="info fa fa-info-circle" title={Strings.localized(`Analytics${this.props.stat.name}Tooltip`)} />
        </h1>
        <div className="top-right-buttons">
          <Button onClick={this.onClipboardClick}>
            <i className="fa fa-clipboard" />
          </Button>
          <Button onClick={this.onDownloadClick}>
            <i className="fa fa-file-download" />
          </Button>
        </div>
        <div className="chart-container">
          <div className="table-header">{this.renderRow(this.props.stat.header, -1)}</div>
          <Scroller
            className="rows-container"
            startFadeRatio={0.05}
            endFadeRatio={0.1}
            scrollbarStartInset={8}
            scrollbarEndInset={8}
            disablePropagation
          >
            <div className="table-rows">{_.map(this.props.stat.rows, this.renderRow)}</div>
          </Scroller>
        </div>
      </div>
    );
  }

  renderRow = (row, rowIndex) => {
    return (
      <div className="row" key={`row-${rowIndex}`}>
        {_.map(row, (value, columnIndex) => {
          const style = {
            width: `${(this.props.stat.grow[columnIndex] / _.sum(this.props.stat.grow)) * 100}%`,
            textAlign: this.props.stat.align[columnIndex] || "left",
          };

          const color = this.props.stat.color && this.props.stat.color[columnIndex];
          if (color) style.color = color;

          return (
            <div key={`cell-${rowIndex}-${columnIndex}`} className="cell" style={style}>
              {value}
            </div>
          );
        })}
      </div>
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps.stat) === JSON.stringify(this.props.stat)) return false;
    return true;
  }

  onClipboardClick = () => {
    Clipboard.copy(this.generateCSV());
    Toast.info("Data copied to clipboard!");
  };

  onDownloadClick = () => {
    Download.string(`${Strings.localized(`Analytics${this.props.stat.name}`)}.csv`, this.generateCSV());
  };

  generateCSV = () => {
    const stat = this.props.stat;
    const data = [stat.header, ...stat.rows];
    return CSV.stringify(data);
  };
}

export default TableBox;
