import { useLocation } from "react-router";

/**
 * Obtain values from the query string:
 * `const { thing } = useQueryString();`
 */
export const useQueryString = () => {
  const location = useLocation();
  return location.query;
};
