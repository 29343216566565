import PropTypes from "prop-types";
import { Grid } from "ripple";
import { Item, SquareItemGridPanelContentRoot } from "./styled";

const getColumns = (count, location) => {
  if (location === "side") {
    if (count <= 4) return 1;
    return 2;
  } else {
    return 4;
  }
};

const SquareItemGridPanelContent = ({ className, style, items, children, location, ...rest }) => {
  return (
    <SquareItemGridPanelContentRoot
      {...rest}
      className={className}
      style={style}
      layout={Grid.layout.columns(getColumns(items.length, location))}
    >
      {items.map((item) => (
        <Item key={item.id}>{children(item)}</Item>
      ))}
    </SquareItemGridPanelContentRoot>
  );
};

SquareItemGridPanelContent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  items: PropTypes.array,
  children: PropTypes.func,
  location: PropTypes.string,
};

export default SquareItemGridPanelContent;
