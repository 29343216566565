import _ from "lodash";

import Strings from "../../../../helpers/strings";

export default function (preprocessed) {
  const names = _.map(preprocessed.events, (e) => e.name);
  const unorderedRows = _.map(_.countBy(names), (value, key) => [key, value]);
  const rows = _.orderBy(unorderedRows, (row) => row[1], "desc");

  this.name = "Names";
  this.header = [Strings.localized("AnalyticsNamesNameColumn"), Strings.localized("AnalyticsNamesCountColumn")];
  this.rows = rows;
  this.grow = [9, 1];
  this.align = ["left", "right"];
}
