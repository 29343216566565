import classNames from "classnames";

class Classes {
  /**
   * Compose multiple classes together into a single class string.
   * Previously a custom implementation, now simply delegates to
   * classnames because our final API ended up being exactly what
   * classnames provides. Using this wrapper instead of using
   * classnames directly helps avoid a naming clash with "classNames"
   * in the context of ReactTransitionGroup transitions in addition to
   * keeping backward compatibility with older apps.
   */
  static build(...args) {
    return classNames(...args);
  }
}

export default Classes;
