import PropTypes from "prop-types";
import { Fragment } from "react";
import { Config, Localization, useLanguage } from "ripple";
import { LanguageButton, LanguageSwitcherRoot, LanguageVerticalSplitter } from "./styled";

const LanguageSwitcher = ({ className, style, color, ...rest }) => {
  const currentLanguage = useLanguage();

  const createOnLanguageButtonClick = (language) => () => {
    Localization.switchToLanguage(language);
  };

  return (
    <LanguageSwitcherRoot {...rest} className={className} style={style}>
      {Config.language.supported.map((l) => (
        <Fragment key={l}>
          <LanguageButton $active={l === currentLanguage} onClick={createOnLanguageButtonClick(l)} $color={color}>
            {l}
          </LanguageButton>
          <LanguageVerticalSplitter $color={color} />
        </Fragment>
      ))}
    </LanguageSwitcherRoot>
  );
};

LanguageSwitcher.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
};

export default LanguageSwitcher;
