import { Image, resource, staggerStep } from "ripple";
import styled from "styled-components";

const arrowHeight = "4vh";

export const MascotRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  width: 40vmin;
  @media screen and (orientation: portrait) {
    width: ${(props) => (props.$wideInPortrait ? "70vmin" : "40vmin")};
  }
`;

export const PhylacteryContainer = staggerStep({
  transition: "slide-in-and-fade-from-bottom",
  delayBefore: 300,
  order: 2,
})(styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-bottom: -3vmin;
  visibility: ${(props) => (props.$show ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.$show ? "inherit" : "none")};
`);

export const PhylacteryArrow = styled(Image).attrs((props) => ({ src: resource("images/Arrow.png") }))`
  position: absolute;
  bottom: calc(-${arrowHeight} + 2px);
  left: 15%;
  height: ${arrowHeight};
`;

export const ChildrenContainer = styled.div`
  background-color: white;
  border-radius: 2vh;
  padding: 1vh;
  padding-bottom: 2vh;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  pointer-events: auto;
  width: 100%;
`;

export const Children = staggerStep({ transition: "", order: 3, delayBefore: 300 })(styled.div``);

export const MascotImage = staggerStep({ transition: "slide-in-and-fade-from-bottom", order: 0 })(styled(Image).attrs(
  (props) => ({ src: props.src, scaling: "fit" }),
)`
  height: 40vh;
  transform: ${(props) => (props.$flip ? "rotate3d(0, 1, 0, 180deg)" : "none")};
  pointer-events: none; /* Because the mascot image overlaps the phylactery a bit */
`);
