import { lighten } from "polished";
import PropTypes from "prop-types";
import { GameProgressBarRoot, ProgressBar, StyledStar } from "./styled";

const GameProgressBar = ({ className, style, game, currentChild, ...rest }) => {
  const index = game.children.indexOf(currentChild);
  const progress = index / (game.children.length - 1);
  const color = game.wantedText("Color")?.trim();
  return (
    <GameProgressBarRoot {...rest} className={className} style={style} $color={color}>
      <ProgressBar $color={color} value={progress} />
      <StyledStar color={lighten(0.2, color)} />
    </GameProgressBarRoot>
  );
};

GameProgressBar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  game: PropTypes.object,
  currentChild: PropTypes.object,
};

export default GameProgressBar;
