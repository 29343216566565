import _ from "lodash";

import Strings from "../../../../helpers/strings";

export default function (preprocessed) {
  const nodeEvents = _.filter(preprocessed.events, (e) => e.name === "node");

  const viewsById = _.countBy(nodeEvents, (e) => e.data.id);

  // This only keeps a single event instance per node ID, but this is what we need.
  // This results in the displayed name and ID being obtained from the last occurrence of each distinct node.
  const nodeEventsById = _.reduce(
    nodeEvents,
    (acc, e) => {
      acc[e.data.id] = e;
      return acc;
    },
    {},
  );

  const createRow = (nodeId, count) => {
    const data = nodeEventsById[nodeId].data;
    return [data.id.substring(0, 6), data.semantic || "Unknown", data.name, count];
  };

  const unorderedRows = _.map(viewsById, (value, key) => createRow(key, value));
  const rows = _.orderBy(unorderedRows, (row) => row[3], "desc");

  this.name = "Nodes";
  this.header = [
    Strings.localized("AnalyticsNodesIdColumn"),
    Strings.localized("AnalyticsNodesSemanticColumn"),
    Strings.localized("AnalyticsNodesNameColumn"),
    Strings.localized("AnalyticsNodesViewsColumn"),
  ];
  this.rows = rows;
  this.grow = [1.2, 2, 8, 1];
  this.align = ["right", "right", "left", "left"];
  this.color = ["#555555", "#555555", null, null];
}
