import { lighten } from "polished";
import { Button, Image, resource, staggerStep, Text, Transitioner } from "ripple";
import styled from "styled-components";
import { OldQuestionRoot, titleColor } from "../../../common";
import SpeakerButton from "../../../components/speaker-button";

export const FlipQuestionRoot = styled(OldQuestionRoot)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Card = staggerStep({ transition: "flip-left" })(styled(Button)`
  background-color: ${(props) => lighten(0.4, props.color)};

  box-shadow: 0 0 0 0.4vmin ${(props) => props.color};

  height: 100%;
  width: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 2vh;
  overflow: hidden;
`);

export const CardTransitioner = styled(Transitioner)`
  width: 18vh;
  height: 25.3vh;
  perspective: 300vh;
  margin: 0.5vh;
`;

export const SmallerCardTransitioner = styled(Transitioner)`
  width: ${(props) => (props.shrink ? "15vh" : "18vh")};
  height: ${(props) => (props.shrink ? "17.5vh" : "21vh")};
  perspective: 300vh;
  margin: 0.5vh;
`;

export const CardImage = styled(Image).attrs((props) => ({ scaling: "fill" }))`
  width: 100%;
  height: ${(props) => (props.$partialHeight ? "82%" : "100%")};
  transition: opacity 150ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? "inherit" : "none")};

  border-radius: 2vh 2vh 0 0;
`;

export const QuestionMarkImage = styled(Image).attrs((props) => ({
  fadeIn: "always",
  src: resource("images/QuestionMark.svg"),
}))`
  position: absolute;
  top: 3vh;
  height: 65%;
  width: 100%;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const CardBar = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0.4vh;

  transition: opacity 150ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? "inherit" : "none")};
`;

export const CardText = styled(Text)`
  color: ${titleColor};
  font-size: ${(props) => props.size + "vh"};
  font-family: "ComicNeue-Bold";
`;

export const InvisibleSpeakerButton = styled(SpeakerButton)`
  position: absolute;
  display: none;
`;
