import PropTypes from "prop-types";
import { useCallback } from "react";
import { useParams } from "react-router";
import { DragPreview, Dropzone, useData } from "ripple";
import AudioTextDroppable from "../../../components/audio-text-droppable";
import FeedbackLayer from "../../../components/feedback-layer";
import ImageAudioContentBox from "../../../components/image-audio-content-box";
import ResponsiveContent from "../../../components/responsive-content";
import TextAudioContentBox from "../../../components/text-audio-content-box";
import TextDragPreview from "../../../components/text-drag-preview";
import { useDragItemsOnTargetsQuestionLogic } from "../../../hooks/use-drag-items-on-targets-question-logic";
import { QuestionPanelContent } from "../styled";
import { Hole, HoleText, Holes, Target, Targets, Type10QuestionRoot } from "./styled";

const Type10Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));

  const targets = node.children.find((c) => c.semantic === "Type10QuestionTargets").children;
  const items = node.children.find((c) => c.semantic === "Type10QuestionItems").children;

  const { feedbacks, getTargetItems, onOver, overs, onDrop, drops } = useDragItemsOnTargetsQuestionLogic(
    targets,
    items,
    onComplete,
  );

  const renderPanelContent = (location) => {
    return (
      <QuestionPanelContent $location={location}>
        {items.map((item) => {
          return (
            <AudioTextDroppable
              key={item.id}
              node={item}
              disabled={Object.values(drops)
                .flatMap((d) => d)
                .includes(item)}
            />
          );
        })}
      </QuestionPanelContent>
    );
  };

  const renderTarget = (target) => {
    const targetItems = getTargetItems(target);
    const Component = target.semantic === "Type10QuestionImageTarget" ? ImageAudioContentBox : TextAudioContentBox;
    return (
      <Target key={target.id} $wide={targets.length < 2}>
        <Component
          image={target.optionalMedia("Image", "Image")} /* Unused for TextContentBox */
          audio={target.optionalMedia("Audio", "Audio")}
          text={target.optionalText("Description")} /* Unused for ImageAudioContentBox */
          glow={overs[target.id]}
          layers={
            <>
              <Dropzone id={target} onOver={onOver} onDrop={onDrop} disabled={!!feedbacks[target.id]} />
              <FeedbackLayer feedback={feedbacks[target.id]} style={{ zIndex: 2 }} />
            </>
          }
          appearance="active"
          dashed
        >
          <Holes>
            {targetItems.map((_, index) => {
              const targetDrops = drops[target.id];
              return (
                <Hole key={index}>
                  <HoleText>{targetDrops ? targetDrops[index]?.wantedText("Title") : null}</HoleText>
                </Hole>
              );
            })}
          </Holes>
        </Component>
      </Target>
    );
  };

  const renderDragPreview = useCallback((item) => <TextDragPreview text={item.wantedText("Title")} />, []);

  return (
    <Type10QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent
        sidePanelContent={renderPanelContent("side")}
        bottomPanelContent={renderPanelContent("bottom")}
      >
        <Targets>{targets.map(renderTarget)}</Targets>
      </ResponsiveContent>
      <DragPreview render={renderDragPreview} />
    </Type10QuestionRoot>
  );
};

Type10Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type10Question;
