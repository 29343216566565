import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { DragPreview, Dropzone, useData } from "ripple";
import { useImmer } from "use-immer";
import AudioTextDroppable from "../../../components/audio-text-droppable";
import FeedbackLayer from "../../../components/feedback-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import TextDragPreview from "../../../components/text-drag-preview";
import { useFeedback } from "../../../hooks/use-feedback";
import { QuestionPanelContent } from "../styled";
import {
  DropzoneBox,
  DropzoneSegment,
  DropzoneWord,
  SentenceBox,
  StyledLayers,
  TextSegment,
  Type9QuestionRoot,
} from "./styled";

const unparagraphize = (html) => {
  return html?.replace("<p>", "").replace("</p>", "");
};

const getSegments = (html) => {
  const unparagraphed = unparagraphize(html);
  const parts = unparagraphed.split(/(\[.+?\])/gm);
  const segments = parts.map((part) => {
    const identifierMatch = part.match(/^\[(.+?)\]$/);
    return identifierMatch ? { type: "identifier", value: identifierMatch[1] } : { type: "text", value: part };
  });
  return segments;
};

const Type9Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const segments = getSegments(node.requiredText("Sentences"));
  const itemIdentifiers = segments.filter((s) => s.type === "identifier").map((s) => s.value);

  const items = node.children;

  const { feedbacks, addPermanentFeedback, addMomentaryFeedback } = useFeedback();

  const [drops, setDrops] = useState([]);

  const [overs, updateOvers] = useImmer({});
  const onOver = useCallback(
    (isOver, dropzoneId) => {
      updateOvers((draft) => {
        draft[dropzoneId] = isOver;
      });
    },
    [updateOvers],
  );

  const onDrop = useCallback(
    (dropzoneId, item) => {
      let newDrops;
      if (item.identifier === dropzoneId) {
        newDrops = [...drops, item];
        setDrops(newDrops);
        addMomentaryFeedback("question", "good");
      } else {
        addMomentaryFeedback("question", "bad");
        return;
      }

      // All good if there are enough drops because we do not accept incorrect items
      if (newDrops.length === itemIdentifiers.length) {
        addPermanentFeedback("question", "good");
        onComplete();
      }
    },
    [addMomentaryFeedback, addPermanentFeedback, drops, itemIdentifiers, onComplete],
  );

  const renderPanelContent = (location) => {
    return (
      <QuestionPanelContent $location={location}>
        {items.map((item) => {
          return <AudioTextDroppable key={item.id} node={item} disabled={drops.includes(item)} />;
        })}
      </QuestionPanelContent>
    );
  };

  const renderSegmentAsText = (segment) => <TextSegment key={segment.id}>{segment.value}</TextSegment>;

  const renderSegmentAsDropzone = (segment) => {
    const identifier = segment.value;
    const item = items.find((i) => i.identifier === identifier);
    return (
      <DropzoneSegment key={segment.id}>
        <DropzoneBox glow={overs[identifier]}>
          <DropzoneWord $show={drops.includes(item)}>{unparagraphize(item?.wantedText("Title"))}</DropzoneWord>
          <Layers>
            <Dropzone id={identifier} onOver={onOver} onDrop={onDrop} />
          </Layers>
        </DropzoneBox>
      </DropzoneSegment>
    );
  };

  const renderSegment = (segment) => {
    if (segment.type === "text") return renderSegmentAsText(segment);
    if (segment.type === "identifier") return renderSegmentAsDropzone(segment);
  };

  const renderDragPreview = useCallback((item) => <TextDragPreview text={item.wantedText("Title")} />, []);

  return (
    <Type9QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent
        sidePanelContent={renderPanelContent("side")}
        bottomPanelContent={renderPanelContent("bottom")}
      >
        <SentenceBox>
          {segments.map(renderSegment)}
          <StyledLayers>
            <FeedbackLayer feedback={feedbacks["question"]} />
          </StyledLayers>
        </SentenceBox>
      </ResponsiveContent>
      <DragPreview render={renderDragPreview} />
    </Type9QuestionRoot>
  );
};

Type9Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type9Question;
