import _ from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";

import Clipboard from "../../../helpers/clipboard";
import Env from "../../../helpers/env";
import Strings from "../../../helpers/strings";
import Toast from "../../../helpers/toast";
import YamlResource from "../../../logic/yaml-resource";

import { getInstanceConfig, setInstanceConfig } from "../../../helpers/config";
import LocationHelper from "../../../helpers/internal/location-helper";
import resource from "../../../helpers/resource";
import Button from "../../components/button";
import Page from "../../components/page";
import Scroller from "../../components/scroller";

const buildArguments = (config) => {
  if (typeof config === "undefined" || _.isEmpty(config)) return "";
  const json = JSON.stringify(config);
  // eslint-disable-next-line quotes
  return `--config="${json.replace(/"/g, '\\"')}"`;
};

const buildURL = (config) => {
  if (typeof config === "undefined" || _.isEmpty(config)) return "";
  const json = JSON.stringify(config);
  return `/?config=${encodeURI(json)}`;
};

// This returns the deepest object that contains
// the last key of the specified keypath.
const getNestedObject = (obj, keypath) => {
  return _.reduce(
    keypath.slice(0, -1),
    (acc, key) => {
      const nestedObject = acc[key];

      // As soon as the nested object doesn't
      // exist, return an empty object so that we can finish reducing
      // but end up with an empty object at the end.
      if (!nestedObject) return {};

      return nestedObject;
    },
    obj,
  );
};

const indentationForLevel = (level) => {
  return level * 20;
};

const updateInputElementSize = (input) => {
  const extraPadding = navigator.vendor.match(/apple/i) ? 4 : 0;
  input.style.width = 0;
  input.style.width = input.scrollWidth + extraPadding + "px";
};

const getConfigValue = (config, keypath) => {
  const nestedObject = getNestedObject(config, keypath);
  if (!nestedObject) return undefined;
  return nestedObject[_.last(keypath)];
};

const setConfigValue = (config, keypath, value) => {
  getNestedObject(config, keypath)[_.last(keypath)] = value;
};

/* String to typed (to convert field values back into types for storage) */
const retype = (value) => {
  if (typeof value !== "string") return value;
  if (value.startsWith("[") && value.endsWith("]")) return value.slice(1, -1).split(",");
  if (value === "true") return true;
  if (value === "false") return false;
  if (value === "null") return null;
  if (!isNaN(value)) return Number(value);
  return value;
};

/* Typed to string (for display in fields) */
const fieldify = (value) => {
  if (value === null) return "null";
  if (Array.isArray(value)) return `[${value.toString()}]`;
  return value.toString();
};

const diff = (ours, theirs) => {
  const difference = {};
  _.forOwn(ours, (ourValue, key) => {
    const theirValue = theirs[key];
    if (Array.isArray(ourValue)) {
      if (!_.isEqual(ourValue, theirValue)) difference[key] = theirValue;
    } else if (ourValue !== null && typeof ourValue === "object") {
      const childDiff = diff(ourValue, theirValue);
      if (childDiff) difference[key] = childDiff;
    } else {
      if (theirValue !== ourValue) difference[key] = theirValue;
    }
  });
  if (!_.isEmpty(difference)) return difference; // Else return undefined
};

const finalDiffThen = (ours, theirs, action) => {
  const difference = diff(ours, theirs);
  if (_.isEmpty(difference)) {
    Toast.warn("There is nothing to copy because no changes were made");
    Clipboard.copy(""); // Empty the clipboard to avoid mistakes!
    return;
  }
  action(difference);
};

const ConfigPage = memo(() => {
  const config = LocationHelper.getValue("config");

  const loadedRef = useRef(false);
  const [updateCount, setUpdateCount] = useState(0);
  const forceUpdate = useCallback(() => setUpdateCount(updateCount + 1), [updateCount]);

  const baseConfigResourceRef = useRef(new YamlResource()); // We keep this unmodified, it's the base config we diff against
  const baseConfigRef = useRef(null);

  const additionalConfigResourceRef = useRef(new YamlResource()); // This is what our editable model is based on, and what we diff against the base config
  const additionalConfigRef = useRef(null);

  const configOverridesRef = useRef(config ? JSON.parse(config) : {}); // Those are specified through the URL and override anything that's configured

  // Represents the data behind the editor (typed)
  const editorTypedConfigRef = useRef(null);

  // The model behind our React controlled inputs.
  // It matches the modified config exactly, except that values are strings
  // instead of typed values. The display model is is necessary to avoid loss when
  // (for example) editing a number with a temporarily NaN value.
  const editorDisplayConfigRef = useRef(null);

  const loadCustomConfig = () => {
    return baseConfigResourceRef.current
      .loadFromURLs(resource("data/core-config.yml"), resource("data/custom-config.yml"))
      .then(() => (baseConfigRef.current = _.cloneDeep(baseConfigResourceRef.current.values)));
  };

  const loadAdditionalConfig = () => {
    return additionalConfigResourceRef.current
      .loadFromURLs(
        resource("data/core-config.yml"),
        resource("data/custom-config.yml"),
        resource("data/local-config.yml[optional]"),
      )
      .then(async () => {
        additionalConfigResourceRef.current.mergeValues(getInstanceConfig()); // Merge in values from the instance config layer
        additionalConfigRef.current = _.cloneDeep(additionalConfigResourceRef.current.values); // We only keep values to work with
      });
  };

  useEffect(() => {
    const load = async () => {
      if (loadedRef.current) return;
      await loadCustomConfig();
      await loadAdditionalConfig();
      editorTypedConfigRef.current = _.cloneDeep(additionalConfigResourceRef.current.values);
      editorDisplayConfigRef.current = _.cloneDeep(additionalConfigResourceRef.current.values);
      loadedRef.current = true;
      forceUpdate();
    };
    load();
  }, [forceUpdate]);

  useEffect(() => {
    // Update the size of all inputs
    _.each(document.querySelectorAll("div.config input"), (input) => updateInputElementSize(input));
  }, [updateCount]);

  const onChange = (keypath) => (event) => {
    setConfigValue(editorTypedConfigRef.current, keypath, retype(event.target.value));
    setConfigValue(editorDisplayConfigRef.current, keypath, event.target.value);
    forceUpdate();
  };

  const onKeyPress = (type) => (event) => {
    if (type === "anything") return;
    if (type === "number" && !/[0-9\\.-]/.test(event.nativeEvent.key)) event.preventDefault();
  };

  const createOnResetValuePress = (keypath) => (event) => {
    const input = event.target.closest(".field").querySelector("input");

    const localValue = getConfigValue(additionalConfigRef.current, keypath);
    const customValue = getConfigValue(baseConfigRef.current, keypath);

    // We reset to the local value first, then to the custom value
    const typedValue = retype(input.value);
    const resetValue = typedValue === localValue ? customValue : localValue;

    setConfigValue(editorTypedConfigRef.current, keypath, resetValue);
    setConfigValue(editorDisplayConfigRef.current, keypath, resetValue);

    updateInputElementSize(input);
    forceUpdate();
  };

  const onReloadPress = useCallback(() => {
    loadAdditionalConfig().then(() => {
      editorTypedConfigRef.current = _.cloneDeep(additionalConfigRef.current);
      editorDisplayConfigRef.current = _.cloneDeep(additionalConfigRef.current);
      forceUpdate();
    });
  }, [forceUpdate]);

  const onSavePress = useCallback(async () => {
    const updatedConfig = diff(baseConfigRef.current, editorTypedConfigRef.current);

    if (Env.isREC) {
      // In REC, save to local config (on disk)
      Env._overwriteLocalConfig(updatedConfig);
    } else {
      // In all other cases, save to instance config
      const instanceConfig = getInstanceConfig();
      const newConfig = _.merge({}, instanceConfig, updatedConfig);
      setInstanceConfig(newConfig);
    }

    await loadAdditionalConfig();
    forceUpdate();
    Toast.info(Strings.localized("ConfigSaveToastNotification"), 10000);
  }, [forceUpdate]);

  const onSaveAndRestartAppPress = useCallback(() => {
    onSavePress();
    setTimeout(() => (window.location.href = "/"), 500);
  }, [onSavePress]);

  const onCopyProcessArgumentsPress = useCallback(() => {
    finalDiffThen(additionalConfigRef.current, editorTypedConfigRef.current, (difference) => {
      Clipboard.copy(buildArguments(difference));
      Toast.info("Process arguments copied to clipboard!");
    });
  }, []);

  const onCopyURLPress = useCallback(() => {
    finalDiffThen(additionalConfigRef.current, editorTypedConfigRef.current, (difference) => {
      Clipboard.copy(buildURL(difference));
      Toast.info("Overrides query string copied to clipboard!");
    });
  }, []);

  const onRestartWithURLPress = useCallback(() => {
    finalDiffThen(additionalConfigRef.current, editorTypedConfigRef.current, (difference) => {
      window.location.href = buildURL(difference);
    });
  }, []);

  const getComment = (keypath) => {
    // We favor comments from the first layers (which are the true reference),
    // not comment overrides in subsequent layers!
    return additionalConfigResourceRef.current.layers.reduce((acc, layer) => {
      if (acc) return acc; // If a value has already been found in a previous layer, keep it
      const comment = layer.document?.contents?.getIn(keypath, true)?.comment;
      // Comments starting with :: (meant for developers) are ignored so that the config page does not contain confusing comments for app deployers
      return comment?.trim().startsWith("::") ? null : comment;
    }, null);
  };

  const renderSection = (level, keypath) => {
    return (
      <div key={keypath.join(".")} className="section">
        <span style={{ marginLeft: indentationForLevel(level) }}>{_.last(keypath) + ":"}</span>
      </div>
    );
  };

  const renderField = (level, keypath, value, valueForDisplay, comment) => {
    const customValue = getConfigValue(baseConfigRef.current, keypath);
    const localValue = getConfigValue(additionalConfigRef.current, keypath);
    const configValue = getConfigValue(configOverridesRef.current, keypath);

    const className = (() => {
      if (!_.isEqual(value, localValue)) return "modified";
      if (!_.isEqual(value, customValue)) return "local";
      return "";
    })();

    // We can reset as long as we haven't come back to the custom value
    const showReset = value !== customValue;

    // Here, we use the custom config as a source of truth for the value's allowed type
    const allowedType = typeof customValue;

    const override = configValue !== localValue ? configValue : undefined;
    const hasOverride = typeof override !== "undefined";

    return (
      <div key={keypath.join(".")} className="field">
        <span style={{ marginLeft: indentationForLevel(level) }}>{_.last(keypath) + ":"}</span>
        <input
          className={className}
          value={fieldify(valueForDisplay)}
          onChange={onChange(keypath)}
          onKeyPress={onKeyPress(allowedType)}
        />
        {showReset && <Button className="reset fa fa-undo" onClick={createOnResetValuePress(keypath)} />}
        {hasOverride && <span className="override">{`(override: ${override})`}</span>}
        {comment && <span className="comment"># {comment}</span>}
      </div>
    );
  };

  const renderElementsRecursively = (object, level = 0, parentKeypath = []) => {
    let elements = [];
    _.forOwn(object, (value, key) => {
      const keypath = parentKeypath.slice(0);
      keypath.push(key);
      if (value !== null && typeof value === "object" && !Array.isArray(value)) {
        elements.push(renderSection(level, keypath));
        elements = elements.concat(renderElementsRecursively(value, level + 1, keypath));
      } else {
        const comment = getComment(keypath);
        const valueForDisplay = getConfigValue(editorDisplayConfigRef.current, keypath);
        elements.push(renderField(level, keypath, value, valueForDisplay, comment));
      }
    });
    return elements;
  };

  return (
    <Page className="config" pauseTimeout="reset">
      <Scroller scrollbarSideInset={10} scrollbarStartInset={40} scrollbarEndInset={40}>
        <div className="editor">{loadedRef.current && renderElementsRecursively(editorTypedConfigRef.current)}</div>
        <div className="top">
          <Button onClick={onReloadPress}>
            {Strings.localized("ConfigActionReloadOnDiskConfig")}
            <i className="fa fa-sync" />
          </Button>
        </div>
        <div className="buttons">
          <Button onClick={onSavePress} disableAfterClickFor={1000}>
            {Strings.localized("ConfigActionSaveToInstanceConfig")}
          </Button>
          {/* Disabled in containers for now because truly restarting the app (inclugin going through the startup
              page) is harder than it seems and does not behave exacly the same in all containers and platforms.
              It doesn't react like in the browser, reloading the same page instead of loading the site root. */}
          {!Env.isContained && (
            <Button onClick={onSaveAndRestartAppPress} disableAfterClick>
              {Strings.localized("ConfigActionSaveToInstanceConfigAndRestartApp")}
            </Button>
          )}
          {!Env.isRCC && (
            <Button onClick={onCopyProcessArgumentsPress}>
              {Strings.localized("ConfigActionCopyProcessArguments")}
            </Button>
          )}
          {!Env.isRCC && <Button onClick={onCopyURLPress}>{Strings.localized("ConfigActionCopyURL")}</Button>}
          {!Env.isContained && (
            <Button onClick={onRestartWithURLPress} disableAfterClick>
              {Strings.localized("ConfigActionRestartWithURL")}
            </Button>
          )}
        </div>
      </Scroller>
    </Page>
  );
});

export default ConfigPage;
