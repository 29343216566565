import PropTypes from "prop-types";
import { useCallback, useEffect, useRef } from "react";
import { usePrevious } from "ripple";
import { useGlobalAudioPlayer } from "../../../hooks/use-global-audio-player";
import PlayButton from "../../play-button";
import { VideoBlockRoot, VideoPausedOverlay } from "./styled";

const VideoBlock = ({ className, style, ...rest }) => {
  const { stop, playing } = useGlobalAudioPlayer();

  const videoRef = useRef(null);

  const onVideoStart = useCallback(() => {
    stop();
  }, [stop]);

  const previousGlobalAudioPlaying = usePrevious(playing);
  useEffect(() => {
    if (!previousGlobalAudioPlaying && playing) videoRef.current.pause();
  }, [playing, previousGlobalAudioPlaying]);

  return (
    <VideoBlockRoot
      {...rest}
      ref={videoRef}
      className={className}
      style={style}
      onStart={onVideoStart}
      pausedOverlay={
        <VideoPausedOverlay>
          <PlayButton />
        </VideoPausedOverlay>
      }
    />
  );
};

VideoBlock.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default VideoBlock;
