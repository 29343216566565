import PropTypes from "prop-types";
import { MediaSrcPropType, TextSrcPropType } from "ripple";
import Layers from "../layers";
import SpeakerButtonLayer from "../speaker-button-layer";
import { Bottom, TextContentBoxRoot, Top, TopText } from "./styled";

const TextAudioContentBox = ({ className, style, text, audio, children, layers, ...rest }) => {
  return (
    <TextContentBoxRoot {...rest} className={className} style={style} {...rest}>
      <Top>
        <TopText>{text}</TopText>
        <Layers>
          <SpeakerButtonLayer src={audio} style={{ zIndex: 1 }} />
        </Layers>
      </Top>
      <Bottom>{children}</Bottom>
      <Layers>{layers}</Layers>
    </TextContentBoxRoot>
  );
};

TextAudioContentBox.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  text: TextSrcPropType,
  audio: MediaSrcPropType,
  children: PropTypes.node,
  layers: PropTypes.node,
};

export default TextAudioContentBox;
