import reactUseMeasure from "react-use-measure";

/**
 * Just forward the hook as-is, but pre-configure the polyfill
 * for simplicity at the point of use.
 * https://github.com/react-spring/react-use-measure
 */
export const useMeasure = (options = {}) => {
  return reactUseMeasure({ ...options });
};
