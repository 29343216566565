import _ from "lodash";

function calculate(preprocessed) {
  const startupEvents = _.filter(preprocessed.events, (e) => e.name === "startup");
  return _.uniqBy(startupEvents, (e) => e.data.fingerprint).length;
}

export default function (preprocessed) {
  this.name = "UniqueUsers";
  this.format = (value) => value;
  this.value = calculate(preprocessed);
}
