import { useEffect, useState } from "react";
import { Random, useConstant, useData } from "ripple";

export const useRandomSuccessRetroaction = (deepestNode) => {
  const client = useData((data) => data.root);
  const mascotSetting = deepestNode.optionalInheritedSetting("Mascot", "unspecified") ?? "mouka";
  const retroactionFolders = useConstant(() => client.children.filter((c) => c.semantic === "SuccessRetroactions"));
  const retroactions = retroactionFolders.find((f) => f.identifier === mascotSetting).children;
  const [retroaction, setRetroaction] = useState(null);
  useEffect(() => {
    setRetroaction(retroactions[Random.int(0, retroactions.length - 1)]);
  }, [deepestNode, retroactions]);
  return retroaction;
};
