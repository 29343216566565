import PropTypes from "prop-types";
import { useCallback } from "react";
import { useParams } from "react-router";
import { useData } from "ripple";
import DragPreview from "ripple/scripts/react/components/drop/drag-preview";
import AudioTextDroppable from "../../../components/audio-text-droppable";
import FeedbackLayer from "../../../components/feedback-layer";
import ImageLayer from "../../../components/image-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import SpeakerButtonLayer from "../../../components/speaker-button-layer";
import TextDragPreview from "../../../components/text-drag-preview";
import { useDragItemsInOrderAndValidateWhenFullQuestionLogic } from "../../../hooks/use-drag-items-in-order-and-validate-when-full-question-logic";
import { QuestionPanelContent } from "../styled";
import {
  BigBox,
  DropzoneBox,
  DropzoneText,
  Dropzones,
  StyledDropzone,
  StyledLayers,
  Top,
  Type16QuestionRoot,
} from "./styled";

const Type16Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const items = node.children;
  const itemIdentifiers = node.identifier.split(",");
  const color = node.wantedInheritedText("Color")?.trim();

  const { feedbacks, onOver, overs, onDrop, drops } = useDragItemsInOrderAndValidateWhenFullQuestionLogic(
    itemIdentifiers,
    onComplete,
  );

  const renderDragPreview = useCallback((item) => <TextDragPreview text={item.wantedText("Title")} />, []);

  const renderPanelContent = (location) => {
    return (
      <QuestionPanelContent $location={location}>
        {items.map((item) => (
          <AudioTextDroppable key={item.id} node={item} disabled={drops.includes(item)} />
        ))}
      </QuestionPanelContent>
    );
  };

  return (
    <Type16QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent
        sidePanelContent={renderPanelContent("side")}
        bottomPanelContent={renderPanelContent("bottom")}
      >
        <BigBox>
          <Top>
            <Layers>
              <ImageLayer src={node.wantedMedia("Image", "Image")} />
              <SpeakerButtonLayer src={node.optionalMedia("Audio", "Audio")} />
            </Layers>
          </Top>
          <Dropzones>
            {itemIdentifiers.map((identifier, index) => {
              const drop = drops[index];
              return (
                <StyledDropzone key={identifier} onOver={onOver} onDrop={onDrop} id={index} $color={color}>
                  <DropzoneBox dashed={!drop} glow={overs[index]}>
                    <DropzoneText>{drop?.wantedText("Title")}</DropzoneText>
                  </DropzoneBox>
                </StyledDropzone>
              );
            })}
          </Dropzones>
          <StyledLayers>
            <FeedbackLayer feedback={feedbacks["question"]} />
          </StyledLayers>
        </BigBox>
      </ResponsiveContent>
      <DragPreview render={renderDragPreview} />
    </Type16QuestionRoot>
  );
};

Type16Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type16Question;
