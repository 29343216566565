import { Button, Image } from "ripple";
import styled from "styled-components";
import { textColor } from "../../common";

const borderRadius = "1.4vh";

export const ActivityCardRoot = styled(Button)`
  isolation: isolate;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WhiteBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - ${borderRadius});
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: ${borderRadius};
  display: flex;
  color: ${textColor};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BackgroundImage = styled(Image).attrs((props) => ({ scaling: "fill", fadeIn: "always" }))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${borderRadius};
`;

export const WhiteBox = styled.div`
  position: absolute;
  bottom: 0;
  border-radius: ${borderRadius};
  background-color: white;
  width: 90%;
  min-height: calc(${borderRadius} * 2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2vmin;
`;
