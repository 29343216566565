import { Image, Text } from "ripple";
import styled from "styled-components";
import Box from "../../../components/box";
import Question from "../../../components/question";
import StandardImage from "../../../components/standard-image";

export const Type18QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardBox = styled(Box).attrs((props) => ({ appearance: "active" }))`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 500ms ease-in-out;
  transform: rotate3d(0, 1, 0, ${(props) => (props.$show ? 0 : "180deg")});
  backface-visibility: hidden;
`;

export const MascotLayer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

export const MascotImage = styled(Image)`
  max-width: 70%;
  max-height: 70%;
  filter: saturate(0%);
  opacity: 0.2;
`;

export const ContentLayer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardImage = styled(StandardImage)`
  aspect-ratio: 1;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

export const CardTitleArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 6px;
`;

export const CardTitle = styled(Text)`
  font-size: 1.8vmax;
`;
