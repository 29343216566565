import { RouteTransitioner, VideoPlayer, staggerStep } from "ripple";
import styled from "styled-components";
import { CustomPage, RoundYellowButton } from "../../common";
import BackButton from "../../components/back-button";
import Box from "../../components/box";
import ProgressPills from "../../components/progress-pills";

export const Page = styled(CustomPage)`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  position: relative;
  flex: 1 1 0;
`;

export const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  transition: opacity 250ms ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  pointer-events: ${(props) => (props.$show ? "inherit" : "none")};
`;

export const TopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transition: opacity 250ms ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  pointer-events: ${(props) => (props.$show ? "inherit" : "none")};
`;

export const IntroVideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: opacity 250ms ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  pointer-events: ${(props) => (props.$show ? "inherit" : "none")};
`;

export const IntroVideoBox = styled(Box)`
  aspect-ratio: 1;

  @media screen and (orientation: landscape) {
    height: 60vmin;
  }
  @media screen and (orientation: portrait) {
    width: 85vmin;
  }
`;

export const IntroVideoPlayer = styled(VideoPlayer).attrs((props) => ({
  showControls: false,
  scaling: "fill",
  showPausedOverlay: true,
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const QuestionRouteTransitioner = styled(RouteTransitioner)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: opacity 250ms ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  pointer-events: ${(props) => (props.$show ? "inherit" : "none")};

  & > .transitioner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
  }
`;

export const BottomProgressPills = staggerStep({ transition: "slide-in-and-fade-from-bottom" })(styled(ProgressPills)`
  position: absolute;
  bottom: 2vmin;
  z-index: 2;
`);

export const InstructionButton = staggerStep({ transition: "slide-in-and-fade-from-right", order: 2 })(styled(
  RoundYellowButton,
)`
  position: absolute;
  top: calc(env(safe-area-inset-top) + 3vmin);
  right: 3vmin;
  width: 8vmin;
  height: 8vmin;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .hotspot {
    margin: -3vmin;
  }

  .ripple-image {
    transform: scale3d(1.25, 1.25, 1);
  }
`);

export const HelpButton = staggerStep({ transition: "slide-in-and-fade-from-right", order: 2 })(styled(
  RoundYellowButton,
)`
  position: absolute;
  top: calc(env(safe-area-inset-top) + 14vmin);
  right: 3vmin;
  width: 6vmin;
  height: 6vmin;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .hotspot {
    margin: -3vmin;
  }

  .ripple-image {
    transform: scale3d(1.25, 1.25, 1);
  }
`);

export const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: 3vmin;
  top: calc(env(safe-area-inset-top) + 3vmin);
  width: 8vmin;
  height: 8vmin;
`;

export const InteractionBlocker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: ${(props) => (props.$block ? "auto" : "none")};
`;

export const QuestionPanelContent = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$location === "bottom" ? "row" : "column")};
  flex-wrap: ${(props) => (props.$location === "bottom" ? "wrap" : "unset")};
  justify-content: center;
  gap: 1vmin;
`;

export const VideoPausedOverlay = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
`;
