import RemoteActions from "../../actions/local/remote-actions";

const defaultState = { state: null, clients: [] };

export default (state = defaultState, action) => {
  switch (action.type) {
    case RemoteActions.UPDATE_REMOTE_CLIENTS:
      return { ...state, clients: action.clients };
    case RemoteActions.UPDATE_REMOTE_STATE:
      return { ...state, state: action.state };
    default:
      return state;
  }
};
