import { lighten } from "polished";
import { Button, Grid, Image, resource, staggerStep } from "ripple";
import styled from "styled-components";
import { OldQuestionRoot, textColor, titleColor } from "../../../common";
import SpeakerButton from "../../../components/speaker-button";
import WordButton from "../../../components/word-button";

export const ClickwordImageQuestionRoot = styled(OldQuestionRoot)``;

export const Content = staggerStep({ transition: "slide-in-and-fade-from-left" })(styled.div`
  display: flex;
  align-items: center;

  @media screen and (orientation: portrait) {
    flex-direction: column;
    margin-left: 0;
  }

  @media screen and (orientation: landscape) {
    flex-direction: row;
    align-items: flex-start;
    margin-left: ${(props) => props.$offset + "px"};
  }
`);

export const Card = staggerStep({ transition: "", order: 1 })(styled.div`
  @media screen and (orientation: landscape) {
    width: 52vh;
    height: 60vh;
  }

  @media screen and (orientation: portrait) {
    width: 37.5vh;
    height: 44vh;
  }

  box-shadow: 0 0 0 0.8vh ${(props) => props.color};
  border-radius: 4vh;
  transition: all 150ms ease-in-out;
  background-color: ${(props) => lighten(0.4, props.color)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
`);

export const CardImage = styled(Image).attrs((props) => ({ scaling: "fill", fadeIn: "always" }))`
  height: 100%;
  width: 100%;
`;
export const WordButtonContainer = styled.div``;

export const LandscapeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 5vh;
  margin-right: 3vh;
`;

export const PortraitButtonContainer = styled(Grid).attrs((props) => ({
  layout: (count) => {
    return Grid.layout.square()(count);
  },
}))`
  max-width: 100vw;
  margin: 2vh;
  .grid-row {
  }
`;

export const ButtonContent = staggerStep({ transition: "" })(styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 37vh;
  margin: 1vh;

  border-radius: 1.5vh;
  background-color: ${(props) => lighten(0.3, props.color)};
  transition: all 150ms ease-in-out;

  ${(props) => {
    if (props.active) {
      return `
          box-shadow: 0 0 2vmax 1vmin ${props.color};
      `;
    } else {
      return `
          box-shadow: 0 0 0 0 ${props.color};
        `;
    }
  }};

  @media screen and (orientation: portrait) {
    height: 6vh;
  }

  @media screen and (orientation: landscape) {
    height: 8vh;
  }
`);

export const ButtonSpeakerButton = styled(SpeakerButton)`
  max-height: 3vmax;
  width: 3vmax;
  margin: 0 0.8vmax;
`;

export const ButtonSplitter = styled.div`
  height: 3.2vmax;
  background-color: ${textColor};
  width: 1px;
`;

export const ButtonText = styled(Button)`
  color: ${titleColor};
  font-family: "ComicNeue-Bold";
  line-height: 6vh;
  text-align: left;
  width: 100%;

  @media screen and (orientation: portrait) {
    font-size: 2.4vh;
    margin-left: 1vh;
    margin-right: 1vh;
  }

  @media screen and (orientation: landscape) {
    font-size: 3.5vh;
    margin-left: 1.5vh;
    margin-right: 1.5vh;
  }
`;

export const WrongImage = styled(Image).attrs((props) => ({
  src: resource("images/Small_Answer_Wrong_Anim.svg"),
  scaling: "fill",
  fadeIn: "always",
}))`
  position: absolute;
  z-index: 3;
  margin-left: -1vh;
  height: 12vh;
  width: 12vh;
`;

export const GoodImage = styled(Image).attrs((props) => ({
  src: resource("images/Small_Answer_Good_Anim.svg"),
  scaling: "fill",
  fadeIn: "always",
}))`
  position: absolute;
  z-index: 3;
  margin-left: -1vh;
  height: 12vh;
  width: 12vh;
`;

export const ChoiceWordButton = staggerStep({ transition: "slide-and-scale-from-left", order: 0 })(
  styled(WordButton)``,
);
