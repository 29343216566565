const checkVisible = (node) => node.visibility === "public" || node.visibility === "discovered";
const checkDiscoverable = (node) => node.visibility !== "private"; // Also consider public and discovered nodes "discoverable"

class Node {
  parent = null;
  texts = [];
  medias = [];
  children = [];
  allChildren = [];
  tags = [];
  allTags = [];
  settings = {};
  addons = {};
  storage = {}; // For use by application code to store arbitrary information

  constructor(id, fields) {
    this.id = id;
    Object.assign(this, fields);
  }

  // #region Depth

  get depth() {
    let depth = 0;
    let node = this;
    while (!!node.parent) {
      depth += 1;
      node = node.parent;
    }
    return depth;
  }

  // #endregion

  // #region Visibility and Discoverability

  /** Returns true if the node itself is visible, regardless of its ancestors' visibility. */
  get isVisible() {
    return checkVisible(this);
  }

  /** Returns true if the node is visible AND is within ancestors which are also visible. */
  #internalIsActuallyVisible(node = this) {
    if (node.parent === null) return checkVisible(node);
    return checkVisible(node) && this.#internalIsActuallyVisible(node.parent);
  }

  get isActuallyVisible() {
    return this.#internalIsActuallyVisible(this);
  }

  /** Returns true if the node itself is discoverable, regardless of its ancestors' discoverability. */
  get isDiscoverable() {
    return checkDiscoverable(this);
  }

  /** Returns true if the node is discoverable AND is within ancestors which are also discoverable. */
  #internalIsActuallyDiscoverable(node = this) {
    if (node.parent === null) return checkDiscoverable(node);
    return checkDiscoverable(node) && this.#internalIsActuallyDiscoverable(node.parent);
  }

  get isActuallyDiscoverable() {
    return this.#internalIsActuallyDiscoverable(this);
  }

  // #endregion

  // #region Siblings

  get siblings() {
    return this.parent.children;
  }

  sibling(increment, { wrapping } = {}) {
    const parent = this.parent;
    if (!parent) return null;

    const siblings = parent.children;
    const indexOfCurrent = siblings.indexOf(this);
    let nextIndex = indexOfCurrent + increment;

    if (wrapping) {
      if (nextIndex < 0) nextIndex = siblings.length - 1;
      if (nextIndex > siblings.length - 1) nextIndex = 0;
    }

    // If we're out of bounds (won't happen if wrapping)
    if (nextIndex < 0 || nextIndex >= siblings.length) return null;

    return siblings[nextIndex];
  }

  previousSibling(options) {
    return this.sibling(-1, options);
  }

  nextSibling(options) {
    return this.sibling(+1, options);
  }

  // #endregion

  // #region Ancestors

  ancestorWithSemanticIncludingSelf(semantic) {
    if (this.semantic === semantic) return this;
    return this.ancestorWithSemantic(semantic);
  }

  ancestorWithSemantic(semantic) {
    if (this.parent.semantic === semantic) return this.parent;
    return this.parent.ancestorWithSemantic(semantic);
  }

  #cachedAncestors = null; // Avoids returning a new array (and re-calculating) each time; nodes don't change
  get ancestors() {
    if (this.#cachedAncestors) return this.#cachedAncestors;
    const ancestors = [];
    let node = this;
    while (node.parent) {
      ancestors.push(node.parent);
      node = node.parent;
    }
    this.#cachedAncestors = ancestors;
    return ancestors;
  }

  // #endregion

  // #region Descendants

  #cachedDescendants = null; // Avoids returning a new array (and re-calculating) each time; nodes don't change
  get descendants() {
    if (this.#cachedDescendants) return this.#cachedDescendants;
    const descendants = this.#internalDescendants();
    this.#cachedDescendants = descendants;
    return descendants;
  }

  #internalDescendants(node = this) {
    const descendants = [];
    descendants.push(...node.children);
    descendants.push(...node.children.flatMap((child) => this.#internalDescendants(child)));
    return descendants;
  }

  // #endregion
}

export default Node;
