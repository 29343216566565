import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { mergeRefs } from "react-merge-refs";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";
import { Audio, resource, useData, useMeasure } from "ripple";
import FeedbackLayer from "../../../components/feedback-layer";
import ImageLayer from "../../../components/image-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import SpeakerButtonLayer from "../../../components/speaker-button-layer";
import { useFeedback } from "../../../hooks/use-feedback";
import {
  ConnectionCircle,
  Content,
  ItemBox,
  LeftColumn,
  LinesCanvas,
  RightColumn,
  Row,
  Type14QuestionRoot,
} from "./styled";

const getBoundsIn = (element, ancestor) => {
  const elementRect = element.getBoundingClientRect();
  const ancestorRect = ancestor.getBoundingClientRect();

  return {
    top: elementRect.top - ancestorRect.top,
    left: elementRect.left - ancestorRect.left,
    width: elementRect.width,
    height: elementRect.height,
  };
};

const getDistance = (point1, point2) => {
  return Math.sqrt((point2.x - point1.x) ** 2 + (point2.y - point1.y) ** 2);
};

const isPairGood = (pair) => {
  return pair[0].node.identifier === pair[1].node.identifier;
};

const Type14Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const isVeryPortrait = useMediaQuery({ query: "(max-aspect-ratio: 1/1.5)" });

  const { feedbacks, addPermanentFeedback, addMomentaryFeedback } = useFeedback();

  const leftColumnNode = node.children[0];
  const leftItems = leftColumnNode.children;

  const rightColumnNode = node.children[1];
  const rightItems = rightColumnNode.children;

  const color = node.wantedInheritedText("Color")?.trim();

  const [canvasMeasureRef, canvasBounds] = useMeasure();

  const contentRef = useRef(null);
  const canvasRef = useRef(null);

  // "Permanent" state
  const [circles, setCircles] = useState([]);
  const [circlePairs, setCirclePairs] = useState([]);

  // Temporary state
  const [startCircle, setStartCircle] = useState(null);
  const [pointerPosition, setPointerPosition] = useState(null);

  // Create circle models as soon as possible
  useLayoutEffect(() => {
    const circleElements = [...contentRef.current.querySelectorAll(".connection-circle")];
    const circles = circleElements.map((e) => {
      const bounds = getBoundsIn(e, contentRef.current);
      return {
        node: [...leftItems, ...rightItems].find((n) => n.id === e.id),
        bounds: bounds,
        center: {
          x: bounds.left + bounds.width / 2,
          y: bounds.top + bounds.height / 2,
        },
      };
    });
    setCircles(circles);
  }, [canvasBounds, leftItems, rightItems]);

  // #region Utility

  const getPointerPosition = useCallback((event) => {
    const rect = contentRef.current.getBoundingClientRect();
    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };
  }, []);

  const getClosestCircle = useCallback(
    (point) => {
      if (point === null) return null;
      return _.minBy(circles, (c) => getDistance(point, c.center));
    },
    [circles],
  );

  // #endregion

  // #region Pointer Events

  const onPointerDown = useCallback(
    (event) => {
      if (event.target.className?.includes("hotspot")) return;
      const pointerPosition = getPointerPosition(event);
      const closestCircle = getClosestCircle(pointerPosition);

      // If dragging from a circle that has already been paired, do nothing
      if (circlePairs.flatMap((p) => p).includes(closestCircle)) return;

      setStartCircle(closestCircle);
      setPointerPosition(pointerPosition);

      Audio.discrete("effects").play(resource("audio/ClicDrag.mp3"));
    },
    [getClosestCircle, getPointerPosition, circlePairs],
  );

  const onPointerMove = useCallback(
    (event) => {
      if (!startCircle) return;
      setPointerPosition(getPointerPosition(event));
    },
    [getPointerPosition, startCircle],
  );

  const onPointerUp = useCallback(
    (event) => {
      if (!startCircle) return;

      Audio.discrete("effects").play(resource("audio/DragRelease.mp3"));

      setStartCircle(null);
      setPointerPosition(null);

      const closestCircle = getClosestCircle(getPointerPosition(event));

      // Do nothing if dragging to the same circle
      if (closestCircle.node === startCircle.node) return;

      // Do nothing if dragging to the same column
      if (closestCircle.node.parent === startCircle.node.parent) return;

      // Remove existing pair if dragging to an already-paired circle
      let newPairs = circlePairs.filter((p) => !p.includes(closestCircle));

      // Add the valid new pair to the cleaned-up list of pairs
      newPairs = [...newPairs, [startCircle, closestCircle]];

      setCirclePairs(newPairs);
    },
    [getClosestCircle, getPointerPosition, circlePairs, startCircle],
  );

  // #endregion

  // Highlight logic
  // useEffect(() => {
  //   if (!startCircle) return;
  //   const closestCircle = getClosestCircle(pointerPosition);

  // });

  // Completion logic
  const [completed, setCompleted] = useState(false);
  useEffect(() => {
    if (completed) return;

    const requiredGoodPairCount = leftColumnNode.children.length;
    if (circlePairs.length < requiredGoodPairCount) return;

    circlePairs.forEach((pair) => {
      const good = isPairGood(pair);
      const addFeedback = good ? addPermanentFeedback : addMomentaryFeedback;
      const feedbackType = good ? "good" : "bad";
      addFeedback(pair[0].node.id, feedbackType);
      addFeedback(pair[1].node.id, feedbackType);
    });

    const goodPairs = circlePairs.filter((p) => isPairGood(p));
    setCirclePairs(goodPairs);

    if (goodPairs.length === requiredGoodPairCount) {
      Audio.discrete("effects").play(resource(`audio/ThumbUp01.mp3`));
      setCompleted(true);
      onComplete();
    } else {
      Audio.discrete("effects").play(resource(`audio/ThumbDown01.mp3`));
    }
  }, [addMomentaryFeedback, addPermanentFeedback, completed, leftColumnNode.children.length, onComplete, circlePairs]);

  // Draw lines and circles when things change
  useEffect(() => {
    if (circles.length === 0) return;

    const lineWidth = circles[0].bounds.width / 7;
    const ctx = canvasRef.current.getContext("2d");

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Draw circles
    const pairedCircles = circlePairs.flatMap((c) => c);
    [...pairedCircles, startCircle].forEach((c) => {
      if (!c) return;
      ctx.beginPath();
      ctx.arc(c.center.x, c.center.y, c.bounds.width / 5, 0, Math.PI * 2);
      ctx.fillStyle = color;
      ctx.fill();
      ctx.closePath();
    });

    // Draw paired lines
    circlePairs.map((pair) => {
      ctx.beginPath();
      ctx.moveTo(pair[0].center.x, pair[0].center.y);
      ctx.lineTo(pair[1].center.x, pair[1].center.y);
      ctx.lineWidth = lineWidth;
      ctx.lineCap = "round";
      ctx.strokeStyle = color;
      ctx.stroke();
    });

    // Draw live line
    if (startCircle && pointerPosition) {
      ctx.beginPath();
      ctx.moveTo(startCircle.center.x, startCircle.center.y);
      ctx.lineTo(pointerPosition.x, pointerPosition.y);
      ctx.lineWidth = lineWidth;
      ctx.lineCap = "round";
      ctx.strokeStyle = color;
      ctx.stroke();
    }
  }, [circles, color, circlePairs, pointerPosition, startCircle]);

  const renderItem = (item, side) => {
    const isDragging = !!startCircle;
    const isInStartColumn = startCircle?.node.parent.children.includes(item);
    const isInPairs = circlePairs
      .flatMap((p) => p)
      .map((c) => c.node)
      .includes(item);
    const closestCircle = getClosestCircle(pointerPosition);
    const isHighlighted = isDragging && !isInStartColumn && item === closestCircle.node && !isInPairs;
    return (
      <Row>
        {side === "right" && <ConnectionCircle className="connection-circle" id={item.id} glow={isHighlighted} />}
        <ItemBox glow={isHighlighted} $size={isVeryPortrait ? "14vh" : "17vh"}>
          <Layers>
            <ImageLayer src={item.wantedMedia("Image", "Image")} />
            <SpeakerButtonLayer src={item.optionalMedia("Audio", "Audio")} corner={side === "left" ? 3 : 4} />
            <FeedbackLayer feedback={feedbacks[item.id]} muted />
          </Layers>
        </ItemBox>
        {side === "left" && <ConnectionCircle className="connection-circle" id={item.id} glow={isHighlighted} />}
      </Row>
    );
  };

  return (
    <Type14QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent>
        <Content ref={contentRef} onPointerDown={onPointerDown} onPointerMove={onPointerMove} onPointerUp={onPointerUp}>
          <LeftColumn>{leftItems.map((item) => renderItem(item, "left"))}</LeftColumn>
          <RightColumn>{rightItems.map((item) => renderItem(item, "right"))}</RightColumn>
          <LinesCanvas
            ref={mergeRefs([canvasRef, canvasMeasureRef])}
            width={canvasBounds.width}
            height={canvasBounds.height}
          />
        </Content>
      </ResponsiveContent>
    </Type14QuestionRoot>
  );
};

Type14Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type14Question;
