import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { useDebug } from "ripple";
import {
  standardQuestionLandscapeContentPadding,
  standardQuestionPortraitContentPadding,
  standardQuestionPortraitTighterContentPadding,
} from "../../common";
import { useIsPortrait } from "../../hooks/use-is-portrait";
import { BottomPanel, MainContent, ResponsiveContentRoot, SidePanel } from "./styled";

const ResponsiveContent = ({
  className,
  style,
  sidePanelContent,
  bottomPanelContent,
  landscapeMainContentWidth,
  children,
  ...rest
}) => {
  const isPortrait = useIsPortrait();
  const isNotVeryPortrait = !useMediaQuery({ query: "(max-aspect-ratio: 1/1.5)" });
  const debug = useDebug();
  return (
    <ResponsiveContentRoot
      {...rest}
      className={className}
      style={style}
      $width={isPortrait ? "100%" : landscapeMainContentWidth ?? "auto"}
    >
      {!isPortrait && sidePanelContent && <SidePanel $debug={debug}>{sidePanelContent}</SidePanel>}
      <MainContent
        $debug={debug}
        $horizontalPadding={
          isPortrait
            ? isNotVeryPortrait
              ? standardQuestionPortraitTighterContentPadding
              : standardQuestionPortraitContentPadding
            : standardQuestionLandscapeContentPadding
        }
      >
        {children}
      </MainContent>
      {isPortrait && bottomPanelContent && <BottomPanel $debug={debug}>{bottomPanelContent}</BottomPanel>}
    </ResponsiveContentRoot>
  );
};

ResponsiveContent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  sidePanelContent: PropTypes.node,
  bottomPanelContent: PropTypes.node,
  landscapeMainContentWidth: PropTypes.string,
};

export default ResponsiveContent;
