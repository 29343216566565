import { PureComponent } from "react";
import PropTypes from "prop-types";
import { DragLayer } from "react-dnd";

function collect(monitor) {
  const item = monitor.getItem();
  return {
    id: item && item.id,
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
}

function getItemStyles(currentOffset) {
  if (!currentOffset) return { display: "none" };

  return {
    zIndex: 1,
    position: "fixed",
    top: 0,
    left: 0,
    pointerEvents: "none",
    userSelect: "none",
    transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
  };
}

class DragPreview extends PureComponent {
  static propTypes = {
    id: PropTypes.any,
    render: PropTypes.func.isRequired,
    currentOffset: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    isDragging: PropTypes.bool,
  };

  render() {
    if (!this.props.isDragging) return null;

    return <div style={getItemStyles(this.props.currentOffset)}>{this.props.render(this.props.id)}</div>;
  }
}

export default DragLayer(collect)(DragPreview);
