import gsap, { Power3 } from "gsap";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef } from "react";
import { Audio, resource } from "ripple";
import { FeedbackOverlayRoot, ImageContainer, ThumbImage, feedbackAnimDuration } from "./styled";

const getColor = (type) => {
  if (type === "bad") return "rgba(255, 151, 151, 0.8)";
  return "rgba(158, 255, 151, 0.8)";
};

const getImageSuffix = (type) => {
  if (type === "good") return "Up";
  if (type === "bad") return "Down";
  return "Up";
};

const getAudioSuffix = (type) => {
  if (type === "good") return "Up";
  if (type === "bad") return "Down";
  return "Up";
};

const FeedbackOverlay = ({ className, style, feedback, muted = false, ...rest }) => {
  const thumbMainRef = useRef(null);
  const thumbOvershootRef = useRef(null);

  const animDurationInSeconds = feedbackAnimDuration / 1000;

  const show = feedback?.show;
  const type = feedback?.type;

  const playShowAnimation = useCallback(
    ({ overshoot = true }) => {
      if (!muted) Audio.discrete("effects").play(resource(`audio/Thumb${getAudioSuffix(type)}01.mp3`));

      // Main thumb
      gsap.to(thumbMainRef.current, {
        opacity: 1,
        scale: 1,
        duration: animDurationInSeconds,
        ease: Power3.easeInOut,
      });

      // Overshoot thumb
      if (overshoot) {
        gsap.fromTo(
          thumbOvershootRef.current,
          { scale: 0.3 },
          {
            scale: 1.5,
            duration: animDurationInSeconds,
            ease: Power3.easeInOut,
          },
        );
        const timeline = gsap.timeline();
        timeline.fromTo(
          thumbOvershootRef.current,
          { opacity: 0 },
          {
            opacity: 1,
            duration: animDurationInSeconds / 2,
          },
        );
        timeline.to(thumbOvershootRef.current, {
          opacity: 0,
          duration: animDurationInSeconds / 2,
        });
      }
    },
    [animDurationInSeconds, muted, type],
  );

  const playHideAnimation = useCallback(() => {
    gsap.to(thumbMainRef.current, {
      opacity: 0,
      scale: 0.3,
      duration: animDurationInSeconds,
      ease: Power3.easeInOut,
    });
  }, [animDurationInSeconds]);

  useEffect(() => {
    if (show) playShowAnimation({ overshoot: type !== "bad" });
    else playHideAnimation();
  }, [animDurationInSeconds, playHideAnimation, playShowAnimation, show, type]);

  return (
    <FeedbackOverlayRoot {...rest} className={className} style={style} $color={getColor(type)} $show={show}>
      <ImageContainer className="app-feedback-overlay-image-container">
        <ThumbImage ref={thumbOvershootRef} src={resource(`images/Thumb-${getImageSuffix(type)}.svg`)} $show={show} />
        <ThumbImage ref={thumbMainRef} src={resource(`images/Thumb-${getImageSuffix(type)}.svg`)} $show={show} />
      </ImageContainer>
    </FeedbackOverlayRoot>
  );
};

FeedbackOverlay.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  feedback: PropTypes.object,
  muted: PropTypes.bool,
};

export default FeedbackOverlay;
