import Sequencer from "../sequencer";

class DoSequenceTask {
  sequencer = new Sequencer();

  constructor(action) {
    this.action = action;
  }

  perform(release) {
    this.action(this.sequencer); // Let the caller enqueue his own subtasks
    this.sequencer.do(release); // Enqueue a final task to release the sequence task's handle
  }

  cancel(release) {
    this.sequencer.clear();
    release();
  }
}

Sequencer.prototype.doSequence = function (action) {
  this.push(new DoSequenceTask(action));
  return this;
};
