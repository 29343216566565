import PropTypes from "prop-types";
import { MediaSrcPropType } from "ripple";
import ImageLayer from "../image-layer";
import Layers from "../layers";
import SpeakerButtonLayer from "../speaker-button-layer";
import { Bottom, ImageAudioContentBoxRoot, Top } from "./styled";

const ImageAudioContentBox = ({ className, style, image, audio, children, layers, ...rest }) => {
  return (
    <ImageAudioContentBoxRoot {...rest} className={className} style={style} {...rest}>
      <Top>
        <Layers>
          <ImageLayer src={image} />
          <SpeakerButtonLayer src={audio} style={{ zIndex: 1 }} />
        </Layers>
      </Top>
      <Bottom>{children}</Bottom>
      <Layers>{layers}</Layers>
    </ImageAudioContentBoxRoot>
  );
};

ImageAudioContentBox.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  image: MediaSrcPropType,
  audio: MediaSrcPropType,
  children: PropTypes.node,
  layers: PropTypes.node,
};

export default ImageAudioContentBox;
