import PropTypes from "prop-types";
import { MediaSrcPropType } from "ripple";
import { ImageLayerRoot } from "./styled";

const ImageLayer = ({ className, style, src, ...rest }) => {
  return <ImageLayerRoot {...rest} className={className} style={style} src={src} />;
};

ImageLayer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  src: MediaSrcPropType,
};

export default ImageLayer;
