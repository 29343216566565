import Localization from "../../../helpers/localization";
import LanguageActions from "../../actions/local/language-actions";

export default (state = Localization.getDefaultLanguage(), action) => {
  switch (action.type) {
    case LanguageActions.LANGUAGE_CHANGE:
      return action.language;
    default:
      return state;
  }
};
