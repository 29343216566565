import { Image, resource } from "ripple";
import styled from "styled-components";
import SpeakerButton from "../speaker-button";

export const SongActivityCardContentRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MascotImage = styled(Image).attrs((props) => ({ scaling: "fit" }))`
  position: absolute;
  height: 80%;
  transform: translate3d(-20%, 0, 0);
  pointer-events: none;
`;

export const PhylacteryContainer = styled.div`
  position: absolute;
  height: 65%;
  width: 50%;
  transform: translate3d(20%, -30%, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Phylactery = styled(Image).attrs((props) => ({ src: resource("images/Phylatere-Song.svg") }))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledSpeakerButton = styled(SpeakerButton)`
  position: absolute;
  width: 5vh;
  height: 5vh;
  z-index: 1;
  .hotspot {
    margin: -4vmin;
  }
`;

export const SongAnim = styled(Image).attrs((props) => ({ src: resource("images/Song-anim.svg") }))`
  transform: scale3d(1.3, 1.3, 1);
  transition: all 250ms ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
`;
