import PropTypes from "prop-types";
import { Image, useNodeMediasDownloaded } from "ripple";
import { DownloadSymbol, ThemeButtonRoot, UndownloadedOverlay } from "./styled";

const ThemeButton = ({ className, style, theme, active, ...rest }) => {
  const downloaded = useNodeMediasDownloaded(theme);

  return (
    <ThemeButtonRoot {...rest} className={className} style={style} $active={active}>
      <Image src={theme.wantedMedia("Icon", "PreserveSizeWithAlpha")} />
      {!downloaded && (
        <UndownloadedOverlay>
          <DownloadSymbol />
        </UndownloadedOverlay>
      )}
    </ThemeButtonRoot>
  );
};

ThemeButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.object,
  active: PropTypes.bool,
};

export default ThemeButton;
