/* eslint-disable react-hooks/exhaustive-deps */
import { produce } from "immer";
import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { Audio, DragPreview, resource, useData } from "ripple";
import { GoodImage, LandscapeCardGrid, PortraitCardGrid, WrongImage, textSize } from "../../../common";
import { useIsPortrait } from "../../../hooks/use-is-portrait";
import {
  BlockOverlay,
  ButtonContentPreview,
  Card,
  CardBar,
  CardDropContainer,
  CardDropzone,
  CardImage,
  CardText,
  CardTextContainer,
  ChoiceWordButton,
  Content,
  DragWordImageRoot,
  DroppableContainer,
  GridContainer,
  LandscapeButtonContainer,
  PortraitButtonContainer,
  QuestionMarkImage,
  WordButtonContainer,
  WordDroppable,
  WordTextDroppable,
} from "./styled";

const DragWordImage = ({ onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const isPortrait = useIsPortrait();
  const items = node.children;

  const buttonItems = useMemo(() => _.shuffle(node.children), [node.children]);

  const color = node.parent.wantedText("Color")?.trim().toLowerCase();
  const [drops, setDrops] = useState({});
  const [wrongDrop, setWrongDrop] = useState(null);
  const hasDroppedAll = Object.keys(drops).length === 4;
  const buttonContainerRef = useRef(null);
  const [offset, setOffset] = useState(0);

  const onDragStart = useCallback(() => {}, []);

  const onTouch = useCallback(() => Audio.discrete("effects").play(resource("audio/ClicDrag.mp3")), []);

  const onDragEnd = useCallback(() => {
    Audio.discrete("effects").play(resource("audio/DragRelease.mp3"));
  }, []);

  const onDrop = useCallback(
    (dropzoneId, droppableId) => {
      if (droppableId.id !== dropzoneId) {
        Audio.discrete("effects").play(resource("audio/Drop_Wrong.mp3"));
        setWrongDrop(dropzoneId);
      } else {
        Audio.discrete("effects").play(resource("audio/Drop_Good.mp3"));
      }
      setDrops(
        produce((draft) => {
          draft[dropzoneId] = droppableId;
        }),
      );
    },
    [drops, setDrops],
  );
  useEffect(() => {
    setTimeout(() => {
      setOffset(buttonContainerRef?.current?.clientWidth);
    }, 10);
  }, []);

  useEffect(() => {
    if (!wrongDrop) return;

    setTimeout(() => {
      setDrops(
        produce(drops, (draft) => {
          delete draft[wrongDrop];
        }),
      );
      setWrongDrop(null);
    }, 1500);
  }, [drops]);

  useEffect(() => {
    if (!hasDroppedAll) return;
    setTimeout(() => {
      onComplete();
    }, 1500);
  }, [hasDroppedAll]);

  const createRenderDropzoneForegroundLayer = (item, drop) => () => {
    const size = textSize(drop?.optionalText("Text"));
    return (
      item.id in drops && (
        <CardDropContainer>
          <CardTextContainer>
            <CardText size={size}>{drop.wantedText("Text")}</CardText>
          </CardTextContainer>
        </CardDropContainer>
      )
    );
  };

  const createRenderDropzoneEmptyLayer = (item) => () => {
    return (
      !(item.id in drops) && (
        <CardDropContainer>
          <QuestionMarkImage />
        </CardDropContainer>
      )
    );
  };

  const renderCard = (item, index) => {
    const hasdrop = item.id in drops;
    const isCorrect = drops[item.id]?.id === item.id;
    return (
      <Card key={item.id} color={color}>
        <CardImage src={item.wantedMedia("ClickImage", "FlipImage")} />
        <CardBar color={color}></CardBar>
        <CardDropzone
          id={item.id}
          color={color}
          onDrop={onDrop}
          active={hasdrop}
          hasDropped={hasdrop}
          renderEmptyLayer={createRenderDropzoneEmptyLayer(item)}
          renderForegroundLayer={createRenderDropzoneForegroundLayer(item, drops[item.id])}
        />
        {hasdrop && isCorrect && <GoodImage />}
        {hasdrop && !isCorrect && <WrongImage />}
      </Card>
    );
  };

  const createRenderDroppableForegroundLayer = (item) => () => (
    <WordTextDroppable show={true}>{item.wantedText("Text")}</WordTextDroppable>
  );

  const createRenderDroppableEmptyLayer = (item) => () => (
    <WordTextDroppable show={true}>{item.wantedText("Text")}</WordTextDroppable>
  );

  const renderWordButton = (item, index) => {
    const active = !Object.values(drops)
      .map((d) => d.id)
      .includes(item.id);

    return (
      <ChoiceWordButton item={item} key={item.id} color={color} disabled={!active} hasDrop={!active}>
        <DroppableContainer>
          <WordTextDroppable show={false}>{item.wantedText("Text")}</WordTextDroppable>
          <WordDroppable
            key={item.id}
            id={item}
            onDragStart={onDragStart}
            onTouch={onTouch}
            onDragEnd={onDragEnd}
            hasDropped={!active}
            renderForegroundLayer={createRenderDroppableForegroundLayer(item)}
            renderEmptyLayer={createRenderDroppableEmptyLayer(item)}
          ></WordDroppable>
        </DroppableContainer>
      </ChoiceWordButton>
    );
  };

  const renderDragPreview = useCallback(
    (item) => (
      <ButtonContentPreview key={item.id} color={color}>
        <CardText size={textSize(item.wantedText("Text"))}>{item.wantedText("Text")}</CardText>
      </ButtonContentPreview>
    ),
    [color],
  );

  return (
    <DragWordImageRoot {...rest}>
      <Content $offset={-offset}>
        {isPortrait ? (
          <>
            <PortraitCardGrid>{items.map(renderCard)}</PortraitCardGrid>
            <PortraitButtonContainer>{buttonItems.map(renderWordButton)}</PortraitButtonContainer>
          </>
        ) : (
          <>
            <WordButtonContainer ref={buttonContainerRef}>
              <LandscapeButtonContainer>{buttonItems.map(renderWordButton)}</LandscapeButtonContainer>
            </WordButtonContainer>
            <GridContainer>
              <LandscapeCardGrid>{items.map(renderCard)}</LandscapeCardGrid>
              {wrongDrop && <BlockOverlay></BlockOverlay>}
            </GridContainer>
          </>
        )}
      </Content>
      <DragPreview render={renderDragPreview} />
    </DragWordImageRoot>
  );
};

DragWordImage.propTypes = {
  onComplete: PropTypes.func,
};

export default DragWordImage;
