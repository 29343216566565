import { useContext, useEffect } from "react";
import { Config, RootBackgroundContext } from "ripple";

export const useTwoStepGameRootBackground = (game) => {
  const replaceRootBackground = useContext(RootBackgroundContext);
  useEffect(() => {
    replaceRootBackground(game.optionalInheritedMedia(["BackgroundIn"], "FullscreenImage"));
    const timeout = setTimeout(() => {
      replaceRootBackground(game.optionalInheritedMedia(["BackgroundInBlurred"], "FullscreenImageHighQuality"));
    }, Config.blurredBackgroundTransitionDelay);
    return () => clearTimeout(timeout);
  }, [game, replaceRootBackground]);
};
