import gsap from "gsap";
import PropTypes from "prop-types";
import { forwardRef, memo, useCallback, useEffect, useRef, useState } from "react";
import Load from "../../../helpers/load";
import { MediaSrcPropType } from "../../../logic/prop-types";
import MediaFile from "../../../model/media-file";
import MapContent from "./map-content";

const fullsizeStyle = { position: "absolute", top: 0, left: 0, width: "100%", height: "100%" };

/**
 * A map content sub-type that loads an image as its background and sizes
 * itself automatically based on the image's dimensions.
 */
const MapImageContent = memo(
  forwardRef(({ src, children, onLoad, onBackgroundClick, ...rest }, ref) => {
    const imageContainerRef = useRef(null);

    const [imageSize, setImageSize] = useState(null);

    const onBackgroundClickInternal = useCallback(
      (event) => {
        if (event.target === event.currentTarget) onBackgroundClick?.();
      },
      [onBackgroundClick],
    );

    // Load the image when the actual src changes
    useEffect(() => {
      imageContainerRef.current?.replaceChildren(); // Remove old image if any
      const actualSrc = src instanceof MediaFile ? src.url : src;
      if (!actualSrc) return;
      Load.image(actualSrc).then((image) => {
        setImageSize({ width: image.width, height: image.height });
        // Append the image after React has some time to render the MapContent
        setTimeout(() => {
          Object.assign(image.style, fullsizeStyle);
          image.style.opacity = 0;
          imageContainerRef.current.appendChild(image);
          // Give some time for the browser to wake up after inserting the big image
          setTimeout(() => {
            gsap.to(image.style, { opacity: 1, duration: 0.25 });
            onLoad?.();
          });
        }, 100);
      });
    }, [onLoad, src]);

    // Render with the size of the loaded image
    return (
      imageSize && (
        <MapContent ref={ref} width={imageSize.width} height={imageSize.height} {...rest}>
          <div ref={imageContainerRef} style={fullsizeStyle} />
          <div
            onClick={onBackgroundClickInternal}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {children}
          </div>
        </MapContent>
      )
    );
  }),
);

MapImageContent.propTypes = {
  src: MediaSrcPropType,
  children: PropTypes.node,
  onLoad: PropTypes.func,
  onBackgroundClick: PropTypes.func,
};

export default MapImageContent;
