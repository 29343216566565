import { Image } from "ripple";
import styled from "styled-components";

export const feedbackAnimDuration = 500;

export const FeedbackOverlayRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.$color};
  transition: all ${feedbackAnimDuration}ms ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  pointer-events: none;
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ThumbImage = styled(Image)`
  position: absolute;
  width: 50%;
  height: 50%;
  min-height: 4.5vh;
  opacity: 0;
  transform: scale3d(0.3, 0.3, 1);
`;
