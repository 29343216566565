import Hammer from "@egjs/hammerjs";
import { gsap, Power3 } from "gsap/all";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  createContext,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import Classes from "../../../helpers/classes";
import Clipboard from "../../../helpers/clipboard";
import Maths from "../../../helpers/maths";
import Styles from "../../../helpers/styles";
import Toast from "../../../helpers/toast";
import Point from "../../../types/point";
import Rect from "../../../types/rect";
import Size from "../../../types/size";
import { Stagger } from "../stagger";

export const MapContentContext = createContext();

/**
 * The manipulatable "canvas" in a Map. You can either use this directly
 * (custom layout), or you can use one of the content sub-types:
 * Map.ImageContent, Map.VideoContent, etc.
 */
const MapContent = memo(
  forwardRef(
    (
      {
        className,
        style,
        children,
        width: contentWidth,
        height: contentHeight,
        maxZoom = 5,
        interactive = true,
        staggerOptions,
        onZoom,
        ...rest
      },
      ref,
    ) => {
      const [visible, setVisible] = useState(false);
      const rootRef = useRef(null);
      const zoomSubscriptions = useRef({});

      const translateXRef = useRef(0);
      const translateYRef = useRef(0);
      const baseScaleRef = useRef(1); // The initial scale that's applied to fill the map with this root
      const contentScaleRef = useRef(1); // The scaled induced by manipulation of the map

      const getViewportScale = useCallback(() => baseScaleRef.current * contentScaleRef.current, []);

      // ###### Map.Element Instances ######

      const updateElements = useCallback(() => {
        _.each(zoomSubscriptions.current, (c) => c?.(contentScaleRef.current, getViewportScale()));
      }, [getViewportScale]);

      // ###### Calculations ######

      const calculateTranslationToCenterContentCoordsPoint = useCallback(
        (point) => {
          const viewportScale = getViewportScale();
          const pointInViewportCoords = point.scaledBy(viewportScale);
          const contentSize = new Size(contentWidth, contentHeight);
          const contentSizeInViewportCoords = contentSize.scaledBy(viewportScale);

          // Clamp position in viewport coords
          // TODO: Fix this logic, it messes up the translation!
          // TODO: What it tries to do is to restrain the area where the "center point"
          // TODO: can be located, but it seems like it does not take the current zoom level
          // TODO: into account (break into this code to see the calculated min and max values;
          // TODO: when switching between zooming in on a frame and fully zooming out, it seems
          // TODO: like the map dimensions BEFORE the zoom occurs are used to calculate the clamping
          // TODO: while it should be caculated *for the dimensions the map will have AFTER the zoom*!).
          // const mapElement = rootRef.current.parentNode;
          // const viewportSize = new Size(mapElement.clientWidth, mapElement.clientHeight);
          // const minX = viewportSize.width / 2;
          // const minY = viewportSize.height / 2;
          // const maxX = contentSizeInViewportCoords.width - viewportSize.width / 2;
          // const maxY = contentSizeInViewportCoords.height - viewportSize.height / 2;
          // pointInViewportCoords.x = Math.max(minX, Math.min(maxX, pointInViewportCoords.x));
          // pointInViewportCoords.y = Math.max(minY, Math.min(maxY, pointInViewportCoords.y));

          const offset = {
            offsetX: -(pointInViewportCoords.x - contentSizeInViewportCoords.width / 2),
            offsetY: -(pointInViewportCoords.y - contentSizeInViewportCoords.height / 2),
          };

          return offset;
        },
        [contentHeight, contentWidth, getViewportScale],
      );

      const calculateScaleToFitContentCoordsFrame = useCallback(
        (frame) => {
          const mapElement = rootRef.current.parentNode;
          const viewportSize = new Size(mapElement.clientWidth, mapElement.clientHeight);
          const contentSize = new Size(contentWidth, contentHeight);
          const scaleToFitFrameInViewport = frame.size.scaleFactorToFitProportionallyIn(viewportSize);
          const scaleToFillViewportWithContent = contentSize.scaleFactorToFillProportionallyIn(viewportSize);
          return scaleToFitFrameInViewport / scaleToFillViewportWithContent;
        },
        [contentHeight, contentWidth],
      );

      // ###### Animation ######

      const animate = useCallback((duration, values, targetValues, onUpdate) => {
        gsap.to(values, {
          ...targetValues,
          duration,
          ease: Power3.easeInOut,
          onUpdate: () => onUpdate(values),
        });
      }, []);

      // ###### Transform Functions ######

      const clampTransformTranslation = useCallback(
        (point) => {
          const viewportScale = getViewportScale();
          const contentSizeInViewportCoords = new Size(contentWidth, contentHeight).scaledBy(viewportScale);
          const mapElement = rootRef.current.parentNode;
          const viewportSize = new Size(mapElement.clientWidth, mapElement.clientHeight);

          const limitX = (contentSizeInViewportCoords.width / 2 - viewportSize.width / 2) / viewportScale;
          const limitY = (contentSizeInViewportCoords.height / 2 - viewportSize.height / 2) / viewportScale;

          return new Point(Maths.clamp(point.x, -limitX, limitX), Maths.clamp(point.y, -limitY, limitY));
        },
        [getViewportScale, contentHeight, contentWidth],
      );

      const getViewportPositionForEvent = useCallback((event) => {
        const element = rootRef.current.parentNode;
        const bounds = element.getBoundingClientRect();
        return new Point(
          (event.center?.x ?? event.clientX) - bounds.left,
          (event.center?.y ?? event.clientY) - bounds.top,
        );
      }, []);

      const translateByViewportDelta = useCallback(
        (viewportDeltaX, viewportDeltaY) => {
          // Because we translate *after* scaling, we must compensate for the scale when calculating translation
          const viewportScale = getViewportScale();

          const { x, y } = clampTransformTranslation(
            new Point(
              translateXRef.current + viewportDeltaX / viewportScale,
              translateYRef.current + viewportDeltaY / viewportScale,
            ),
          );

          translateXRef.current = x;
          translateYRef.current = y;
        },
        [clampTransformTranslation, getViewportScale],
      );

      const setTranslation = useCallback((contentX, contentY) => {
        translateXRef.current = contentX;
        translateYRef.current = contentY;
      }, []);

      const resetTranslation = useCallback(() => {
        translateXRef.current = 0;
        translateYRef.current = 0;
      }, []);

      const setScale = useCallback(
        (wantedScale, aroundViewportPoint = null) => {
          if (!rootRef.current) return;

          const mapElement = rootRef.current.parentNode;
          const viewportSize = new Size(mapElement.clientWidth, mapElement.clientHeight);
          aroundViewportPoint = aroundViewportPoint ?? new Point(viewportSize.width / 2, viewportSize.height / 2);

          const oldScale = contentScaleRef.current;
          const newScale = Maths.clamp(wantedScale, 1, maxZoom);

          // Set the new scale
          contentScaleRef.current = newScale;

          // Offset so that the zoom point stays centered
          // Explanation: https://stackoverflow.com/a/30410948/167983
          const scaleDifference = newScale - oldScale;
          const offsetFromCenterInContentCoordsX = (aroundViewportPoint.x - viewportSize.width / 2) / oldScale;
          const offsetFromCenterInContentCoordsY = (aroundViewportPoint.y - viewportSize.height / 2) / oldScale;
          translateByViewportDelta(
            -(offsetFromCenterInContentCoordsX * scaleDifference),
            -(offsetFromCenterInContentCoordsY * scaleDifference),
          );

          onZoom?.(newScale);
        },
        [maxZoom, translateByViewportDelta, onZoom],
      );

      const resetScale = useCallback(() => {
        contentScaleRef.current = 1;
      }, []);

      const updateTransform = useCallback(() => {
        if (!rootRef.current) return;
        // Bypass React state for maximum performance
        const viewportScale = getViewportScale();
        rootRef.current.style.transform = `scale3d(${viewportScale}, ${viewportScale}, 1) translate3d(${translateXRef.current}px, ${translateYRef.current}px, 0)`;
        updateElements();
      }, [getViewportScale, updateElements]);

      // ###### Manipulation: Pan ######

      const panPositionRef = useRef(null);

      const onPanStart = useCallback(
        (event) => {
          if (!interactive) return;
          panPositionRef.current = getViewportPositionForEvent(event);
        },
        [getViewportPositionForEvent, interactive],
      );

      const onPanMove = useCallback(
        (event) => {
          if (!interactive) return;
          if (event.srcEvent.altKey) return;

          const position = getViewportPositionForEvent(event);
          translateByViewportDelta(position.x - panPositionRef.current.x, position.y - panPositionRef.current.y);
          updateTransform();

          panPositionRef.current = position;
        },
        [getViewportPositionForEvent, translateByViewportDelta, updateTransform, interactive],
      );

      // ###### Manipulation ######

      const pinchStartScaleRef = useRef(null);
      const pinchPositionRef = useRef(null);

      const onPinchStart = useCallback(
        (event) => {
          if (!interactive) return;
          pinchStartScaleRef.current = contentScaleRef.current;
          pinchPositionRef.current = getViewportPositionForEvent(event);
        },
        [getViewportPositionForEvent, interactive],
      );

      const onPinch = useCallback(
        (event) => {
          if (!interactive) return;
          event.srcEvent.stopPropagation();
          const position = getViewportPositionForEvent(event);

          // Workaround for hammer.js pinchstart sometimes not being fired
          if (!pinchPositionRef.current) return;

          translateByViewportDelta(position.x - pinchPositionRef.current.x, position.y - pinchPositionRef.current.y);
          setScale(pinchStartScaleRef.current * event.scale, position);
          updateTransform();

          pinchPositionRef.current = position;
        },
        [getViewportPositionForEvent, setScale, translateByViewportDelta, updateTransform, interactive],
      );

      const onWheel = useCallback(
        (event) => {
          if (!interactive) return;
          const position = getViewportPositionForEvent(event);
          setScale(contentScaleRef.current + event.deltaY / 1000, position);
          updateTransform();
        },
        [getViewportPositionForEvent, setScale, updateTransform, interactive],
      );

      const onTap = useCallback((event) => {
        if (!event.srcEvent.altKey) return;
        event.srcEvent.stopPropagation();

        const bounds = rootRef.current.getBoundingClientRect();
        const touchX = event.srcEvent.clientX - bounds.left;
        const touchY = event.srcEvent.clientY - bounds.top;
        const coordinates = { x: touchX / bounds.width, y: touchY / bounds.height };

        Clipboard.copy(JSON.stringify(coordinates));
        Toast.info("Coordinates copied to clipboard!");
      }, []);

      useEffect(() => {
        const mapElement = rootRef.current.parentNode;
        const hammer = new Hammer(mapElement, { domEvents: true });

        const pinch = new Hammer.Pinch();
        const pan = new Hammer.Pan();

        pan.recognizeWith(pinch);
        pinch.recognizeWith(pan);

        hammer.add(pan);
        hammer.on("panstart", onPanStart);
        hammer.on("panmove", onPanMove);

        hammer.add(pinch);
        hammer.on("pinchstart", onPinchStart);
        hammer.on("pinch", onPinch);

        hammer.on("tap", onTap);

        mapElement.addEventListener("mousewheel", onWheel);

        return () => {
          hammer.destroy();
          mapElement.removeEventListener("mousewheel", onWheel);
        };
      }, [onPanMove, onPanStart, onPinch, onPinchStart, onTap, onWheel]);

      // ###### API #######

      const center = useCallback(
        (normalizedPoint, options) => {
          options = { animation: {}, offsetInViewport: { x: 0, y: 0 }, ...options };
          const actualAnimation = { animate: true, duration: 500, ...options.animation };
          const point = normalizedPoint.convertNormalizedToAbsoluteIn(new Rect(0, 0, contentWidth, contentHeight));
          const viewportScale = getViewportScale();
          const { offsetX: targetOffsetX, offsetY: targetOffsetY } = calculateTranslationToCenterContentCoordsPoint(
            point,
            options.offsetInViewport,
          );

          const startValues = {
            offsetX: translateXRef.current * viewportScale,
            offsetY: translateYRef.current * viewportScale,
          };

          const targetValues = { offsetX: targetOffsetX, offsetY: targetOffsetY };

          animate(
            actualAnimation.animate ? actualAnimation.duration / 1000 : 0,
            startValues,
            targetValues,
            (values) => {
              setTranslation(values.offsetX / viewportScale, values.offsetY / viewportScale);
              updateTransform();
            },
          );
        },
        [
          animate,
          calculateTranslationToCenterContentCoordsPoint,
          contentHeight,
          contentWidth,
          getViewportScale,
          setTranslation,
          updateTransform,
        ],
      );

      const fit = useCallback(
        (normalizedFrame, options) => {
          options = { animation: {}, zoomOut: true, offsetInViewport: { x: 0, y: 0 }, ...options };
          const actualAnimation = { animate: true, duration: 500, ...options.animation };
          const absoluteFrame = normalizedFrame.convertNormalizedToAbsoluteIn(
            new Rect(0, 0, contentWidth, contentHeight),
          );
          let { offsetX: targetOffsetX, offsetY: targetOffsetY } = calculateTranslationToCenterContentCoordsPoint(
            absoluteFrame.center,
          );

          const viewportScale = getViewportScale();
          const targetScale = calculateScaleToFitContentCoordsFrame(absoluteFrame);

          // TODO: This is no good! It does not offset the centering properly (varies by zoom level)
          // but it does the job for now as a quick fix as the effect is somewhat subtle. Only affects
          // the fit operation when an offset is provided. Trash this ASAP!
          targetOffsetX -= options.offsetInViewport.x / (viewportScale - targetScale);
          targetOffsetY -= options.offsetInViewport.y / (viewportScale - targetScale);

          const startValues = {
            offsetX: translateXRef.current * viewportScale,
            offsetY: translateYRef.current * viewportScale,
            scale: contentScaleRef.current,
          };

          const targetValues = {
            offsetX: targetOffsetX,
            offsetY: targetOffsetY,
            scale: options.zoomOut
              ? targetScale
              : targetScale < contentScaleRef.current
                ? contentScaleRef.current
                : targetScale,
          };

          animate(
            actualAnimation.animate ? actualAnimation.duration / 1000 : 0,
            startValues,
            targetValues,
            (values) => {
              setTranslation(values.offsetX / viewportScale, values.offsetY / viewportScale);
              setScale(values.scale);
              updateTransform();
            },
          );
        },
        [
          animate,
          calculateScaleToFitContentCoordsFrame,
          calculateTranslationToCenterContentCoordsPoint,
          getViewportScale,
          setScale,
          setTranslation,
          updateTransform,
          contentWidth,
          contentHeight,
        ],
      );

      const getZoom = useCallback(() => contentScaleRef.current, []);

      const getSize = useCallback(
        () => ({ width: contentWidth, height: contentHeight }),
        [contentHeight, contentWidth],
      );

      useImperativeHandle(ref, () => ({ fit, center, getZoom, getSize }), [center, fit, getZoom, getSize]);

      // ###### Final Setup ######

      // Set the base scale to fill the map
      useEffect(() => {
        const mapElement = rootRef.current.parentNode;
        const viewportSize = new Size(mapElement.clientWidth, mapElement.clientHeight);
        const rootSize = new Size(contentWidth, contentHeight);

        baseScaleRef.current = rootSize.scaleFactorToFillProportionallyIn(viewportSize);
        updateTransform();
        updateElements();

        setVisible(true);
      }, [resetScale, resetTranslation, updateTransform, contentWidth, contentHeight, updateElements]);

      // ###### Context ######

      const zoomSubscribe = useCallback(
        (onZoom) => {
          const id = uuidv4();
          zoomSubscriptions.current[id] = onZoom;
          onZoom(contentScaleRef.current, getViewportScale()); // Send initial values on subscribe
          return id;
        },
        [getViewportScale],
      );

      const zoomUnsubscribe = useCallback((id) => delete zoomSubscriptions.current[id], []);

      const context = useMemo(
        () => ({
          zoomSubscribe,
          zoomUnsubscribe,
        }),
        [zoomSubscribe, zoomUnsubscribe],
      );

      // ###### Render ######

      return (
        <div
          {...rest}
          ref={rootRef}
          className={Classes.build("map-content", className)}
          style={Styles.merge(style, {
            width: contentWidth,
            height: contentHeight,
            visibility: visible ? "visible" : "hidden",
          })}
        >
          {/* Wrapping the content in a Stagger enables automatic staggering
          of any stagger-enabled components in the children or their descendants, even if the
          map content is added to the DOM after the page stagger runs (for example, after the image loads). */}
          <Stagger options={staggerOptions}>
            <MapContentContext.Provider value={context}>{children}</MapContentContext.Provider>
          </Stagger>
        </div>
      );
    },
  ),
);

MapContent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  maxZoom: PropTypes.number,
  interactive: PropTypes.bool, // Set to false to disable pan and zoom (does not affect interactivity of children)
  staggerOptions: PropTypes.object,
  onZoom: PropTypes.func,
};

export default MapContent;
