import { Text } from "ripple";
import styled from "styled-components";
import { standardGap } from "../../../common";
import Box from "../../../components/box";
import Question from "../../../components/question";

export const Type10QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Targets = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${standardGap};
  align-items: flex-start;
  justify-content: center;
`;

export const Target = styled.div`
  width: ${(props) => (props.$wide ? "50vh" : "30vh")};
`;

export const Holes = styled.div`
  padding: ${standardGap} calc(${standardGap} / 2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: calc(${standardGap} / 2);
`;

export const Hole = styled(Box)`
  flex: 1;
  max-width: 70%;
  height: 6vh;
`;

export const HoleText = styled(Text)`
  font-size: 2.5vh;
`;
