import _ from "lodash";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import Classes from "../../../helpers/classes";

function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

// From: https://gist.github.com/runeb/c11f864cd7ead969a5f0
// Using fixes from: https://gist.github.com/runeb/c11f864cd7ead969a5f0#gistcomment-2371745
// Modified to return a promise, reject on error + some other small tweaks
const readImageAndOrientation = function (file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = function () {
      const base64Image = `data:${file.type};base64,${arrayBufferToBase64(fileReader.result)}`;
      const scanner = new DataView(fileReader.result);
      let idx = 0;
      let orientation = 1; // Non-rotated is the default

      // Not a JPEG
      if (fileReader.result.length < 2 || scanner.getUint16(idx) !== 0xffd8) {
        resolve({ base64Image, orientation });
        return;
      }

      idx += 2;
      let maxBytes = scanner.byteLength;
      let littleEndian = false;
      while (idx < maxBytes - 2) {
        const uint16 = scanner.getUint16(idx, littleEndian);
        idx += 2;
        switch (uint16) {
          case 0xffe1: {
            // Start of EXIF
            const endianNess = scanner.getUint16(idx + 8);
            // II (0x4949) Indicates Intel format - Little Endian
            // MM (0x4D4D) Indicates Motorola format - Big Endian
            if (endianNess === 0x4949) {
              littleEndian = true;
            }
            const exifLength = scanner.getUint16(idx, littleEndian);
            maxBytes = exifLength - idx;
            idx += 2;
            break;
          }
          case 0x0112: {
            // Orientation tag
            // Read the value, its 6 bytes further out
            // See page 102 at the following URL
            // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
            orientation = scanner.getUint16(idx + 6, littleEndian);
            maxBytes = 0; // Stop scanning
            break;
          }
        }
      }

      resolve({ base64Image, orientation });
    };
    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(file);
  });
};

// Original: https://stackoverflow.com/a/41818901/167983
// Modified to return a promise, set canvas size based correctly,
// reject on error and fix a glaring async issue.
function autoRotateImage(loadResult) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-restricted-globals
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const orientation = loadResult.orientation;
      const shouldRotate = orientation === 6 || orientation === 8;

      // Set the canvas dimensions to the rotated size
      canvas.height = shouldRotate ? image.width : image.height;
      canvas.width = shouldRotate ? image.height : image.width;

      // Rotate and draw source image into the off-screen canvas
      // https://www.impulseadventure.com/photo/exif-orientation.html
      if (orientation === 6) {
        context.rotate((90 * Math.PI) / 180);
        context.translate(0, -canvas.width);
      } else if (orientation === 8) {
        context.rotate((-90 * Math.PI) / 180);
        context.translate(-canvas.height, 0);
      }
      context.drawImage(image, 0, 0);

      // Encode image to data-uri with base64
      resolve(canvas.toDataURL("image/jpeg", 100));
    };
    image.onerror = reject;
    image.src = loadResult.base64Image;
  });
}

const FilePicker = memo(({ className, children, accept = null, capture = null, onPick, ...rest }) => {
  const onChange = useCallback(
    (e) => {
      onPick?.(
        _.map(e.target.files, (file) => {
          return {
            readAsBlob: () => Promise.resolve(URL.createObjectURL(file)),
            readAsImage: () =>
              new Promise((resolve, reject) => {
                readImageAndOrientation(file).then((result) => {
                  // Auto-rotate the image to "normal" orientation based on EXIF data.
                  // Without this, images are sideways on mobile most of the time.
                  autoRotateImage(result).then((rotatedBase64) => {
                    // eslint-disable-next-line no-restricted-globals
                    const image = new Image();
                    image.onload = () => resolve(image);
                    image.onerror = reject;
                    image.src = rotatedBase64;
                  });
                });
              }),
          };
        }),
      );
    },
    [onPick],
  );

  return (
    <div {...rest} className={Classes.build("ripple-file-picker", className)}>
      {children}
      <input type="file" accept={accept} capture={capture} onChange={onChange} />
    </div>
  );
});

FilePicker.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  accept: PropTypes.string, // Same as HTML `<input accept="..."/>` attribute
  capture: PropTypes.string, // Same as HTML `<input capture="..."/>` attribute
  onPick: PropTypes.func,
};

export default FilePicker;
