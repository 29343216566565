import { lighten } from "polished";
import { Button, Droppable, Dropzone, Grid, Image, resource, staggerStep, Text } from "ripple";
import styled from "styled-components";
import { OldQuestionRoot, titleColor, WordContainer } from "../../../common";
import WordButton from "../../../components/word-button";

export const DragWordImageRoot = styled(OldQuestionRoot)``;

export const LandscapeButtonContainer = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5vh;
  margin-right: 3vh;
`;

export const PortraitButtonContainer = styled(Grid).attrs((props) => ({
  layout: (count) => {
    return Grid.layout.square()(count);
  },
}))`
  max-width: 100vw;
  margin: 2vh;
  .grid-row {
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  @media screen and (orientation: portrait) {
    flex-direction: column;
    margin-left: 0;
  }

  @media screen and (orientation: landscape) {
    flex-direction: row;
    align-items: flex-start;
    margin-left: ${(props) => props.$offset + "px"};
  }
`;

export const Card = staggerStep({ transition: "flip-left" })(styled(Button)`
  isolation: isolate;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  width: 18vh;
  height: 25.3vh;
  margin: 0.5vh;
  background-color: ${(props) => lighten(0.4, props.color)};
  transition: all 150ms ease-in-out;
  border-radius: 2vh;
  overflow: hidden;
`);

export const CardImage = styled(Image).attrs((props) => ({ scaling: "fill", fadeIn: "always" }))`
  height: auto;
  width: 100%;
  transition: opacity 150ms ease-in-out;
  opacity: 1;

  border-radius: 2vh 2vh 0 0;
`;

export const CardBar = styled(Button)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 15%;
  width: 100%;

  transition: opacity 150ms ease-in-out;
  background-color: ${(props) => lighten(0.3, props.color)};
`;

export const ChoiceWordButton = styled(WordButton)`
  opacity: ${(props) => (props.hasDrop ? 0.3 : 1)};
`;

export const ButtonContentPreview = styled(WordContainer)`
  justify-content: center;
  width: 18vh;
  height: 6vh;
  transition: transform 150ms ease-in-out;
  box-shadow: 0 0 1vmax 1vmin ${(props) => props.color};
`;
export const DroppableContainer = styled.div`
  position: relative;
  height: 6vh;
  pointer-events: auto;

  .ripple-droppable {
    margin: 0 1vmax;
  }
`;

export const WordDroppable = styled(Droppable)`
  position: absolute;
  top: 0;
  height: 6vh;
  left: 0;
  width: 100%;
  margin-left: -15vw;
`;

export const WordTextDroppable = styled(Text)`
  color: ${titleColor};
  font-size: ${(props) => (props.size ?? 3) + "vmin"};
  font-family: "ComicNeue-Bold";
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: 100%;
  margin-right: 2vmax;
`;

export const WordButtonContainer = staggerStep({ transition: "slide-in-and-fade-from-left" })(styled.div``);

export const QuestionMarkImage = styled(Image).attrs((props) => ({
  fadeIn: "always",
  src: resource("images/QuestionMark.svg"),
}))`
  height: 3vh;
  margin: 0.5vh;
`;

export const CardDropzone = styled(Dropzone)`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 2vh;

  ${(props) => {
    if (props.active) {
      return `
      border: 0.4vh solid ${props.color};
    `;
    } else {
      return `

        border: 0.4vh dashed ${props.color};
    `;
    }
  }};

  &.over {
    border: 0.6vh solid ${(props) => props.color};
  }
`;

export const CardDropContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
export const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 15%;
`;

export const CardText = styled(Text)`
  color: ${titleColor};
  font-size: ${(props) => (props.size ?? 3) + "vh"};
  font-family: "ComicNeue-Bold";
  text-align: center;
`;

export const BlockOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 12;
`;

export const GridContainer = styled.div`
  position: relative;
`;
