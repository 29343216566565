import { useCallback } from "react";
import { Navigator, useData } from "ripple";
import { useIsMobileVersion } from "./use-is-mobile-version";

export const useActivityBackButtonCallback = (activity) => {
  const client = useData((data) => data.root);
  const isMobileVersion = useIsMobileVersion();
  return useCallback(() => {
    if (isMobileVersion) {
      Navigator.navigate({ node: activity.parent });
    } else {
      Navigator.navigate({ node: client });
    }
  }, [activity.parent, client, isMobileVersion]);
};
