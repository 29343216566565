import _ from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Route, useRouteMatch } from "react-router";
import { Audio, Config, Image, Navigator, Strings, Toast, resource, useData, usePrevious } from "ripple";
import { ActivityPageTitle, GameTopBarContainer } from "../../common";
import ConfirmButton from "../../components/confirm-button";
import GamePills from "../../components/game-pills";
import GameProgressBar from "../../components/game-progress-bar";
import HelpPanel from "../../components/help-panel";
import MascotInstruction from "../../components/mascot-instruction";
import MascotSuccess from "../../components/mascot-success";
import PlayButton from "../../components/play-button";
import { useActivityBackButtonCallback } from "../../hooks/use-activity-back-button-callback";
import { useIsMobileVersion } from "../../hooks/use-is-mobile-version";
import { useProgressStorage } from "../../hooks/use-progress-storage";
import { useRandomSuccessRetroaction } from "../../hooks/use-random-success-retroaction";
import { useTwoStepGameRootBackground } from "../../hooks/use-two-step-game-root-background";
import ClickwordImageQuestion from "./click-word-image-question";
import ClickwordQuestion from "./click-word-question";
import ClickWordSentenceQuestion from "./click-word-sentence-question";
import DragQuestion from "./drag-question";
import DragWordComplexeQuestion from "./drag-word-complexe-question";
import DragWordImageQuestion from "./drag-word-image-question";
import DragWordSimpleQuestion from "./drag-word-simple-question";
import FlipQuestion from "./flip-question";
import {
  Bottom,
  BottomProgressPills,
  Content,
  HelpButton,
  InstructionButton,
  InteractionBlocker,
  IntroVideoBox,
  IntroVideoContainer,
  IntroVideoPlayer,
  Page,
  QuestionRouteTransitioner,
  StyledBackButton,
  TopRight,
  VideoPausedOverlay,
} from "./styled";
import Type1Question from "./type-1-question";
import Type10Question from "./type-10-question";
import Type11Question from "./type-11-question";
import Type12Question from "./type-12-question";
import Type13Question from "./type-13-question";
import Type14Question from "./type-14-question";
import Type15Question from "./type-15-question";
import Type16Question from "./type-16-question";
import Type18Question from "./type-18-question";
import Type19Question from "./type-19-question";
import Type2Question from "./type-2-question";
import Type3Question from "./type-3-question";
import Type4Question from "./type-4-question";
import Type6Question from "./type-6-question";
import Type8Question from "./type-8-question";
import Type9Question from "./type-9-question";

const QuestionsActivityPage = memo(() => {
  const isMobileVersion = useIsMobileVersion();

  const { url } = useRouteMatch();
  const { id } = useRouteMatch(`${url}/:type/:id`).params;

  const introVideoPlayerRef = useRef(null);

  const currentQuestion = useData((data) => data.requiredNode(id));
  const currentGame = currentQuestion.parent;
  const activity = currentQuestion.parent.parent;

  const introVideo = activity.optionalMedia("ActivityIntroVideo", "ActivityIntroVideo");
  const [showIntroVideo, setShowIntroVideo] = useState(!!introVideo && !Config.disableActivityIntroVideo);

  const games = Config.testing ? activity.allChildren : activity.children;
  const questions = isMobileVersion
    ? currentGame.children // On mobile, only show questions from the current game
    : games.flatMap((c) => (Config.testing ? c.allChildren : c.children)); // On web, show all questions from all games as a flat series

  const index = _.indexOf(questions, currentQuestion);

  const mascotText = currentQuestion.wantedInheritedText("MascotText");
  const mascotAudio = currentQuestion.wantedInheritedMedia("MascotAudio", "Audio");

  const successRetroaction = useRandomSuccessRetroaction(currentQuestion);
  const mascotSuccessText =
    currentQuestion.optionalInheritedText("MascotSuccessText") ?? successRetroaction?.wantedText("MascotSuccessText");
  const mascotSuccessNextButtonText = Strings.localized(
    index === questions.length - 1 ? "LastQuestionNextButton" : "NextButton",
  );
  const mascotSuccessAudio =
    currentQuestion.optionalInheritedMedia("MascotSuccessAudio", "Audio") ??
    successRetroaction?.wantedMedia("MascotSuccessAudio", "Audio");

  const [showInstructionMascot, setShowInstructionMascot] = useState(!Config.disableInstructionMascot);
  const [showSuccessMascot, setShowSuccessMascot] = useState(false);
  const [completed, setCompleted] = useState(false);

  const { addCompleted } = useProgressStorage();

  useTwoStepGameRootBackground(currentGame);

  // Show the instruction mascot for each question change, EVEN
  // if the instruction text and/or audio are the same (yes, really!)
  // Track previous values explicitly to avoid mascot popping back
  // every time there is a hot reload.
  const previousQuestion = usePrevious(currentQuestion);
  useEffect(() => {
    if (currentQuestion === previousQuestion) return;
    if (Config.disableInstructionMascot) return;
    setShowInstructionMascot(true);
  }, [currentQuestion, previousQuestion]);

  const onComplete = useCallback(
    (options = { delay: 1500 }) => {
      setCompleted(true);
      setShowInstructionMascot(false);
      setTimeout(() => {
        Audio.discrete("effects").play(resource("audio/ActivityCompleted.mp3"));
        setShowSuccessMascot(true);
      }, options.delay);
      addCompleted(currentQuestion);
    },
    [addCompleted, currentQuestion],
  );

  const onNextQuestionClick = useCallback(() => {
    setShowSuccessMascot(false);
    setCompleted(false);
    setTimeout(() => {
      const nextIndex = index + 1;
      if (nextIndex > questions.length - 1) {
        if (isMobileVersion) {
          Navigator.navigate({ node: activity.parent });
        } else {
          Navigator.navigate({ path: "/home" });
        }
      } else {
        Navigator.navigate({ node: questions[index + 1] });
      }
    }, 500);
  }, [activity.parent, index, isMobileVersion, questions]);

  const onGameClick = useCallback((game) => {
    if (!Config.disableInstructionMascot) setShowInstructionMascot(true);
    setShowSuccessMascot(false);
    if (game.children.length > 0) {
      Navigator.navigate({ node: game.children[0] });
    } else {
      Toast.warn("This game has no child questions to display! Not navigating.");
    }
  }, []);

  const onInstructionMascotShouldHide = useCallback(() => {
    setShowInstructionMascot(false);
  }, []);

  const onInstructionButtonClick = useCallback(() => {
    setShowInstructionMascot(true);
  }, []);

  const [showHelpPanel, setShowHelpPanel] = useState(false);
  const onHelpButtonClick = useCallback(() => setShowHelpPanel(true), []);
  const onHelpPanelCloseClick = useCallback(() => setShowHelpPanel(false), []);

  const onBackButtonClick = useActivityBackButtonCallback(activity);

  const onSkipIntroVideoButtonClick = useCallback(() => {
    introVideoPlayerRef.current?.pause();
    setShowIntroVideo(false);
  }, []);

  return (
    <Page>
      <ActivityPageTitle>{activity.wantedText("Title")?.replace("<br>", " ")}</ActivityPageTitle>
      <GameTopBarContainer>
        {isMobileVersion ? (
          <GameProgressBar game={currentGame} currentChild={showIntroVideo ? null : currentQuestion} />
        ) : (
          <GamePills games={games} selectedGame={showIntroVideo ? null : currentGame} onClick={onGameClick}></GamePills>
        )}
      </GameTopBarContainer>
      <Content>
        <QuestionRouteTransitioner classNames="slide-in-from-right-overlapping" $show={!showIntroVideo}>
          <Route path={`${url}/drag/:id`}>
            <DragQuestion onComplete={onComplete} />
          </Route>
          <Route path={`${url}/flip/:id`}>
            <FlipQuestion onComplete={onComplete} />
          </Route>
          <Route path={`${url}/click-word/:id`}>
            <ClickwordQuestion onComplete={onComplete} />
          </Route>
          <Route path={`${url}/click-word-image/:id`}>
            <ClickwordImageQuestion onComplete={onComplete} />
          </Route>
          <Route path={`${url}/click-word-sentence/:id`}>
            <ClickWordSentenceQuestion onComplete={onComplete} />
          </Route>
          <Route path={`${url}/drag-word-image/:id`}>
            <DragWordImageQuestion onComplete={onComplete} />
          </Route>
          <Route path={`${url}/drag-word-simple/:id`}>
            <DragWordSimpleQuestion onComplete={onComplete} />
          </Route>
          <Route path={`${url}/drag-word-complexe/:id`}>
            <DragWordComplexeQuestion onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-1/:id`}>
            <Type1Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-2/:id`}>
            <Type2Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-3/:id`}>
            <Type3Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-4/:id`}>
            <Type4Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-6/:id`}>
            <Type6Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-8/:id`}>
            <Type8Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-9/:id`}>
            <Type9Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-10/:id`}>
            <Type10Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-11/:id`}>
            <Type11Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-12/:id`}>
            <Type12Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-13/:id`}>
            <Type13Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-14/:id`}>
            <Type14Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-15/:id`}>
            <Type15Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-16/:id`}>
            <Type16Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-18/:id`}>
            <Type18Question onComplete={onComplete} />
          </Route>
          <Route path={`${url}/type-19/:id`}>
            <Type19Question onComplete={onComplete} />
          </Route>
        </QuestionRouteTransitioner>
        <IntroVideoContainer $show={showIntroVideo}>
          <IntroVideoBox>
            <IntroVideoPlayer
              ref={introVideoPlayerRef}
              src={introVideo}
              pausedOverlay={
                <VideoPausedOverlay>
                  <PlayButton />
                </VideoPausedOverlay>
              }
              autoPlay
            />
          </IntroVideoBox>
          <ConfirmButton onClick={onSkipIntroVideoButtonClick} />
        </IntroVideoContainer>
      </Content>
      <Bottom $show={!showIntroVideo}>
        <BottomProgressPills game={currentGame} activeNode={currentQuestion} />
      </Bottom>
      <StyledBackButton activity={activity} onClick={onBackButtonClick} />
      <TopRight $show={!showIntroVideo}>
        <InstructionButton $show={!showIntroVideo} onClick={onInstructionButtonClick}>
          <Image src={resource("images/Button_Help_02@2x.png")} />
        </InstructionButton>
        {(currentQuestion?.optionalInheritedText("Help") ||
          currentQuestion?.optionalInheritedMedia("Help1", { image: "HelpImage", video: "HelpVideo" }) ||
          currentQuestion?.optionalInheritedMedia("Help2", { image: "HelpImage", video: "HelpVideo" })) && (
          <HelpButton $show={!showIntroVideo} onClick={onHelpButtonClick}>
            <Image src={resource("images/Button_Tool_Symbol.png")} />
          </HelpButton>
        )}
      </TopRight>
      <InteractionBlocker $block={completed} />
      <MascotInstruction
        show={showInstructionMascot && !showIntroVideo}
        image={currentQuestion.wantedInheritedMedia("MascotImage", "PreserveSizeWithAlpha")}
        audio={mascotAudio}
        text={mascotText}
        onShouldHide={onInstructionMascotShouldHide}
        autoPlay
        wideInPortrait
      />
      <MascotSuccess
        show={showSuccessMascot}
        text={mascotSuccessText}
        nextButtonText={mascotSuccessNextButtonText}
        audio={mascotSuccessAudio}
        image={currentQuestion.wantedInheritedMedia("MascotImage", "PreserveSizeWithAlpha")}
        starImage={currentGame.wantedMedia("AnimatedStars", "AnimatedSvg")}
        onNextQuestionClick={onNextQuestionClick}
        wideInPortrait
      />
      <HelpPanel show={showHelpPanel} node={currentQuestion} onCloseClick={onHelpPanelCloseClick} />
    </Page>
  );
});

export default QuestionsActivityPage;
