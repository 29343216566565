import _ from "lodash";
import Point from "./point";
import Size from "./size";

class Rect {
  constructor(x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }
  static parse(json) {
    if (json === null || typeof json === "undefined") return null;
    const obj = JSON.parse(json);
    if (obj === null) throw new Error("Rect.parse: Could not parse JSON");
    if (_.isUndefined(obj.x)) throw new Error("Rect.parse: x is not defined");
    if (_.isUndefined(obj.y)) throw new Error("Rect.parse: y is not defined");
    if (_.isUndefined(obj.width)) throw new Error("Rect.parse: width is not defined");
    if (_.isUndefined(obj.height)) throw new Error("Rect.parse: height is not defined");
    return new Rect(obj.x, obj.y, obj.width, obj.height);
  }
  static tryParse(json) {
    try {
      return this.parse(json);
    } catch (error) {
      // eslint-disable-next-line no-empty
    }
  }
  get origin() {
    return new Point(this.x, this.y);
  }
  get size() {
    return new Size(this.width, this.height);
  }
  /** Returns the rect's center coordinates (takes the origin into account) */
  get center() {
    return new Point(this.x + this.width / 2.0, this.y + this.height / 2.0);
  }
  get left() {
    return this.x;
  }
  set left(value) {
    this.width += this.x - value;
    this.x = value;
  }
  get top() {
    return this.y;
  }
  set top(value) {
    this.height += this.y - value;
    this.y = value;
  }
  get right() {
    return this.x + this.width;
  }
  set right(value) {
    this.width = value - this.x;
  }
  get bottom() {
    return this.y + this.height;
  }
  set bottom(value) {
    this.height = value - this.y;
  }

  /** Set the rect's width by scaling it around the given x coordinate (in the Rect's coordinate system) */
  setWidthAround(width, x) {
    const pinRatio = (x - this.x) / this.width;
    this.x = x - width * pinRatio;
    this.width = width;
  }
  /** Set the rect's width by scaling it around the given y coordinate (in the Rect's coordinate system) */
  setHeightAround(height, y) {
    const pinRatio = (y - this.y) / this.height;
    this.y = y - height * pinRatio;
    this.height = height;
  }
  /** Set the rect's size by scaling it around the given Point (in the Rect's coordinate system) */
  setSizeAround(size, point) {
    this.setWidthAround(size.width, point);
    this.setHeightAround(size.height, point);
  }

  /** Returns true if the rect is almost equal to another rect */
  isAlmostEqualTo(rect) {
    if (!rect) return false;
    return (
      Math.abs(rect.x - this.x) < 0.0001 &&
      Math.abs(rect.y - this.y) < 0.0001 &&
      Math.abs(rect.width - this.width) < 0.0001 &&
      Math.abs(rect.height - this.height) < 0.0001
    );
  }
  /** Returns a rect with the same origin but with its size scaled by the given factor */
  scaledBy(factor) {
    return new Rect(this.x, this.y, this.width * factor, this.height * factor);
  }
  /** Returns a rect with the same size but with its position offset by the given size */
  offsetBy(size) {
    return new Rect(this.x + size.width, this.y + size.height, this.width, this.height);
  }
  /** Returns a rect with the same size but centered on the given point */
  centeredOn(point) {
    const x = point.x - this.width / 2.0;
    const y = point.y - this.height / 2.0;
    return new Rect(x, y, this.width, this.height);
  }
  /** Returns a rect this corresponds to the current rect centered into another rect */
  centeredIn(destination) {
    const centeredSourceOrigin = destination.center.minus(this.size.scaledBy(0.5));
    return new Rect(centeredSourceOrigin.x, centeredSourceOrigin.y, this.width, this.height);
  }
  /** Returns a rect this corresponds to the current rect fitted proportionally and centered into the destination */
  fitIn(destination) {
    const factor = this.size.scaleFactorToFitProportionallyIn(destination.size);
    return this.scaledBy(factor).centeredIn(destination);
  }
  /** Returns a rect filling the destination proportionally with the current rect */
  fillIn(destination) {
    const factor = this.size.scaleFactorToFillProportionallyIn(destination.size);
    return this.scaledBy(factor).centeredIn(destination);
  }
  /** Returns a rect with edges inset by the specified amount in each direction */
  insetBy(left, top, right, bottom) {
    return new Rect(this.x + left, this.y + top, this.width - left - right, this.height - top - bottom);
  }
  /** Converts the current rect (assuming it is specified in normalized 0..1
   *  coordinates) into an absolute rect in the specified target rect. */
  convertNormalizedToAbsoluteIn(targetRect) {
    return new Rect(
      this.x * targetRect.width,
      this.y * targetRect.height,
      this.width * targetRect.width,
      this.height * targetRect.height,
    );
  }
  /** Converts the current rect (assuming it is specified in absolute coordinates
   *  in the coordinate system of the target rect) into a normalized rect. */
  convertAbsoluteToNormalizedIn(targetRect) {
    return new Rect(
      this.x / targetRect.width,
      this.y / targetRect.height,
      this.width / targetRect.width,
      this.height / targetRect.height,
    );
  }
  /* Transforms the rectangle from the `from` coordinate system to the `to` coordiate system. */
  transformFromTo(fromCoordinateSystemRect, toCoordinateSystemRect) {
    const normalized = this.convertAbsoluteToNormalizedIn(fromCoordinateSystemRect);
    return normalized.convertNormalizedToAbsoluteIn(toCoordinateSystemRect);
  }
  /* Returns whether the current rect contains the specified point. */
  contains(point) {
    const containedHorizontally = point.x > this.x && point.x < this.x + this.width;
    const containedVertically = point.y > this.y && point.y < this.y + this.height;
    return containedHorizontally && containedVertically;
  }
}

export default Rect;
