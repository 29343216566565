import PropTypes from "prop-types";
import { useParams } from "react-router";
import { useData } from "ripple";
import { isGoodAnswer } from "../../../../logic/logic";
import AudioTextButton from "../../../components/audio-text-button";
import Box from "../../../components/box";
import ImageLayer from "../../../components/image-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import SpeakerButtonLayer from "../../../components/speaker-button-layer";
import { useFeedback } from "../../../hooks/use-feedback";
import { QuestionPanelContent } from "../styled";
import { ImageContainer, ImagesCssGrid, Type6QuestionRoot } from "./styled";

const Type6Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const words = node.children.filter((c) => c.semantic.endsWith("Word"));
  const images = node.children.filter((c) => c.semantic.endsWith("Image"));

  const { feedbacks, addPermanentFeedback, addMomentaryFeedback } = useFeedback();

  const createOnItemClick = (item) => () => {
    if (isGoodAnswer(item)) {
      addPermanentFeedback(item.id);
      onComplete();
    } else {
      addMomentaryFeedback(item.id, "bad");
    }
  };

  const renderPanelContent = (location) => {
    return (
      <QuestionPanelContent $location={location}>
        {words.map((item) => (
          <AudioTextButton key={item.id} node={item} onClick={createOnItemClick(item)} feedback={feedbacks[item.id]} />
        ))}
      </QuestionPanelContent>
    );
  };

  return (
    <Type6QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent
        sidePanelContent={renderPanelContent("side")}
        bottomPanelContent={renderPanelContent("bottom")}
      >
        <ImagesCssGrid>
          {images.map((imageItem) => {
            return (
              <ImageContainer key={imageItem.id}>
                <Box>
                  <Layers>
                    <ImageLayer src={imageItem.wantedMedia("Image", "Image")} />
                    <SpeakerButtonLayer src={imageItem.optionalMedia("Audio", "Audio")} />
                  </Layers>
                </Box>
              </ImageContainer>
            );
          })}
        </ImagesCssGrid>
      </ResponsiveContent>
    </Type6QuestionRoot>
  );
};

Type6Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type6Question;
