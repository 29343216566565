import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { useData } from "ripple";
import { isGoodAnswer } from "../../../../logic/logic";
import Box from "../../../components/box";
import ButtonLayer from "../../../components/button-layer";
import FeedbackLayer from "../../../components/feedback-layer";
import ImageLayer from "../../../components/image-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import SpeakerButtonLayer from "../../../components/speaker-button-layer";
import SquareItemGridPanelContent from "../../../components/square-item-grid-panel-content";
import { useFeedback } from "../../../hooks/use-feedback";
import { QuestionPanelContent } from "../styled";
import { SplitButton, SplitButtonLayer, Type8QuestionRoot, Word, WordBox, WordLayer } from "./styled";

const Type8Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const items = node.children;

  const [expand, setExpand] = useState(false);
  const { feedbacks, addPermanentFeedback, addMomentaryFeedback } = useFeedback();

  const onWordClick = useCallback(() => setExpand(!expand), [expand]);

  const createOnItemClick = (item) => () => {
    if (isGoodAnswer(item)) {
      addPermanentFeedback(item.id);
      onComplete();
    } else {
      addMomentaryFeedback(item.id, "bad");
    }
  };

  const renderPanelContent = (location) => {
    return (
      <QuestionPanelContent $location={location}>
        <SquareItemGridPanelContent items={items} location={location}>
          {(item) => (
            <Box appearance="active">
              <Layers>
                <ImageLayer src={item.wantedMedia("Image", "Image")} />
                <ButtonLayer onClick={createOnItemClick(item)} />
                <SpeakerButtonLayer src={item.optionalMedia("Audio", "Audio")} />
                <FeedbackLayer feedback={feedbacks[item.id]} />
              </Layers>
            </Box>
          )}
        </SquareItemGridPanelContent>
      </QuestionPanelContent>
    );
  };

  return (
    <Type8QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent
        sidePanelContent={renderPanelContent("side")}
        bottomPanelContent={renderPanelContent("bottom")}
      >
        <WordBox>
          <Layers>
            <WordLayer>
              <Word $expand={expand}>{node.wantedText("Type8Word")}</Word>
            </WordLayer>
            <SplitButtonLayer>
              <SplitButton style={{ zIndex: 1 }} onClick={onWordClick}>
                a/b
              </SplitButton>
            </SplitButtonLayer>
            <ButtonLayer onClick={onWordClick} />
            <SpeakerButtonLayer src={node.optionalMedia("Audio", "Audio")} />
          </Layers>
        </WordBox>
      </ResponsiveContent>
    </Type8QuestionRoot>
  );
};

Type8Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type8Question;
