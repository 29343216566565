import Config from "../../helpers/config";
import Log from "../../helpers/log";

const dbSchemaVersion = 1;
const dbEventsStoreName = "events";

function filteredOut(analyticsEvent, appId) {
  if (appId && analyticsEvent.appId !== appId) return true;
  return false;
}

function createDbApi(db) {
  return {
    put: function (event) {
      const transaction = db.transaction(dbEventsStoreName, "readwrite");
      const objectStore = transaction.objectStore(dbEventsStoreName);
      objectStore.put(event);
    },
    getRange: function (appId, startDate, endDate, onComplete) {
      const transaction = db.transaction(dbEventsStoreName, "readonly");
      const objectStore = transaction.objectStore(dbEventsStoreName);
      const index = objectStore.index("time");
      const range = IDBKeyRange.bound(startDate, endDate);

      const eventsInRange = [];
      index.openCursor(range).onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const analyticsEvent = cursor.value;

          // Add the primary key as an "order" so that we can sort
          // events that occur on the exact same date and time.
          analyticsEvent.order = cursor.primaryKey;

          // We perform filtering manually here because IndexedDB seems very limited in its querying capabilities.
          // This should not become an issue with (smaller) local databases anyway.
          if (!filteredOut(analyticsEvent, appId)) eventsInRange.push(analyticsEvent);

          cursor.continue();
        } else {
          onComplete(eventsInRange);
        }
      };
    },
  };
}

const AnalyticsDb = {
  initialize: function (onInitialize) {
    const dbName = `analytics-${Config.id}`;
    const openRequest = indexedDB.open(dbName, dbSchemaVersion);
    openRequest.onerror = (event) => {
      Log.error("Failed to open analytics IndexedDB storage: " + event.srcElement.error.message);
    };
    // eslint-disable-next-line ripple/callback-naming
    openRequest.onupgradeneeded = (event) => {
      const db = event.target.result;
      const objectStore = db.createObjectStore(dbEventsStoreName, { autoIncrement: true });
      objectStore.createIndex("time", "time", { unique: false });
    };
    openRequest.onsuccess = (event) => {
      const db = event.target.result;
      onInitialize(createDbApi(db));
    };
  },
};

export default AnalyticsDb;
