import { Revealer } from "ripple";
import styled from "styled-components";

export const QuestionMascotContainerRoot = styled(Revealer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.centered {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 2.5vh;
  }

  &.side {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 5vh;
    padding-right: 20px;
  }

  @media screen and (orientation: portrait) {
    background-color: ${(props) => props.$showBackground ?? "rgba(0, 0, 0, 0.6)"};
  }
`;
