import { Image } from "ripple";
import styled from "styled-components";
import Box from "../box";

export const ImageAudioContentBoxRoot = styled(Box)`
  isolation: isolate;
  .box-content {
    display: flex;
    flex-direction: column;
  }
`;

export const Top = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

export const TopImage = styled(Image).attrs((props) => ({ scaling: "fill" }))``;

export const Bottom = styled.div`
  width: 100%;
  & > * {
    width: 100%;
  }
`;
