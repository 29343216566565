import PropTypes from "prop-types";
import { GamePillsRoot, Pill } from "./styled";

const GamePills = ({ games, selectedGame, onClick, ...rest }) => {
  const createOnPillClick = (game) => () => onClick(game);
  return (
    <GamePillsRoot {...rest}>
      {games.map((game) => (
        <Pill
          key={game.id}
          color={game.wantedText("Color")?.trim()}
          active={game === selectedGame}
          onClick={createOnPillClick(game)}
        />
      ))}
    </GamePillsRoot>
  );
};

GamePills.propTypes = {
  games: PropTypes.array,
  selectedGame: PropTypes.object,
  onClick: PropTypes.func,
};

export default GamePills;
