import DataActions from "../../actions/local/data-actions";

export default (state = null, action) => {
  switch (action.type) {
    case DataActions.DATA_SERVER_UNREACHABLE:
      return action.error.stack;
    case DataActions.DATA_ERROR:
      return action.error.stack;
    default:
      return state;
  }
};
