const NavConfigurator = {
  configure: function (pagesFunction) {
    this.pagesFunction = pagesFunction;
  },
  routeInfo: function (options) {
    return this.pagesFunction(options);
  },
};

export default NavConfigurator;
