const RemoteActions = {
  UPDATE_REMOTE_CLIENTS: "@@local/UPDATE_REMOTE_CLIENTS",
  updateClients: (clients) => {
    return { type: RemoteActions.UPDATE_REMOTE_CLIENTS, description: clients, clients };
  },
  UPDATE_REMOTE_STATE: "@@local/UPDATE_REMOTE_STATE",
  updateState: (state) => {
    return { type: RemoteActions.UPDATE_REMOTE_STATE, description: state, state };
  },
};

export default RemoteActions;
