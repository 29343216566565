class Color {
  static hexToRgb(hex) {
    // Remove '#' if present
    hex = hex.replace("#", "");

    // Ensure hex has 6 characters
    if (hex.length !== 6) throw new Error(`Invalid hex color ${hex}`);

    // Split hex into R, G, B components
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Return RGB values as an object
    return { r: r, g: g, b: b };
  }
}

export default Color;
