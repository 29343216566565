import PropTypes from "prop-types";
import { ActivityCardRoot, BackgroundImage, WhiteBackground, WhiteBox } from "./styled";

const ActivityCard = ({ activity, children, cardContent, ...rest }) => {
  return (
    <ActivityCardRoot {...rest}>
      <WhiteBackground>
        {activity &&
          (cardContent ?? (
            <BackgroundImage src={activity.optionalMedia("ActivityCardImage", "PreserveSizeWithAlpha")} />
          ))}
      </WhiteBackground>
      {children && <WhiteBox>{children}</WhiteBox>}
    </ActivityCardRoot>
  );
};

ActivityCard.propTypes = {
  activity: PropTypes.object,
  children: PropTypes.node,
  cardContent: PropTypes.node,
};

export default ActivityCard;
