import Env from "../helpers/env";

/** A representation of a media file (a media with a specific semantic and format) */
export default class MediaFile {
  constructor(
    media,
    nodeId, // [NotNull] There is always a node that contains (or would contain) the media
    semantic, // [NotNull] The semantic that we asked for, regardless of the media existing or not
    formatDescriptor, // [NotNull] The format(Descriptor) that we asked for, regardless of it existing or not
    language, // [NotNull] Because either we are provided a language or we use the current language
    getUrl, // [NotNull]
  ) {
    this.media = media;
    this.nodeId = nodeId;
    this.semantic = semantic;
    this.formatDescriptor = formatDescriptor;
    this.language = language;
    this.getUrl = getUrl;
  }

  get type() {
    return this.media.type;
  }

  get format() {
    // The format descriptor can either be a single format or an object describing which format to pick for each possible
    // media type (use this if the media accepts multiple types on the server).
    // Example: const formatDescriptor = { image: "MyImageFormat", video: "MyVideoFormat" }
    // Picks: MyImageFormat if the media's type is "image", MyVideoFormat if the media's type if "video"
    const format = typeof this.formatDescriptor === "string" ? this.formatDescriptor : this.formatDescriptor[this.type];
    if (typeof format === "undefined") throw new Error(`No format specified for media type ${this.type}`);
    return format;
  }

  get url() {
    const format = this.format;
    if (!format) return null;
    return this.getUrl(format);
  }

  /**
   * If the URL is an RCC local file, return the platform-native file URL instead of the one the webview expects.
   * An example would be to open a native (ex: Obj-C/Swift/Java) image viewer by providing the nativeURL to the native code directly.
   */
  get nativeURL() {
    if (!Env.isRCC) return this.url;
    return Env.isIos
      ? this.url?.replace("ripple://localhost/_app_file_/", "file:///")
      : this.url?.replace("http://localhost/_app_file_/", "file:///");
  }
}
