import { Text } from "ripple";
import styled from "styled-components";
import { titleColor, WordContainer } from "../../common";
import SpeakerButton from "../speaker-button";

export const WordButtonRoot = styled(WordContainer)`
  isolation: isolate;

  &.disabled {
    opacity: 0.3;
  }

  ${(props) => {
    if (props.active) {
      return `
          box-shadow: 0 0 2vmax 1vmin ${props.color};
      `;
    } else {
      return `
          box-shadow: 0 0 0 0 ${props.color};
        `;
    }
  }};
`;

export const CustomSpeakerButton = styled(SpeakerButton)`
  margin: 0 0.8vmax;
  margin-right: 0.8vmax;
  flex-shrink: 0;
  .hotspot {
    margin: -2vmax;
  }
  z-index: 1;
`;

export const ButtonText = styled(Text)`
  color: ${titleColor};
  font-family: "ComicNeue-Bold";
  line-height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (orientation: portrait) {
    font-size: 2.4vh;
    margin-left: 1vh;
  }

  @media screen and (orientation: landscape) {
    font-size: 3.5vh;
    margin-left: 1.5vh;
  }
`;
