import PropTypes from "prop-types";
import { LayersRoot } from "./styled";

/**
 * Use this in combination with various XyzLayer components
 * to quickly build components by stacking layers on top of
 * each other without having to style anything.
 */
const Layers = ({ className, style, children, ...rest }) => {
  return (
    <LayersRoot {...rest} className={className} style={style}>
      {children}
    </LayersRoot>
  );
};

Layers.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
};

export default Layers;
