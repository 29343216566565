/* global device FileTransfer */
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import YAML from "yaml";
import Config from "./config";
import Log from "./log";
import resource from "./resource";

// Original Source: https://stackoverflow.com/a/11381730
// Modified to support iPad in desktop mode: https://stackoverflow.com/a/58979271/167983
/* eslint-disable no-useless-escape */
function checkIfMobileBrowser() {
  let check = false;
  (function (a) {
    const isIpadInMobileMode = navigator.userAgent.match(/iPad/i) !== null;
    const isIpadInDesktopMode =
      navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      ) ||
      isIpadInMobileMode ||
      isIpadInDesktopMode
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}
/* eslint-enable no-useless-escape */

function isCordova() {
  // The user agent is overridden in the Cordova config.xml file
  // and is available as soon as the app starts loading.
  return navigator.userAgent === "rcc";
}

// To use electron features, Electron and the nodejs APIs must be required using
// `window.require()` instead of `require()`: if we're running in an Electron
// environment, the nodejs `require()` is available on `window`, which is the
// official way to require Electron from the renderer process. If we used the
// straight `require()` (which is webpack's implementation, in our environment) it
// would try to look up a webpack module named "electron" which doesn't exist.

const container = (() => {
  if (window.isREC) {
    const ipcRenderer = window.ipcRenderer;
    return {
      name: "rec",
      kind: "desktop",
      send: (eventName, payload) => ipcRenderer.send(eventName, payload),
      sendSync: (eventName, payload) => ipcRenderer.sendSync(eventName, payload),
      subscribe: (eventName, listener) => ipcRenderer.on(eventName, listener),
      unsubscribe: (eventName, listener) => ipcRenderer.removeListener(eventName, listener),
    };
  } else if (isCordova()) {
    return {
      name: "rcc",
      kind: "mobile",
      // No-ops for now
      send: (eventName, payload) => {},
      sendSync: (eventName, payload) => {},
      subscribe: (eventName, listener) => {},
      unsubscribe: (eventName, listener) => {},
    };
  }
  return null;
})();

export default class Env {
  static get platform() {
    if (Env.isREC) return "kiosk";
    if (Env.isIos) return "ios";
    if (Env.isAndroid) return "android";
    return "browser";
  }

  static get isContained() {
    return !!container;
  }

  static get isMobile() {
    // Because we override the user agent in RCC, the
    // canned regex in `checkIfMobileBrowser` won't work in this case.
    return checkIfMobileBrowser() || Env.isRCC;
  }

  static get isDesktop() {
    return Env.isREC || !Env.isMobile;
  }

  // Detect small form-factor devices (portrait and landscape)
  // more reliably than with media queries. Suggested here:
  // https://stackoverflow.com/a/24704585/167983
  static get isPhone() {
    return /mobile/i.test(navigator.userAgent) && !/ipad|tablet/i.test(navigator.userAgent);
  }

  static get isRCC() {
    return container && container.name === "rcc";
  }

  static get isREC() {
    return container && container.name === "rec";
  }

  static get isIos() {
    return Env.isRCC && typeof device !== "undefined" && device.platform === "iOS";
  }

  static get isAndroid() {
    return Env.isRCC && typeof device !== "undefined" && device.platform === "Android";
  }

  static get storageDirectory() {
    if (Env.isREC) throw new Error("Storage directory is not yet supported in REC");
    return Env.isIos ? cordova.file.documentsDirectory : cordova.file.dataDirectory;
  }

  static addContainerNavigationItem(label, navigationOptions) {
    Env._sendToContainer("add-navigation-item", { label, navigationOptions });
  }

  static addContainerSeparatorNavigationItem() {
    Env._sendToContainer("add-navigation-item", "separator");
  }

  static openInstance(
    id,
    options = {},
    openOptions = {
      force: false, // Set to true to force opening instance even from slave instances.
    },
  ) {
    if (!Config.env.openInstances) {
      Log.warn(`Opening instances is disabled`);
      return;
    }

    if (Config.ipc.role === "slave" && !openOptions.force) {
      Log.warn(
        `Not opening instance ${id} because the current instance is a slave. Use 'openOptions.force' to override.`,
      );
      return;
    }

    if (id === Config.id) {
      Log.warn(`Not opening instance ${id} because it is the current instance`);
      return;
    }

    Log.info(`Opening new instance with ID '${id}' and options`, options);
    Env._sendToContainer("open-instance", { id, options });
  }

  static shutdownComputer() {
    Env._sendToContainer("shutdown-computer");
  }

  static fileExists(path) {
    if (!Env.isContained) return Promise.resolve();
    if (Env.isREC) return Env._fileExistsREC(path);
    if (Env.isRCC) return Env._fileExistsRCC(path);
    throw new Error("Can't check file existence in unknown container");
  }

  static readFile(path) {
    if (!Env.isContained) return Promise.resolve();
    if (Env.isREC) throw new Error("File reading is not yet supported in REC");
    if (Env.isRCC) return Env._readFileRCC(path);
    throw new Error("Can't read file in unknown container");
  }

  static appendFile(path, contents) {
    if (!Env.isContained) return Promise.resolve();
    if (Env.isREC) return Env._appendFileREC(path, contents);
    if (Env.isRCC) return Env._appendFileRCC(path, contents);
    throw new Error("Can't append to file in unknown container");
  }

  static writeFile(path, contents) {
    if (!Env.isContained) return Promise.resolve();
    if (Env.isREC) return Env._writeFileREC(path, contents);
    if (Env.isRCC) return Env._writeFileRCC(path, contents);
    throw new Error("Can't write file in unknown container");
  }

  static downloadFile(source, destination, onProgress) {
    if (!Env.isContained) return Promise.resolve();
    if (Env.isREC) return Env._downloadFileREC(source, destination, onProgress);
    if (Env.isRCC) return Env._downloadFileRCC(source, destination, () => {}); // TODO: Implement progress reporting in RCC!
    throw new Error("Can't download file in unknown container");
  }

  // Local Config

  static async _overwriteLocalConfig(object) {
    if (Env.isREC) {
      Env._sendToContainer("overwrite-local-config", object);
    } else if (Env.isRCC) {
      const localConfigPath = resource("data/local-config.yml");
      await Env._writeFileRCC(localConfigPath, YAML.stringify(object, { lineWidth: 0 }));
    } else {
      throw new Error("Cannot write to local config in this environment.");
    }
  }

  static async _updateLocalConfig(object) {
    if (Env.isREC) {
      Env._sendToContainer("update-local-config", object);
    } else if (Env.isRCC) {
      const localConfigPath = resource("data/local-config.yml");
      const exists = await Env._fileExistsRCC(localConfigPath);
      const existingConfig = exists ? YAML.parse(await Env._readFileRCC(localConfigPath)) : {};
      const newConfig = _.merge({}, existingConfig, object);
      const newString = YAML.stringify(newConfig, { lineWidth: 0 });
      await Env._writeFileRCC(localConfigPath, newString);
    } else {
      throw new Error("Cannot write to local config in this environment.");
    }
  }

  // Internal

  static _sendToContainer(eventName, payload) {
    if (!Env.isContained) return;
    container.send(eventName, payload);
  }

  static _sendToContainerSync(eventName, payload) {
    if (!Env.isContained) return;
    return container.sendSync(eventName, payload);
  }

  static _subscribeToReceiveFromContainerOnce(eventName, callback) {
    const subscription = Env._subscribeToReceiveFromContainer(eventName, (info) => {
      Env._unsubscribeToReceiveFromContainer(eventName, subscription);
      callback(info);
    });
  }

  static _subscribeToReceiveFromContainer(eventName, callback) {
    if (!Env.isContained) return;
    const listener = (event, arg) => callback(arg);
    container.subscribe(eventName, listener);

    return listener;
  }

  static _unsubscribeToReceiveFromContainer(eventName, listener) {
    if (!Env.isContained) return;
    container.unsubscribe(eventName, listener);
  }

  static _fileExistsREC(path) {
    return new Promise((resolve) => {
      const id = uuidv4();
      Env._sendToContainer("file-exists", { id, path });
      Env._subscribeToReceiveFromContainerOnce(`file-exists-${id}`, resolve);
    });
  }

  static _appendFileREC(path, contents) {
    return new Promise((resolve) => {
      const id = uuidv4();
      Env._sendToContainer("file-append", { id: id, path, contents });
      Env._subscribeToReceiveFromContainerOnce(`file-appended-${id}`, resolve);
    });
  }

  static _writeFileREC(path, contents) {
    return new Promise((resolve) => {
      const id = uuidv4();
      Env._sendToContainer("file-write", { id: id, path, contents });
      Env._subscribeToReceiveFromContainerOnce(`file-written-${id}`, resolve);
    });
  }

  static _downloadFileREC(source, destination, onProgress = () => {}) {
    return new Promise((resolve) => {
      const id = uuidv4();
      Env._sendToContainer("download", { id, source, destination });
      const progressSubscription = Env._subscribeToReceiveFromContainer(`download-progress-${id}`, (info) => {
        onProgress(info);
      });
      Env._subscribeToReceiveFromContainerOnce(`downloaded-${id}`, (result) => {
        Env._unsubscribeToReceiveFromContainer(`download-progress-${id}`, progressSubscription);
        resolve(result);
      });
    });
  }

  static _getDirectoryCreatingIntermediatesRCC(dir, path) {
    return new Promise((resolve) => {
      const components = path.split("/");
      const current = components[0];
      const remaining = components.slice(1);
      dir.getDirectory(current, { create: true }, (newDir) => {
        if (remaining.length === 0) {
          resolve(newDir);
          return;
        } else {
          Env._getDirectoryCreatingIntermediatesRCC(newDir, remaining.join("/")).then(resolve);
        }
      });
    });
  }

  static _fileExistsRCC = (path) => {
    return new Promise((resolve) => {
      window.resolveLocalFileSystemURL(Env.storageDirectory, (rootDir) => {
        rootDir.getFile(
          path,
          { create: false },
          () => resolve(true),
          () => resolve(false),
        );
      });
    });
  };

  static _readFileRCC = (path) => {
    return new Promise((resolve) => {
      const components = path.split("/");
      window.resolveLocalFileSystemURL(Env.storageDirectory, (rootDir) => {
        Env._getDirectoryCreatingIntermediatesRCC(rootDir, components.slice(0, -1).join("/")).then((dir) => {
          dir.getFile(components[components.length - 1], { create: true }, (fileEntry) => {
            fileEntry.file((file) => {
              const reader = new FileReader();
              reader.onloadend = function () {
                resolve(this.result);
              };
              reader.readAsText(file);
            });
          });
        });
      });
    });
  };

  static _writeFileRCC(path, contents) {
    return new Promise((resolve) => {
      const components = path.split("/");
      window.resolveLocalFileSystemURL(Env.storageDirectory, (rootDir) => {
        Env._getDirectoryCreatingIntermediatesRCC(rootDir, components.slice(0, -1).join("/")).then((dir) => {
          dir.getFile(components[components.length - 1], { create: true }, (file) => {
            file.createWriter((writer) => {
              writer.onerror = (error) => Log.error(`Failed to write file ${file}`, error);
              writer.onwriteend = resolve;
              writer.write(new Blob([contents]));
            });
          });
        });
      });
    });
  }

  static _downloadFileRCC(source, destination) {
    return new Promise((resolve) => {
      const components = destination.split("/");
      window.resolveLocalFileSystemURL(Env.storageDirectory, (rootDir) => {
        Env._getDirectoryCreatingIntermediatesRCC(rootDir, components.slice(0, -1).join("/")).then((dir) => {
          window.resolveLocalFileSystemURL(
            `${Env.storageDirectory}/${destination}`,
            () => {
              // File already exists
              resolve({ result: "skipped" });
            },
            () => {
              // File does not exist, download it
              const sourceURL = encodeURI(source);
              const destinationURL = `${Env.storageDirectory}/${destination}`;
              const transfer = new FileTransfer();
              transfer.download(
                sourceURL,
                destinationURL,
                (entry) => resolve({ result: "success" }),
                (error) =>
                  resolve({
                    result: "failure",
                    error: `Failed to download ${destinationURL} : ${JSON.stringify(error)}`,
                  }),
                false,
              );
            },
          );
        });
      });
    });
  }
}
