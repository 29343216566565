import { lighten, transparentize } from "polished";
import { Droppable, Dropzone, Image, Text, resource, staggerStep } from "ripple";
import styled from "styled-components";
import { OldQuestionRoot, RoundYellowButton, titleColor } from "../../../common";
import SpeakerButton from "../../../components/speaker-button";

export const DragQuestionRoot = styled(OldQuestionRoot)``;

export const CompletedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100%;
  width: 100%;
`;

export const Content = staggerStep({ transition: "appear-floating" })(styled.div`
  display: flex;
  align-items: center;

  @media screen and (orientation: portrait) {
    flex-direction: column;
    margin-left: 0;
  }

  @media screen and (orientation: landscape) {
    flex-direction: row;
    align-items: center;
    margin-left: -18vh;
  }
`);

export const DraggableContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
`;

export const Droppables = styled.div`
  margin-top: 4vh;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
`;

export const DroppableCard = styled(Droppable)`
  width: 12vh;
  height: 15vh;
  margin: 2vmin;
`;

export const DroppableCardImage = staggerStep({ transition: "appear-floating" })(styled(Image).attrs((props) => ({
  scaling: "fill",
  fadeIn: "always",
}))`
  width: 12vh;
  height: 15vh;

  border-radius: 1.5vh;
  border: 0;
  box-shadow: 0 0 0 0.4vh ${(props) => props.color};
  opacity: ${(props) => (props.dragging ? 0.4 : 1)};
  transition: all 150ms ease-in-out;
`);

export const DraggingDroppableImage = styled(DroppableCardImage)`
  transition: transform 150ms ease-in-out;
  transform: rotate(10deg);
`;

export const Card = styled.div`
  @media screen and (orientation: landscape) {
    width: 45vh;
    height: 63vh;
  }

  @media screen and (orientation: portrait) {
    width: 37.5vh;
    height: 52.7vh;
  }

  border-radius: 4vh;
  transition: all 150ms ease-in-out;
  ${(props) => {
    if (props.empty && !props.dragging) {
      return `
        border: 0.8vh dashed ${props.color};
        box-shadow: 0 0 0 0 ${props.color};
    `;
    } else if (props.dragging) {
      return `
        border: 0.8vh dashed ${props.color};
        box-shadow: 0 0 4vh 0 ${props.color};
    `;
    } else if (!props.empty) {
      return `
        border: 0;
        box-shadow: 0 0 0 0.8vh ${props.color};
    `;
    }
  }};

  background-color: ${(props) => transparentize(0.3, lighten(0.3, props.color))};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  overflow: hidden;
`;

export const QuestionMarkImage = styled(Image).attrs((props) => ({
  fadeIn: "always",
  src: resource("images/QuestionMark.svg"),
}))`
  height: 75%;
  max-width: 100%;
  margin: 7vh;
`;

export const CardDropzoneContainer = styled.div`
  position: relative;
  top: 0;
  height: 84%;
  width: 100%;
`;

export const CardDropzone = styled(Dropzone)`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 4vh 4vh 0 0;
`;

export const CardBar = styled.div.attrs((props) => ({ muted: true }))`
  height: 16.5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: opacity 150ms ease-in-out;
  background-color: ${(props) => lighten(0.3, props.color)};
  padding-right: 2vh;
`;

export const CardText = styled(Text)`
  margin-left: 2vh;
  color: ${titleColor};
  font-size: 4vh;
  line-height: 4vh;
`;

export const CardAudioSymbol = styled(RoundYellowButton).attrs((props) => ({ as: "div" }))`
  margin-left: auto;
  margin-right: 1vh;
  min-width: 7vh;
  width: 7vh;
  height: 7vh;
`;

export const CardDropContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const CardImage = styled(Image).attrs((props) => ({ scaling: "fill", fadeIn: "always" }))`
  height: 100%;
  width: 100%;
`;

export const CardSpeakerButton = styled(SpeakerButton)`
  min-width: 7vh;
  width: 7vh;
  height: 7vh;
`;
