import PropTypes from "prop-types";
import { StandardImageRoot } from "./styled";

const StandardImage = ({ className, style, ...rest }) => {
  return <StandardImageRoot {...rest} className={className} style={style} />;
};

StandardImage.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default StandardImage;
