import PropTypes from "prop-types";
import { renderResourceChild, textColor } from "../../../common";
import LanguageSwitcher from "../../../components/language-switcher";
import {
  Header,
  ResourceButton,
  ResourceSwitcher,
  ResourcesSheetRoot,
  StyledCloseButton,
  StyledScroller,
} from "./styled";

const ResourcesSheet = ({ className, show, resources, resource, onResourceClick, onCloseClick, ...rest }) => {
  const createOnResourceButtonClick = (resource) => () => onResourceClick(resource);
  return (
    <ResourcesSheetRoot {...rest} className={className} show={show}>
      <Header>
        <LanguageSwitcher color={textColor} />
        <StyledCloseButton onClick={onCloseClick} />
      </Header>
      <ResourceSwitcher>
        {resources.map((r) => (
          <ResourceButton key={r.id} $active={r === resource} onClick={createOnResourceButtonClick(r)}>
            {r.wantedText("Title")}
          </ResourceButton>
        ))}
      </ResourceSwitcher>
      {resource && <StyledScroller>{resource.children.map(renderResourceChild)}</StyledScroller>}
    </ResourcesSheetRoot>
  );
};

ResourcesSheet.propTypes = {
  className: PropTypes.string,
  resources: PropTypes.array,
  resource: PropTypes.object,
  show: PropTypes.bool,
  onResourceClick: PropTypes.func,
  onCloseClick: PropTypes.func,
};

export default ResourcesSheet;
