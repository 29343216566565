import DownloadNodeIdActions from "../../actions/local/download-node-id-actions";

export default (state = null, action) => {
  switch (action.type) {
    case DownloadNodeIdActions.SET_ID:
      return action.id;
    default:
      return state;
  }
};
