import { Chart } from "chart.js";
import _ from "lodash";
import PropTypes from "prop-types";
import { Component, createRef } from "react";
import { connect } from "react-redux";
import Classes from "../../../helpers/classes";
import Strings from "../../../helpers/strings";
import Styles from "../../../helpers/styles";

class BarBox extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    stat: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.chartElement = createRef();
  }

  render() {
    return (
      <div className={Classes.build("box bar", this.props.className)} style={Styles.merge(this.props.style)}>
        <h1>
          {Strings.localized(`Analytics${this.props.stat.name}`)}
          &nbsp;
          <i className="info fa fa-info-circle" title={Strings.localized(`Analytics${this.props.stat.name}Tooltip`)} />
        </h1>
        <div className="chart-container">
          <canvas ref={this.chartElement} />
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (this.props.language !== prevProps.language) return true;
    if (JSON.stringify(this.props.stat) === JSON.stringify(prevProps.stat)) return false;
    return true;
  }

  update = () => {
    const labels = _.map(this.props.stat.value, (v) => v.label);
    const values = _.map(this.props.stat.value, (v) => v.value);

    if (this.chart) this.chart.destroy();
    this.chart = new Chart(this.chartElement.current, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: this.props.stat.barLabel,
            data: values,
            backgroundColor: "#00a5e3",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
        },
        tooltips: {
          backgroundColor: "rgba(50, 50, 50, 0.9)",
        },
      },
    });
  };
}

const mapStateToProps = (state) => ({ language: state.language });

export default connect(mapStateToProps)(BarBox);
