import { Image } from "ripple";
import styled from "styled-components";

export const TagLayerRoot = styled.div`
  position: relative;
  pointer-events: none;
`;

export const TagRectangle = styled.div`
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  width: 3vh;
  height: 3vh;
  border-radius: 0 0 0 1vh;
  padding: 0.3vh;
`;

export const TagIcon = styled(Image)`
  transform: translate3d(2px, -2px, 0);
  svg {
    path[id^="bg--inject"] {
      visibility: hidden;
    }
    path {
      fill: ${(props) => props.$color};
    }
  }
`;
