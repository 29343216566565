let CloudInfo = function (url, namespace) {
  this.url = url;

  function buildUrl(path) {
    return `${url}/${path}`;
  }

  this.buildPingUrl = function () {
    return buildUrl(`${namespace}/ping.txt`);
  };

  this.buildClientUrl = function (id) {
    return buildUrl(`${namespace}/${id}.xml`);
  };

  this.buildClientsUrl = function () {
    return buildUrl(`${namespace}/clients.xml`);
  };

  this.buildMediaUrl = function (formatMetadatas, media, format) {
    // Find the ID corresponding to the named format, as our S3 storage stores files by ID (as on the server)
    // and does not support getting medias by format name like our server API does.
    const formatMetadata = formatMetadatas
      .filter((fm) => fm.name === format)
      // If custom overrides a core format name, we must favor that (take the one with the highest id)
      .reduce((max, item) => (item.id > max.id ? item : max));

    if (!formatMetadata) return null;

    const formatId = formatMetadata.id;
    const formatExtension = formatMetadata.extension;

    return buildUrl(`${namespace}/${media.id}/${media.revision}/${formatId}.${formatExtension}`);
  };
};

export default CloudInfo;
