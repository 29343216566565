import { useCallback } from "react";
import { useImmer } from "use-immer";

/**
 * Abstracts the state and logic necessary to handle both permanent
 * and momentary feedback in children without having to use refs
 * and/or wrap logic in otherwise unnecessary sub-components.
 */
export const useFeedback = () => {
  const [feedbacks, updateFeedbacks] = useImmer({});

  const addPermanentFeedback = useCallback(
    (id, type = "neutral") => {
      // Do not add if there is already one for this id
      if (feedbacks[id]) return;

      updateFeedbacks((draft) => {
        draft[id] = { type, show: true };
      });
    },
    [feedbacks, updateFeedbacks],
  );

  const addMomentaryFeedback = useCallback(
    (id, type = "neutral") => {
      // Do not add if there is already one for this id
      if (feedbacks[id]) return;

      // Remove the momentary feedback after a delay
      setTimeout(() => {
        updateFeedbacks((draft) => {
          draft[id].show = false;
        });
        setTimeout(() => {
          updateFeedbacks((draft) => {
            delete draft[id];
          });
        }, 500);
      }, 1500);

      updateFeedbacks((draft) => {
        draft[id] = { type, show: true };
      });
    },
    [feedbacks, updateFeedbacks],
  );

  return { feedbacks, addPermanentFeedback, addMomentaryFeedback };
};
