import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import Classes from "../../../helpers/classes";
import { usePrevious } from "../../hooks/use-previous";

/**
 * This component prevents its contents from being rendered (or displayed)
 * based on the component's visibility in the screen.
 */
const Visible = memo(({ className, children, alwaysVisible, onViewEnter, onViewExit, ...rest }) => {
  const [ref, inView] = useInView();
  const previousInView = usePrevious(inView);

  useEffect(() => {
    if (inView && !previousInView) {
      if (onViewEnter) onViewEnter();
    } else if (!inView && previousInView) {
      if (onViewExit) onViewExit();
    }
  }, [inView, onViewEnter, onViewExit, previousInView]);

  return (
    <div {...rest} ref={ref} className={Classes.build("ripple-visible", className, { hidden: !inView })}>
      {(inView || alwaysVisible) && children}
    </div>
  );
});

Visible.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  alwaysVisible: PropTypes.bool, // Disables the Visible's behaviour, acting like a normal div
  onViewEnter: PropTypes.func,
  onViewExit: PropTypes.func,
};

export default Visible;
