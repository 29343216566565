import _ from "lodash";

function calculate(preprocessed) {
  if (preprocessed.sessions.length === 0) return 0;
  const sums = _.map(preprocessed.sessions, (events) => _.filter(events, (e) => e.name === "navigation").length);
  if (sums.length === 0) return 0;
  return Math.round(_.mean(sums));
}

export default function (preprocessed) {
  this.name = "AverageNavigationsPerSession";
  this.format = (value) => value;
  this.value = calculate(preprocessed);
}
