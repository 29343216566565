import { produce } from "immer";
import DownloadFilesActions from "../../actions/local/download-files-actions";

export default (state = [], action) => {
  switch (action.type) {
    case DownloadFilesActions.ADD_FILE:
      return produce(state, (draft) => {
        draft.push({ url: action.url, name: action.name, loaded: 0, total: 0 });
      });
    case DownloadFilesActions.UPDATE_FILE_PROGRESS:
      return produce(state, (draft) => {
        const file = draft.find((f) => f.url === action.url);
        file.loaded = action.loaded;
        file.total = action.total;
      });
    case DownloadFilesActions.REMOVE_FILE: {
      return produce(state, (draft) => draft.filter((f) => f.url !== action.url));
    }
    default:
      return state;
  }
};
