import { Text } from "ripple";
import styled from "styled-components";
import { CustomPage, StandardScroller, textColor } from "../../common";
import BackButton from "../../components/back-button";
import LanguageSwitcher from "../../components/language-switcher";

export const Page = styled(CustomPage)`
  background-color: white;
`;

export const Header = styled.div`
  height: 12vmin;
  background-color: ${textColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 3vmin;
`;

export const HeaderLeft = styled.div`
  flex: 1 1 0;
`;

export const HeaderCenter = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const HeaderRight = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledBackButton = styled(BackButton)`
  width: 6vmin;
  height: 6vmin;
`;

export const StyledLanguageSwitcher = styled(LanguageSwitcher)``;

export const Title = styled(Text)`
  font-size: 3vmin;
`;

export const ContentScroller = styled(StandardScroller)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  .scroller-content {
    padding: 4vmin 18%;
  }

  .scroller-thumb {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;
