import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { MediaTransitioner, useConstant, useData, usePrevious } from "ripple";
import DragPreview from "ripple/scripts/react/components/drop/drag-preview";
import Dropzone from "ripple/scripts/react/components/drop/dropzone";
import Sequencer from "sequencer.js";
import FeedbackLayer from "../../../components/feedback-layer";
import ImageAudioDroppable from "../../../components/image-audio-droppable";
import ImageDragPreview from "../../../components/image-drag-preview";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import SquareItemGridPanelContent from "../../../components/square-item-grid-panel-content";
import { useFeedback } from "../../../hooks/use-feedback";
import { QuestionPanelContent } from "../styled";
import { BigBox, ItemsLayer, Type3QuestionRoot } from "./styled";

const Type3Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const items = node.children;
  const identifiersInOrder = useConstant(() => node.identifier.split(",").map((t) => t.trim()));
  const singleMode = identifiersInOrder.length === 1;

  const { feedbacks, addMomentaryFeedback } = useFeedback();
  const [referenceImage, setReferenceImage] = useState(node.wantedMedia("ImageA", "Image"));
  const [drops, setDrops] = useState([]);

  const onDrop = useCallback(
    (dropzoneId, item) => {
      setDrops([...drops, item]);
    },
    [drops],
  );

  const [isOver, setIsOver] = useState(false);
  const onOver = useCallback((over) => setIsOver(over), []);

  const sequencerRef = useRef(new Sequencer());
  const previousDrops = usePrevious(drops);
  useEffect(() => {
    if (drops.length !== identifiersInOrder.length) return;
    if (previousDrops?.length === identifiersInOrder.length) return; // Prevent double completion check

    const allGood = drops.map((item, index) => item.identifier === identifiersInOrder[index]).every((r) => r === true);

    const sequencer = sequencerRef.current;
    sequencer.clear();

    if (!allGood) {
      addMomentaryFeedback(node.id, "bad");
      setDrops([]);
    } else {
      const end = () => onComplete({ delay: 0 });
      if (singleMode) {
        end();
      } else {
        sequencer.do(() => addMomentaryFeedback(node.id, "good"));
        sequencer.doWait(1750);
        sequencer.do(() => setReferenceImage(node.wantedMedia("ImageB", "Image")));
        sequencer.doWait(500);
        sequencer.do(end);
      }
    }
  }, [
    addMomentaryFeedback,
    drops,
    drops.length,
    identifiersInOrder,
    items.length,
    node,
    node.id,
    node.identifier,
    onComplete,
    previousDrops?.length,
    singleMode,
  ]);

  useEffect(() => {
    const sequencer = sequencerRef.current;
    return () => sequencer.clear();
  }, []);

  useEffect(() => {
    const sequencer = sequencerRef.current;
    return () => sequencer.clear();
  }, []);

  const renderPanelContent = (location) => {
    return (
      <QuestionPanelContent $location={location}>
        <SquareItemGridPanelContent items={items} location={location}>
          {(item) => <ImageAudioDroppable node={item} disabled={drops.includes(item)} />}
        </SquareItemGridPanelContent>
      </QuestionPanelContent>
    );
  };

  const renderMiniItem = (item) => <ImageDragPreview key={item.id} image={item.wantedMedia("Image", "Image")} />;

  const renderDragPreview = useCallback(renderMiniItem, []);

  return (
    <Type3QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent
        sidePanelContent={renderPanelContent("side")}
        bottomPanelContent={renderPanelContent("bottom")}
      >
        <BigBox glow={isOver}>
          <Layers>
            <MediaTransitioner src={referenceImage} classNames="crossfade" mediaProps={{ scaling: "fill" }} />
            {!singleMode && <ItemsLayer>{drops.map(renderMiniItem)}</ItemsLayer>}
            <FeedbackLayer feedback={feedbacks[node.id]} />
            <Dropzone onOver={onOver} onDrop={onDrop} />
          </Layers>
        </BigBox>
      </ResponsiveContent>
      <DragPreview render={renderDragPreview} />
    </Type3QuestionRoot>
  );
};

Type3Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type3Question;
