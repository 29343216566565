import _ from "lodash";
import queryString from "query-string";

const LocationHelper = {
  getValue: function (name) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) === name) {
        return decodeURIComponent(pair[1]);
      }
    }
  },
  urlFromPathAndQuery: function (path, query) {
    if (!query || _.isEmpty(query)) return path;
    return `${path}?${queryString.stringify(query)}`;
  },
  urlFromLocation: function (location) {
    return LocationHelper.urlFromPathAndQuery(location.pathname, location.query);
  },
};

export default LocationHelper;
