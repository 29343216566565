// This triggers webpack bundling of styles.
// The styles are injected in a <style> tag at runtime,
// which makes them HMR-compatible.
import { App, Config, Env, resource } from "ripple";
import "../styles/main.less";
import nav from "./core/nav";
import stats from "./core/stats";
import { checkIfMobileVersion } from "./logic/mobile";
import AppRoot from "./react/app-root";
import localEpic from "./redux/local/local-epic";
import localReducer from "./redux/local/local-reducer";
import masterEpic from "./redux/master/master-epic";
import masterReducer from "./redux/master/master-reducer";

App.bootstrap({
  element: document.querySelector("#app"),
  appRoot: AppRoot,
  home: () => {
    const mobileHomeViewed = localStorage.getItem("mobile-home-viewed") === "true";
    if (!checkIfMobileVersion() || !mobileHomeViewed) return "/home";
    return "/mobile-main";
  },
  nav: nav, // Dynamic navigation logic
  stats: stats, // Custom stats to add in the `/analytics` dashboard
  localReducer: localReducer, // Root reducer for the `local` portion of the local store
  localEpic: localEpic, // Epics triggered by dispatches to the local store
  masterReducer: masterReducer, // Root reducer for the IPC master store (maintained by the IPC master)
  masterEpic: masterEpic, // Epics triggered by IPC dispatches (run on the master store maintained by the IPC master)
  fonts: {
    custom: {
      families: [
        "ComicNeue-LightItalic",
        "ComicNeue-Light",
        "ComicNeue-BoldItalic",
        "ComicNeue-Regular",
        "ComicNeue-Bold",
        "ComicNeue-Italic",
      ],
      urls: [resource("fonts/comic-neue/stylesheet.css")],
    },
  },
  excludeNodeFromInitialFetch: (node) => {
    if (node.parent?.semantic === "Theme") return true;
    return false;
  },
  excludeMediaFromInitialFetch: (media) => false,
  onStart: () => {
    if (Config.openIpadInstance) Env.openInstance("ipad", { config: { forceMobile: true } });
    if (Config.openIphoneInstance) Env.openInstance("iphone", { config: { forceMobile: true } });
  },
});
