import { useCallback, useState } from "react";
import { useImmer } from "use-immer";
import { useFeedback } from "./use-feedback";

export const useDragItemsInOrderAndValidateWhenFullQuestionLogic = (itemIdentifiers, onComplete) => {
  const { feedbacks, addPermanentFeedback, addMomentaryFeedback } = useFeedback();

  const [drops, setDrops] = useState([]);

  const [overs, updateOvers] = useImmer({});
  const onOver = useCallback(
    (isOver, dropzoneId) => {
      updateOvers((draft) => {
        draft[dropzoneId] = isOver;
      });
    },
    [updateOvers],
  );

  const onDrop = useCallback(
    (dropzoneIndex, item) => {
      const newDrops = [...drops];
      newDrops[dropzoneIndex] = item;

      setDrops(newDrops);

      if (newDrops.filter((d) => !!d).length === itemIdentifiers.length) {
        if (newDrops.every((d, index) => d?.identifier === itemIdentifiers[index])) {
          // All good!
          addPermanentFeedback("question");
          onComplete();
        } else {
          // Not perfect...
          addMomentaryFeedback("question", "bad");
          setDrops([]);
        }
      }
    },
    [addMomentaryFeedback, addPermanentFeedback, drops, itemIdentifiers, onComplete],
  );

  return { feedbacks, onOver, overs, onDrop, drops };
};
