import { desaturate, lighten } from "polished";
import { Slider } from "ripple";
import styled from "styled-components";
import Star from "../star";

export const GameProgressBarRoot = styled.div`
  position: relative;
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2vmax;
  background-color: ${(props) => desaturate(0.4, lighten(0.3, props.$color))};
`;

export const ProgressBar = styled(Slider)`
  position: absolute;
  width: 100%;
  height: 100%;

  .track {
    height: 100%;
    border-radius: 2vmax;
    background-color: transparent;
  }

  .fill {
    transition: all 200ms ease-in-out;
    background-color: ${(props) => props.$color};
  }
`;

export const StyledStar = styled(Star)`
  margin-right: 1.5vmin;
  height: 70%;
  position: absolute;
  right: 0;
`;
