import PropTypes from "prop-types";
import { memo } from "react";
import Classes from "../../../helpers/classes";
import getSrcType from "../../../helpers/internal/get-src-type";
import { MediaSrcPropType } from "../../../logic/prop-types";
import Image from "../image";
import Video from "../video";

const Media = memo(({ className, src, ...rest }) => {
  const actualClassName = Classes.build("ripple-media", className);

  // Render the appropriate component based on media type
  const type = getSrcType(src);
  if (!type) return null;
  if (type === "image" || type === "vector") return <Image {...rest} className={actualClassName} src={src} />;
  if (type === "video") return <Video {...rest} className={actualClassName} src={src} />;
  if (type === "generic") return <Video {...rest} className={actualClassName} src={src} />; // We can assume binary files are most probably not a picture (webm, etc.)
  throw new Error(`Unsupported media type '${type}'`);
});

Media.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  src: MediaSrcPropType,
  children: PropTypes.node,
  onLoad: PropTypes.func, // Common interface for media readiness
};

export default Media;
