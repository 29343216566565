import styled from "styled-components";
import { standardGap, standardQuestionMaxContentSize } from "../../../common";
import FeedbackLayer from "../../../components/feedback-layer";
import ImageLayer from "../../../components/image-layer";
import Layers from "../../../components/layers";
import Question from "../../../components/question";

export const Type11QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Quadrants = styled.div`
  position: relative;
  aspect-ratio: 1;
  @media screen and (orientation: landscape) {
    width: ${standardQuestionMaxContentSize};
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const Quadrant = styled.div`
  position: absolute;
  width: calc(50% - ${standardGap} / 2);
  height: calc(50% - ${standardGap} / 2);
`;

export const BorderImageLayer = styled(ImageLayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transform: rotate3d(0, 0, 1, ${(props) => -90 * props.$index}deg);
  overflow: visible; /* Allow SVG glow to render past image bounds */

  svg {
    path:last-child {
      stroke: ${(props) => props.$color};
      stroke-dasharray: 4, 11;
      stroke-width: 4px;
    }
    g[id^="Glow--inject"] {
      opacity: ${(props) => (props.$over ? 1 : 0)};
    }
  }
`;

export const ContentLayers = styled(Layers)`
  mask: url(${(props) => props.$mask});
  mask-size: cover;
`;

export const ItemsLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.$quadrantNumber <= 2 ? "flex-end" : "flex-start")};
  justify-content: ${(props) =>
    props.$quadrantNumber === 1 || props.$quadrantNumber === 4 ? "flex-start" : "flex-end"};
  padding: ${standardGap};
  gap: calc(${standardGap} / 2);
`;

export const StyledFeedbackLayer = styled(FeedbackLayer)`
  .app-feedback-overlay-image-container {
    transform: ${(props) => {
      if (props.$quadrantNumber === 1) return "translate3d(-9%, 9%, 0)";
      if (props.$quadrantNumber === 2) return "translate3d(9%, 9%, 0)";
      if (props.$quadrantNumber === 3) return "translate3d(9%, -9%, 0)";
      if (props.$quadrantNumber === 4) return "translate3d(-9%, -9%, 0)";
    }};
  }
`;
