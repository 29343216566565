import { useState } from "react";

/**
 * Store a constant value (that can be itself modified) and use it in a way similar to state.
 * The main goal of this hook is to provide better semantics and a less error-prone syntax for when
 * we need to initialize a value once in the component's lifetime.
 */
export const useConstant = (initialState) => {
  // Using useState ensures that the first render is blocked while the
  // initial state is being computed (if initialState is a function).
  const [constant] = useState(initialState);
  return constant;
};
