import { Image, resource } from "ripple";
import styled from "styled-components";
import { CustomPage, HomeTitle, RoundYellowButton } from "../../common";
import MascotHome from "../../components/mascot-home";

export const Page = styled(CustomPage)`
  align-items: center;
`;

export const Title = styled(HomeTitle)`
  margin-top: env(safe-area-inset-top);
`;

export const Logo = styled(Image).attrs((props) => ({ src: resource("images/Splash_Logo.png"), scaling: "fit" }))`
  position: absolute;
  height: 5vmax;
  bottom: 5vmax;
  pointer-events: none;
`;

export const NextButton = styled(RoundYellowButton)`
  position: absolute;
  right: 4vmax;
  bottom: 4vmax;
  width: 8vmax;
  height: 8vmax;
`;

export const NextButtonSymbol = styled(Image).attrs((props) => ({ src: resource("images/Button_Next_Symbol.svg") }))``;

export const MascotsContainer = styled.div`
  position: absolute;
  bottom: 16%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4vw;
`;

export const MoukaMascot = styled(MascotHome)``;

export const RaliMascot = styled(MascotHome)``;
