import { Canvas } from "@react-three/fiber";
import PropTypes from "prop-types";
import { memo, useContext } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { RippleContext } from "ripple";

const ReduxLocalStoreProvider = ({ children }) => {
  const rippleContext = useContext(RippleContext);
  return <ReduxProvider store={rippleContext.store}>{children}</ReduxProvider>;
};

ReduxLocalStoreProvider.propTypes = {
  children: PropTypes.node,
};

/**
 * A `@react-three/fiber` canvas pre-configured with good defaults.
 */
const ThreeFiberCanvas = memo(({ children, camera = {}, ...rest }) => {
  const ripple = useContext(RippleContext);

  return (
    <Canvas camera={camera} {...rest}>
      {
        // Manually forwarding the Ripple context is necessary because we're crossing reconciler boundaries
        // https://github.com/react-spring/react-three-fiber/issues/262#issuecomment-568274573
      }
      <RippleContext.Provider value={ripple}>
        <ReduxLocalStoreProvider>{children}</ReduxLocalStoreProvider>
      </RippleContext.Provider>
    </Canvas>
  );
});

ThreeFiberCanvas.propTypes = {
  children: PropTypes.node,
  camera: PropTypes.object,
};

export default ThreeFiberCanvas;
