import PropTypes from "prop-types";
import { PlayButtonRoot, PlaySymbol } from "./styled";

const PlayButton = ({ className, style, ...rest }) => {
  return (
    <PlayButtonRoot {...rest} className={className} style={style}>
      <PlaySymbol />
    </PlayButtonRoot>
  );
};

PlayButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default PlayButton;
