import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Audio, resource, useData } from "ripple";
import { GoodImage, WrongImage } from "../../../common";
import {
  BoldWordSentence,
  Card,
  CardImage,
  CardImageButton,
  CardSpeakerButton,
  ClickWordSentenceQuestionRoot,
  Content,
  Line,
  SentenceBar,
  WordButton,
  WordSentence,
} from "./styled";

const ClickWordSentenceQuestion = ({ onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const items = node.children;
  const client = useData((data) => data.root);
  const color = node.parent.wantedText("Color")?.trim();
  const audio = node.wantedMedia("Audio", "Audio");

  const line1 = _.takeWhile(items, (item) => item.semantic === "WordItem");
  let line2 = null;
  if (line1.length !== items.length) line2 = _.takeRightWhile(items, (item) => item.semantic === "WordItem");

  const [clickedWord, setClickedWord] = useState(null);
  const [goodAnswer, setGoodAnswer] = useState(false);

  const createOnWordClick = (word) => () => {
    if (word.settings.Answer === "good") {
      setGoodAnswer(true);
    } else {
      setClickedWord(word);
    }
  };

  // Good Answer
  useEffect(() => {
    if (!goodAnswer) return;
    Audio.discrete("effects").play(resource("audio/ThumbUp01.mp3"));
    setTimeout(() => {
      onComplete();
    }, 2000);
  }, [goodAnswer, client, onComplete]);

  // Bad Answer
  useEffect(() => {
    if (!clickedWord) return;
    Audio.discrete("effects").play(resource("audio/ThumbDown01.mp3"));
    setTimeout(() => {
      setClickedWord(null);
    }, 2000);
  }, [clickedWord, client]);

  const renderWordButton = (word) => {
    let inactive = false;
    if (clickedWord) {
      inactive = clickedWord.id !== word.id ?? false;
    }
    return (
      <WordButton inactive={inactive} disabled={inactive} color={color} key={word.id} onClick={createOnWordClick(word)}>
        {word.wantedText("Text")}
      </WordButton>
    );
  };

  const renderWordSentence = (word) => {
    const answer = word.settings.Answer === "good";
    return answer ? (
      <BoldWordSentence>{word.wantedText("Text")}</BoldWordSentence>
    ) : (
      <WordSentence>{word.wantedText("Text")}</WordSentence>
    );
  };

  return (
    <ClickWordSentenceQuestionRoot {...rest}>
      <Content>
        <Card color={color}>
          <CardImage src={node.wantedMedia("CardImage", "CardImage")} />
          <CardImageButton color={color}>
            <CardSpeakerButton src={audio} />
            {goodAnswer && <GoodImage />}
            {clickedWord && clickedWord.settings.Answer === "bad" && <WrongImage />}
          </CardImageButton>

          <SentenceBar color={color}>
            {!goodAnswer && (
              <>
                <Line>{line1.map(renderWordButton)}</Line>
                {line2 && <Line>{line2.map(renderWordButton)}</Line>}
              </>
            )}
            {goodAnswer && (
              <>
                <Line>{line1.map(renderWordSentence)}</Line>
                {line2 && <Line>{line2.map(renderWordSentence)}</Line>}
              </>
            )}
          </SentenceBar>
        </Card>
      </Content>
    </ClickWordSentenceQuestionRoot>
  );
};

ClickWordSentenceQuestion.propTypes = { onComplete: PropTypes.func };

export default ClickWordSentenceQuestion;
