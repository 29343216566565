import { Button, Image, resource, staggerStep } from "ripple";
import styled from "styled-components";

export const ThemeButtonRoot = staggerStep({ transition: "scale-up-fade" })(styled(Button)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  aspect-ratio: 1/1;
  border-radius: 50%;
  transition: all 250ms ease-in-out;
  border: ${(props) => (props.$active ? "2px solid white" : "2px solid transparent")};
  box-shadow: ${(props) => (props.$active ? "0 1vmin 10px rgba(0, 0, 0, 0.25)" : "0 0 5px rgba(0, 0, 0, 0.25)")};
  overflow: hidden;

  .ripple-image {
    opacity: ${(props) => (props.$active ? 1 : 0.5)};
  }
`);

export const UndownloadedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
`;

export const DownloadSymbol = styled(Image).attrs((props) => ({
  src: resource("images/Button_Download_Symbol.png"),
}))`
  filter: brightness(100000%);
`;
