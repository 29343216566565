import { memo, useCallback, useState } from "react";
import { Navigator, useData } from "ripple";
import { Logo, MascotsContainer, MoukaMascot, NextButton, NextButtonSymbol, Page, RaliMascot, Title } from "./styled";

const MobileHomePage = memo(() => {
  const client = useData((data) => data.root);
  const themes = client.children.filter((c) => c.semantic === "Theme");
  const [messageIndex, setMessageIndex] = useState(0);

  const onNextButtonClick = useCallback(() => {
    if (messageIndex === 1) {
      localStorage.setItem("mobile-home-viewed", true);
      Navigator.navigate({ node: themes[0] });
    } else setMessageIndex(messageIndex + 1);
  }, [messageIndex, themes]);

  return (
    <Page rootBackground={client.wantedMedia("Background", "FullscreenImage")}>
      <Title>{client.wantedText("HomeTitle")}</Title>
      <MascotsContainer>
        <MoukaMascot
          image={client.wantedMedia("MascotImage", "PreserveSizeWithAlpha")}
          audio={client.wantedMedia(`HomeWelcomeMobile${messageIndex + 1}MoukaAudio`, "Audio")}
          text={client.wantedText(`HomeWelcomeMobileMouka${messageIndex + 1}`)}
        />
        <RaliMascot
          image={client.wantedMedia("MascotImage2", "PreserveSizeWithAlpha")}
          audio={client.wantedMedia(`HomeWelcomeMobile${messageIndex + 1}RaliAudio`, "Audio")}
          text={client.wantedText(`HomeWelcomeMobileRali${messageIndex + 1}`)}
        />
      </MascotsContainer>
      <NextButton onClick={onNextButtonClick}>
        <NextButtonSymbol />
      </NextButton>
      <Logo />
    </Page>
  );
});

export default MobileHomePage;
