import _ from "lodash";

export default class Format {
  /** Formats a time interval (in milliseconds) to a string with the format "0:00" */
  static time(interval) {
    if (!interval || isNaN(interval)) return "00:00";
    if (interval <= 0) return "00:00";
    const actualSeconds = Math.floor(interval / 1000.0);
    const seconds = _.padStart(actualSeconds % 60, 2, "0");
    const minutes = _.padStart(Math.floor(actualSeconds / 60), 2, "0");
    return `${minutes}:${seconds}`;
  }
}
