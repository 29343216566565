import svgInject from "@iconfu/svg-inject";
import PropTypes from "prop-types";
import { forwardRef, memo, useEffect, useRef } from "react";
import { mergeRefs } from "react-merge-refs";
import Classes from "../../../helpers/classes";
import Config from "../../../helpers/config";
import Log from "../../../helpers/log";
import { MediaSrcPropType } from "../../../logic/prop-types";
import MediaFile from "../../../model/media-file";

const RippleImage = memo(
  forwardRef(
    (
      {
        style,
        className,
        id,
        src,
        scaling = "stretch", // Most similar to <img/> when specifying a fixed width and height
        density: externalDensity = "auto", // Unless the filename contains a density indicator (ex: `@2x`), same behavior as <img/> (consider the image as @1x),
        fadeIn = "never", // Most similar to <img/>
        onLoad,
      },
      ref,
    ) => {
      const rootRef = useRef(null);

      const url = (() => {
        if (!Config.load.images) return null;
        if (!src) return null;

        if (typeof src === "string") return src;

        // We assume it's an image because we can't know if the underlying format
        // is an image based on the media's type (the media may be a video, but the
        // format may be an image thumbnail for that video).
        if (src instanceof MediaFile) return src.url;

        return null;
      })();

      const density = (() => {
        if (externalDensity === "auto") {
          // When the density prop is set to "auto", we extract the image density (for example `2`)
          // from the URL. Examples of valid patterns: `@2x`, `_2x`, `@3x`, etc. If the URL does not
          // contain a density pattern, we consider the image to be @1x.
          const match = /[@_](\d)x(?![a-zA-Z0-9])/.exec(url);
          if (match && match.length === 2) return parseInt(match[1]);
          return 1;
        }
        return externalDensity;
      })();

      const srcType = (() => {
        if (!src) return "image";
        if (
          typeof src === "string" &&
          (src.endsWith(".svg") || src.endsWith("/Vector")) /* special case for media URLs on Blur if using a raw URL */
        ) {
          return "vector";
        }
        if (src instanceof MediaFile) return src.type;
        return "image";
      })();

      // When the src changes, preload and append the image to the DOM
      const urlRef = useRef(null);
      const densityRef = useRef(null);
      useEffect(() => {
        // Do not trigger another load if the URL is the same.
        // This avoids loading the image again each time an ancestor renders.
        if (url === urlRef.current && density === densityRef.current) return;
        urlRef.current = url;
        densityRef.current = density;

        const loadImage = (url) => {
          // We preload the image using a DOM element for two reasons:
          // - Images sometimes only partially load on iOS when using a react-created `<img onload>` and/or `new Image().onload`
          //   (see the approach used prior to this commit). For some unknown reason, this triggered only when a PIXI stage
          //   was present in the page, and only on iOS. The issue seemed to occur when fading-in images that were not yet
          //   fully loaded in the DOM. The problem disappeared when we disabled fade-in, but we took another approach to keep it.
          // - If we used an HTML Image the image would be loaded two times (even if already cached); once when loading
          //   Image and another time when setting `<img src>`.
          return new Promise((resolve, reject) => {
            if (!url) {
              resolve(null);
              return;
            }

            const image = document.createElement("img");
            image.crossOrigin = "anonymous";
            image.srcset = `${url} ${density}x`;
            image.src = url; // Fallback
            image.onload = () => resolve(image);
            image.onerror = () => reject();

            // Prevent dragging a ghost of the image in kiosk mode
            if (Config.interaction.mode === "kiosk") image.style["pointer-events"] = "none";
          });
        };

        loadImage(url)
          .then((image) => {
            if (!rootRef.current) return; // Image was disposed before the callback could execute

            // Remove the current element(s) if any
            [...rootRef.current.getElementsByClassName("loaded-image-internal")]?.forEach((e) =>
              rootRef.current.removeChild(e),
            );

            // Do not append if no image was loaded
            if (!image) return;

            const appendElement = (element) => {
              if (!rootRef.current) return;

              // Append the new element
              element.classList.add("loaded-image-internal");
              if (rootRef.current) rootRef.current.appendChild(element);

              if (fadeIn === "always") {
                element.style.opacity = 0;
                element.style.transition = "opacity 200ms ease-in-out";

                // Append the new element and fade it in after is has initially rendered with opacity 0.
                // Both `requestAnimationFrame()` and `setTimeout()` are required for the fade to work in Chrome and Safari iOS.
                requestAnimationFrame(() => setTimeout(() => (element.style.opacity = 1), 0));
              }

              // Running the callback asynchronously is important for the SVG ids to be fully altered
              // when the load callback (that may want to modify the SVG dynamically) is called.
              if (onLoad) setTimeout(onLoad);
            };

            if (srcType === "image" || srcType === "video") {
              // When the source is an image, append the img tag directly in the DOM
              appendElement(image);
            } else if (srcType === "vector") {
              // When the source is a vector image (SVG), inject it inline instead of loading it using img, so
              // that we can style it and access its contents with JavaScript (for example to animate specific SVG elements).
              // NOTE: When injecting SVG generated by SVGator that contains animations, the SVG must be generated with CSS
              // animations rather than JS animations, otherwise the IDs in the JS won't match the actual elements with their
              // `--inject` suffix (see below) and the animations won't play. If we want to support JS animations embedded by
              // SVGator in an SVG `<script>` tag, we will need to modify `svg-inject` further.
              // https://www.svgator.com/help/getting-started/what-export-options-are-available
              svgInject(image, {
                // Disabling caching ensures that afterLoad is called every time but the browser's cache still applies.
                // This should also reduce memory usage when displaying large/complex SVG.
                useCache: false,
                // We MUST set this to true (it is by default, but we explicitly document it here) else
                // inlined SVGs are sometimes broken (visually deformed) possibly due to the browser assuming
                // unique IDs in the whole DOM. To alter svg content, target classes using a "begins with" selector:
                // path[id^="mylayername--inject"] (the `--inject` part is important to avoid selecting too many things
                // because we're checking for a prefix)
                makeIdsUnique: true,
                afterLoad: (svg) => {
                  // Remove the `<title>` element if present, because it makes
                  // a native browser tooltip appear when hovering the mouse on the image.
                  const titleElement = svg.querySelector("title");
                  if (titleElement) titleElement.parentNode.removeChild(titleElement);

                  appendElement(svg);
                },
              });
            } else {
              Log.error(`Image: Unsupported image src type '${srcType}'`);
            }
          })
          .catch((error) => {
            Log.error(`Image: Load failed ${url}`, error);
          });
      }, [url, fadeIn, srcType, onLoad, density]);

      return (
        <div
          ref={mergeRefs([ref, rootRef])}
          className={Classes.build("ripple-image", { fade: fadeIn === "always" }, scaling, className)}
          style={style}
          id={id}
        >
          {/* Image will be inserted here dynamically after load */}
        </div>
      );
    },
  ),
);

RippleImage.propTypes = {
  src: MediaSrcPropType,
  className: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
  scaling: PropTypes.oneOf(["fit", "fill", "stretch"]),
  density: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]), // "auto" will automatically extract the density from the URL and fallback to 1 otherwise
  fadeIn: PropTypes.oneOf(["never", "always"]),
  onLoad: PropTypes.func, // Common interface for media readiness
};

export default RippleImage;
