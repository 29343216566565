import styled from "styled-components";
import { standardQuestionMaxContentSize } from "../../../common";
import Box from "../../../components/box";
import Question from "../../../components/question";
import StandardImage from "../../../components/standard-image";

export const Type2QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BigImageBox = styled(Box)`
  aspect-ratio: 1;
  @media screen and (orientation: landscape) {
    width: ${standardQuestionMaxContentSize};
  }
`;

export const BigImage = styled(StandardImage)`
  width: 100%;
  height: 100%;
`;
