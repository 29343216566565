import PropTypes from "prop-types";
import { memo, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import Classes from "../../../helpers/classes";
import Config from "../../../helpers/config";
import Styles from "../../../helpers/styles";
import Timeout from "../../../helpers/timeout";
import { MediaSrcPropType } from "../../../logic/prop-types";
import { useStagger } from "../../hooks/specialized/use-stagger";
import { useKeyboardShortcut } from "../../hooks/use-keyboard-shortcut";
import { RootBackgroundContext } from "../../root";
import Media from "../media";
import { StaggerContext } from "../stagger";
import { TransitionContext } from "../transitioner";

const Page = memo(
  ({
    name,
    className,
    style,
    children,
    staggerOptions = {},
    background,
    rootBackground,
    pauseTimeout /* In custom apps, use `useTimeoutPause` instead of this! */,
    ...rest
  }) => {
    const transitionContext = useContext(TransitionContext);
    const transitionState = transitionContext.state;

    const replaceRootBackground = useContext(RootBackgroundContext);
    const divRef = useRef(null);

    const stagger = useStagger({
      delay: 150, // Use a small delay by default to accomodate the page transition
      ...staggerOptions,
    });

    const shortcutOptions = useMemo(() => ({ type: "builtin" }), []);

    const onForcePageEnterStaggerShortcut = useCallback(() => stagger.enter({ reset: true }), [stagger]);
    useKeyboardShortcut("Force Page Enter Stagger", "ctrl|cmd+a", onForcePageEnterStaggerShortcut, shortcutOptions);

    const onForcePageEnterSlowStaggerShortcut = useCallback(
      () => stagger.enter({ reset: true, timeMultiplier: 3 }),
      [stagger],
    );
    useKeyboardShortcut(
      "Force Page Enter Stagger (Slow)",
      "ctrl|cmd+shift+a",
      onForcePageEnterSlowStaggerShortcut,
      shortcutOptions,
    );

    useEffect(() => {
      if (pauseTimeout) return Timeout.pause("Page", pauseTimeout);
    }, [pauseTimeout]);

    // Update the root background
    useEffect(() => {
      if (typeof rootBackground === "undefined") return;
      replaceRootBackground(rootBackground);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootBackground]);

    // Replace the root background when transitioning in.
    // This covers the case where we're interrupting a transition mid-flight (for example,
    // navigate from A to B then quickly from B to A before the first transition completes).
    // Page B is mounted and changes the background but page A is never unmounted, which results
    // in its "mount" not firing and it keeping page B's background, unless we watch for the page
    // transitioning back in before being unmounted.
    useEffect(() => {
      if (transitionState === "entering" && typeof rootBackground !== "undefined")
        replaceRootBackground(rootBackground);
    }, [transitionState, replaceRootBackground, rootBackground, name]);

    return (
      <div ref={divRef} {...rest} className={Classes.build("ripple-page", className)} style={Styles.merge(style)}>
        {/* We provide a "free" stagger instance but allow overriding it with an external
        one to provide custom options or to use the same stagger as another component. */}
        <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
          <StaggerContext.Provider value={stagger}>
            <Media
              scaling="fill"
              fadeIn="always"
              className="background-media"
              src={background}
              autoPlay={true}
              loop={true}
              muted={Config.audio.groups.rootBackground.muted}
            />
            {children}
          </StaggerContext.Provider>
        </DndProvider>
      </div>
    );
  },
);

Page.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  staggerOptions: PropTypes.object,
  background: MediaSrcPropType,
  rootBackground: MediaSrcPropType, // If undefined, the root background is left as-is. To remove the root background, pass `null`.
  pauseTimeout: PropTypes.any, // Soft-deprecated; use the `useTimeoutPause` in custom pages or components instead
};

export default Page;
