import { v4 as uuidv4 } from "uuid";
import Config from "./config";

function validateEventName(eventName) {
  const regex = /^([a-z0-9]+-?)+$/; // Accepts names such as: "event-name-xyz"
  const valid = regex.test(eventName);
  if (!valid) throw new Error(`Invalid event name ${eventName}`);
}

function generateUUID() {
  return uuidv4();
}

class Analytics {
  static _emit = null; // Must be set early on so that we can emit events in the stream
  static _sessionId = generateUUID(); // The initial session ID. Can change afterwards.
  static _instanceId = null;
  static _fingerprint = null;
  static _customStats = {
    numeric: [],
    proportions: [],
  };

  static _setup(emit) {
    Analytics._emit = emit;

    // Get the instance ID or generate it once if not present
    const itemName = "analytics-instance-id";
    let generatedInstanceId = localStorage.getItem(itemName);
    if (!generatedInstanceId) generatedInstanceId = generateUUID();
    localStorage.setItem(itemName, generatedInstanceId);
    Analytics._fingerprint = generatedInstanceId; // Always use the generated instance ID as a fingerprint

    // The config-specified instance ID takes precedence
    Analytics._instanceId = Config.analytics.instanceId || generatedInstanceId;
  }

  static _newSession() {
    Analytics._sessionId = generateUUID();
  }

  /**
   * Track an event (anything that occurs at a discrete moment in time) in the
   * Analytics subsystem for the purpose of generating aggregate analytics at a later time.
   * @param {string} eventName - A unique name to distinguish this type of event from other events
   * @param {object} userData - An object containing data that might be useful to generate aggregate analytics later
   */
  static track(eventName, userData) {
    if (!Config.analytics.enabled) return;
    validateEventName(eventName);

    const eventId = generateUUID();
    const time = new Date();

    const event = {
      eventId: eventId,
      sessionId: Analytics._sessionId,
      appId: Config.analytics.appId,
      instanceId: Analytics._instanceId,
      time: time,
      name: eventName,
      data: userData || {},
    };

    Analytics._emit(event);
  }
}

export default Analytics;
