import styled from "styled-components";
import { YellowButton } from "../../common";
import { Image, resource } from "ripple";

export const ConfirmButtonRoot = styled(YellowButton)`
  margin-top: 5vh;
  height: 6vh;
  width: 16vh;
  border-radius: 6vh;
  align-self: center;
`;

export const NextSymbol = styled(Image).attrs((props) => ({ src: resource("images/Button_Next_Symbol.svg") }))``;
