import PropTypes from "prop-types";
import { useParams } from "react-router";
import { useData } from "ripple";
import CardsGrid from "../../../components/cards-grid";
import FeedbackLayer from "../../../components/feedback-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import { useFindPairsQuestionLogic } from "../../../hooks/use-find-pairs-question-logic";
import {
  CardBox,
  CardButton,
  CardImage,
  CardSpeakerButton,
  CardTitle,
  CardTitleArea,
  ContentLayer,
  Type19QuestionRoot,
} from "./styled";

const Type19Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const color = node.wantedInheritedText("Color")?.trim();
  const cards = node.children;

  const { chosenCards, createOnCardClick, feedbacks } = useFindPairsQuestionLogic(cards, onComplete);

  return (
    <Type19QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent>
        <CardsGrid cards={cards}>
          {(card) => {
            const audio = card.optionalMedia("Audio", "Audio");
            const active = chosenCards.includes(card);
            return (
              <CardBox glow={active} $color={color}>
                <Layers>
                  <ContentLayer>
                    <CardImage src={card.wantedMedia("Image", "Image")} />
                    <CardButton onClick={createOnCardClick(card)} />
                    <CardTitleArea>
                      <CardTitle>{card.wantedText("Title")}</CardTitle>
                      {audio && <CardSpeakerButton src={audio} />}
                    </CardTitleArea>
                  </ContentLayer>
                  <FeedbackLayer feedback={feedbacks[card.id]} />
                </Layers>
              </CardBox>
            );
          }}
        </CardsGrid>
      </ResponsiveContent>
    </Type19QuestionRoot>
  );
};

Type19Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type19Question;
