import { Html } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import PropTypes from "prop-types";
import { Suspense, forwardRef, memo, useCallback, useContext, useImperativeHandle, useRef } from "react";
import Classes from "../../../helpers/classes";
import { MediaSrcPropType } from "../../../logic/prop-types";
import RippleContext from "../../core/ripple-context";
import InteractionHint from "../internal/interaction-hint";
import Pin from "./components/pin";
import Scene from "./components/scene";

const Panorama = memo(
  forwardRef(({ className, src, muted, children, hint, animateHint, hintShowDelay, hintHideDelay, ...rest }, ref) => {
    const rippleContext = useContext(RippleContext);
    const hintRef = useRef(null);
    const hideHint = useCallback(() => hintRef.current.hide(), []);
    useImperativeHandle(ref, () => ({ hideHint }), [hideHint]);
    return (
      <div {...rest} className={Classes.build("ripple-panorama", className)} onPointerDown={hideHint}>
        <Canvas camera={{ position: [0, 0, 0.1] }} frameloop="demand">
          <RippleContext.Provider value={rippleContext}>
            <Suspense
              fallback={
                <Html center>
                  <div className="panorama-spinner" />
                </Html>
              }
            >
              <Scene src={src} muted={muted}>
                {children}
              </Scene>
            </Suspense>
          </RippleContext.Provider>
        </Canvas>
        <InteractionHint ref={hintRef} animate={animateHint} showDelay={hintShowDelay} hideDelay={hintHideDelay}>
          {hint}
        </InteractionHint>
      </div>
    );
  }),
);

Panorama.propTypes = {
  className: PropTypes.string,
  src: MediaSrcPropType,
  muted: PropTypes.bool,
  children: PropTypes.node,
  hint: PropTypes.oneOfType([PropTypes.node, MediaSrcPropType]),
  animateHint: PropTypes.bool,
  hintShowDelay: PropTypes.number,
  hintHideDelay: PropTypes.number,
};

Panorama.Pin = Pin;

export default Panorama;
