import Env from "./env";
import Log from "./log";
import Toast from "./toast";

export default class API {
  static _isStarted = false;

  static _isNotStarted() {
    if (!API._isStarted) {
      Log.warn("Cannot perform operation because API is not listening");
      return true;
    }
    return false;
  }

  // Setup

  /** Starts the API on the provided port */
  static _start(port) {
    if (!Env.isREC) return;
    API._isStarted = true;
    Env._sendToContainer("api-start", port);
  }

  static messageSubscribe(name, onReceive) {
    if (!Env.isREC || API._isNotStarted()) return;
    if (!name) throw new Error("HTTP message name not provided when subscribing!");
    return Env._subscribeToReceiveFromContainer("api-message", (data) => {
      // If the expected schema isn't found warn about that with a usage hint
      if (!data.name) {
        Toast.warn(
          `Invalid API message format:\n${JSON.stringify(data)}\n\nValid format:\n{ name: "myevent", data: { ... } }`,
          6000,
        );
        return;
      }

      // If the received message is not for us, skip it
      if (data.name !== name) return;

      onReceive(data.data);
    });
  }

  /** Unsubscribe a toggle switch subscription. The subscription object that must be provided to this function is
   *  obtained by storing the return value of a `toggleSwitchSubscribe` call. */
  static messageUnsubscribe(listener) {
    if (!listener) return;
    if (!Env.isREC || API._isNotStarted()) return;
    Env._unsubscribeToReceiveFromContainer("api-message", listener);
  }
}
