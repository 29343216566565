import { useCallback, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { useFeedback } from "./use-feedback";

const getItemTargets = (item) => {
  return item.identifier?.split(",").map((t) => t.trim()) ?? [];
};

export const useDragItemsOnTargetsQuestionLogic = (targets, items, onComplete, options) => {
  options = { showPermanentFeedbackOnTargetCompletion: true, ...options };
  const { feedbacks, addPermanentFeedback, addMomentaryFeedback } = useFeedback();

  const [completedTargets, setCompletedTargets] = useState([]);
  const [completedTargetsDelayed, setCompletedTargetsDelayed] = useState([]);

  const [drops, updateDrops] = useImmer(
    targets.reduce((acc, target) => {
      acc[target.id] = [];
      return acc;
    }, {}),
  );

  const [overs, updateOvers] = useImmer({});
  const onOver = useCallback(
    (isOver, target) => {
      updateOvers((draft) => {
        draft[target.id] = isOver;
      });
    },
    [updateOvers],
  );

  const getTargetItems = useCallback(
    (target) => {
      return items.filter((i) => {
        const destinations = getItemTargets(i);
        return destinations.includes(target.identifier);
      });
    },
    [items],
  );

  const checkTargetCompleted = useCallback(
    (target, newDrops = null) => {
      newDrops = newDrops ?? drops;
      return getTargetItems(target).every((i) => newDrops[target.id].includes(i));
    },
    [drops, getTargetItems],
  );

  const onDrop = useCallback(
    (target, item) => {
      const itemDestinations = getItemTargets(item);
      if (itemDestinations.includes(target.identifier)) {
        if (drops[target.id].includes(item)) return;
        updateDrops((draft) => {
          const newTargetDrops = [...(draft[target.id] ?? []), item];
          draft[target.id] = newTargetDrops;
          if (checkTargetCompleted(target, draft)) {
            if (options.showPermanentFeedbackOnTargetCompletion) {
              addPermanentFeedback(target.id, "good");
            } else {
              addMomentaryFeedback(target.id, "good");
            }
            setCompletedTargets((state) => [...state, target]);
            setTimeout(() => setCompletedTargetsDelayed((state) => [...state, target]), 1750);
          }
        });
      } else {
        addMomentaryFeedback(target.id, "bad");
      }
    },
    [
      addMomentaryFeedback,
      addPermanentFeedback,
      checkTargetCompleted,
      drops,
      options.showPermanentFeedbackOnTargetCompletion,
      updateDrops,
    ],
  );

  useEffect(() => {
    if (
      targets.every((target) => {
        const targetItems = getTargetItems(target);
        return targetItems.every((targetItem) => drops[target.id].includes(targetItem));
      })
    ) {
      onComplete();
    }
  }, [onComplete, targets, drops, items, getTargetItems]);

  return {
    feedbacks,
    getTargetItems,
    getItemTargets,
    onOver,
    overs,
    onDrop,
    drops,
    completedTargets,
    completedTargetsDelayed,
  };
};
