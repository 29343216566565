import PropTypes from "prop-types";
import { useEffect } from "react";
import { Audio, Classes, resource, useConstant } from "ripple";
import { v4 as uuidv4 } from "uuid";
import { useEffectAfterMount } from "../../hooks/use-effect-after-mount";
import { useIsPortrait } from "../../hooks/use-is-portrait";
import { QuestionMascotContainerRoot } from "./styled";

const QuestionMascotContainer = ({ className, style, show, ...rest }) => {
  const isPortrait = useIsPortrait();

  const id = useConstant(() => uuidv4());

  useEffect(() => {
    if (!show) return;
    setTimeout(() => Audio.discrete("effects").play(resource(`audio/SwooshIn.mp3`)), 400);
  }, [id, show]);

  // Using "after mount" prevents the swoosh out audio from playing when initially hidden
  useEffectAfterMount(() => {
    if (show) return;
    Audio.discrete("effects").play(resource(`audio/SwooshOut.mp3`));
  }, [id, show]);

  return (
    <QuestionMascotContainerRoot
      {...rest}
      className={Classes.build(className, { centered: isPortrait }, { side: !isPortrait })}
      style={style}
      show={show}
      showPointerEvents={"none"}
    />
  );
};

QuestionMascotContainer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  show: PropTypes.bool,
};

export default QuestionMascotContainer;
