import _ from "lodash";
import { Node } from "mediaclient.js";
import Localization from "../helpers/localization";
import Log from "../helpers/log";
import MediaFile from "../model/media-file";

//
// NOTE: For Ripple to do its job properly, it's important to use the
// appropriate functions to obtain texts and medias:
//
// - Use the "required" functions when the data MUST be present for code that depends
//   on it to run properly (Ripple "fails early" when the data is missing, simplifying diagnosics)
//
// - Use the "wanted" functions for things that SHOULD be present but MAY not be present when doing
//   data entry. Using "wanted" functions logs warnings and display placeholders for missing data, keeping the app functional.
//
// - Use the "optional" functions for data that CAN be present but is optional, and for which the app
//   has an appropriate fallback mechanism in case of missing data.
//

// Recursively inherit things from parent nodes

function inherit(node, selector, ignore = () => false) {
  const thing = selector(node);
  if (thing && !ignore(thing)) return thing;

  const parent = node.parent;
  if (!parent) return null;

  return inherit(parent, selector, ignore);
}

function getSpecifiedOrCurrentLanguage(language) {
  return language || Localization.getCurrentLanguage();
}

/**
 * Obtain the localized metadata for a given semantic from a list of metadatas
 * (where a metadata is anything that has a semantic and a language)
 * */
function getLocalized(metadatas, semantic, language, fallback, checkValidity) {
  const matches = _.filter(metadatas, (m) => m.semantic === semantic);
  if (matches.length === 0) return null;

  // We take the exact language if it's there
  const localized = _.find(matches, (t) => t.language === language);
  if (localized && checkValidity(localized)) return localized;

  // If fallback is disabled, don't try anything else
  if (!fallback) return null;

  // Fallback to neutral language
  const neutral = _.find(matches, (t) => t.language === "zx");
  if (neutral && checkValidity(neutral)) return neutral;

  // Fallback to any language in the order in which they are defined
  return _.find(matches, (m) => checkValidity(m)) || null;
}

/**
 * Obtain the localized Text instance (if any) for the provided arguments.
 * @param {Node} The node to get the text from
 * @param {String} The required text semantic name
 * @param {Language} The language we're looking for
 * @param {boolean=true} Whether to fallback to neutral and other languages or not
 */
function getText(node, semantic, language, fallback = true) {
  if (typeof language === "undefined") throw new Error(`Language was not provided for getText() call!`);
  return getLocalized(node.texts, semantic, language, fallback, (text) => text.value.trim() !== "");
}

/**
 * Obtain the localized media info (if any) for the provided media semantic and <tt>Language</tt>.
 * @param {Node} The node to get the media from
 * @param {String} The required media semantic name
 * @param {Language} The language we're looking for
 * @param {boolean=true} Whether to fallback to neutral and other languages or not
 */
function getMedia(node, semantic, language, fallback = true) {
  if (typeof language === "undefined") throw new Error("Language was not provided in Node getMedia() call");

  const media = getLocalized(node.medias, semantic, language, fallback, (media) => true);
  if (!media) return { media: null, getUrl: (format) => null };

  // It's the user's responsibility to get the URL for the required
  // formats once the media itself has been retrieved.
  const getUrl = (format) => node.addons.buildMediaUrl(media, format);

  return { media, getUrl };
}

// Text

Node.prototype.requiredText = function (semantic, language = null, fallback) {
  const string = this.optionalText(semantic, language, fallback);
  if (!string)
    throw new Error(
      `Required text of semantic '${semantic}' not found on node of type '${this.semantic}' (${this.id})`,
    );
  return string;
};

Node.prototype.wantedText = function (semantic, language = null, fallback) {
  const string = this.optionalText(semantic, language, fallback);
  if (!string) {
    Log.warn(`Wanted text of semantic '${semantic}' not found on node of type '${this.semantic}' (${this.id})`);
  }
  return string;
};

Node.prototype.optionalText = function (semanticDescriptor, language = null, fallback) {
  const actualLanguage = getSpecifiedOrCurrentLanguage(language);

  // Allows passing an array of semantics instead of a single semantic, for automatic fallback if non-existent.
  // Makes this:
  //   node.optionalText(["ExternalTitle", "Title"])
  // Equivalent to this:
  //   const externalTitle = itemNode.optionalText("ExternalTitle");
  //   const title = itemNode.wantedText("Title");
  //   const src = externalTitle ?? title;
  const semanticsByDescSpecificity = Array.isArray(semanticDescriptor) ? semanticDescriptor : [semanticDescriptor];

  for (let i = 0; i < semanticsByDescSpecificity.length; i++) {
    const semantic = semanticsByDescSpecificity[i];
    const text = getText(this, semantic, actualLanguage, fallback);
    if (text) return text.value;

    // If nothing is found by the end of the loop, consider the missing text to be the less specific semantic
    // (most often it is less specific because it is the default semantic, thus more likely to exist)
    if (i === semanticsByDescSpecificity.length - 1) return text?.value ?? null;
  }
};

Node.prototype.wantedInheritedText = function (semantic, language = null, fallback) {
  const text = this.optionalInheritedText(semantic, language, fallback);
  if (!text) {
    Log.warn(
      `Wanted inherited text of semantic '${semantic}' not found from node of type '${this.semantic}' (${this.id})`,
    );
  }
  return text;
};

Node.prototype.optionalInheritedText = function (semantic, language = null, fallback) {
  return inherit(this, (n) => n.optionalText(semantic, language, fallback));
};

Node.prototype.ifText = function (semantic, language = null, fallback) {
  const text = this.optionalText(semantic, language, fallback);
  return (rendering) => (text ? rendering(text, semantic, language) : null);
};

Node.prototype.ifInheritedText = function (semantic, language = null, fallback) {
  const text = this.optionalInheritedText(semantic, language, fallback);
  return (rendering) => (text ? rendering(text, semantic, language) : null);
};

// Media

const cachedMediaFiles = {};

Node.prototype.requiredMedia = function (semanticDescriptor, formatDescriptor, language = null, fallback) {
  const media = this.optionalMedia(semanticDescriptor, formatDescriptor, language, fallback);
  if (!media)
    throw new Error(
      `Required media of semantic '${semanticDescriptor}' and format ${JSON.stringify(
        formatDescriptor,
      )} not found on node of type '${this.semantic}' (${this.id})`,
    );
  return media;
};

Node.prototype.wantedMedia = function (semanticDescriptor, formatDescriptor, language = null, fallback) {
  const media = this.optionalMedia(semanticDescriptor, formatDescriptor, language, fallback);
  if (!media) {
    Log.warn(
      `Wanted media of semantic '${semanticDescriptor}' and format ${JSON.stringify(
        formatDescriptor,
      )} not found on node of type '${this.semantic}' (${this.id})`,
    );
  }
  return media;
};

Node.prototype.optionalMedia = function (semanticDescriptor, formatDescriptor, language = null, fallback) {
  if (typeof formatDescriptor !== "string" && typeof formatDescriptor !== "object")
    throw new Error(`Invalid format descriptor ${formatDescriptor}`);

  const actualLanguage = getSpecifiedOrCurrentLanguage(language);

  const getMediaFile = (semantic) => {
    const { media, getUrl } = getMedia(this, semantic, actualLanguage, fallback);
    const key = `media-file:${this.id}:${media?.id ?? "null"}:${semantic}:${JSON.stringify(
      formatDescriptor,
    )}:${actualLanguage}`;

    if (!media) return null;

    // We use a cache to provide the same instance for multiple calls given the same combination
    // of arguments. Having a cache like this avoids direct instance comparison, and incidentally
    // unnecessary renders of media components and other subtle issues. The cache is stored in the node
    // itself so that it gets flushed when data changes and nodes are replaced with newer versions.

    // If a MediaFile instance already exists for this combination of arguments, return the existing
    // instance. We use the node's built-in `storage` object as a cache.
    const existingMediaFile = cachedMediaFiles[key];
    if (existingMediaFile) return existingMediaFile;

    const newMediaFile = new MediaFile(media, this.id, semantic, formatDescriptor, actualLanguage, getUrl);
    cachedMediaFiles[key] = newMediaFile;

    return newMediaFile;
  };

  // Allows passing an array of semantics instead of a single semantic, for automatic fallback if non-existent.
  // Makes this:
  //   node.optionalMedia(["AlbumThumbnail", "Media"], "AlbumThumbnail")
  // Equivalent to this:
  //   const explicitThumbnail = itemNode.optionalMedia("AlbumThumbnail", "AlbumThumbnail");
  //   const implicitThumbnail = itemNode.wantedMedia("Media", "AlbumThumbnail");
  //   const src = explicitThumbnail ?? implicitThumbnail;
  const semanticsByDescSpecificity = Array.isArray(semanticDescriptor) ? semanticDescriptor : [semanticDescriptor];
  for (let i = 0; i < semanticsByDescSpecificity.length; i++) {
    const mediaFile = getMediaFile(semanticsByDescSpecificity[i]);
    if (mediaFile) return mediaFile;

    // If nothing is found by the end of the loop, consider the missing media to be the less specific semantic
    // (most often it is less specific because it is the default semantic, thus more likely to exist)
    if (i === semanticsByDescSpecificity.length - 1) return mediaFile;
  }
};

Node.prototype.wantedInheritedMedia = function (semantic, formatDescriptor, language = null, fallback) {
  const media = this.optionalInheritedMedia(semantic, formatDescriptor, language, fallback);
  if (!media) {
    Log.warn(
      `Wanted inherited media of semantic '${semantic}' and format ${JSON.stringify(
        formatDescriptor,
      )} not found from node of type '${this.semantic}' (${this.id})`,
    );
  }
  return media;
};

Node.prototype.optionalInheritedMedia = function (semantic, formatDescriptor, language = null, fallback) {
  return inherit(this, (n) => n.optionalMedia(semantic, formatDescriptor, language, fallback));
};

Node.prototype.ifMedia = function (semanticDescriptor, formatDescriptor, language = null, fallback) {
  const media = this.optionalMedia(semanticDescriptor, formatDescriptor, language, fallback);
  return (rendering) => (media ? rendering(media, semanticDescriptor, formatDescriptor, language) : null);
};

Node.prototype.ifInheritedMedia = function (semanticDescriptor, formatDescriptor, language = null, fallback) {
  const media = this.optionalInheritedMedia(semanticDescriptor, formatDescriptor, language, fallback);
  return (rendering) => (media ? rendering(media, semanticDescriptor, formatDescriptor, language) : null);
};

// Settings

Node.prototype.requiredSetting = function (key) {
  const value = this.optionalSetting(key);
  if (!value) {
    throw new Error(`Required setting with key '${key}' not found on node of type '${this.semantic}' (${this.id})`);
  }
  return value;
};

Node.prototype.wantedSetting = function (key) {
  const value = this.optionalSetting(key);
  if (!value) {
    Log.warn(`Wanted setting with key '${key}' not found on node of type '${this.semantic}' (${this.id})`);
  }
  return value;
};

Node.prototype.optionalSetting = function (key) {
  return this.settings[key];
};

Node.prototype.wantedInheritedSetting = function (key, neutralValue) {
  const value = this.optionalInheritedMedia(key, neutralValue);
  if (!value) {
    Log.warn(`Wanted inherited setting with key '${key}' not found on node of type '${this.semantic}' (${this.id})`);
  }
};

Node.prototype.optionalInheritedSetting = function (key, neutralValue) {
  if (!neutralValue) throw new Error("Neutral value must be specified for setting inheritance to work.");
  return inherit(
    this,
    (n) => n.optionalSetting(key),
    (v) => v === neutralValue,
  );
};
