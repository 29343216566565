import _ from "lodash";
import ShortcutsActions from "../../actions/local/shortcuts-actions";

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ShortcutsActions.ADD_SHORTCUT:
      return _.assign({}, state, {
        [action.id]: {
          keyEquivalent: action.keyEquivalent,
          type: action.shortcutType,
          description: action.description,
          handler: action.handler,
        },
      });
    case ShortcutsActions.REMOVE_SHORTCUT: {
      const copy = _.cloneDeep(state);
      delete copy[action.id];
      return copy;
    }
    default:
      return state;
  }
};
