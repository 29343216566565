import PropTypes from "prop-types";
import { useCallback } from "react";
import { useParams } from "react-router";
import { DragPreview, Dropzone, MediaTransitioner, useData } from "ripple";
import AudioTextDroppable from "../../../components/audio-text-droppable";
import FeedbackLayer from "../../../components/feedback-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import SpeakerButtonLayer from "../../../components/speaker-button-layer";
import TextDragPreview from "../../../components/text-drag-preview";
import { useDragItemsOnTargetsQuestionLogic } from "../../../hooks/use-drag-items-on-targets-question-logic";
import { QuestionPanelContent } from "../styled";
import { Target, Targets, Type4QuestionRoot } from "./styled";

const Type4Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));

  const targets = node.children.find((c) => c.semantic === "Type4QuestionTargets").children;
  const items = node.children.find((c) => c.semantic === "Type4QuestionItems").children;

  const { feedbacks, onOver, overs, onDrop, drops, completedTargetsDelayed } = useDragItemsOnTargetsQuestionLogic(
    targets,
    items,
    onComplete,
    { showPermanentFeedbackOnTargetCompletion: false },
  );

  const renderPanelContent = (location) => {
    return (
      <QuestionPanelContent $location={location}>
        {items.map((item) => {
          return (
            <AudioTextDroppable
              key={item.id}
              node={item}
              disabled={Object.values(drops)
                .flatMap((d) => d)
                .includes(item)}
            />
          );
        })}
      </QuestionPanelContent>
    );
  };

  const renderDragPreview = useCallback((item) => <TextDragPreview text={item.wantedText("Title")} />, []);

  const renderTarget = (target) => {
    const completed = completedTargetsDelayed.includes(target);
    return (
      <Target key={target.id} $wide={targets.length < 2} glow={overs[target.id]}>
        <Layers>
          <MediaTransitioner
            src={target.wantedMedia(completed ? "ImageB" : "ImageA", "Image")}
            classNames="crossfade"
            mediaProps={{ scaling: "fill" }}
          />
          <Dropzone disabled={completed} id={target} onOver={onOver} onDrop={onDrop} />
          <SpeakerButtonLayer src={target.optionalMedia("Audio", "Audio")} />
          <FeedbackLayer feedback={feedbacks[target.id]} />
        </Layers>
      </Target>
    );
  };

  return (
    <Type4QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent
        sidePanelContent={renderPanelContent("side")}
        bottomPanelContent={renderPanelContent("bottom")}
      >
        <Targets>{targets.map(renderTarget)}</Targets>
      </ResponsiveContent>
      <DragPreview render={renderDragPreview} />
    </Type4QuestionRoot>
  );
};

Type4Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type4Question;
