import PropTypes from "prop-types";
import { useGlobalAudioPlayer } from "../../hooks/use-global-audio-player";
import {
  MascotImage,
  Phylactery,
  PhylacteryContainer,
  SongActivityCardContentRoot,
  SongAnim,
  StyledSpeakerButton,
} from "./styled";

const SongActivityCardContent = ({ className, style, activity, ...rest }) => {
  const audio = activity.wantedMedia("Audio", "Audio");

  const { playing, src: globalAudioSrc } = useGlobalAudioPlayer();

  return (
    <SongActivityCardContentRoot {...rest} className={className} style={style}>
      <PhylacteryContainer>
        <Phylactery />
        <SongAnim $show={playing && globalAudioSrc === audio} />
        <StyledSpeakerButton src={audio} />
      </PhylacteryContainer>
      <MascotImage src={activity.wantedInheritedMedia("MascotImage", "PreserveSizeWithAlpha")} />
    </SongActivityCardContentRoot>
  );
};

SongActivityCardContent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  activity: PropTypes.object,
};

export default SongActivityCardContent;
