import Sequencer from "../sequencer";

class DoWaitForPromiseTask {
  constructor(promise, onFulfill, onReject) {
    this.promise = promise;
    this.onFulfill = onFulfill;
    this.onReject = onReject;
  }

  perform(release) {
    this.promise.then(
      (value) => {
        this.onFulfill?.(value);
        release();
      },
      (reason) => {
        this.onReject?.(reason);
        release();
      },
    );
  }

  cancel(release) {
    // Allows monkey-patching an ES6 promise with a cancel function that will
    // automatically be invoked by the sequencer when it cancels this task.
    // Details: http://stackoverflow.com/a/25346945/167983
    if (typeof this.promise.cancel !== "undefined") this.promise.cancel();
    release();
  }
}

Sequencer.prototype.doWaitForPromise = function (promise, onFulfill, onReject) {
  this.push(new DoWaitForPromiseTask(promise, onFulfill, onReject));
  return this;
};
