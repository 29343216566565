import PropTypes from "prop-types";
import { MediaSrcPropType } from "ripple";
import { standardButtonDistanceFromBoxCorner } from "../../common";
import { SpeakerButtonLayerRoot, StyledSpeakerButton } from "./styled";

const SpeakerButtonLayer = ({
  className,
  style,
  src,
  corner = 4,
  inset = standardButtonDistanceFromBoxCorner,
  ...rest
}) => {
  return (
    <SpeakerButtonLayerRoot {...rest} className={className} style={style}>
      {src && <StyledSpeakerButton src={src} $corner={corner} $inset={inset} />}
    </SpeakerButtonLayerRoot>
  );
};

SpeakerButtonLayer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  src: MediaSrcPropType,
  corner: PropTypes.number,
  inset: PropTypes.string,
};

export default SpeakerButtonLayer;
