import Emitter from "component-emitter";
import { Promise } from "es6-promise";
import Url from "url";
import CloudInfo from "./cloud-info";
import ServerInfo from "./server-info";

/**
 * This is a workaround for the fact that in some fetch / http request implementations
 * the port number defaults to the current tab's port, which may not match
 * the port where the server can actually be reached.
 */
function urlByAddingPortIfMissing(url) {
  let urlObject = Url.parse(url);

  // eslint-disable-next-line no-self-assign
  urlObject.port = urlObject.port;

  if (urlObject.protocol === "https:" && !urlObject.port) urlObject.port = 443;

  urlObject.host = null; // Only hostname must be set for the port to be picked up
  return urlObject.format();
}

function rejectOnTimeout(ms, promise) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => reject(new Error(`Request timed out after ${ms} ms`)), ms);
    promise.then(
      (result) => {
        clearTimeout(timeoutId);
        resolve(result);
      },
      (error) => {
        clearTimeout(timeoutId);
        reject(error);
      },
    );
  });
}

let ApiClient = function ({ dataSource, mediaSource, server, cloud }) {
  let serverInfo = new ServerInfo(server.url, server.apiVersion);
  let cloudInfo = new CloudInfo(cloud.url, cloud.namespace);

  let that = this;

  function getInfo(source) {
    if (source === "server") return serverInfo;
    if (source === "cloud") return cloudInfo;
    throw new Error(`Unsupported source ${source}`);
  }

  function getRequiresAuthentication() {
    if (dataSource === "server") return true;
    if (dataSource === "cloud") return false;
    throw new Error(`Unsupported source ${dataSource}`);
  }

  function authenticatedRequest(authenticated, url, fetchOptions = {}, options = {}, ensure) {
    that.emit("request", url);

    const authOptions = {
      credentials: "include",
      headers: { Authorization: `Basic ${btoa(server.username + ":" + server.password)}` },
    };

    const otherOptions = {
      redirect: "manual",
    };

    const actualFetchOptions = authenticated ? { ...authOptions, ...otherOptions, ...fetchOptions } : fetchOptions;

    let requestPromise = fetch(urlByAddingPortIfMissing(url), actualFetchOptions)
      .then((response) => {
        // 1. Error out based on HTTP codes

        if (!response.ok) throw new Error(response.statusText);

        return response;
      })
      .then((response) => {
        // 2. Error out based on content validation

        // No validation required if the argument isn't provided
        if (typeof options.ensure === "undefined") return response;

        // Return a validated response promise
        return new Promise((resolve, reject) => {
          // Cloning the response allows us to test it
          // without consuming its contents permanently.
          options.ensure(response.clone()).then((valid) => {
            if (valid) {
              resolve(response);
            } else {
              reject(Error(`Response validation failed for URL '${url}'`));
            }
          });
        });
      });

    return rejectOnTimeout(options.timeout || 120000, requestPromise);
  }

  this.ping = function (timeout = null) {
    // Our API guarantees that a successful ping response won't be empty.
    // This additional check ensures that we catch ERR_CONNECTION_REFUSED errors
    // that are otherwise ignored by the Fetch API.
    let ensure = (reponse) => reponse.text().then((t) => t.startsWith("Pong!"));
    return authenticatedRequest(
      false,
      getInfo(dataSource).buildPingUrl(),
      {},
      {
        timeout: timeout,
        ensure: ensure,
      },
    );
  };

  this.fetchClientXml = function (id, timeout = null) {
    return authenticatedRequest(
      getRequiresAuthentication(),
      getInfo(dataSource).buildClientUrl(id),
      {},
      {
        timeout: timeout,
      },
    ).then((response) => response.text());
  };

  this.fetchClientsXml = function (timeout = null) {
    return authenticatedRequest(
      getRequiresAuthentication(),
      getInfo(dataSource).buildClientsUrl(),
      {},
      {
        timeout: timeout,
      },
    ).then((response) => response.text());
  };

  this.buildMediaUrl = function (...args) {
    return getInfo(mediaSource).buildMediaUrl(...args);
  };

  /**
   * Updates texts in batch on the server.
   * Provide a definition of texts to modify, like so:
   *
   * [
   *   {
   *     nodeId: "458a4b0b-cc1f-4c62-927f-1b2af8bba88f",
   *     semantic: "title",
   *     language: "en", // Optional
   *     value: "<p>example</p>",
   *     rawValue: "example" // Optional
   *   }
   * ]
   */
  this.updateTexts = function (definition) {
    return authenticatedRequest(true, serverInfo.buildUpdateTextsUrl(), {
      method: "POST",
      "Content-Type": "application/json",
      body: JSON.stringify(definition),
    });
  };
};

Emitter(ApiClient.prototype);

export default ApiClient;
