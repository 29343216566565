import Status from "../../../core/status";
import DataActions from "../../actions/local/data-actions";

export default (state = Status.LOADING, action) => {
  switch (action.type) {
    case DataActions.DATA_SERVER_UNREACHABLE:
      return Status.SERVER_UNREACHABLE;
    case DataActions.DATA_REQUESTED:
      return Status.DATA_REQUESTED;
    case DataActions.DATA_RECEIVED:
      return Status.DATA_RECEIVED;
    case DataActions.DATA_LOAD_LOCAL:
      return Status.DATA_LOCAL;
    case DataActions.DATA_LOAD_STATIC:
      return Status.DATA_STATIC;
    case DataActions.DATA_LOAD_NONE:
      return Status.DATA_NONE;
    case DataActions.DATA_ERROR:
      return Status.DATA_ERROR;
    case DataActions.DATA_DOWNLOADING_MEDIAS:
      return Status.DOWNLOADING_MEDIAS;
    default:
      return state;
  }
};
