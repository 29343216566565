import { Button, Revealer } from "ripple";
import styled from "styled-components";
import { StandardScroller, textColor, yellowColor } from "../../../common";
import CloseButton from "../../../components/close-button";

export const ResourcesSheetRoot = styled(Revealer).attrs((props) => ({ transition: "slide-in-and-out-from-bottom" }))`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - env(safe-area-inset-top));
  background-color: white;
  border-radius: 2vmin 2vmin 0 0;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin-top: -2vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4vmin;
`;

export const StyledCloseButton = styled(CloseButton)`
  margin-right: -2vmin;
  width: 7vmin;
  height: 7vmin;
`;

export const StyledScroller = styled(StandardScroller).attrs((props) => ({ fadeRatio: 0.05 }))`
  width: 100%;
  height: 100%;
  .scroller-content {
    padding: 4vmin;
  }
`;

export const ResourceSwitcher = styled.div`
  margin-top: -4vmin;
  background-color: ${textColor};
  height: 4vmax;
  align-self: center;
  border-radius: 4vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ResourceButton = styled(Button)`
  height: 100%;
  font-size: 2vmax;
  border-radius: 4vmin;
  padding: 0 2.5vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: all 250ms ease-in-out;
  background-color: ${(props) => (props.$active ? yellowColor : "transparent")};
`;
