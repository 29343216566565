const socketIoClient = require("socket.io-client");

class IpcClient {
  static initialized = false;
  static id = null;
  static socket;

  static connect(
    url,
    id,
    { onConnect, onClients, onRestoreSharedState, onAction, onUpdateRemoteState, registerCustomEvents = () => {} },
  ) {
    if (this.initialized) {
      if (console) console.warn("Already connected to an IPC server!");
      return;
    }
    this.initialized = true;

    if (console) console.info(`IpcClient: Connecting to ${url} ...`);

    this.id = id;
    this.socket = socketIoClient(url, { transports: ["websocket"] });

    this.socket.on("connect", () => {
      if (console) console.info(`IpcClient: Connected to ${url}`);
      if (onConnect) onConnect();

      // Send info about this client to the server immediately
      // after the connection is established
      this.socket.emit("client-info", this.id);
    });

    this.socket.on("disconnect", () => {
      if (console) console.warn(`IpcClient: Disconnected from ${url}`);
    });

    this.socket.on("clients", onClients);
    this.socket.on("restore-shared-state", onRestoreSharedState);
    this.socket.on("update-remote-state", onUpdateRemoteState);
    this.socket.on("action", onAction);

    registerCustomEvents(this.socket);
  }

  static sendInitialRemoteState(state) {
    this.socket.emit("initial-remote-state", state);
  }

  static updateRemoteState(state) {
    this.socket.emit("update-remote-state", state);
  }

  static undoLastRemoteStateUpdate() {
    this.socket.emit("undo-remote-state");
  }

  static resetToInitialRemoteState() {
    this.socket.emit("reset-remote-state");
  }

  static clearRemoteStateHistory() {
    this.socket.emit("clear-remote-state-history");
  }

  static dispatch(payload) {
    // Socket.io seems to have a pre-connection emit queue (emits are performed
    // after connecting), so no need to do anything special to ensure emits are
    // not sent in the void if performed too early
    this.socket.emit("action", { id: this.id, payload });
  }
}

module.exports = IpcClient;
