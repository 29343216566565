import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Config, Strings, Timeout, usePrevious } from "ripple";
import { Background, Countdown, GracePopupRevealer, Popup, PopupButton, PopupButtons, Title } from "./styled";

const GracePopup = memo(() => {
  const grace = useSelector((state) => state.timeout.grace);
  const pathname = useSelector((state) => state.router.location.pathname);

  const [show, setShow] = useState(false);
  const previousGrace = usePrevious(grace);
  const previousPathname = usePrevious(pathname);

  useEffect(() => {
    const enteringGracePeriod = !previousGrace && grace;
    const onHomepage = pathname === Config.home;
    const wasNotPreviouslyOnHomepage = previousPathname !== Config.home;

    if (enteringGracePeriod && !onHomepage) {
      setShow(true);
    } else if (onHomepage && wasNotPreviouslyOnHomepage) {
      setShow(false);
    }
  }, [previousGrace, grace, pathname, previousPathname]);

  const continueButtonPressed = useCallback(() => {
    // The timeout counter will reset itself because
    // there is an interaction when pressing the button,
    // so we only need to hide the overlay.
    setShow(false);
  }, []);

  const restartButtonPressed = useCallback(() => {
    setShow(false);
    Timeout.force();
  }, []);

  return (
    <GracePopupRevealer show={show}>
      <Background>
        <Popup>
          <Title>{Strings.localized("TimeoutGracePopupTitle")}</Title>
          <Countdown>{grace || 0}</Countdown>
          <PopupButtons>
            <PopupButton onClick={continueButtonPressed}>
              {Strings.localized("TimeoutGracePopupContinueButton")}
            </PopupButton>
            <PopupButton onClick={restartButtonPressed}>
              {Strings.localized("TimeoutGracePopupRestartButton")}
            </PopupButton>
          </PopupButtons>
        </Popup>
      </Background>
    </GracePopupRevealer>
  );
});

export default GracePopup;
