const ShortcutsActions = {
  ADD_SHORTCUT: "@@local/ADD_SHORTCUT",
  addShortcut: (id, keyEquivalent, shortcutType, description, handler) => {
    return { type: ShortcutsActions.ADD_SHORTCUT, id, keyEquivalent, shortcutType, description, handler };
  },
  REMOVE_SHORTCUT: "@@local/REMOVE_SHORTCUT",
  removeShortcut: (id) => {
    return { type: ShortcutsActions.REMOVE_SHORTCUT, id };
  },
};

export default ShortcutsActions;
