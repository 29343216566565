import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";
import { Grid, useConstant, useData } from "ripple";
import { isGoodAnswer } from "../../../../logic/logic";
import Box from "../../../components/box";
import ButtonLayer from "../../../components/button-layer";
import FeedbackLayer from "../../../components/feedback-layer";
import ImageLayer from "../../../components/image-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import SpeakerButtonLayer from "../../../components/speaker-button-layer";
import { useFeedback } from "../../../hooks/use-feedback";
import { useIsPortrait } from "../../../hooks/use-is-portrait";
import { CustomGrid, Item, Type1QuestionRoot } from "./styled";

const Type1Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const items = node.children;
  const columns = (() => {
    if (items.length <= 8) return 2;
    return 3;
  })();
  const isPortrait = useIsPortrait();
  const isExtremelyPortrait = useMediaQuery({ query: "(max-aspect-ratio: 1/1.8)" });
  const rows = Math.ceil(items.length / columns);
  const itemHeight = (() => {
    if (!isPortrait) return `calc(65vh / ${Math.max(2, rows)})`;
    if (columns === 1) return "80vw";
    if (columns === 2) return isExtremelyPortrait ? "40vw" : "32vw";
    if (columns === 3) return "30vw";
  })();
  const allGoodItems = useConstant(() => items.filter((i) => isGoodAnswer(i)));

  const [currentAcceptedItems, setCurrentAcceptedItems] = useState([]);
  const { feedbacks, addPermanentFeedback, addMomentaryFeedback } = useFeedback();

  const createOnItemClick = (item) => () => {
    setCurrentAcceptedItems((items) => [...items, item]);
    if (isGoodAnswer(item)) {
      addPermanentFeedback(item.id);
    } else {
      addMomentaryFeedback(item.id, "bad");
    }
  };

  useEffect(() => {
    const completed = allGoodItems.every((item) => currentAcceptedItems.includes(item));
    if (completed) onComplete();
  }, [allGoodItems, currentAcceptedItems, onComplete]);

  return (
    <Type1QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent>
        <CustomGrid layout={Grid.layout.columns(columns)}>
          {items.map((item) => (
            <Item key={item.id} node={item} $height={itemHeight}>
              <Box appearance="active">
                <Layers>
                  <ImageLayer src={item.wantedMedia("Image", "Image")} />
                  <FeedbackLayer feedback={feedbacks[item.id]} />
                  <ButtonLayer onClick={createOnItemClick(item)} />
                  <SpeakerButtonLayer src={item.optionalMedia("Audio", "Audio")} />
                </Layers>
              </Box>
            </Item>
          ))}
        </CustomGrid>
      </ResponsiveContent>
    </Type1QuestionRoot>
  );
};

Type1Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type1Question;
