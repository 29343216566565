export default (src) => {
  if (!src) return null;

  // Find the type based on the extension if the provided src is an URL
  if (typeof src === "string") {
    if (/\.(jpg|jpeg|png|webp|bmp|gif)$/.test(src)) return "image";
    if (/\.(mp4|m4v|mpg|mpeg|mov)$/.test(src)) return "video";
    throw new Error(`Can't guess src kind for: ${src}`);
  }

  // From there we can assume that we're working with a Media instance
  return src.type;
};
