import { Grid } from "ripple";
import styled from "styled-components";
import { standardGap } from "../../common";

export const SquareItemGridPanelContentRoot = styled(Grid)`
  gap: ${standardGap};
  .grid-row {
    gap: ${standardGap};
  }
`;

export const Item = styled.div`
  @media screen and (orientation: landscape) {
    height: 12vh;
    width: 12vh;
  }
  @media screen and (orientation: portrait) {
    height: 22vmin;
    width: 22vmin;
  }
  & > * {
    width: 100%;
    height: 100%;
  }
`;
