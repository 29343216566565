import Sequencer from "../sequencer";

class DoWaitForReleaseTask {
  constructor(action) {
    this.action = action;
  }

  perform(release) {
    // The caller is provided with the release() function directly.
    // The use of an handle is an internal implementation detail.
    this.action(release);
  }

  cancel(release) {
    release();
  }
}

Sequencer.prototype.doWaitForRelease = function (action) {
  this.push(new DoWaitForReleaseTask(action));
  return this;
};
