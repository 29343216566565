import { Promise } from "es6-promise";
import _ from "lodash";
import YAML, { Scalar } from "yaml";
import Log from "../helpers/log";

export default class YamlResource {
  layers = []; // Contains a raw object for each loaded layer
  values = {}; // Contains the values of all layers merged together in sequence

  fetchLocal(url) {
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => resolve(xhr.response);
      xhr.onerror = () => reject(new TypeError(`Failed to fetch local file from ${url}`));
      xhr.open("GET", url);
      xhr.send(null);
    });
  }

  loadLayer(url, optional) {
    const nullLayer = { url: url, optional: optional, document: null };
    return this.fetchLocal(url)
      .then((text) => {
        const document = YAML.parseDocument(text);
        if (document.contents instanceof Scalar) return nullLayer;
        return { url: url, optional: optional, document: document };
      })
      .catch((error) => {
        if (!optional) {
          Log.error(`Could not load resource file '${url}'`);
          throw new Error(`Could not load '${url}' (${error})`);
        }
        return nullLayer;
      });
  }

  loadFromURLs(...urls) {
    const loads = _.map(urls, (url) => {
      const optionalToken = "[optional]";
      const optional = url.endsWith(optionalToken);
      const cleanUrl = url.replace(optionalToken, "").trim();
      return this.loadLayer(cleanUrl, optional);
    });

    return Promise.all(loads).then((layers) => {
      this.layers = layers; // Keep layers for raw access
      _.each(layers, (layer) => {
        if (layer.document !== null) {
          const optionalSuffix = layer.optional ? " [optional]" : "";
          const resourceName = `${layer.url}${optionalSuffix}`;
          this.mergeValues(layer.document.toJS());
          Log.info(`Loaded resource '${resourceName}'`);
        } else {
          Log.info(`Skipped optional resource '${layer.url}' (not present)`);
        }
      });
    });
  }

  mergeValues(obj) {
    _.mergeWith(this.values, obj, (objValue, srcValue) => {
      // Just replace arrays instead of trying to merge them!
      if (objValue instanceof Array) return srcValue;
    });
  }
}
