import PropTypes from "prop-types";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from "react";
import Classes from "../../../../helpers/classes";
import MediaFile from "../../../../model/media-file";
import { useTimeout } from "../../../hooks/use-timeout";
import Image from "../../image";

const InteractionHint = memo(
  forwardRef(
    (
      {
        className,
        style,
        children,
        animate = true,
        showDelay = 500,
        hideDelay = null, // Make the hint auto-disappear after a delay instead of only after manipulation.
        ...rest
      },
      ref,
    ) => {
      const [show, setShow] = useState(false);

      useEffect(() => {
        const timeout = setTimeout(() => setShow(true), showDelay);
        return () => clearTimeout(timeout);
      }, [showDelay]);

      useTimeout(() => setShow(false), show ? hideDelay : null);

      const hide = useCallback(() => setShow(false), []);

      useImperativeHandle(ref, () => ({ hide }), [hide]);

      return (
        <div {...rest} className={Classes.build("ripple-interaction-hint", { show })} style={style}>
          <div className={Classes.build("interaction-hint-content", { animate: animate })}>
            {(() => {
              if (typeof children === "string" || children instanceof MediaFile) return <Image src={children} />;
              return children;
            })()}
          </div>
        </div>
      );
    },
  ),
);

InteractionHint.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  show: PropTypes.bool,
  animate: PropTypes.bool,
  showDelay: PropTypes.number,
  hideDelay: PropTypes.number,
};

export default InteractionHint;
