import { lighten } from "polished";
import { Text } from "ripple";
import styled from "styled-components";
import { textColor } from "../../common";
import SpeakerButton from "../speaker-button";

export const AudioTextRowRoot = styled.div`
  margin: 0.7vh 1.2vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1vh;
`;

export const VerticalSplitter = styled.div`
  width: 2px;
  height: 100%;
  background-color: ${(props) => props.$color};
  pointer-events: none;
`;

export const Title = styled(Text)`
  font-size: 3vh;
  pointer-events: none;
  margin-right: 0.8vh;

  b,
  strong {
    color: ${lighten(0.5, textColor)};
    font-weight: normal;
  }
`;

export const StyledSpeakerButton = styled(SpeakerButton)`
  .hotspot {
    margin: -1.4vh;
  }
`;
