import { lighten } from "polished";
import { Button, Droppable, Dropzone, Grid, Image, resource, staggerStep, Text } from "ripple";
import styled from "styled-components";
import { textColor, titleColor } from "../../../common";
import Question from "../../../components/question";
import SpeakerButton from "../../../components/speaker-button";
import WordButton from "../../../components/word-button";

export const DragWordSimpleQuestionRoot = styled(Question)``;

export const Card = staggerStep({ transition: "appear-floating" })(styled.div`
  position: relative;
  align-self: center;

  @media screen and (orientation: landscape) {
    width: 45vh;
    height: 63vh;
  }

  @media screen and (orientation: portrait) {
    width: 37.5vh;
    height: 52.7vh;
  }

  margin-top: 0.8vh;
  box-shadow: 0 0 0 0.8vh ${(props) => props.color};
  border-radius: 2vh;
  transition: all 150ms ease-in-out;
  background-color: ${(props) => lighten(0.3, props.color)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
`);

export const CardImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 80%;
  width: 100%;
  &.clicked {
    transform: scale3d(0.98, 0.98, 1);
  }
`;

export const CardImage = styled(Image).attrs((props) => ({ scaling: "fill", fadeIn: "always" }))`
  height: 80%;
  width: 100%;
  transition: opacity 150ms ease-in-out;
  opacity: 1;
`;

export const SentenceBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  height: 20%;
  width: 100%;
  background-color: ${(props) => lighten(0.4, props.color)};
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.5vh 2vh;
  gap: 1vh;
`;

export const WordText = staggerStep({ transition: "" })(styled(Text)`
  background-color: ${(props) => lighten(0.3, props.color)};
  border: 0.4vh solid ${(props) => props.color};
  border-radius: 0.8vh;
  color: ${textColor};
  font-size: 3vh;
  line-height: 3.5vh;
  text-align: center;
  padding: 0 0.5vh;
  transition: all 150ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
`);

export const QuestionMarkImage = styled(Image).attrs((props) => ({
  fadeIn: "always",
  src: resource("images/QuestionMark.svg"),
}))`
  position: absolute;
  top: 0.4vh;
  height: 3vh;
  width: 100%;
`;

export const DropzoneText = staggerStep({ transition: "" })(styled(Text)`
  background-color: ${(props) => lighten(0.3, props.color)};
  border: 0.4vh dashed ${(props) => props.color};
  border-radius: 0.8vh;
  color: ${(props) => lighten(0.3, props.color)};
  font-size: 3vh;
  line-height: 3.5vh;
  padding: 0 0.5vh;
  transition: all 150ms ease-in-out;
  opacity: ${(props) => (props.inactive ? 0.5 : 1)};
`);

export const DropzoneContainer = styled.div`
  position: relative;
  height: 3.9vh;
`;

export const WordDropzone = styled(Dropzone)`
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -3.9vh;
  &.over {
    box-shadow: 0 0 2vmax 0.4vmax ${(props) => props.color};
  }
`;

export const WordSentence = styled(Text)`
  font-family: "ComicNeue-Light";
  color: ${textColor};
  font-size: 3.3vh;
`;

export const BoldWordSentence = styled(WordSentence)`
  font-family: "ComicNeue-Bold";
`;

export const CardSpeakerButton = styled(SpeakerButton)`
  position: absolute;

  width: 7vh;
  height: 7vh;

  right: 2.5vh;
  bottom: 2.5vh;
  &.clicked {
    transform: scale3d(0.9, 0.9, 1);
  }

  .hotspot {
    margin: -100vw;
  }
`;

export const ChoiceWordButton = styled(WordButton)`
  opacity: ${(props) => (props.hasDrop ? 0.3 : 1)};
`;

export const ButtonContentPreview = styled.div`
  justify-content: center;
`;

export const DroppableContainer = styled.div`
  position: relative;
  height: 6vh;
  .ripple-droppable {
    margin: 0 1vmax;
  }
`;

export const WordDroppable = styled(Droppable)`
  position: absolute;
  top: 0;
  height: 6vh;
  width: 100%;
  margin: 2vmin;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const WordTextDroppable = styled(Button)`
  color: ${titleColor};
  font-size: ${(props) => (props.size ?? 3) + "vh"};
  font-family: "ComicNeue-Bold";
  width: 100%;
  opacity: ${(props) => (props.show ? 1 : 0)};
  margin: 0 1vh;
  cursor: pointer;
`;

export const DroppableWord = styled(Droppable)`
  width: 100%;
  height: 6vh;
  margin: 2vmin;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const DroppableText = styled(Text)`
  color: ${titleColor};
  font-size: ${(props) => (props.size ?? 3) + "vh"};
  font-family: "ComicNeue-Bold";
  text-align: center;
  width: 100%;
`;

export const WordButtonContainer = staggerStep({ transition: "slide-in-and-fade-from-left" })(styled.div``);

export const LandscapeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5vh;
  margin-right: 3vh;
`;

export const PortraitButtonContainer = styled(Grid).attrs((props) => ({
  layout: (count) => {
    return Grid.layout.square()(count);
  },
}))`
  max-width: 100vw;
  margin: 2vh;
  .grid-row {
  }
`;
