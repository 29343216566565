import { useCallback, useRef } from "react";
import { useIsMobileVersion } from "./use-is-mobile-version";

export const useProgressStorage = () => {
  const progressRef = useRef(JSON.parse(localStorage.getItem("progress")) ?? {});
  const isMobileVersion = useIsMobileVersion();
  const addCompleted = useCallback(
    (node) => {
      // By not storing progress in the web version of the app, we let
      // the app fall back to its default navigation as if no progress was
      // made, which is what we want in this case.
      if (!isMobileVersion) return;

      progressRef.current[node.id] = true;
      localStorage.setItem("progress", JSON.stringify(progressRef.current));
    },
    [isMobileVersion],
  );

  const getCompletedTotal = useCallback(() => Object.keys(progressRef.current).length, []);

  const getCompleted = useCallback((node) => {
    return Object.keys(progressRef.current).includes(node.id);
  }, []);

  const getGameCompleted = useCallback((game) => {
    return game.children.every((c) => Object.keys(progressRef.current).includes(c.id));
  }, []);

  return { addCompleted, getCompleted, getGameCompleted, getCompletedTotal };
};
