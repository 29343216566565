import YamlResource from "../logic/yaml-resource";
import Localization from "./localization";
import resource from "./resource";

const yamlResource = new YamlResource();

export const loadStrings = async (...args) => {
  return await yamlResource.loadFromURLs(
    resource("data/core-strings.yml"),
    resource("data/custom-strings.yml"),
    resource("data/local-strings.yml[optional]"),
  );
};

export default class Strings {
  /** Returns the localized string that matches the provided resource key */
  static localized(resourceKey, language = null, fallbackLanguage = null) {
    const actualLanguage = language ?? Localization.getCurrentLanguage();
    const localizedString = Strings.optionalLocalized(resourceKey, actualLanguage, fallbackLanguage);
    if (!localizedString) return null;
    return localizedString;
  }

  /** Returns the localized string that matches the provided resource key, or null if not defined */
  static optionalLocalized(resourceKey, language = null, fallbackLanguage = null) {
    const actualLanguage = language ?? Localization.getCurrentLanguage();

    const obj = yamlResource.values[resourceKey];
    if (typeof obj === "undefined") return null;

    // Try to get the value for the exact language and
    // fallback on language neutral value if provided
    let localizedValue = obj[actualLanguage];
    if (!localizedValue) localizedValue = obj["zx"];
    if (!localizedValue) localizedValue = obj[fallbackLanguage];
    if (typeof localizedValue === "undefined") return null;

    return localizedValue;
  }
}
