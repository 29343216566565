import PropTypes from "prop-types";
import { useCallback } from "react";
import { useParams } from "react-router";
import { DragPreview, useData } from "ripple";
import Dropzone from "ripple/scripts/react/components/drop/dropzone";
import FeedbackLayer from "../../../components/feedback-layer";
import ImageAudioDroppable from "../../../components/image-audio-droppable";
import ImageDragPreview from "../../../components/image-drag-preview";
import ImageLayer from "../../../components/image-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import SpeakerButtonLayer from "../../../components/speaker-button-layer";
import SquareItemGridPanelContent from "../../../components/square-item-grid-panel-content";
import { useDragItemsOnTargetsQuestionLogic } from "../../../hooks/use-drag-items-on-targets-question-logic";
import { useIsPortrait } from "../../../hooks/use-is-portrait";
import { QuestionPanelContent } from "../styled";
import { ItemsLayer, Target, Targets, Type13QuestionRoot } from "./styled";

const Type13Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const isPortrait = useIsPortrait();

  const targets = node.children.find((c) => c.semantic === "Type13QuestionTargets").children;
  const items = node.children.find((c) => c.semantic === "Type13QuestionItems").children;

  const { feedbacks, onOver, overs, onDrop, drops } = useDragItemsOnTargetsQuestionLogic(targets, items, onComplete);

  const renderPanelContent = (location) => {
    return (
      <QuestionPanelContent $location={location}>
        <SquareItemGridPanelContent items={items} location={location}>
          {(item) => (
            <ImageAudioDroppable
              node={item}
              disabled={Object.values(drops)
                .flatMap((d) => d)
                .includes(item)}
            />
          )}
        </SquareItemGridPanelContent>
      </QuestionPanelContent>
    );
  };

  const renderMiniItem = (item) => <ImageDragPreview key={item.id} image={item.wantedMedia("Image", "Image")} />;

  const renderTarget = (target) => {
    const image = target.wantedMedia("Image", "Image");
    const audio = target.wantedMedia("Audio", "Audio");
    return (
      <Target key={target.id} $count={targets.length} $isPortrait={isPortrait} glow={overs[target.id]}>
        <Layers>
          <ImageLayer src={image} />
          <ItemsLayer>{drops[target.id].map(renderMiniItem)}</ItemsLayer>
          <Dropzone id={target} onOver={onOver} onDrop={onDrop} disabled={!!feedbacks[target.id]} />
          <SpeakerButtonLayer src={audio} />
          <FeedbackLayer feedback={feedbacks[target.id]} />
        </Layers>
      </Target>
    );
  };

  const renderDragPreview = useCallback(renderMiniItem, []);

  return (
    <Type13QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent
        sidePanelContent={renderPanelContent("side")}
        bottomPanelContent={renderPanelContent("bottom")}
      >
        <Targets>{targets.map(renderTarget)}</Targets>
      </ResponsiveContent>
      <DragPreview render={renderDragPreview} />
    </Type13QuestionRoot>
  );
};

Type13Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type13Question;
