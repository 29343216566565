import { useEffect } from "react";
import Phidgets from "../../../helpers/phidgets";
import { useLatestRef } from "../internal/use-latest-ref";

export const usePhidgetDigitalInput = (description, identification, onStateChange) => {
  const deviceSerialNumber = identification?.deviceSerialNumber;
  const isHubPortDevice = identification?.isHubPortDevice;
  const hubPort = identification?.hubPort;
  const channel = identification?.channel;

  const mostRecentCallbackRef = useLatestRef(onStateChange);

  useEffect(() => {
    if (!Phidgets.validateIdentification(deviceSerialNumber, isHubPortDevice, hubPort, channel)) return;
    const subscription = Phidgets.digitalInputSubscribe(
      description,
      { deviceSerialNumber, isHubPortDevice, hubPort, channel },
      (state) => {
        const mostRecentCallback = (...args) => mostRecentCallbackRef.current(...args);
        mostRecentCallback?.(state);
      },
    );
    return () => subscription.unsubscribe();
  }, [description, deviceSerialNumber, isHubPortDevice, hubPort, channel, mostRecentCallbackRef]);
};
