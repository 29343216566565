import styled from "styled-components";
import { standardQuestionLandscapeContentPadding, textColor } from "../../common";

export const QuestionRoot = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${standardQuestionLandscapeContentPadding};
  margin-bottom: calc(${standardQuestionLandscapeContentPadding} + env(safe-area-inset-bottom));
  color: ${textColor};
`;
