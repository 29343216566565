import { Button, Grid, Image, MediaTransitioner, Slider, Text, resource, staggerStep } from "ripple";
import styled from "styled-components";
import { StandardScroller, textColor } from "../../../common";

const gridGap = "2vmax";

export const ThemeContentRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BackgroundMediaTransitioner = styled(MediaTransitioner).attrs((props) => ({
  classNames: "fade-in-over-long",
  mediaProps: { scaling: "fill" },
}))`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const HeaderBox = styled(Button)`
  margin-top: ${gridGap};
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  width: calc(100% - ${gridGap} * 2);
  height: 7vh;
  align-self: center;
  border-radius: 1.4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderTitle = styled(Text)`
  font-size: 2vh;
`;

export const HeaderBottom = styled.div`
  position: relative;
  height: 2vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  display: ${(props) => (props.$show ? "flex" : "none")};
`;

export const HeaderSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  transition: opacity 150ms ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
`;

export const HeaderSubtitleIcon = styled(Image).attrs((props) => ({ src: resource("images/Download-Symbol.png") }))`
  height: 1.8vh;
  width: 1.8vh;
`;

export const HeaderSubtitleText = styled(Text)`
  margin-left: 1vh;
  font-size: 1.5vh;
`;

export const HeaderProgressBar = styled(Slider).attrs((props) => ({ disabled: true }))`
  position: absolute;
  width: 100%;
  height: 1vh;

  .track {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 0.5vh;
  }

  .fill {
    background-color: white;
  }

  transition: opacity 150ms ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
`;

export const ActivitiesScroller = styled(StandardScroller)`
  position: relative;
  flex: 1 1 0;
`;

export const ActivitiesGrid = staggerStep({ delayBefore: 1000 })(styled(Grid)`
  gap: ${gridGap};
  padding: ${gridGap};
  .grid-row {
    gap: ${gridGap};
  }
`);

export const Activity = staggerStep({ transition: "scale-up-fade" })(styled.div`
  width: calc((100% - ${(props) => (props.$columns === 1 ? "0vmax" : gridGap)}) / ${(props) => props.$columns});
  aspect-ratio: 4/3;
`);

export const ActivityWhiteBoxContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ActivityTitle = styled(Text)`
  font-size: 2vmax;
  color: ${textColor};
  text-align: center;
`;

export const ActivityStars = styled.div`
  width: 100%;
  margin-top: 1vmin;
  margin-bottom: 0.5vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.2vmax;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ActivityStarButton = styled(Button)`
  width: 13%;
  z-index: 1;
  .hotspot {
    margin: -2vmin;
  }
`;
