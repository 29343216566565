import { useEffect, useRef } from "react";
import { useLatestRef } from "./internal/use-latest-ref";

export const useUnmount = (onUnmount) => {
  const mostRecentCallbackRef = useLatestRef(onUnmount);
  const componentWillUnmount = useRef(false);

  useEffect(() => {
    return () => {
      componentWillUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    const mostRecentCallback = (...args) => mostRecentCallbackRef.current(...args);
    return () => {
      if (!componentWillUnmount.current) return;
      mostRecentCallback();
    };
  }, [mostRecentCallbackRef]);
};
