import { lighten } from "polished";
import { Button, Image, Selector, staggerStep, Text } from "ripple";
import styled from "styled-components";
import { CustomPage, RoundYellowButton, titleColor } from "../../common";
import BackButton from "../../components/back-button";
import ProgressPills from "../../components/progress-pills";
import SpeakerButton from "../../components/speaker-button";

export const Page = styled(CustomPage)``;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Overlay = staggerStep({ transition: "slide-in-and-out-from-left", order: 2 })(styled(Image).attrs(
  (props) => ({ fadeIn: "always", scaling: "fit" }),
)`
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  width: 30vmax;

  @media screen and (orientation: portrait) {
    display: none;
  }
`);

export const SelectorContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SelectorArrow = staggerStep({})(styled(Button)`
  width: 6vh;
  height: 6vh;
  z-index: 5;

  @media screen and (orientation: portrait) {
    display: none;
  }

  .hotspot {
    margin: -24vh -25vw;
  }

  &.disabled {
    opacity: 0;
  }

  .ripple-image {
    transform: scale(2, 2);
  }
`);

export const LeftSelectorArrow = styled(SelectorArrow)`
  position: absolute;
  top: 40vh;
  left: 4.5vmax;
`;
export const RightSelectorArrow = styled(SelectorArrow)`
  position: absolute;
  top: 40vh;
  right: 4.5vmax;
  transform: rotate3d(0, 0, 1, 180deg);
`;

export const CardsSelector = staggerStep({ transition: "slide-in-and-fade-from-right" })(styled(Selector)`
  height: 65vh;

  @media screen and (orientation: landscape) {
    width: 55vh;
  }
  @media screen and (orientation: portrait) {
    width: 45vh;
  }
`);

export const Card = styled(Button)`
  background-color: ${(props) => lighten(0.4, props.color)};
  border: 0.8vh solid ${(props) => props.color};
  width: 45vh;
  height: 100%;

  display: flex;
  flex-direction: column;
  border-radius: 4vh;
  overflow: hidden;
`;

export const CardImage = styled(Image).attrs((props) => ({ scaling: "fill", fadeIn: "always" }))`
  height: 85%;
  width: 100%;
  border-radius: 3.3vh 3.3vh 0 0;

  transition: opacity 150ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? "inherit" : "none")};
`;

export const CardBar = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 2vh;

  transition: opacity 150ms ease-in-out;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  pointer-events: ${(props) => (props.$show ? "inherit" : "none")};
`;

export const CardText = styled(Text)`
  margin-left: 3vh;
  color: ${titleColor};
  font-size: 4vh;
  line-height: 4vh;
`;

export const CardAudioSymbol = styled(RoundYellowButton).attrs((props) => ({ as: "div" }))`
  margin-left: auto;
  margin-right: 1vh;
  min-width: 7vh;
  width: 7vh;
  height: 7vh;
`;

export const CardProgressPills = staggerStep({ transition: "slide-in-and-fade-from-bottom" })(styled(ProgressPills)``);

export const CardSpeakerButton = styled(SpeakerButton)`
  min-width: 7vh;
  width: 7vh;
  height: 7vh;
`;

export const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: 3vmin;
  top: calc(env(safe-area-inset-top) + 3vmin);
  width: 8vmin;
  height: 8vmin;
`;
