import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { Audio, DragPreview, resource, useData } from "ripple";
import { useImmer } from "use-immer";
import { GoodImage } from "../../../common";
import { useIsPortrait } from "../../../hooks/use-is-portrait";
import {
  BoldWordSentence,
  ButtonContentPreview,
  Card,
  CardImage,
  CardImageContainer,
  CardSpeakerButton,
  ChoiceWordButton,
  Content,
  DragWordComplexeQuestionRoot,
  DroppableContainer,
  DropzoneContainer,
  DropzoneText,
  LandscapeButtonContainer,
  Line,
  PortraitButtonContainer,
  QuestionMarkImage,
  SentenceBar,
  WordButtonContainer,
  WordDroppable,
  WordDropzone,
  WordSentence,
  WordText,
  WordTextDroppable,
} from "./styled";

const DragWordComplexeQuestion = ({ onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const items = node.children;
  const buttonItems = useMemo(
    () => _.shuffle(node.children.filter((item) => item.semantic !== "LineBreak")),
    [node.children],
  );
  const client = useData((data) => data.root);
  const color = node.parent.wantedText("Color")?.trim();
  const audio = node.wantedMedia("Audio", "Audio");
  const isPortrait = useIsPortrait();
  const buttonContainerRef = useRef(null);
  const [offset, setOffset] = useState(0);

  const [goodAnswer, setGoodAnswer] = useState(false);
  const [drops, updateDrops] = useImmer({});
  const hasDroppedAll = Object.keys(drops).length === buttonItems.length;

  const line1 = _.takeWhile(items, (item) => item.semantic === "WordItem");
  let line2 = null;
  if (line1.length !== items.length) line2 = _.takeRightWhile(items, (item) => item.semantic === "WordItem");

  const onDragStart = useCallback(() => {}, []);

  const onTouch = useCallback(() => Audio.discrete("effects").play(resource("audio/ClicDrag.mp3")), []);

  const onDragEnd = useCallback(() => {
    Audio.discrete("effects").play(resource("audio/DragRelease.mp3"));
  }, []);

  const onDrop = useCallback(
    (dropzoneId, droppableId) => {
      if (droppableId.id === dropzoneId) {
        Audio.discrete("effects").play(resource("audio/Drop_Good.mp3"));
        updateDrops((draft) => {
          draft[dropzoneId] = droppableId;
        });
      } else {
        Audio.discrete("effects").play(resource("audio/Drop_Wrong.mp3"));
      }
    },
    [updateDrops],
  );
  useEffect(() => {
    setTimeout(() => {
      setOffset(buttonContainerRef?.current?.clientWidth);
    }, 10);
  }, []);

  useEffect(() => {
    if (!hasDroppedAll) return;
    setGoodAnswer(true);
    Audio.discrete("effects").play(resource("audio/ThumbUp01.mp3"));
    setTimeout(() => {
      onComplete();
    }, 1500);
  }, [client, hasDroppedAll, onComplete]);

  const createRenderDropzoneEmptyLayer = (item) => () => {
    return (
      !(item.id in drops) && (
        <div>
          <DropzoneText color={color} key={item.id}>
            {item.wantedText("Text")}
          </DropzoneText>
          <QuestionMarkImage />
        </div>
      )
    );
  };

  const createRenderDropzoneForegroundLayer = (item) => () => {
    return (
      <WordText show={true} color={color} key={item.id}>
        {item.wantedText("Text")}
      </WordText>
    );
  };

  const renderWordSentence = (word) => {
    const answer = word.settings.Answer === "good";
    return answer ? (
      <BoldWordSentence key={word.id}>{word.wantedText("Text")}</BoldWordSentence>
    ) : (
      <WordSentence key={word.id}>{word.wantedText("Text")}</WordSentence>
    );
  };

  const renderWordButton = (word) => {
    return (
      <DropzoneContainer key={word.id}>
        {/* HACK Add text invisible require to determine the size of the dropzone. */}
        <WordText show={false} color={color} key={word.id}>
          {word.wantedText("Text")}
        </WordText>
        <WordDropzone
          color={color}
          id={word.id}
          onDrop={onDrop}
          hasDropped={word.id in drops}
          renderEmptyLayer={createRenderDropzoneEmptyLayer(word)}
          renderForegroundLayer={createRenderDropzoneForegroundLayer(word)}
        />
      </DropzoneContainer>
    );
  };

  const createRenderDroppableForegroundLayer = (item) => () => (
    <WordTextDroppable show={true}>{item.wantedText("Text")}</WordTextDroppable>
  );
  const createRenderDroppableEmptyLayer = (item) => () => (
    <WordTextDroppable show={true}>{item.wantedText("Text")}</WordTextDroppable>
  );

  const renderChoiceWordButton = (item, index) => {
    const hasdrop = item.id in drops;
    return (
      <ChoiceWordButton item={item} key={item.id} color={color} hasDrop={hasdrop}>
        <DroppableContainer>
          {/* HACK Add text invisible require to determine the size of the dropzone. */}
          <WordTextDroppable show={false}>{item.wantedText("Text")}</WordTextDroppable>
          <WordDroppable
            key={item.id}
            id={item}
            onDragStart={onDragStart}
            onTouch={onTouch}
            onDragEnd={onDragEnd}
            hasDropped={item.id in drops}
            renderForegroundLayer={createRenderDroppableForegroundLayer(item)}
            renderEmptyLayer={createRenderDroppableEmptyLayer(item)}
          />
        </DroppableContainer>
      </ChoiceWordButton>
    );
  };

  const renderDragPreview = useCallback(
    (item) => (
      <ButtonContentPreview key={item.id} color={color}>
        <WordText show={true} color={color}>
          {item.wantedText("Text")}
        </WordText>
      </ButtonContentPreview>
    ),
    [color],
  );

  return (
    <DragWordComplexeQuestionRoot {...rest}>
      <Content $offset={-offset}>
        {!isPortrait && (
          <WordButtonContainer ref={buttonContainerRef}>
            <LandscapeButtonContainer>{buttonItems.map(renderChoiceWordButton)}</LandscapeButtonContainer>
          </WordButtonContainer>
        )}
        <Card color={color}>
          <CardImage src={node.wantedMedia("CardImage", "CardImage")} />
          <CardImageContainer color={color}>
            <CardSpeakerButton src={audio} />
            {goodAnswer && <GoodImage />}
          </CardImageContainer>

          <SentenceBar color={color}>
            {!goodAnswer && (
              <>
                <Line>{line1.map(renderWordButton)}</Line>
                {line2 && <Line>{line2.map(renderWordButton)}</Line>}
              </>
            )}
            {goodAnswer && (
              <>
                <Line>{line1.map(renderWordSentence)}</Line>
                {line2 && <Line>{line2.map(renderWordSentence)}</Line>}
              </>
            )}
          </SentenceBar>
        </Card>

        {isPortrait && <PortraitButtonContainer>{buttonItems.map(renderChoiceWordButton)}</PortraitButtonContainer>}
      </Content>
      <DragPreview render={renderDragPreview} />
    </DragWordComplexeQuestionRoot>
  );
};

DragWordComplexeQuestion.propTypes = { onComplete: PropTypes.func };

export default DragWordComplexeQuestion;
