import { Image, resource } from "ripple";
import styled from "styled-components";
import { RoundYellowButton } from "../../common";

export const SpeakerButtonRoot = styled(RoundYellowButton)`
  /* Default size */
  width: 3vh;
  min-width: 3vh;
  height: 3vh;
  min-height: 3vh;
`;

export const AudioImage = styled(Image).attrs((props) => ({ src: resource("images/Button_Audio_Symbol_Anim.svg") }))`
  padding: 0.2vh;

  svg {
    *[id^="button_audio_symbol_anim-speaker-anim--inject"] {
      animation: ${(props) =>
        props.$animate
          ? "button_audio_symbol_anim-speaker-anim_c_o 2000ms linear infinite normal forwards !important"
          : "none !important"};
    }

    *[id^="button_audio_symbol_anim-speaker-anim_ts--inject"] {
      animation: ${(props) =>
        props.$animate
          ? "button_audio_symbol_anim-speaker-anim_ts__ts 2000ms linear infinite normal forwards !important"
          : "none !important"};
    }
  }
`;
