import _ from "lodash";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { Maths, useData } from "ripple";
import ConfirmButton from "../../../components/confirm-button";
import FeedbackLayer from "../../../components/feedback-layer";
import ImageLayer from "../../../components/image-layer";
import Layers from "../../../components/layers";
import ResponsiveContent from "../../../components/responsive-content";
import SpeakerButtonLayer from "../../../components/speaker-button-layer";
import { useFeedback } from "../../../hooks/use-feedback";
import { BigImageBox, ButtonFill, RoundButton, RoundButtonBox, RoundButtonsRow, Type15QuestionRoot } from "./styled";

const Type15Question = ({ className, style, onComplete, ...rest }) => {
  const { id } = useParams();
  const node = useData((data) => data.requiredNode(id));
  const color = node.wantedInheritedText("Color")?.trim();

  const { feedbacks, addPermanentFeedback, addMomentaryFeedback } = useFeedback();

  const [filledCount, setFilledCount] = useState(0);

  const onConfirmClick = useCallback(() => {
    if (feedbacks[node.id]) return; // If already showing feedback, do nothing
    if (filledCount === parseInt(node.wantedText("Count"))) {
      addPermanentFeedback(node.id);
      onComplete();
    } else {
      addMomentaryFeedback(node.id, "bad");
    }
  }, [addMomentaryFeedback, addPermanentFeedback, feedbacks, filledCount, node, onComplete]);

  const createOnRoundButtonClick = (index) => () => {
    if (index >= filledCount) setFilledCount(Maths.clamp(filledCount + 1, 0, 6));
    else setFilledCount(Maths.clamp(filledCount - 1, 0, 6));
  };

  const renderRoundButton = (index) => {
    return (
      <RoundButton key={index} onClick={createOnRoundButtonClick(index)}>
        <RoundButtonBox>
          <ButtonFill $color={color} $show={index + 1 <= filledCount} />
        </RoundButtonBox>
      </RoundButton>
    );
  };

  return (
    <Type15QuestionRoot {...rest} className={className} style={style} node={node}>
      <ResponsiveContent>
        <BigImageBox>
          <Layers>
            <ImageLayer src={node.wantedMedia("Image", "Image")} />
            <SpeakerButtonLayer src={node.optionalMedia("Audio", "Audio")} />
            <FeedbackLayer feedback={feedbacks[node.id]} />
          </Layers>
        </BigImageBox>
        <RoundButtonsRow>{_.range(0, 6).map(renderRoundButton)}</RoundButtonsRow>
        <ConfirmButton disabled={filledCount === 0} onClick={onConfirmClick} />
      </ResponsiveContent>
    </Type15QuestionRoot>
  );
};

Type15Question.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default Type15Question;
