import Env from "./env";
import Log from "./log";

export default class NFC {
  static scan(onScan) {
    if (!Env.isRCC) {
      Log.warn("NFC operations are no-ops when not running in RCC");
      return;
    }

    /* eslint-disable no-undef */
    const onDetection = (event) => {
      nfc.removeNdefListener(onDetection);
      nfc.invalidateSession();
      const ndefRecord = event.tag.ndefMessage[0];

      // Obtain only the embedded string value by parsing the NDEF record
      // https://github.com/chariotsolutions/phonegap-nfc/issues/242#issuecomment-229734432
      const languageCodeLength = ndefRecord.payload[0];
      const code = nfc.bytesToString(ndefRecord.payload).substring(languageCodeLength + 1);

      Log.info(`NFC: Scanned code ${code}`);

      onScan(code);
    };

    nfc.beginSession();
    nfc.addNdefListener(onDetection, undefined, () => {
      nfc.removeNdefListener(onDetection);
      nfc.invalidateSession();
      Log.error("Failed to add NFC 'ndef' listener");
    });
    /* eslint-enable no-undef */
  }
}
