import PropTypes from "prop-types";
import { useContext } from "react";
import { resource } from "ripple";
import { QuestionContext } from "../question";
import { TagIcon, TagLayerRoot, TagRectangle } from "./styled";

const getResource = (icon) => {
  if (icon === "flip") return resource("images/Button_Flip_Symbol.svg");
  if (icon === "drag") return resource("images/Button_Drag_Symbol.svg");
  return null;
};

const TagLayer = ({ className, style, icon, ...rest }) => {
  const context = useContext(QuestionContext);
  return (
    <TagLayerRoot {...rest} className={className} style={style}>
      <TagRectangle>
        <TagIcon src={getResource(icon)} $color={context.color} />
      </TagRectangle>
    </TagLayerRoot>
  );
};

TagLayer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.string,
};

export default TagLayer;
