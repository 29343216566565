import DownloadProgressActions from "../../actions/local/download-progress-actions";

export default (state = null, action) => {
  switch (action.type) {
    case DownloadProgressActions.UPDATE_PROGRESS:
      return action.progress;
    default:
      return state;
  }
};
