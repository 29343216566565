import { fromEvent } from "rxjs";

const MediaClientObservables = {
  initialize: function (client) {
    return {
      request$: fromEvent(client, "request"),
    };
  },
};

export default MediaClientObservables;
