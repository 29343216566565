import { Dropzone, Text } from "ripple";
import styled from "styled-components";
import { standardGap } from "../../../common";
import Box from "../../../components/box";
import Layers from "../../../components/layers";
import Question from "../../../components/question";

export const Type16QuestionRoot = styled(Question)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BigBox = styled(Box)`
  @media screen and (orientation: landscape) {
    width: 55vh;
  }
`;

export const Top = styled.div`
  position: relative;
  aspect-ratio: 1;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

export const Dropzones = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${standardGap};
  gap: ${standardGap};
`;

export const DropzoneText = styled(Text)`
  font-size: 3vh;
`;

export const StyledDropzone = styled(Dropzone)`
  flex: 1;
  height: 7vh;
`;

export const DropzoneBox = styled(Box).attrs((props) => ({ appearance: "active" }))`
  width: 100%;
  height: 100%;
`;

export const StyledLayers = styled(Layers)`
  pointer-events: none;
`;
