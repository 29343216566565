export default class Sort {
  /** Returns a new object with its keys sorted in ascending alphabetical order */
  static keys(obj) {
    return Object.keys(obj)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: obj[key],
        }),
        {},
      );
  }
}
